<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e2"
    persistent
    width="420"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2"> Describe the problem </v-card-title>
      <v-divider />
      <v-card-text class="pt-4">
        <v-form ref="formRef">
          <v-row>
            <v-col v-if="computedProblems?.length" cols="12">
              <v-autocomplete
                v-model="form.problem"
                label="Problem"
                multiple
                :items="computedProblems"
                hide-details
                dense
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.comment"
                label="Comment"
                hide-details
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="handleClose">
          Cancel
        </v-btn>
        <v-btn
          large
          class="pl-4 pr-2 text-normal flat-chip flat-chip--warning"
          @click="handleConfirm"
        >
          Set status to
          <v-chip color="warning" dark class="ml-2">
            <span class="white--text">{{ recheckButtonData?.name }}</span>
          </v-chip>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";

export default {
  props: {
    recheckButtonData: {
      type: Object,
      default: () => ({}),
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ["confirm", "close"],

  mixins: [Dialog],

  data: () => ({
    form: {
      problem: [],
      comment: null,
    },
  }),

  computed: {
    computedProblems() {
      const problems =
        this.formData?.status?.problems?.filter((problem) => {
          return problem.sibling_id === this.recheckButtonData?.id;
        }) || [];

      return problems.map((problem) => {
        return {
          text: `${problem.category} / ${problem.problem}`,
          value: problem.id,
        };
      });
    },
  },

  methods: {
    resetForm() {
      this.form = {
        problem: null,
        comment: null,
      };
    },
    handleClose() {
      this.$emit("close");
      this.dialog = false;
    },
    handleConfirm() {
      this.$emit("confirm", this.form);
      this.dialog = false;
    },
  },
};
</script>
