<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="920"
    content-class="my-shadow--e2"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Create new status
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <status-form v-model="formData" ref="statusForm" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          @click="handleConfirm"
        >
          Create status
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import StatusForm from "./StatusForm.vue";

export default {
  components: { StatusForm },

  mixins: [Dialog],

  data: () => ({
    formData: {},
  }),

  methods: {
    resetForm() {
      this.$set(this, "formData", {});
    },
    handleConfirm() {
      this.resetForm();
      this.dialog = false;
    },
  },
};
</script>
