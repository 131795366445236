<template>
  <v-container fluid class="wd-v3" style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <div>
          <h2>Worksection Daily Report</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </div>
      </v-col>
      <v-col cols="12">
        <div class="mb-2 d-flex">
          <v-spacer />
          <v-tooltip bottom max-width="200">
            <template #activator="{ on }">
              <div v-on="on">
                <v-switch
                  v-model="isAutoUpdateEnabled"
                  hide-details
                  dense
                  class="ma-0"
                  label="Auto refresh"
                />
              </div>
            </template>
            <div>
              <div class="mb-2">
                <v-chip label small>{{ updateTimeoutTimer }}</v-chip> until
                update.
              </div>

              <div>
                Updates the dashboard every {{ timeoutTotalMinutes }} minutes to
                upload the latest data.
              </div>
            </div>
          </v-tooltip>
        </div>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.executive"
              multiple
              outlined
              hide-details
              dense
              :items="filtersData.executive"
              label="Executive"
              clearable
              :loading="loadings.filters"
              item-text="email"
              item-value="email"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-text-field
              v-model="filters.time"
              outlined
              hide-details
              clearable
              dense
              type="number"
              label="Time (m)"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.labels"
              outlined
              hide-details
              clearable
              dense
              :items="filtersData.labels"
              :loading="loadings.filters"
              label="Labels"
              multiple
              item-text="title"
              item-value="title"
            >
              <template #selection="{ item, index }">
                <span v-if="index === 0">{{ item.title }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ filters.labels.length - 1 }} more)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <div class="d-flex">
              <v-item-group class="my-group d-flex">
                <v-chip
                  title="Previous day"
                  style="height: 40px"
                  class="px-2"
                  outlined
                  label
                  @click="handlePrevDay"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-chip>
                <div>
                  <v-dialog v-model="dateModal" ref="dialog" max-width="320">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        solo
                        style="
                          border-radius: 0;
                          border-top: 1px solid rgba(155, 155, 155, 0.3);
                          border-bottom: 1px solid rgba(155, 155, 155, 0.3);
                          border-left: 1px solid rgba(155, 155, 155, 0.05);
                        "
                        hide-details
                        v-model="filters.date"
                        label="Date"
                        class="reset-box-shadow"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-card class="shadow-e1-bordered" rounded="lg">
                      <v-card-text class="px-0">
                        <v-date-picker
                          v-model="filters.date"
                          scrollable
                          full-width
                        >
                        </v-date-picker>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions class="pa-0">
                        <v-list dense style="width: 100%">
                          <v-list-item class="justify-center">
                            <small><b>When enter the page set to</b></small>
                          </v-list-item>
                          <v-list-item
                            class="justify-center"
                            @click="
                              () => {
                                filters.date_preset = 'yesterday';
                                collectDateFrom('yesterday');
                              }
                            "
                          >
                            Yesterday
                          </v-list-item>
                          <v-list-item
                            class="justify-center"
                            @click="
                              () => {
                                filters.date_preset = 'today';
                                collectDateFrom('today');
                              }
                            "
                          >
                            Today
                          </v-list-item>
                          <v-list-item
                            class="justify-center"
                            @click="
                              () => {
                                filters.date_preset = 'tomorrow';
                                collectDateFrom('tomorrow');
                              }
                            "
                          >
                            Tomorrow
                          </v-list-item>
                        </v-list>
                      </v-card-actions>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateModal = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="dateModal = false">
                          OK</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <v-chip
                  title="Next day"
                  style="height: 40px"
                  class="px-2"
                  outlined
                  label
                  @click="handleNextDay"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-chip>
              </v-item-group>
            </div>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-btn
              :loading="loadings.table"
              color="primary"
              block
              class="text-normal"
              style="height: 40px"
              @click="fetchData"
              >Get Data
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <template v-if="loadings.table">
        <v-col cols="12">
          <v-row>
            <v-col v-for="idx in [0, 1, 2]" :key="idx" cols="12">
              <v-card class="styled-card--default">
                <v-card-title class="d-flex justify-space-between">
                  <div
                    class="skeleton"
                    style="width: 120px; height: 32px"
                  ></div>
                  <div class="d-flex">
                    <div
                      class="skeleton"
                      style="width: 80px; height: 32px"
                    ></div>
                    <div
                      class="skeleton mx-2"
                      style="width: 80px; height: 32px"
                    ></div>
                    <div
                      class="skeleton"
                      style="width: 80px; height: 32px"
                    ></div>
                  </div>
                  <div class="d-flex">
                    <div
                      class="skeleton"
                      style="width: 80px; height: 32px"
                    ></div>
                    <div
                      class="skeleton ml-2"
                      style="width: 40px; height: 32px"
                    ></div>
                  </div>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-skeleton-loader type="table" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-else-if="!loadings.table && tableData.length !== 0">
        <v-col cols="12">
          <v-text-field
            v-model="globalSearch"
            hide-details
            dense
            placeholder="Type to search"
            append-icon="mdi-magnify"
            style="max-width: 320px"
          ></v-text-field>
        </v-col>
        <template v-for="(table, idx) in tableData">
          <v-col
            v-if="table.items && table.items.length > 0"
            :key="idx"
            cols="12"
          >
            <v-card
              :class="{
                'styled-card--dark': true,
                'mb-8': idx !== tableData.length - 1,
              }"
            >
              <v-card-title>
                <div>
                  {{ table.name }}
                </div>
                <v-spacer />
                <div>
                  <v-row dense class="align-center">
                    <v-col v-if="table.totalTime && idx === 0">
                      <v-chip label :color="getTimeColor(table.totalTime)">
                        total: {{ humanTime(table.totalTime) }}
                      </v-chip>
                    </v-col>
                    <v-col v-if="table.meetingTime">
                      <v-chip color="white" label outlined>
                        meeting {{ humanTime(table.meetingTime) }}
                      </v-chip>
                    </v-col>
                    <v-col v-if="table.regularTime">
                      <v-chip color="white" label outlined>
                        regular: {{ humanTime(table.regularTime) }}
                      </v-chip>
                    </v-col>
                    <v-col> </v-col>
                  </v-row>
                </div>
                <v-spacer />
                <div class="d-flex" style="gap: 8px">
                  <a
                    class="reset-link-styles"
                    :href="getMonthlyDashboardLink"
                    target="_blank"
                  >
                    <v-chip
                      class="white--text"
                      color="transparent"
                      label
                      @click="() => {}"
                    >
                      Monthly
                      <v-icon class="ml-2" small>mdi-open-in-new</v-icon>
                    </v-chip>
                  </a>
                  <a
                    class="reset-link-styles"
                    :href="getWeeklyDashboardLink"
                    target="_blank"
                  >
                    <v-chip
                      class="white--text"
                      color="transparent"
                      label
                      @click="() => {}"
                    >
                      Weekly
                      <v-icon class="ml-2" small>mdi-open-in-new</v-icon>
                    </v-chip>
                  </a>
                  <a
                    class="reset-link-styles"
                    :href="getWorksectionLink"
                    target="_blank"
                  >
                    <v-chip
                      c
                      class="white--text"
                      color="transparent"
                      label
                      @click="() => {}"
                    >
                      Worksection
                      <v-icon class="ml-2" small>mdi-open-in-new</v-icon>
                    </v-chip>
                  </a>
                  <v-menu
                    :close-on-content-click="false"
                    nudge-bottom="40"
                    content-class="remove-dialog-shadow"
                  >
                    <template #activator="{ on }">
                      <v-chip v-on="on" color="white" class="ml-2 px-2" label>
                        <v-icon small>mdi-filter</v-icon>
                        <v-icon small>mdi-chevron-down</v-icon>
                      </v-chip>
                    </template>
                    <v-card width="240" class="styled-card--default">
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Sort by"
                              dense
                              filled
                              hide-details
                              value="time"
                              :items="['default', 'time']"
                              @change="setSortBy($event)"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </v-card-title>
              <v-divider />
              <v-card-text class="pa-0">
                <v-data-table
                  :search="globalSearch"
                  :headers="headers"
                  :items="table.items"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  group-by="group_by"
                  :custom-sort="_$smartCustomTableSort"
                  :items-per-page="-1"
                  hide-default-footer
                  class="relative-tr"
                  multi-sort
                >
                  <template #[`group.header`]="{ isOpen, toggle, group }">
                    <td>
                      <v-btn icon @click="toggle" class="mr-2">
                        <v-icon>
                          {{
                            isOpen ? "mdi-chevron-down" : "mdi-chevron-right"
                          }}
                        </v-icon>
                      </v-btn>
                      {{ removeAlphabetPrefix(group) }}
                    </td>
                    <td :colspan="headers.length - 1"></td>
                  </template>
                  <template
                    v-for="(h, idx) in headers"
                    #[`item.${h.value}`]="{ value, item }"
                  >
                    <table-body-factory
                      :header-data="h"
                      :itemKey="h.value"
                      :data="value"
                      :table-data="table"
                      :row-data="item"
                      :key="h.value + idx"
                      :filters="filters"
                      @createSubtask="handleOpenSubtaskCreatingModel"
                      @closeTask="handleOpenCloseTaskDialog"
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </template>
        <v-col
          cols="12"
          v-if="
            !isContainOverdueTasks &&
            $moment(filters.date).isSame($moment().format('YYYY-MM-DD'))
          "
          class="d-flex justify-center"
        >
          <achievement-card />
        </v-col>
      </template>
      <template
        v-else-if="!loadings.table && tableData.length === 0 && isFetchedOnce"
      >
        <v-col cols="12" class="d-flex justify-center">
          <v-card class="styled-card--light">
            <v-card-title> No items for current filters </v-card-title>
            <v-divider />
            <v-card-text>
              <span style="opacity: 0.8">Change filters and try again...</span>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-dialog
      v-model="createTaskDialogModel"
      persistent
      content-class="remove-dialog-shadow"
      width="auto"
    >
      <v-card class="styled-card--default" rounded="lg">
        <v-card-title style="font-size: 14px">
          Create subtask
          <v-spacer />
          <v-btn icon @click="createTaskDialogModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="d-flex justify-center pt-4 px-0">
          <task-card
            :form="form"
            borderless
            :filters-data="filtersData"
            :loadings="loadings"
            @createTask="
              () => {
                handleCreateSubtask();
              }
            "
            disable-create-button
          />
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex">
          <v-spacer />
          <v-chip
            label
            color="transparent"
            class="error--text mr-2"
            @click="createTaskDialogModel = false"
          >
            Close</v-chip
          >
          <v-chip
            :disabled="loadings.createSubtask"
            color="success"
            style="width: 130px"
            class="d-flex justify-center"
            label
            @click="
              () => {
                handleCreateSubtask();
              }
            "
          >
            Create
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <close-task-dialog
      :model-value="closeTaskDialogModel"
      @update:modelValue="closeTaskDialogModel = $event"
      :form="closeTaskForm"
      :checkboxes="filtersData.checkboxes"
      @update:form="closeTaskForm = $event"
      @closeTask="handleCloseTask"
    />
  </v-container>
</template>

<script>
import { DAILY_REPORT_V3_HEADERS } from "@/utils/defaultData";
import TableSorting from "@/mixins/TableSorting";
import Location from "../../../../mixins/Location";
import TableBodyFactory from "@/components/Ltt/Dashboards/WorksectionDailyReportV3/TableBodyFactory.vue";
import QueryBuilder from "@/mixins/QueryBuilder";
import { deepClone } from "@/utils/functions";
import TaskCard from "@/components/SimilarTaskCreating/UI/TaskCard.vue";
import service from "@/plugins/service";
import AchievementCard from "@/components/UI/AchievementCard.vue";
import CloseTaskDialog from "@/components/Ltt/Dashboards/WorksectionDailyReportV3/CloseTaskDialog.vue";
import moment from "moment/moment";
import FiltersHandling from "../../../../mixins/components/FiltersHandling";
export default {
  components: {
    CloseTaskDialog,
    AchievementCard,
    TaskCard,
    TableBodyFactory,
  },
  mixins: [FiltersHandling, TableSorting, Location, QueryBuilder],
  data: () => ({
    closeTaskDialogModel: false,
    isFetchedOnce: false,
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Worksection",
        disabled: true,
      },
      {
        text: "Daily v3",
        disabled: true,
      },
    ],
    createTaskDialogModel: false,
    isSelectedFilterDateIsToday: false,
    isContainOverdueTasks: false,
    dateModal: false,
    sortBy: ["now", "priority", "weekly"],
    sortDesc: [true, true, false],
    globalSearch: null,
    tableData: [],
    createdTaskData: null,
    loadings: {
      table: false,
      createSubtask: false,
      filters: false,
      closeTask: false,
    },
    componentLocalId: "wdrv3-autoupdate",
    isAutoUpdateEnabled: false,
    timeoutTotalMinutes: 5,
    headers: DAILY_REPORT_V3_HEADERS,
    filters: {
      time: null,
      labels: [],
      executive: [],
      date: null,
      date_preset: "today",
    },
    form: {
      project_id: null, // required
      parent_task_id: null, // required
      reporter: null, // required
      task_title: null, // required
      task_desc: null,
      priority: 8,
      date: [],
      tags: [],
      estimated_time: null,
      estimated_money: null,
    },
    filtersData: {
      executive: [],
      labels: [],
      checkboxes: [],
    },
    updateTimeout: null,
    chartOptions: {
      chart: {
        type: "area",
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        width: 2,
        curve: "straight",
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
        marker: {
          show: false,
        },
      },
    },
    seriesTasksParentId: null,
    updateTimeoutTimer: "0:00",
    closeTaskForm: {
      rowData: {},
      checkboxes: [],
      bug_cause_description: null,
      refactoring_description: null,
    },
    taskTemplatesData: {
      need_details: {
        taskNamePrefix: "[Need Details] ",
        taskDescriptionPrefix: `<p>I need details in this task:</p>`,
        tags: [982082],
      },
      need_review: {
        taskNamePrefix: "[Need Review] ",
        taskDescriptionPrefix: `<p>I need review in this task:</p>`,
        tags: [382398],
      },
      testing_dev: {
        taskNamePrefix: "[!Testing: Dev] ",
        taskDescriptionPrefix: `<p>You can test this task on the dev. version:</p>`,
        date: [
          moment(new Date()).add(1, "d").startOf("day").format("YYYY-MM-DD"),
          moment(new Date()).add(1, "d").endOf("day").format("YYYY-MM-DD"),
        ],
        tags: [993995],
      },
      testing_prod: {
        taskNamePrefix: "[!Testing: Prod] ",
        taskDescriptionPrefix: `<p>You can test this task on the prod. version:</p>`,
        date: [
          moment(new Date()).add(1, "d").startOf("day").format("YYYY-MM-DD"),
          moment(new Date()).add(1, "d").endOf("day").format("YYYY-MM-DD"),
        ],
        tags: [994003],
      },
    },
  }),
  async mounted() {
    await this.parseQuery("filters");

    if (!this.$route.query.executive) {
      this.filters.executive = [this.$store.getters["auth/user"].email];
    }

    this.isAutoUpdateEnabled =
      localStorage.getItem(this.componentLocalId) === "true";

    if (this.$route.query.date_preset) {
      this.collectDateFrom(this.filters.date_preset);
    }

    if (!this.$route.query.date) {
      this.filters.date_preset = "today";
      this.collectDateFrom("today");
    }

    this.fetchFilters();
    this.fetchData();
  },
  watch: {
    createdTaskData(taskData) {
      if (!taskData) return;

      this.previewTaskDialogModel = true;
    },
    "filters.date"(newDate) {
      const date = this.$moment(newDate).format("YYYY-MM-DD");
      const today = this.$moment().format("YYYY-MM-DD");
      const yesterday = this.$moment()
        .subtract(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD");
      const tomorrow = this.$moment()
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD");

      if (date !== today && date !== yesterday && date !== tomorrow) {
        this.filters.date_preset = null;
        console.log("remove date preset");
      }
    },
    isAutoUpdateEnabled(value) {
      localStorage.setItem(this.componentLocalId, value);

      if (value) return this.startUpdateTimeout(this.timeoutTotalMinutes);

      this.clearUpdateTimeout();
    },
  },
  computed: {
    getMonthlyDashboardLink() {
      const route = this.$router.resolve({
        name: "monthly-report",
        query: {
          executive: this.filters.executive,
        },
      });

      return route.href;
    },
    getWeeklyDashboardLink() {
      const route = this.$router.resolve({
        name: "worksection.week.report",
        query: {
          executive: this.filters.executive,
          label: this.filters.labels[0],
        },
      });

      return route.href;
    },
    getWorksectionLink() {
      const date = this.$moment(this.filters.date).format("DD.MM.YYYY");

      const executives = [];

      if (this.filters.executive && this.filters.executive.length > 0) {
        this.filters.executive.forEach((executiveEmail) => {
          const userData = this.filtersData.executive.find(
            (v) => v.email === executiveEmail
          );

          if (userData) executives.push(userData.id);
        });
      }

      let usersQuery = "";

      if (executives.length > 0) {
        executives.forEach((id) => {
          usersQuery += `&fs_ut[]=${id}`;
        });
      }

      return `https://ra.worksection.com/priority/?order=important&desc=0&fs_ok=1&fs_pt[]=0&fs_pt[]=2&fs_pt[]=3&fs_st[]=0${
        usersQuery ? usersQuery : ""
      }&fs_dc1=${date}&fs_dc2=${date}&flt=1`;
    },
  },
  methods: {
    async handleCloseTask() {
      try {
        const url = "/worksection/task/close";
        const payload = {
          ...this.closeTaskForm.rowData,
          ...this.closeTaskForm,
        };

        delete payload.rowData;

        this.loadings.closeTask = false;

        await service.post(url, payload);
      } catch (e) {
        console.error("Error while closing task.", e);
      } finally {
        this.loadings.closeTask = false;
      }
    },
    handleOpenCloseTaskDialog(data) {
      this.closeTaskDialogModel = true;
      this.closeTaskForm.rowData = {
        project_id: data.project_id,
        task_id: data.id,
        parent_id: data.parent_id,
      };

      const tags = Object.values(data.tags);

      if (
        tags.includes("Bug detected") ||
        tags.includes("Bug") ||
        tags.includes("Need to fix (bug)") ||
        tags.includes("ProcessBug")
      ) {
        const IS_BUG_ID = 6;
        this.closeTaskForm.checkboxes.push(IS_BUG_ID);
      }
    },
    async handleCreateSubtask(taskData = null) {
      try {
        const url = "/worksection/task/create";

        const payload = {
          type: "CREATE_TASKS",
          main_task: taskData || this.form,
        };

        this.loadings.createSubtask = true;

        const resp = await service.post(url, payload);

        if (resp && resp.data.success) {
          this.createTaskDialogModel = false;
          this.createdTaskData = resp.data.task;

          return this.$message.notification({
            title: "Successfully created",
            text: `<a
                      href="${
                        "https://ra.worksection.com/" +
                        this.createdTaskData.page
                      }"
                      target="_blank"
                  >${this.createdTaskData.name}</a
                  >`,
            type: "success",
          });
        }

        this.$message.notification({
          title: "Something wrong.",
          text: resp.data.message,
          type: "error",
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.createSubtask = false;
      }
    },
    handleOpenSubtaskCreatingModel({ data, type }) {
      this.form.task_title = null;
      this.form.tags = null;
      this.form.task_desc = null;
      this.form.project_id = data.project_id;
      this.form.parent_task_id = data.id;

      const start = this.$moment(new Date())
        .startOf("day")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).endOf("day").format("YYYY-MM-DD");

      this.form.date = [start, end];
      this.form.reporter =
        data.creator_email || this.$store.getters["auth/user"].email;

      if (type === "empty") {
        return (this.createTaskDialogModel = true);
      }

      this.form.task_title =
        this.taskTemplatesData[type].taskNamePrefix + data.task_name.text;
      this.form.tags = this.taskTemplatesData[type].tags;
      this.form.task_desc =
        this.taskTemplatesData[type].taskDescriptionPrefix +
        `<a href="${data.task_name.value}" target="_blank">${data.task_name.value}</a>`;

      this.createTaskDialogModel = true;
    },
    collectDateFrom(date) {
      if (date === "today") {
        return (this.filters.date = this.$moment().format("YYYY-MM-DD"));
      }
      if (date === "yesterday" || !date) {
        this.filters.date = this.$moment()
          .subtract(1, "day")
          .startOf("day")
          .format("YYYY-MM-DD");
      }
      if (date === "tomorrow" || !date) {
        this.filters.date = this.$moment()
          .add(1, "day")
          .startOf("day")
          .format("YYYY-MM-DD");
      }
    },
    startUpdateTimeout(minutesOptions) {
      let totalSeconds = minutesOptions * 60; // Преобразуем минуты в секунды

      this.updateTimeout = setInterval(() => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        this.updateTimeoutTimer = `${minutes}:${
          seconds < 10 ? "0" : ""
        }${seconds}`;

        if (totalSeconds <= 0) {
          clearInterval(this.updateTimeout); // Останавливаем таймер, если достигнут 0
          this.fetchData();
          this.startUpdateTimeout(minutesOptions);
        } else {
          totalSeconds--; // Уменьшаем общее количество секунд на 1
        }
      }, 1000); // Обновляем каждую секунду (1000 миллисекунд)
    },
    clearUpdateTimeout() {
      clearTimeout(this.updateTimeout);
      this.updateTimeoutTimer = "0:00";
    },
    removeAlphabetPrefix(text) {
      const regex = /^[a-z]\.\s+/gi;
      return text.replace(regex, "");
    },
    handlePrevDay() {
      const tmprDate = this.$moment(this.filters.date);
      this.filters.date = tmprDate.subtract(1, "day").format("YYYY-MM-DD");
    },
    handleNextDay() {
      const tmprDate = this.$moment(this.filters.date);
      this.filters.date = tmprDate.add(1, "day").format("YYYY-MM-DD");
    },
    async fetchFilters() {
      try {
        this.loadings.filters = true;
        const resp = await this.$store.dispatch(
          "worksectionReportDaily/fetchFilters",
          {}
        );
        if (resp) {
          this.filtersData.executive = resp.executives;
          this.filtersData.labels = resp.labels;
          this.filtersData.checkboxes = resp.checkboxes;
        }
      } catch (e) {
        console.error("Error while loading filter items.", e);
      } finally {
        this.loadings.filters = false;
      }
    },
    setSortBy(type) {
      if (type === "time") {
        this.sortBy = ["now", "priority", "weekly"];
        this.sortDesc = [true, true, false];
        return;
      }

      this.sortBy = ["abc", "priority", "now", "priority_project"];
      this.sortDesc = [false, true, true, true];
    },
    getTimeColor(x) {
      const d = this.$moment.duration(x, "milliseconds");
      const h = Math.floor(d.asHours());

      if (h >= 5 && h <= 7) return "warning";

      if (h < 5) return "error";

      return "success";
    },
    humanTime(x) {
      const d = this.$moment.duration(x, "milliseconds");
      const h = Math.floor(d.asHours());
      const m = Math.floor(d.asMinutes()) - h * 60;

      if (!h && !m) return "";

      if (!h) return `${m}m`;

      return `${h}h ${m}m`;
    },
    processTableData(tableItems) {
      const tableItemsTmpr = deepClone(tableItems) || [];

      tableItemsTmpr.forEach((table) => {
        table.items = this.fixTableDataItems(table.items);
      });

      this.tableData = tableItemsTmpr;
    },
    async fetchData() {
      try {
        const payload = {
          filter: {
            ...this.filters,
            time: parseInt(this.filters.time) * 60 * 1000,
          },
        };

        this.loadings.table = true;

        this.isSelectedFilterDateIsToday = this.$moment(
          this.filters.date
        ).isSame(this.$moment(), "day");

        this.tableData = [];
        this.isFetchedOnce = true;

        const resp = await this.$store.dispatch(
          "daily-report-v3/fetchData",
          payload
        );

        return this.processTableData(resp.table);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.table = false;
      }
    },
    fixTableDataItems(items) {
      return items.map((item) => {
        const keys = Object.keys(item);

        item._json = JSON.stringify(item);

        keys.forEach((key) => {
          const value = item[key];
          if (key === "priority") item[key] = parseInt(value);
          if (key === "priority_project") item[key] = parseInt(value);
          if (String(value) === "0") item[key] = null;
        });

        return item;
      });
    },
  },
};
</script>

<style lang="scss">
.wd-v3 {
  &__card {
    h2 {
      padding-left: 16px;
    }
  }

  .v-data-table {
    tbody {
      tr {
        //td {
        //  &:nth-child(1),
        //  &:nth-child(4),
        //  &:nth-child(5),
        //  &:nth-child(6),
        //  &:nth-child(7) {
        //    border-right: 1px solid rgba(155, 155, 155, 0.5);
        //  }
        //}
      }

      tr.done {
        background-color: rgba(124, 227, 40, 0.14);

        &:hover {
          background-color: rgba(85, 210, 22, 0.14) !important;
        }
      }

      tr.denied {
        background-color: rgba(227, 140, 40, 0.14);

        &:hover {
          background-color: rgba(210, 44, 22, 0.14) !important;
        }
      }
    }
  }
}
</style>
