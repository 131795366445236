<template>
  <div class="site-workplace-dashboard">
    <v-container fluid style="padding-bottom: 200px">
      <v-row dense>
        <v-col cols="12">
          <h2>Site Workplace</h2>
          <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
        </v-col>
      </v-row>
      <v-form ref="formRef">
        <v-row dense class="mt-6">
          <v-col cols="6" md="4" lg="2" xl="fill">
            <smart-date-filter
              label="Deadline"
              :model-value="filter.deadline"
              @update:modelValue="filter.deadline = $event"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.status"
              :items="filterItems.field_status"
              label="Status"
              :menu-props="menuProps"
              item-value="value"
              item-text="text"
              hide-details
              multiple
              chips
              deletable-chips
              small-chips
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.executive"
              :items="executive_items"
              label="Executive"
              :menu-props="menuProps"
              item-value="value"
              item-text="text"
              :loading="loadings.filters"
              multiple
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.page_type"
              :items="page_type_items"
              label="Page Type"
              item-value="value"
              :menu-props="menuProps"
              :loading="loadings.filters"
              item-text="text"
              multiple
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              label="Project"
              v-model="filter.project_id"
              :rules="[(v) => !!v || 'Project is required.']"
              :items="filtersData.projects"
              :loading="filtersDataPendingKeys?.projects"
              :menu-props="menuProps"
              hide-details="auto"
              clearable
              dense
              outlined
              @change="handleProjectFilterChange"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.sub_project"
              :items="filtersData.subprojects"
              label="Subprojects"
              :menu-props="menuProps"
              :loading="filtersDataPendingKeys?.subprojects"
              multiple
              hide-details
              clearable
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-text-field
              v-model="filter.url_id"
              dense
              hide-details
              outlined
              label="Url id"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.field_status"
              hide-details
              dense
              clearable
              multiple
              outlined
              :items="filterItems.field_status"
              label="Field Status"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.field_executive"
              hide-details
              dense
              clearable
              multiple
              outlined
              :items="executive_items"
              label="Field Executive"
              :loading="loadings.filters"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <smart-date-filter
              label="Field Date"
              :model-value="filter.field_date"
              @update:modelValue="filter.field_date = $event"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.field_type"
              :items="filterItems.field_type"
              auto-select-first
              :loading="loadings.filters"
              clearable
              :menu-props="menuProps"
              outlined
              dense
              multiple
              hide-details
              label="Field type"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-checkbox
              v-model="filter.overdue_pages"
              label="Show Overdue Pages"
              :true-value="1"
              hide-details
              dense
              class="ma-0"
              :false-value="null"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <easy-range-input
              label="Need Top"
              :model-value="filter.need_top"
              @update:modelValue="filter.need_top = $event"
              :presets="[
                ['1 - 3', [1, 3]],
                ['1 - 5', [1, 5]],
                ['1 - 10', [1, 10]],
                ['1 - 50', [1, 50]],
                ['1 - 100', [1, 100]],
              ]"
            ></easy-range-input>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filter.type"
              :items="[
                { text: 'NEW', value: 'new' },
                { text: 'CURRENT', value: 'current' },
              ]"
              hide-details
              outlined
              dense
              clearable
              :menu-props="menuProps"
              label="Type"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-checkbox
              v-model="filter.overdue_fields"
              label="Show Overdue Fields"
              dense
              hide-details
              class="ma-0"
              :true-value="1"
              :false-value="null"
            ></v-checkbox>
          </v-col>
          <v-col cols="fill" class="d-flex justify-end">
            <v-btn
              width="140"
              height="40"
              class="text-normal"
              @click="fetchData"
              color="primary"
              :loading="loading"
            >
              Get Data
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row dense class="mt-4">
        <v-col v-if="!loading" cols="12">
          <v-card class="styled-card--default">
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                style="max-width: 350px"
                dense
                single-line
                hide-details
              ></v-text-field>
              <v-spacer />
              <a
                class="reset-link-styles"
                href="https://docs.google.com/document/d/1MPYg-PKHd6ErmcdxoUedDpYmGBeB0_0pjSXxcWSJ-Ro/edit#heading=h.nvikt9t9ws17"
                target="_blank"
              >
                <v-chip @click="() => {}" class="mr-2" label outlined>
                  Usage guide <v-icon small right>mdi-open-in-new</v-icon>
                </v-chip></a
              >
              <v-menu
                bottom
                offset-y
                :close-on-content-click="false"
                content-class="my-shadow--e3"
              >
                <template #activator="{ on }">
                  <v-chip label outlined v-on="on">
                    Colors guide
                    <v-icon small right>mdi-information-outline</v-icon>
                  </v-chip>
                </template>
                <v-card class="styled-card--default pa-2">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>Meaning</th>
                        <th>Color</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="color in filterItems.field_status"
                        :key="color.text"
                      >
                        <td>
                          {{ color.text }}
                        </td>
                        <td>
                          <div
                            draggable="true"
                            :style="{
                              width: '60px',
                              height: '40px',
                              background: color.color,
                            }"
                          ></div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-menu>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <v-data-table
                :headers="headers"
                :items="items"
                :footer-props="{
                  itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
                }"
                :items-per-page="50"
                multi-sort
                :sort-by="['priority', 'need_top', 'deadline']"
                :sort-desc="[false, false, false]"
                :custom-sort="customSort"
                :loading="loading"
                :search="search"
                class="fixed-table-sw-d"
                fixed-header
                v-if="error_message === null"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                      style="white-space: nowrap"
                    >
                      <td :style="{ 'background-color': item.status_color }">
                        <span
                          style="cursor: pointer"
                          @click="openUrlDialog(item)"
                          >{{ item.url }}</span
                        >
                        <v-btn
                          small
                          icon
                          @click="openUrl(item.url)"
                          target="_blank"
                        >
                          <v-icon small>mdi-login-variant</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        {{ item.need_top }}
                      </td>
                      <td
                        class="text-center"
                        @click="openUrlDialog(item)"
                        style="cursor: pointer"
                      >
                        {{ item.status }}
                      </td>
                      <td-deadline :data="item" @openDialog="openUrlDialog" />
                      <td-score :value="item.score" />
                      <td
                        class="text-center"
                        :style="{
                          'background-color': item[i.value]['status_color'],
                        }"
                        v-for="i in headers_fields"
                        :key="i.value"
                      >
                        <div
                          style="cursor: pointer"
                          v-if="
                            (fields_by_pt[`t_${item.id_page_type}`] &&
                              fields_by_pt[`t_${item.id_page_type}`].indexOf(
                                i.value.replace('field_', '')
                              ) !== -1) ||
                            (fields_by_pt[`t_0`] &&
                              fields_by_pt[`t_0`].indexOf(
                                i.value.replace('field_', '')
                              ) !== -1)
                          "
                          @click="
                            openFieldDialog({
                              status_id: item[i.value].status_id,
                              status: item[i.value].status,
                              field: i.value.replace('field_', ''),
                              field_id: item[i.value].field_id,
                              url: item.url,
                              md5: item.md5,
                              url_id: item.id,
                              page_deadline: item.deadline,
                              deadline: item[i.value].deadline,
                            })
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-icon
                                  color="success"
                                  v-if="item[i.value].status === 2"
                                  >mdi-check</v-icon
                                >
                                <span
                                  v-else-if="item[i.value]['diff'] !== null"
                                  title="how many days has it been since the last change"
                                >
                                  {{ item[i.value]["diff"] }}
                                </span>
                                <v-icon v-else small>mdi-pencil</v-icon>
                                <span
                                  v-if="
                                    [
                                      'field_Title',
                                      'field_Description',
                                      'field_H1',
                                    ].indexOf(i.value) !== -1
                                  "
                                  title="Length"
                                >
                                  -
                                  {{
                                    item[i.value]["len"] !== null
                                      ? item[i.value]["len"]
                                      : "?"
                                  }}
                                </span>
                                <sup
                                  v-if="
                                    item[i.value]['deadline'] !== null &&
                                    item[i.value]['status'] !== 'done' &&
                                    item[i.value]['left_days'] <= 7
                                  "
                                  title="How many days past the deadline"
                                  :class="{
                                    'error--text font-weight-bold':
                                      item[i.value]['left_days'] < 0,
                                    'warning--text font-weight-bold':
                                      item[i.value]['left_days'] >= 0 &&
                                      item[i.value]['left_days'] <= 3,
                                    'success--text font-weight-bold':
                                      item[i.value]['left_days'] >= 4,
                                  }"
                                  >{{ item[i.value]["left_days"] }}</sup
                                >
                              </div>
                            </template>
                            <span title="Deadline">{{
                              item[i.value]["deadline"]
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          <v-icon small>mdi-lock-outline</v-icon>
                        </div>
                      </td>
                      <td class="text-center">{{ item.executive }}</td>
                      <td>{{ item.page_type }}</td>
                      <td
                        class="text-center"
                        @click="openUrlDialog(item)"
                        style="cursor: pointer"
                      >
                        {{ item.priority }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              <v-alert v-else type="error" class="mb-0" text>
                <div>{{ error_message }}</div>
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card class="styled-card--default">
            <v-card-title>
              <div class="skeleton" style="width: 260px; height: 28px" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-skeleton-loader type="table" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog_field_data.dialog"
      content-class="hide-scrollbar shadow-e2-bordered"
      max-width="1440px"
    >
      <v-card class="styled-card--default">
        <v-card-title>
          <v-row align="center">
            <v-col class="grow">
              {{ dialog_field_data.field }}
            </v-col>
            <v-col class="shrink" style="white-space: nowrap">
              <v-chip
                v-if="
                  dialog_field_data.field_id === 5 ||
                  dialog_field_data.field_id === 6 ||
                  dialog_field_data.field_id === 7
                "
                @click="goToTDH1Dashboard(dialog_field_data)"
                class="mr-2"
                outlined
                label
              >
                TDH1
                <v-icon small right>mdi-open-in-new</v-icon>
              </v-chip>
              <a
                :href="`/search-console/url/${dialog_field_data.md5}`"
                target="_blank"
                class="reset-link-styles"
              >
                <v-chip outlined label @click="() => {}">
                  Search Console
                  <v-icon small right>mdi-open-in-new</v-icon>
                </v-chip>
              </a>
              <v-btn
                icon
                @click="dialog_field_data.dialog = false"
                class="ml-4"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle>
          {{ dialog_field_data.url }}
          <v-icon color="primary" small @click="openUrl(dialog_field_data.url)">
            mdi-login-variant
          </v-icon>
        </v-card-subtitle>
        <v-divider />
        <div class="pt-2">
          <v-card-text v-if="loadings.fieldsData">
            <v-row>
              <v-col cols="12">
                <div class="skeleton" style="height: 195px" />
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="4">
                    <div class="skeleton" style="height: 500px" />
                  </v-col>
                  <v-col cols="8">
                    <div class="skeleton" style="height: 500px" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <div class="skeleton" style="height: 50px" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text
            v-else-if="
              !loadings.fieldsData &&
              dialog_field_data.data.new_element &&
              dialog_field_data.data.new_element.visible
            "
          >
            <v-card class="styled-card--default">
              <v-card-title>
                Element info
                <v-spacer />
                <v-menu
                  content-class="my-shadow--e3"
                  :close-on-content-click="false"
                  bottom
                  offset-y
                >
                  <template #activator="{ on }">
                    <v-chip
                      class="flat-chip flat-chip--primary"
                      v-on="on"
                      label
                      :loading="loadings.fieldsData"
                    >
                      <v-icon small left> mdi-eye </v-icon>
                      Show elements history
                      <v-icon small right>mdi-history</v-icon></v-chip
                    >
                  </template>
                  <v-card class="styled-card--default">
                    <v-simple-table dense>
                      <thead>
                        <tr>
                          <th>Element</th>
                          <th>Last update</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, idx) in dialog_field_data.data
                            .new_element.history"
                          :key="idx"
                        >
                          <td>
                            {{ item.block }}
                          </td>
                          <td>
                            {{ item.date }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card>
                </v-menu>
              </v-card-title>
              <v-card-subtitle>
                <div v-if="dialog_field_data.data.new_element.current">
                  <b>{{ dialog_field_data.data.new_element.current }}</b>
                </div>
                <div v-else>
                  <v-chip color="warning">
                    <v-icon small left>mdi-alert-outline</v-icon> Current
                    element empty</v-chip
                  >
                </div>
              </v-card-subtitle>
              <v-divider />
              <v-card-text>
                <v-textarea
                  v-model="dialog_field_data.data.new_element.new"
                  filled
                  dense
                  rows="2"
                  hide-details
                  label="New Element"
                ></v-textarea>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-card class="styled-card--default">
                  <v-card-title> Configuration </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="dialog_field_data.status"
                          :items="filterItems.field_status"
                          auto-select-first
                          clearable
                          filled
                          hide-details
                          :menu-props="menuProps"
                          label="Status"
                          :prepend-inner-icon="
                            statusIcon(dialog_field_data.status)
                          "
                          @change="handleResetCheckboxes"
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          bottom
                          offset-y
                          :close-on-content-click="false"
                          open-on-hover
                          content-class="my-shadow--e3"
                          max-width="330px"
                        >
                          <template #activator="{ on }">
                            <div v-on="on">
                              <v-text-field
                                v-model="dialog_field_data.deadline"
                                readonly
                                filled
                                label="Deadline"
                                prepend-inner-icon="mdi-calendar"
                                persistent-hint
                                hide-details="auto"
                                dense
                                :messages="`DL by page: ${dialog_field_data.page_deadline}`"
                              ></v-text-field>
                            </div>
                          </template>
                          <v-card class="styled-card--default pa-0">
                            <v-date-picker
                              v-model="dialog_field_data.deadline"
                              scrollable
                              full-width
                            >
                            </v-date-picker>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="dialog_field_data.comment"
                          filled
                          label="Comment"
                          hide-details
                          rows="3"
                        ></v-textarea>
                      </v-col>
                      <v-col v-if="dialog_field_data.show_date" cols="12">
                        <v-text-field
                          filled
                          dense
                          v-model="dialog_field_data.date"
                          readonly
                          hide-details
                          @click="dialog_field_data.dialog_date = true"
                          label="Date"
                          prepend-inner-icon="mdi-calendar"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <template
                    v-if="
                      dialog_field_data.links_for_ta &&
                      dialog_field_data.links_for_ta.length > 0
                    "
                  >
                    <v-divider />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-row
                            dense
                            v-for="(item, i) in dialog_field_data.links_for_ta"
                            :key="i"
                          >
                            <v-col>
                              <v-text-field
                                placeholder="Link for TA"
                                v-model="item.link"
                                required
                                filled
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col
                              class="flex-shrink-0"
                              style="max-width: 65px"
                            >
                              <v-btn
                                icon
                                @click="deleteLinkForTA('dialog_field_data', i)"
                                class="ma-2"
                              >
                                <v-icon dark> mdi-close </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </template>
                  <template v-if="dialog_field_data.links_for_ta.length < 3">
                    <v-divider />
                    <v-card-actions>
                      <v-row>
                        <v-col class="text-end">
                          <v-chip
                            class="mr-2"
                            label
                            outlined
                            @click="
                              dialog_field_data.links_for_ta.push({ link: '' })
                            "
                          >
                            Link for TA
                            <v-icon small right> mdi-plus </v-icon>
                          </v-chip>
                          <v-chip
                            label
                            outlined
                            @click="
                              dialog_field_data.show_date =
                                !dialog_field_data.show_date
                            "
                          >
                            Old data
                            <v-icon small right>
                              {{
                                dialog_field_data.show_date
                                  ? "mdi-minus"
                                  : "mdi-plus"
                              }}
                            </v-icon>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </template>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="styled-card--default">
                  <v-card-title> Checkboxes </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        v-for="item in checkboxes_items"
                        :key="item.id"
                        cols="12"
                      >
                        <v-checkbox
                          class="ma-0"
                          hide-details
                          v-model="dialog_field_data.checkboxes"
                          :value="item.id"
                          :label="item.name"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <template
                    v-if="
                      dialog_field_data.data &&
                      dialog_field_data.data.manual_links &&
                      dialog_field_data.data.manual_links.length > 0
                    "
                  >
                    <v-divider />
                    <v-card-actions>
                      <v-chip
                        v-for="manual_link in dialog_field_data.data
                          .manual_links"
                        :key="manual_link"
                        label
                        class="mr-2"
                        @click="goTo(manual_link)"
                      >
                        Open Manual
                        <v-icon right small>mdi-open-in-new</v-icon>
                      </v-chip>
                    </v-card-actions>
                  </template>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card class="styled-card--light">
                  <v-card-title>
                    Checkboxes history
                    <v-spacer />
                    <v-chip
                      label
                      class="flat-chip flat-chip--primary"
                      @click="isShowHistory = !isShowHistory"
                    >
                      {{ isShowHistory ? "Hide history" : "Show history" }}
                      <v-icon small right>{{
                        isShowHistory ? "mdi-eye-off" : "mdi-eye"
                      }}</v-icon>
                    </v-chip>
                  </v-card-title>
                  <template v-if="isShowHistory">
                    <v-divider />
                    <v-card-text class="pa-0">
                      <v-simple-table dense height="280" fixed-header>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">Date</th>
                              <th class="">User</th>
                              <th class="">Changes</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in field_history" :key="item.id">
                              <td class="text-center" style="min-width: 110px">
                                {{ item.date }}
                              </td>
                              <td class="">
                                {{ item.email }}
                              </td>
                              <td class="">
                                <div v-if="item.changes.status">
                                  Status: {{ item.changes.status }}
                                </div>
                                <div v-if="item.changes.value">
                                  Comment: {{ item.changes.value }}
                                </div>
                                <div v-if="item.changes.checkboxes">
                                  Checkboxes: {{ item.changes.checkboxes }}
                                </div>
                                <div v-if="item.changes.deadline">
                                  Deadline: {{ item.changes.deadline }}
                                </div>
                                <div v-if="item.changes.links_for_ta">
                                  TA Link: {{ item.changes.links_for_ta }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </template>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="alertSuccess">
              <v-col>
                <v-alert type="success" class="mt-5"
                  >Successfully saved!</v-alert
                >
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="handleCloseFieldDialog"
            width="120px"
            class="mr-2"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="saveField"
            :loading="loadingSave"
            :disabled="loadingSave"
            width="180px"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_url_data.dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <v-row align="center">
            <v-col class="grow"> Edit url </v-col>
            <v-col class="shrink">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="loading_request_indexing"
                    icon
                    color="info"
                    @click="requestIndexing"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-google</v-icon>
                  </v-btn>
                </template>
                <span>Request Indexing</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle>
          {{ dialog_url_data.url }}
        </v-card-subtitle>
        <v-card-text>
          <v-alert
            text
            dismissible
            v-if="url_alert.status"
            :type="url_alert.type"
            >{{ url_alert.msg }}</v-alert
          >
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="dialog_url_data.status"
                :items="filterItems.field_status"
                auto-select-first
                :loading="loadings.filters"
                clearable
                :menu-props="menuProps"
                solo
                hide-details
                label="Status"
                :prepend-inner-icon="statusIcon(dialog_url_data.status)"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-menu
                bottom
                offset-y
                :close-on-content-click="false"
                max-width="360"
              >
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-text-field
                      solo
                      v-model="dialog_url_data.deadline"
                      readonly
                      label="Date"
                      hide-details
                      prepend-inner-icon="mdi-calendar"
                    ></v-text-field>
                  </div>
                </template>

                <v-card class="styled-card--default pa-0">
                  <v-date-picker
                    v-model="dialog_url_data.deadline"
                    scrollable
                    full-width
                  >
                  </v-date-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
          <v-text-field
            solo
            v-model="dialog_url_data.priority"
            type="number"
            class="mt-4"
            label="Priority"
          ></v-text-field>
          <div class="text-subtitle-1 my-1 text-center">Dashboard Content</div>
          <v-row>
            <v-col
              cols="12"
              md="2"
              v-for="el in content_checkboxes_items"
              :key="el.id"
            >
              <v-checkbox
                dense
                class="d-inline pa-2"
                v-model="dialog_url_data.content_checkboxes"
                hide-details
                :label="el.label"
                :value="el.id"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row
            dense
            v-for="(item, i) in dialog_url_data.links_for_ta"
            :key="i"
          >
            <v-col>
              <v-text-field
                label="Link for TA"
                v-model="item.link"
                required
                solo
                hide-details
              ></v-text-field>
            </v-col>
            <v-col class="flex-shrink-0" style="max-width: 65px">
              <v-btn
                icon
                @click="deleteLinkForTA('dialog_url_data', i)"
                class="ma-2"
              >
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="dialog_url_data.links_for_ta.length < 3" class="mb-0">
            <v-col cols="12" class="text-center py-0">
              <v-btn
                class="mx-2"
                dark
                small
                color="info"
                text
                @click="dialog_url_data.links_for_ta.push({ link: '' })"
              >
                Add link for TA
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12">
              <div class="text-subtitle-1 text-center">URL History</div>
              <v-data-table
                :items="urlHistory.items"
                :headers="urlHistory.headers"
              ></v-data-table>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <div class="text-subtitle-1 text-center">TA History</div>
              <v-simple-table
                dense
                height="150"
                fixed-header
                v-if="cb_history.length > 0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Date</th>
                      <th class="text-center">User</th>
                      <th class="text-center">Source</th>
                      <th class="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in cb_history" :key="item.id">
                      <td class="text-center" style="min-width: 110px">
                        {{ item.date }}
                      </td>
                      <td class="text-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{ item.email }}
                            </span>
                          </template>
                          <span>{{ item.created_at }}</span>
                        </v-tooltip>
                      </td>
                      <td class="text-center">
                        {{ item.source }}
                      </td>
                      <td class="text-center" style="min-width: 110px">
                        <v-btn icon @click="goTo(item.ta_url)" target="_blank">
                          <v-icon>mdi-login-variant</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <div class="text-subtitle-1 text-center">Fields History</div>
              <v-simple-table dense height="180" fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">Date</th>
                      <th class="">User</th>
                      <th class="">Field</th>
                      <th class="">Changes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in fields_history" :key="item.id">
                      <td class="text-center" style="min-width: 110px">
                        {{ item.date }}
                      </td>
                      <td class="">
                        {{ item.email }}
                      </td>
                      <td class="">
                        {{ item.field_name }}
                      </td>
                      <td class="">
                        <div v-if="item.changes.status">
                          Status: {{ item.changes.status }}
                        </div>
                        <div v-if="item.changes.value">
                          Comment: {{ item.changes.value }}
                        </div>
                        <div v-if="item.changes.checkboxes">
                          Checkboxes: {{ item.changes.checkboxes }}
                        </div>
                        <div v-if="item.changes.deadline">
                          Deadline: {{ item.changes.deadline }}
                        </div>
                        <div v-if="item.changes.links_for_ta">
                          TA Link: {{ item.changes.links_for_ta }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-alert v-if="alertSuccess" type="success" class="mt-5"
            >Successfully saved!</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog_url_data.dialog = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveUrl"
            :loading="loadingSave"
            :disabled="loadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      ref="dialog_field_data_date"
      v-model="dialog_field_data.dialog_date"
      persistent
      width="290px"
    >
      <v-date-picker v-model="dialog_field_data.date" scrollable>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="dialog_field_data.dialog_date = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dialog_field_data_date.save(date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-dialog
      ref="dialog_field_data_deadline"
      v-model="dialog_field_data.dialog_deadline"
      persistent
      width="290px"
    >
      <v-date-picker v-model="dialog_field_data.deadline" scrollable>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="dialog_field_data.dialog_deadline = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dialog_field_data_deadline.save(deadline)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import eventPipe from "@/events/eventPipe";
import Location from "@/mixins/Location";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import TdDeadline from "@/components/SiteWorkplace/UI/SiteWorkplace/TdDeadline";
import Project from "../../mixins/Project";
import TdScore from "@/components/SiteWorkplace/UI/SiteWorkplace/TdScore";
import EasyRangeInput from "@/components/UI/EasyRangeInput";
import {
  DEFAULT_MENU_PROPS,
  SITE_WORKPLACE_URL_HISTORY_HEADERS,
} from "@/utils/defaultData";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import service from "@/plugins/service";
import Notification from "@/mixins/Notification";
import UploadFiltersDataMixin from "@/mixins/UploadFiltersDataMixin";

export default {
  name: "Dashboard",
  components: { SmartDateFilter, EasyRangeInput, TdScore, TdDeadline },
  mixins: [
    Location,
    FiltersHandling,
    Project,
    Notification,
    UploadFiltersDataMixin,
  ],
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Site workplace",
        disabled: true,
      },
    ],
    menuProps: DEFAULT_MENU_PROPS,
    show_more: false,
    url_alert: {
      status: false,
      type: "success",
      msg: "",
    },
    urlHistory: {
      items: [],
      headers: SITE_WORKPLACE_URL_HISTORY_HEADERS,
    },
    loadingSave: false,
    loading_request_indexing: false,
    alertSuccess: false,
    date: "",
    deadline: "",
    date_modal: false,
    filter_menu: false,
    filter: {
      status: [],
      field_status: [],
      field_type: [],
      field_executive: [],
      field_date: [null, null],
      type: "",
      url_id: null,
      overdue_pages: null,
      overdue_fields: null,
      deadline: [null, null],
      need_top: [null, null],
      executive: [],
      page_type: [],
      sub_project: [],
      project_id: null,
    },
    dialog_url_data: {
      dialog: false,
      dialog_date: false,
      url: "",
      url_id: 0,
      status: false,
      deadline: false,
      links_for_ta: [],
      priority: 0,
      cb_editor_url_status: false,
      fields_in_progress: false,
      content_checkboxes: [],
    },
    loadings: {
      filters: false,
      fieldsData: false,
    },
    filtersData: {
      projects: [],
      subprojects: [],
    },
    isShowHistory: false,
    dialog_field_data: {
      dialog: false,
      dialog_date: false,
      dialog_deadline: false,
      url: "",
      field: "",
      field_id: 0,
      urlID: 0,
      status: false,
      date: "",
      deadline: "",
      new_status: "",
      comment: "",
      links_for_ta: [],
      checkboxes: [],
      show_date: false,
      data: {
        manual_links: [],
        new_element: {},
      },
    },
    field_history: [],
    fields_history: [],
    cb_history: [],
    filterItems: {
      field_status: [],
      field_type: [],
    },
    content_checkboxes_items: [
      { id: "tl", label: "T L" },
      { id: "t", label: "T" },
      { id: "tn", label: "T N" },
      { id: "dl", label: "D L" },
      { id: "dn", label: "D N" },
      { id: "h1n", label: "H1 N" },
    ],
    executive_items: [],
    checkboxes_items: [],
    page_type_items: [],
    sub_project_items: [],
    modal_date: false,
    search: "",
    error_message: null,
    loading: false,
    headers_base: [
      { text: "Page", value: "url" },
      { text: "Need Top", value: "need_top" },
      { text: "Status", value: "status", align: "center" },
      { text: "Deadline", value: "deadline", align: "center", width: 130 },
      { text: "Score", value: "score", align: "center" },
    ],
    headers_fields: [],
    fields_by_pt: {},
    items: [],
  }),
  async mounted() {
    await this.parseQuery("filter");

    this.uploadFiltersData(["projects", "subprojects"], {
      project: this.idProject,
    });

    this.fetchFilters();

    if (
      this.$route.query.project_id &&
      this.$route.query.project_id.includes(",")
    ) {
      this.$message.notification({
        title: "Warning",
        text: "You can choose no more than one project.",
        type: "warning",
        duration: 6000,
      });
    }

    if (this.idProject) {
      this._$setActiveProject(this.idProject, false);
      this.filter.project_id = Number(this.idProject);
      this.$forceUpdate();
    }

    await this.checkQueryParamsOnNewProject();

    // self.fillDates()
    this.fetchFilterData();

    this.$watch("filter.project_id", (value) => {
      this.handleProjectFilterChange(value);
      this.filter.sub_project = [];
      this.uploadFiltersData(["subprojects"], {
        project: this.filter.project_id,
      });
      this.fetchFilterData();
    });

    this.fetchData();
    this.$forceUpdate();

    if (this.$route.query.url) {
      this.search = this.$route.query.url;
    }

    await this.$nextTick();

    eventPipe.$on("update-active-project", () => {
      this.fetchData.bind(this);
      this.fetchFilterData.bind(this);
      this.filter.project_id = Number(this.$store.getters.active_project);
    });
  },
  computed: {
    dateRangeText() {
      return this.filter.deadline.join(" ~ ");
    },
    headers() {
      return this.headers_base.concat(this.headers_fields).concat([
        { text: "Executive", value: "executive", align: "center" },
        { text: "Page Type", value: "page_type", align: "center" },
        { text: "Priority", value: "priority", align: "center" },
      ]);
    },
    idProject() {
      return this.$store.state.project.active_project || this.filter.project_id;
    },
  },
  methods: {
    async fetchFilters() {
      try {
        const filters = ["field_status", "field_type"];
        const payload = {
          type: `/site-workplace/dashboard`,
          take: filters,
        };
        this.loadings.filters = true;
        const resp = await this.$store.dispatch("global/getFilters", payload);
        this.loadings.filters = false;

        if (resp) {
          this.filterItems = { ...this.filterItems, ...resp };
        }
      } catch (e) {
        console.error(e);
        this.loadings.filters = false;
      }
    },
    goToTDH1Dashboard(data) {
      const url = `/tdh1-dashboard?project_id=${this.idProject}&url=${data.urlID}`;
      window.open(url, "_blank");
    },
    handleProjectFilterChange(event) {
      this._$setActiveProject(event, false);
    },
    async checkQueryParamsOnNewProject() {
      const queryId = this.$route.query.project_id;

      if (queryId) {
        return this._$setActiveProject(queryId, false);
      }
      this._$setActiveProject(this.idProject, false);
    },
    openSC() {
      let url = "https://" + this.$store.state.project.domain;
      window.open(
        `https://search.google.com/search-console?resource_id=${url}`,
        "_blank"
      );
    },
    deleteLinkForTA(arr, index) {
      this[arr].links_for_ta = this[arr].links_for_ta.filter(
        (v, i) => i !== index
      );
    },
    async requestIndexing() {
      try {
        const url = "/url-control/request-indexing";

        const payload = {
          url:
            "https://" +
            this.$store.state.project.domain +
            this.dialog_url_data.url,
          projectID: this.idProject,
        };

        this.loading_request_indexing = true;

        const response = await service.post(url, payload);

        if (response.data.error !== null) {
          this.url_alert = {
            status: true,
            type: "error",
            msg: response.data.error.message,
          };
        } else {
          this.url_alert = {
            status: true,
            type: "success",
            msg: "Request for indexing successfully sent.",
          };
        }
      } catch (e) {
        this.url_alert = {
          status: true,
          type: "error",
          msg: "Problem with indexing url.",
        };
        console.error("Error while requesting indexing.", e);
      } finally {
        this.loading_request_indexing = false;
      }
    },
    async fetchFilterData() {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        const url = `/site-workplace/${this.idProject}/get-filter-data`;

        this.loadings.filters = true;

        const response = await service.get(url);

        this.page_type_items = response.data.page_type_items;
        this.sub_project_items = response.data.sub_project_items;
        this.executive_items = response.data.executive_items;
      } catch (e) {
        console.error("Error while fetching filters data.", e);
      } finally {
        this.loadings.filters = false;
      }
    },
    fillDates() {
      let date_from = "";
      let date_to = "";

      let d = new Date();

      date_to = d.toJSON().split("T")[0];

      d.setDate(d.getDate() - 6);

      date_from = d.toJSON().split("T")[0];

      this.filter.deadline = [date_from, date_to];
    },
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          let a_val = parseInt(a[sortBy[i]]);
          let b_val = parseInt(b[sortBy[i]]);

          if (sortBy[i] === "deadline") {
            a_val = new Date(a[sortBy[i]]).getTime();
            b_val = new Date(b[sortBy[i]]).getTime();
          }

          if (sortBy[i] === "priority" || sortBy[i] === "deadline") {
            if (isNaN(a_val)) a_val = 0;
            if (isNaN(b_val)) b_val = 0;

            if (a_val === 0 && b_val !== 0) {
              return 1;
            }
            if (b_val === 0 && a_val !== 0) {
              return -1;
            }
          }

          if (a_val > b_val) return sortDesc[i] ? -1 : 1;
          if (a_val < b_val) return sortDesc[i] ? 1 : -1;
        }

        return 0;
      });

      return items;
    },
    openUrl(url) {
      return this.goTo("https://" + this.$store.state.project.domain + url);
    },
    goTo(url) {
      window.open(url, "_blank");
    },
    statusIcon(status) {
      switch (status) {
        case 0:
          return "mdi-flask-empty-outline";
        case 1:
          return "mdi-clock-outline";
        case 2:
          return "mdi-check";
      }
      return "mdi-close";
    },
    openFieldDialog(item) {
      let self = this;

      self.dialog_field_data.comment = "";

      self.checkboxes_items = [];
      self.field_history = [];
      self.alertSuccess = false;

      self.dialog_field_data.dialog = true;
      self.dialog_field_data.url = item.url;
      self.dialog_field_data.md5 = item.md5;
      self.dialog_field_data.urlID = item.url_id;
      self.dialog_field_data.field = item.field;
      self.dialog_field_data.field_id = item.field_id;
      self.dialog_field_data.status = item.status_id;
      self.dialog_field_data.date = new Date().toISOString().slice(0, 10);
      self.dialog_field_data.deadline = item.deadline;
      self.dialog_field_data.page_deadline = item.page_deadline;
      self.dialog_field_data.links_for_ta = [];
      self.dialog_field_data.show_date = false;
      self.dialog_field_data.data.manual_links = [];

      self.loadFieldHistory();
      self.loadCheckboxes();
      self.loadFieldData(item.url_id);
    },
    async loadFieldData(urlID) {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        const url = `/site-workplace/${this.idProject}/get-field-data`;
        const config = {
          params: {
            field: this.dialog_field_data.field,
            urlID,
          },
        };

        this.loadings.fieldsData = true;

        const response = await service.get(url, true, config);

        this.dialog_field_data.data = response.data;
      } catch (e) {
        console.error("Error while loading field data.", e);
      } finally {
        this.loadings.fieldsData = false;
      }
    },
    async loadContentCheckboxes() {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        const url = `/site-workplace/${this.idProject}/get-checkboxes`;
        const config = {
          params: {
            urlID: this.dialog_url_data.url_id,
            field: "none",
          },
        };

        const response = await service.get(url, true, config);

        this.dialog_url_data.content_checkboxes = JSON.parse(
          response.data.content_checkboxes
        );
      } catch (e) {
        this.sheet = true;
        console.error("Error while loading content for checkboxes.", e);
      }
    },
    async loadCheckboxes() {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        const url = `/site-workplace/${this.idProject}/get-checkboxes`;
        const config = {
          params: {
            urlID: this.dialog_field_data.urlID,
            field: this.dialog_field_data.field,
          },
        };

        const response = await service.get(url, true, config);

        this.checkboxes_items = response.data.items;
        this.dialog_field_data.checkboxes = JSON.parse(
          response.data.checkboxes
        );
      } catch (e) {
        this.sheet = true;
        console.error("Error while loading checkboxes.", e);
      }
    },
    async loadFieldHistory() {
      try {
        const url = `/site-workplace/${this.idProject}/load-field-history`;
        const config = {
          params: {
            urlID: this.dialog_field_data.urlID,
            field: this.dialog_field_data.field,
          },
        };

        const response = await service.get(url, true, config);

        this.field_history = response.data.items;
      } catch (e) {
        console.error("Error while loading field history.", e);
      }
    },
    async loadFieldsHistory() {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        const url = `/site-workplace/${this.idProject}/load-field-history`;
        const config = {
          params: {
            urlID: this.dialog_url_data.url_id,
          },
        };

        const response = await service.get(url, true, config);

        this.fields_history = response.data.items;
      } catch (e) {
        console.error("Error while loading fields history.", e);
      }
    },
    handleResetCheckboxes() {
      const TODO_ID = 1;
      const IN_PROGRESS_ID = 3;

      if ([TODO_ID, IN_PROGRESS_ID].includes(this.dialog_field_data.status)) {
        this.dialog_field_data.checkboxes = [];
      }
    },
    async loadUrlHistory(urlId) {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }
        if (!urlId)
          return this.mWarning({
            title: "Validation error",
            text: "Url id is required.",
          });

        const url = `/site-workplace/${this.idProject}/load-url-history?urlID=${urlId}`;

        const resp = await service.get(url);

        if (resp) {
          this.urlHistory.items = resp.data.items;
        }
      } catch (e) {
        console.error("Error while loading urls history.", e);
      }
    },
    async loadCBHistory() {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        this.cb_history = [];

        const url = `/site-workplace/${this.idProject}/load-cb-history`;
        const config = {
          params: {
            urlID: this.dialog_url_data.url_id,
          },
        };

        const response = await service.get(url, true, config);

        this.cb_history = response.data.items;
      } catch (e) {
        console.error("Error while loading content ball history.", e);
      }
    },
    openUrlDialog(item) {
      let self = this;

      let fields_by_pt = self.fields_by_pt;

      let fields_in_progress = false;

      for (let y in self.headers_fields) {
        let i = self.headers_fields[y];
        if (
          (fields_by_pt[`t_${item.id_page_type}`] &&
            fields_by_pt[`t_${item.id_page_type}`].indexOf(
              i.value.replace("field_", "")
            ) !== -1) ||
          (fields_by_pt[`t_0`] &&
            fields_by_pt[`t_0`].indexOf(i.value.replace("field_", "")) !== -1)
        ) {
          if (
            item[i.value].status_id !== null &&
            item[i.value].status_id !== "done" &&
            item[i.value].deadline !== null
          ) {
            fields_in_progress = true;
          }
        }
      }

      self.field_history = [];

      self.dialog_url_data.dialog = true;
      self.dialog_url_data.url = item.url;
      self.dialog_url_data.url_id = item.id;
      self.dialog_url_data.status = item.status_id;
      self.dialog_url_data.priority = item.priority;
      self.dialog_url_data.deadline = item.deadline;
      self.dialog_url_data.cb_editor_url = item.cb_editor_url;
      self.dialog_url_data.cb_editor_url_status = item.cb_editor_url != null;
      self.dialog_url_data.links_for_ta = [];
      self.dialog_url_data.fields_in_progress = fields_in_progress;

      self.alertSuccess = false;

      self.loadFieldsHistory();
      self.loadCBHistory();
      self.loadContentCheckboxes();
      this.loadUrlHistory(item.id);
    },
    handleCloseFieldDialog() {
      this.dialog_field_data.comment = "";
      this.dialog_field_data.dialog = false;
    },
    formatTKPos(pos) {
      return pos === 0 ? "-" : pos;
    },
    goToChanges(id) {
      this.$router.push({ name: "site-workplace.changes", query: { id: id } });
    },
    async saveField() {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        this.loadingSave = true;
        this.alertSuccess = false;

        const url = `/site-workplace/${this.idProject}/save-field`;
        const payload = this.dialog_field_data;

        await service.post(url, payload);

        this.loadingSave = false;
        this.alertSuccess = true;
        this.dialog_field_data.dialog = false;
        this.fetchData(false);
      } catch (e) {
        this.sheet = true;
        console.error("Error while saving field.", e);
      } finally {
        this.dialog_field_data.comment = "";
      }
    },
    async saveUrl() {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        this.loadingSave = true;
        this.alertSuccess = false;

        const url = `/site-workplace/${this.idProject}/save-url`;
        const payload = this.dialog_url_data;

        await service.post(url, payload);

        this.loadingSave = false;
        this.alertSuccess = true;
        this.dialog_url_data.dialog = false;
      } catch (e) {
        this.sheet = true;
        console.error("Error while saving url.", e);
      }
    },
    async fetchData(triggerLoading = true) {
      try {
        if (!this.idProject) {
          this.$refs.formRef?.validate();
          return this.mWarning({
            title: "Validation error",
            text: "Project id is required.",
          });
        }

        this.error_message = null;
        this.filter_menu = false;

        const url = `/site-workplace/${this.idProject}/dashboard`;
        const config = {
          params: {
            filter: this.filter,
          },
        };

        if (triggerLoading) this.loading = true;

        const response = await service.get(url, true, config);

        if (response.data.success) {
          // this.items = this.sortTableItems(r.data.items)
          this.items = response.data.items;
          this.headers_fields = response.data.headers_fields;
          this.fields_by_pt = response.data.fields_by_pt;
        } else {
          this.error_message = response.data.message;
          this.sheet = true;
        }
      } catch (e) {
        console.error("Error while loading main data.", e);
      } finally {
        this.loading = false;
      }
    },
    sortTableItems(data) {
      // :sort-by="['deadline', 'priority', 'clicks', 'impressions']"
      // :sort-desc="[false, false, true, true]"
      return data.sort((a, b) => {
        let {
          deadline: deadline1,
          priority: priority1,
          // clicks: c1,
          // impressions: d1,
        } = a;
        let {
          deadline: deadline2,
          priority: priority2,
          // clicks: c2,
          // impressions: d2,
        } = b;

        deadline1 = getTime(deadline1);
        deadline2 = getTime(deadline2);
        if (deadline1 === 0 && deadline2 !== 0) return 1;
        if (deadline2 === 0 && deadline1 !== 0) return -1;
        if (deadline1 > deadline2) return 1;
        if (deadline1 < deadline2) return -1;

        priority1 = parseInt(priority1);
        priority2 = parseInt(priority2);
        if (isNaN(priority1) && !isNaN(priority2)) return 1;
        if (isNaN(priority2) && !isNaN(priority1)) return -1;
        if (priority1 > priority2) return 1;
        if (priority1 < priority2) return -1;

        function getTime(date) {
          return new Date(date).getTime();
        }

        return 0;
      });
    },
  },
};
</script>

<style lang="scss">
.fixed-table-sw-d thead > tr > th:first-child {
  z-index: 3 !important;
}

.fixed-table-sw-d thead > tr > th:first-child,
.fixed-table-sw-d td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fff;
}
.site-workplace-dashboard-skeleton-button-1 {
  .v-skeleton-loader__button {
    width: 100% !important;
  }
}
.site-workplace-dashboard-skeleton-button-2 {
  .v-skeleton-loader__button {
    width: 100px !important;
  }
}
</style>
