<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="text-body-2 pa-3 pr-2 pt-2">
      {{ data.title }}
      <v-spacer />
      <v-btn-toggle v-model="selectedDataType" class="grouped-buttons">
        <v-btn class="text-normal" key="problems" value="problems" height="32">
          Amount
        </v-btn>
        <v-btn class="text-normal" key="percents" value="percents" height="32">
          Percent
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text class="pa-3">
      <ApexCharts
        height="280"
        :options="computedChartsOptions"
        :series="data[selectedDataType] || []"
        class="visible-overflow"
      >
      </ApexCharts>
    </v-card-text>
  </v-card>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },

  props: ["data"],

  data: () => ({
    selectedDataType: "problems",
  }),

  computed: {
    computedMaxSeriesValue() {
      const values = this.data[this.selectedDataType]?.map(
        (series) => series.data
      );

      const flatArray = values?.flat();

      return flatArray ? Math.max(...flatArray) : 100;
    },
    computedChartsOptions() {
      if (this.selectedDataType === "problems") {
        return {
          chart: {
            toolbar: {
              show: false,
            },
            type: "line",
          },
          stroke: {
            curve: "straight",
          },
          xaxis: {
            categories: this.data.categories || [],
          },
          yaxis: {
            labels: {
              offsetX: -14,
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 10,
              bottom: -10,
              top: -20,
            },
          },
        };
      }

      if (this.selectedDataType === "percents") {
        return {
          chart: {
            toolbar: {
              show: false,
            },
            type: "line",
          },
          stroke: {
            curve: "straight",
          },
          xaxis: {
            categories: this.data.categories || [],
          },
          yaxis: {
            max: this.computedMaxSeriesValue,
            tickAmount: 5,
            labels: {
              offsetX: -14,
              formatter: (v) => `${Number(v).toFixed(0)}%`,
            },
          },
          grid: {
            show: false,
            padding: {
              left: -10,
              right: 10,
              bottom: -10,
              top: -20,
            },
          },
        };
      }

      return {};
    },
  },
};
</script>
