<template>
  <div>
    <div v-if="$attrs.label" class="text-body-2">
      {{ $attrs.label }}
    </div>
    <v-radio-group
      v-model="modelValue"
      hide-details="auto"
      dense
      v-bind="$attrs"
      :error-messages="$attrs['error-messages']"
      @change="$emit('blur')"
      label=""
      row
      class="mt-0"
    >
      <v-radio
        v-for="idx in Array.from({ length: to || 5 }, (_, i) => i + 1)"
        :value="idx"
        :key="idx"
        :label="String(idx)"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: ["to", "value"],

  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
