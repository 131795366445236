<template>
  <v-dialog v-model="dialog" content-class="my-shadow--e2" width="740">
    <v-card flat rounded="lg" elevation="0">
      <v-card-title class="text-body-2 pa-3">
        Add new project
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="px-3 pt-3">
        <v-row>
          <v-col cols="12">
            <div class="text-body-2">Name</div>
            <v-text-field
              v-model="form.name"
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div class="text-body-2">URL</div>
            <v-text-field
              v-model="form.url"
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <div class="text-body-2">Country</div>
            <v-autocomplete
              v-model="form.country"
              :items="$store.state.reuse.countries"
              placeholder="Type to search"
              hide-details
              dense
              item-text="name"
              item-value="id"
              outlined
            />
          </v-col>
          <v-col cols="4">
            <div class="text-body-2">Language</div>
            <v-autocomplete
              v-model="form.language"
              :items="$store.state.reuse.languages"
              placeholder="Type to search"
              hide-details
              dense
              item-text="name"
              item-value="id"
              outlined
            />
          </v-col>
          <v-col cols="4">
            <div class="text-body-2">Niche</div>
            <v-autocomplete
              v-model="form.niche"
              :items="filtersData?.niches"
              :loading="filtersDataPendingKeys?.niches"
              placeholder="Type to search"
              hide-details
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn class="px-6 text-normal" large text @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn
          class="px-6 text-normal"
          large
          color="success"
          @click="handleCreateProject"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../mixins/Dialog";
import { projectApiService } from "../../../views/project/services/projectApiService";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

export default {
  mixins: [Dialog, UploadFiltersDataMixin],

  data: () => ({
    form: {
      name: "",
      url: "",
      country: "",
      language: "",
      niche: "",
    },
    loadings: {
      create: false,
    },
  }),

  mounted() {
    this.uploadFiltersData(["niches"]);
  },

  methods: {
    resetForm() {
      this.form = {
        name: "",
        url: "",
        country: "",
        language: "",
        niche: "",
      };
    },
    async handleCreateProject() {
      try {
        this.loadings.create = false;

        const { data } = await projectApiService().createProject(this.form);

        if (!data?.id) {
          return this.$message.notification({
            title: "Not created",
            text: "The project has not been created, please check the completed data and try again.",
            type: "error",
          });
        }

        this.$message.notification({
          title: "Successfully created",
          text: `The project ${this.form.name} has been successfully created.`,
          type: "success",
        });

        this.dialog = false;
        this.resetForm();
        this.$emit("created");
      } catch (e) {
        console.error("Error while creating project.", e);
      } finally {
        this.loadings.create = false;
      }
    },
  },
};
</script>
