export const FREELANCER_DATA_TABLE_ITEMS = [
  {
    id: 2,
    name: "Bob",
    contacts: "asd",
    language_id: 1,
    status: 0,
    price_per_100_words: "asd",
    experience: "asd",
    thematics: "asd",
    specialization: "asd",
    portfolio: null,
    avg_complete_time: "asd",
    seo_knowledge: "asd",
    niche_knowledge: "asd",
    texts_quality: null,
    avg_edits_time: null,
    payments: "asd",
    min_order: "asd",
    price_text_optimization: null,
    price_urgency: null,
    punctual: "asd",
    communication: "asd",
    summary_recommendation: null,
    comments: null,
    nda: null,
    experience_competitors: null,
    created_at: "2025-01-20T13:02:21.000000Z",
    updated_at: "2025-01-20T13:02:21.000000Z",
  },
];

export const FREELANCER_DATA_TABLE_HEADERS = [
  {
    text: "",
    value: "_actions",
    sortable: false,
  },
  {
    text: "Name / Nickname",
    value: "name",
  },
  {
    text: "Contact",
    value: "contacts",
  },
  {
    text: "Working Language",
    value: "language_id",
  },
  {
    text: "Rate per 100 Words",
    value: "price_per_100_words",
  },
  {
    text: "Experience in Gambling/Betting (Years)",
    value: "experience",
  },
  {
    text: "Topics",
    value: "thematics",
  },
  {
    text: "Specialization",
    value: "specialization",
  },
  {
    text: "Freelancer Status",
    value: "status",
  },
  {
    text: "Portfolio / Cases",
    value: "portfolio",
  },
  {
    text: "Average Delivery Time",
    value: "avg_complete_time",
  },
  {
    text: "SEO Knowledge (Yes/No)",
    value: "seo_knowledge",
  },
  {
    text: "Understanding of Terminology and Niche Knowledge (1-10)",
    value: "niche_knowledge",
  },
  {
    text: "Text Quality (1-10)",
    value: "texts_quality",
  },
  {
    text: "Average Time for Revisions and Corrections",
    value: "avg_edits_time",
  },
  {
    text: "Payment Methods",
    value: "payments",
  },
  {
    text: "Minimum Order",
    value: "min_order",
  },
  {
    text: "Rate for Text Optimization",
    value: "price_text_optimization",
  },
  {
    text: "Rate for Urgent Orders",
    value: "price_urgency",
  },
  {
    text: "Punctuality",
    value: "punctual",
  },
  {
    text: "Communication",
    value: "communication",
  },
  {
    text: "Final Recommendation",
    value: "summary_recommendation",
  },
  {
    text: "Comments",
    value: "comments",
  },
  {
    text: "NDA Availability",
    value: "nda",
  },
  {
    text: "Experience with Competitors",
    value: "experience_competitors",
  },
  {
    text: "Created",
    value: "created_at",
    width: "160px",
  },
  {
    text: "Updated",
    value: "updated_at",
    width: "160px",
  },
];
