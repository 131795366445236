<template>
  <div class="ltt-pro-all">
    <v-alert
      border="right"
      colored-border
      type="error"
      elevation="2"
      v-if="urls_with_bad_performance.length > 0"
    >
      You have {{ urls_with_bad_performance.length }} urls with performance less
      than 50%.
    </v-alert>

    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12">
          <page-header
            title="LTT PRO - ALL"
            :breadcrumbs="[
              { text: 'Home', to: '/' },
              { text: 'Ltt Pro', disabled: true },
              { text: 'All', disabled: true },
            ]"
          />
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                dense
                v-model="filter.niche"
                :items="data.niches"
                item-value="id"
                item-text="name"
                outlined
                label="Niche"
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filter.seo"
                :items="seoItems"
                dense
                :loading="loadings.seo"
                label="Responsible seo"
                clearable
                outlined
                placeholder="Select user"
                item-text="email"
                item-value="id"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <smart-date-filter
                :model-value="filter.date_range"
                :default-type="true"
                @update:modelValue="filter.date_range = $event"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-switch
                class="ma-1"
                v-model="filter.not_checking_links"
                dense
                :true-value="1"
                :false-value="null"
                inset
                label="Not checking links"
                hide-details
              ></v-switch>
            </v-col>
            <v-col cols="fill" class="d-flex justify-end">
              <v-btn
                style="height: 40px"
                width="180"
                color="primary"
                class="text-normal"
                @click="fetch_data"
                :loading="loadings.tables"
              >
                Get Data
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <Links
        v-for="item in items"
        v-bind:key="item.id"
        :items="item.data"
        :name="item.name"
        :search_active="false"
        :id_project="project_id"
        :min_parameters="min_parameters"
      ></Links>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            @click="$store.dispatch('toggle_select_project_menu')"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "../../events/eventPipe";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import Location from "../../mixins/Location";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import PageHeader from "../UI/PageHeader.vue";
import Links from "./Child/Links.vue";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "IndexAll",
  mixins: [FiltersHandling, Location],
  components: { PageHeader, SmartDateFilter, Links },
  data() {
    return {
      loading: false,
      project_id: this.$store.state.project.active_project,
      loadings: {
        fetch_data: false,
        tables: false,
      },
      data: {
        niches: [
          { id: 1, name: "essay" },
          { id: 2, name: "casino" },
        ],
      },
      min_parameters: {
        crowd: [],
        guest_post: [],
      },
      date_modal: false,
      seoItems: [],
      filter: {
        seo: null,
        niche: 1,
        not_checking_links: false,
        date_range: [
          dateFormat(new Date().setDate(date.getDate() - 2), "yyyy-mm-dd"),
          dateFormat(date, "yyyy-mm-dd"),
        ],
      },
      items: [],
      urls_with_bad_performance: [],
      //    problems_types: [],
      //  problems_priorities: [],
    };
  },
  async created() {
    await this.initializeFiltersWatcher("filter");
    await this.collectParametersFromQuery("filter");

    this.fetchUsers();

    eventPipe.$on("update-active-project", () => {
      this.fetch_min_parameters();
      this.fetch_data();
    });

    this.fetch_min_parameters();
    this.update_filter();
    this.fetch_data();
  },
  methods: {
    async fetchUsers() {
      this.loadings.seo = true;
      const projectId = this.$store.state.project.active_project;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.seoItems = resp;
      }

      this.loadings.seo = false;
    },
    fetch_data() {
      this.date_modal = false;

      if (this.id_project !== null && this.id_project !== undefined) {
        this.loadings.tables = true;
        let url = `${this.$store.state.server_url}/ltt/base/0`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          filter: this.filter,
        };

        axios
          .post(url, data, config)
          .then((response) => {
            this.items = response.data;
          })
          .catch(function (error) {
            alert(error);
          })
          .finally(() => {
            this.loadings.tables = false;
          });
      }
    },
    fetch_min_parameters() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/ltt/project/min-parameters/${self.id_project}`;

        self.update_loading("fetch_min_parameters", true);

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {};

        axios
          .post(url, data, config)
          .then(function (response) {
            self.min_parameters.crowd = response.data.crowd;
            self.min_parameters.guest_post = response.data.guest_post;
          })
          .catch(function (error) {
            alert(error);
          })
          .finally(() => {
            self.update_loading("fetch_min_parameters", false);
          });
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
    update_filter() {
      let data = this.$route.query;
      if (data.project) {
        this.$store.state.project.active_project = data.project;

        if (data.date_range)
          this.filter.date_range = data.date_range.split(",");
        if (data.type) this.filter.type = Number(data.type);

        this.$router.replace({ query: null });
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    date_range_text() {
      return this.filter.date_range.join(" ~ ");
    },
  },
};
</script>

<style lang="scss">
.ltt-pro-all {
  .v-card {
  }
}
</style>
