<template>
  <div class="content-planing">
    <v-row>
      <v-col cols="12">
        <page-header
          title="Content Planning"
          :breadcrumbs="breadcrumbs"
        ></page-header>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <div class="content-planing__filters">
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.data_source"
                label="Data source"
                outlined
                dense
                clearable
                :items="filtersData.data_source"
                hide-details
                @change="handleFilterChange($event, 'data_source')"
                :menu-props="menuProps"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.team"
                label="Team"
                :items="filtersData.team"
                hide-details
                dense
                outlined
                clearable
                @change="handleFilterChange($event, 'team')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <smart-autocomplete
                :model-value="filters.project"
                @update:modelValue="filters.project = $event"
                label="Project"
                outlined
                :items="_$getProjects"
                item-text="name"
                item-value="id"
                name="project"
              ></smart-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.page_type"
                label="Page type"
                :items="filtersData.page_type"
                hide-details
                dense
                outlined
                clearable
                @change="handleFilterChange($event, 'page_type')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.executive"
                label="Executive"
                :items="executive"
                item-text="text"
                item-value="value"
                dense
                clearable
                outlined
                hide-details
                @change="
                  handleFilterChange($event, FILTERS_QUERY_IDS.EXECUTIVE)
                "
                prepend-inner-icon="mdi-account-outline"
                :menu-props="menuProps"
                :loading="loadings.filters"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <div class="content-planing__date-picker">
                <smart-date-filter
                  :model-value="filters.date"
                  @update:modelValue="filters.date = $event"
                  outlined
                />
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.type"
                label="Content type"
                dense
                clearable
                outlined
                :items="['current', 'new']"
                hide-details
                @change="handleFilterChange($event, FILTERS_QUERY_IDS.TYPE)"
                :menu-props="menuProps"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.niche"
                label="Niche"
                :items="niches"
                item-value="id"
                outlined
                item-text="name"
                dense
                clearable
                @change="handleFilterChange($event, FILTERS_QUERY_IDS.NICHE)"
                hide-details
                :menu-props="menuProps"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project_status"
                :items="filtersData.project_status"
                hide-details
                dense
                :loading="loadings.filters"
                :menu-props="menuProps"
                label="Project Status"
                outlined
                clearable
                @change="handleFilterChange($event, 'project_status')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill" class="d-flex align-center">
              <v-checkbox
                v-model="filters.show_worksheet"
                label="Show worksheet"
                dense
                class="ma-0"
                hide-details
                @change="handleFilterChange($event, 'show_worksheet')"
                :true-value="true"
                :false-value="null"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project_categories"
                :items="filtersData.project_categories"
                hide-details
                multiple
                dense
                outlined
                label="Project categories"
                placeholder="Type to search"
              />
            </v-col>
            <v-scroll-x-transition>
              <v-col
                v-if="filters.data_source === 'by_elements'"
                cols="6"
                md="4"
                lg="2"
                xl="fill"
              >
                <v-autocomplete
                  v-model="filters.element"
                  :items="filtersData.element"
                  :loading="loadings.filters"
                  dense
                  outlined
                  hide-details
                  clearable
                  multiple
                  label="Element"
                  placeholder="Select"
                  @change="handleFilterChange($event, 'element')"
                ></v-autocomplete>
              </v-col>
            </v-scroll-x-transition>
            <v-col class="d-flex justify-end" cols="6" md="4" lg="2" xl="fill">
              <v-btn
                block
                height="40"
                class="text-normal"
                color="primary"
                @click="handleGetData"
                :loading="loadings.tableData"
              >
                Get data
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="projectCards.length !== 0" class="pt-4">
      <v-col class="d-flex">
        <table-search
          style="max-width: 420px"
          variant="vuetify"
          :model-value="projectSearchModel"
          @update:modelValue="projectSearchModel = $event"
          hide-details
          dense
          append-icon="mdi-magnify"
          outlined
          placeholder="Search by project name"
        ></table-search>
        <v-spacer />
        <v-btn
          height="40"
          class="text-normal"
          @click="colapseAllSections(projectCards)"
          title="Collapse section"
        >
          Collapse all sections
          <v-icon small right>mdi-format-vertical-align-center</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="projectCards.length === 0 && loadings.tableData">
      <div class="mt-3 pa-3" style="position: relative">
        <Loader :show="loadings.tableData" />
        <v-skeleton-loader type="table-row-divider@6"></v-skeleton-loader>
      </div>
    </template>
    <v-row dense>
      <template v-for="card in projectCards">
        <v-col
          v-if="
            (projectSearchModel && card.project.includes(projectSearchModel)) ||
            !projectSearchModel
          "
          :key="`${card.id}-card`"
          cols="12"
        >
          <v-card flat outlined rounded="lg" class="styled-card--darken-body">
            <v-card-title class="pa-4 text-body-2">
              <v-icon small left @click="goTo(card.project)">
                mdi-open-in-new
              </v-icon>
              {{ card.project }}
              <v-tooltip bottom content-class="pa-0 my-shadow--e3">
                <template #activator="{ on }">
                  <v-chip
                    v-on="on"
                    :color="getProjectStatusColor(card.project_status)"
                    style="width: 12px; height: 12px"
                    class="pa-0 ml-2"
                  >
                    <!--            {{ card.project_status }}-->
                  </v-chip>
                </template>
                <v-card class="pa-2 styled-card--light">
                  <div>
                    <small>
                      <b> Status </b>
                    </small>
                  </div>
                  <div>
                    {{ card.project_status }}
                  </div>
                </v-card>
              </v-tooltip>
              <v-spacer />
              <v-chip
                outlined
                label
                @click="collapseSection(card)"
                :title="`${
                  card.collapsed ? 'Expand section' : 'Collapse section'
                }`"
              >
                {{ card.collapsed ? "Expand" : "Collapse" }}
                <v-icon small right>
                  {{ card.collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
                </v-icon>
              </v-chip>
            </v-card-title>
            <template v-if="!card.collapsed">
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-card class="styled-card--default pa-0">
                      <div
                        v-for="(table, index) in tables"
                        :key="`${index}-styled-card`"
                      >
                        <v-data-table
                          v-if="
                            card[table.items] && card[table.items].length > 0
                          "
                          :headers="card[table.headers]"
                          :items="card[table.items]"
                          disable-sort
                          hide-default-footer
                          class="content-planing-table"
                        >
                          <template
                            v-for="key in card[table.headers].map(
                              (v) => v.value
                            )"
                            #[`item.${key}`]="{ item, value }"
                          >
                            <template v-if="key === 'type'">
                              <type-td :data="item" :key="key" />
                            </template>
                            <template v-else-if="key === 'subproj'">
                              <subproject-td :data="item" :key="key" />
                            </template>
                            <template v-else-if="key === 'page'">
                              <page-td :data="item" :key="key" />
                            </template>
                            <template v-else-if="key === 'amount'">
                              <amount-td :data="item" :key="key" />
                            </template>
                            <template v-else-if="statuses.includes(key)">
                              <v-chip
                                label
                                color="transparent"
                                @click="goToSw(card.projectId, value)"
                                :key="key"
                              >
                                {{ getValue(value) }}
                              </v-chip>
                            </template>
                            <template v-else-if="key === 'overdue'">
                              <v-chip
                                label
                                @click="goToSw(card.projectId, value)"
                                :class="`flat-chip flat-chip--${getOverdueColor(
                                  value
                                )}`"
                                :key="key"
                              >
                                {{ getValue(value) }}
                              </v-chip>
                            </template>
                            <template v-else-if="isWeekTd(key)">
                              <div
                                @click="goToSw(card.projectId, value)"
                                class="colored-td colored-td--inline colored-td--clickable"
                                :key="`${key}-week`"
                              >
                                <CustomBadge :data="value" />
                              </div>
                            </template>
                            <template v-else>
                              {{ getValue(value) }}
                            </template>
                          </template>
                        </v-data-table>
                        <v-divider v-if="index !== tables.length - 1" />
                      </div>
                    </v-card>
                    <v-card
                      v-for="(table, idx) in card.worksheet_table.items"
                      class="styled-card--primary-header pa-0 mt-4"
                      :key="`wst-${idx}`"
                    >
                      <v-card-title>
                        <v-row dense>
                          <v-col>
                            {{ table.name }}
                          </v-col>
                          <v-col>
                            <table-search
                              :model-value="table.search"
                              @update:modelValue="table.search = $event"
                              placeholder="Search in subproject"
                            />
                          </v-col>
                          <v-col class="d-flex justify-end">
                            <chip-select
                              :model-value="table.sort"
                              @update:modelValue="table.sort = $event"
                              :items="[
                                {
                                  text: 'Default',
                                  value: 'default',
                                },
                                {
                                  text: 'Deadline',
                                  value: 'deadline',
                                },
                              ]"
                              rounded
                              label="Sort by"
                            >
                              <template #prepend>
                                <v-icon small left>mdi-filter</v-icon>
                              </template>
                            </chip-select>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-divider />
                      <v-card-text class="pa-0">
                        <v-data-table
                          :search="table.search"
                          :items="table.items"
                          :headers="table.headers"
                          class="relative-tr"
                          hide-default-footer
                          multi-sort
                          :items-per-page="-1"
                          :sort-by="worksheetsTableSorting.sort[table.sort]"
                          :sort-desc="worksheetsTableSorting.desc[table.sort]"
                          :custom-sort="_$smartCustomTableSort"
                        >
                          <template
                            v-for="key in table.headers.map((v) => v.value)"
                            #[`item.${key}`]="{ value, item }"
                          >
                            <div :key="key">
                              <template v-if="isStatusTd(key)">
                                <status-td :data="value" />
                              </template>
                              <template
                                v-else-if="key === 'last_change_status'"
                              >
                                <last-change-status
                                  :value="value"
                                  :data="item"
                                />
                              </template>
                              <template v-else-if="key === 'el_dl'">
                                <element-deadline
                                  :value="value"
                                  :data="item"
                                  :details="item.el_dl_details"
                                />
                              </template>
                              <template v-else-if="key === 'el_resp'">
                                <user-preview
                                  :name="value"
                                  hide-initials
                                  :opacity="0.3"
                                />
                              </template>
                              <template v-else-if="key === 'page_resp'">
                                <user-preview
                                  :name="value"
                                  hide-initials
                                  :opacity="0.3"
                                />
                              </template>
                              <template v-else-if="key === 'json'">
                                <div
                                  class="fixed-table-row-actions absolute"
                                  style="
                                    right: 6px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                  "
                                >
                                  <v-chip
                                    label
                                    title="History"
                                    color="white"
                                    small
                                    @click="handleShowHistory(item)"
                                  >
                                    <v-icon small left>mdi-history</v-icon>
                                    HISTORY
                                  </v-chip>
                                </div>
                              </template>
                              <template v-else-if="key === 'url'">
                                <a
                                  :href="'http://' + card.project + value"
                                  target="_blank"
                                >
                                  {{ value }}
                                </a>
                              </template>
                              <template v-else-if="key === 'current_status'">
                                <current-status :data="value" />
                              </template>
                              <template v-else-if="key === 'page_dl'">
                                <page-deadline
                                  :value="value"
                                  :data="item"
                                  :details="item.page_dl_details"
                                />
                              </template>
                              <template v-else>
                                {{ value }}
                              </template>
                            </div>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <url-history-dialog
      :model-value="urlHistoryDialogModel"
      @update:modelValue="urlHistoryDialogModel = $event"
      :loading="loadings.urlHistory"
      :data="urlHistoryTableData"
    />
  </div>
</template>

<script>
import {
  CURRENT_ITEMS_INTERFACE,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import CustomBadge from "@/components/Dashboards/ContentPlaning/components/CustomBadge";
import TypeTd from "@/components/Dashboards/ContentPlaning/components/TypeTd";
import SubprojectTd from "@/components/Dashboards/ContentPlaning/components/SubprojectTd";
import PageTd from "@/components/Dashboards/ContentPlaning/components/PageTd";
import AmountTd from "@/components/Dashboards/ContentPlaning/components/AmountTd";
import Loader from "@/components/Main/Loader";
import { deepClone, uid } from "@/utils/functions";
import Location from "@/mixins/Location";

import Project from "@/mixins/Project";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import StatusTd from "@/components/Dashboards/ContentPlaning/components/StatusTd.vue";
import service from "@/plugins/service";
import UrlHistoryDialog from "@/components/Dashboards/ContentPlaning/components/UrlHistoryDialog.vue";
import SmartAutocomplete from "@/components/UI/SmartAutocomplete.vue";
import ChipSelect from "@/components/ChipSelect.vue";
import TableSorting from "@/mixins/TableSorting";
import CurrentStatus from "@/components/Dashboards/ContentPlaning/components/CurrentStatus.vue";
import UserPreview from "@/components/UI/UserPreview.vue";
import LastChangeStatus from "@/components/Dashboards/ContentPlaning/components/LastChangeStatus.vue";
import ElementDeadline from "@/components/Dashboards/ContentPlaning/components/ElementDeadline.vue";
import PageDeadline from "@/components/Dashboards/ContentPlaning/components/PageDeadline.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import TableSearch from "../../../components/UI/TableSearch.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

export default {
  components: {
    PageDeadline,
    ElementDeadline,
    LastChangeStatus,
    UserPreview,
    CurrentStatus,
    ChipSelect,
    SmartAutocomplete,
    UrlHistoryDialog,
    TableSearch,
    StatusTd,
    PageHeader,
    SmartDateFilter,
    AmountTd,
    PageTd,
    SubprojectTd,
    TypeTd,
    CustomBadge,
    Loader,
  },
  mixins: [
    Location,
    FiltersHandling,
    Project,
    TableSorting,
    UploadFiltersDataMixin,
  ],
  data: () => ({
    worksheetsTableSorting: {
      sort: {
        default: ["pr", "nt", "cl_28d", "imp_28d"],
        deadline: ["el_dl", "page_dl", "pr", "nt", "cl_28d", "imp_28d"],
      },
      desc: {
        default: [false, false, true, true],
        deadline: [false, false, false, false, true, true],
      },
    },
    urlHistoryTableData: {},
    urlHistoryDialogModel: false,
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Content Planning",
        disabled: true,
      },
    ],
    tables: [
      {
        items: "newItems",
        headers: "newHeaders",
      },
      {
        items: "currentItems",
        headers: "currentHeaders",
      },
    ],
    statuses: [
      "new",
      "in_progress",
      "to_do",
      "ta",
      "cw",
      "need_details",
      "waiting_for_content",
      "proofreading",
      "need_review",
      "edit_text_",
      "publishing",
      "wait_for_indexation",
      "need_approve",
      "approved",
      "done",
      "dev",
    ],
    menuProps: DEFAULT_MENU_PROPS,
    executive: [],
    projectCards: [],
    projectSearchModel: "",
    filtersData: {
      executive: [],
      data_source: [],
      project_status: [],
      project_categories: [],
      team: [],
    },
    filters: {
      project_categories: [],
      date: [],
      page_type: null,
      element: [],
      team: null,
      niche: 2,
      executive: null,
      show_worksheet: null,
      type: null,
      project_id: null,
      project: [],
      project_status: 1,
      data_source: "by_pages",
    },
    FILTERS_QUERY_IDS: {
      NICHE: "niche",
      EXECUTIVE: "executive",
      TYPE: "type",
    },
    menus: {
      date: null,
    },
    loading: false,
    loadings: {
      urlHistory: false,
      filters: false,
      tableData: false,
    },
    elementsOnLoading: [],
    currentTableItemsInterface: CURRENT_ITEMS_INTERFACE,
    searchTimeout: null,
  }),

  watch: {
    projectSearchModel(query = "") {
      console.log(query);
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(
        () => this.handleSearchProject(query),
        500
      );
    },
  },

  async mounted() {
    this.uploadFiltersData(["project_categories"]);
    await this.fetchFilters();
    await this.parseQuery("filters");

    if (!this.$route.query.date) {
      const start = this.$moment(new Date())
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = this.$moment(new Date()).endOf("month").format("YYYY-MM-DD");
      this.filters.date = [start, end];
    }

    if (Object.keys(this.$route.query).length)
      this.$nextTick(() => {
        this.fetchTableData();
      });
  },

  methods: {
    colapseAllSections(sections) {
      sections.forEach((section) => {
        section.collapsed = true;
      });
    },
    collapseSection(section) {
      section.collapsed = !section.collapsed;
    },
    async handleShowHistory(rowData) {
      try {
        const url = `/dashboards/content/planning-workplace-history/${rowData.url_id}/${rowData.field_id}`;

        this.loadings.urlHistory = true;
        this.urlHistoryDialogModel = true;

        const response = await service.post(url);

        this.urlHistoryTableData = response.data || {};

        console.log("CHECK RESPONSE", response);
      } catch (e) {
        console.error("Error while loading history.", e);
      } finally {
        this.loadings.urlHistory = false;
      }
    },
    isStatusTd(keyString) {
      const regex = /^status_/;

      return regex.test(keyString);
    },
    getProjectStatusColor(statusString) {
      const fixedStatusString = String(statusString).toLowerCase();

      const colors = {
        pause: "info",
        disable: "error",
        active: "success",
      };

      return colors[fixedStatusString];
    },
    getOverdueColor(value) {
      if (!value.value || value.value === "-") return "transparent";
      return "error";
    },
    goToSw(projectId, data) {
      // Go To Site Workplace
      if (typeof data !== "object") return;

      // eslint-disable-next-line no-prototype-builtins
      if (!data.hasOwnProperty("filters")) return;

      let query = "";

      Object.keys(data.filters).forEach((key) => {
        const prefix = query.length === 0 ? "?" : "&";
        let value = data.filters[key];

        if (String(value) !== "0" && !value) return;

        if (Array.isArray(value)) {
          if (value.length === 1 && value[0] === "-") {
            value = null;
          } else {
            value = value.join(",");
          }
        }

        if (String(value) !== "0" && !value) return;

        query += `${prefix}${key}=${value}`;
      });

      const projectIdQuery = "&project_id=" + projectId;

      let dashboard =
        this.filters["data_source"] === "by_pages"
          ? "dashboard"
          : "site-worksheet";

      window.open(
        "/site-workplace/" + dashboard + "/" + query + projectIdQuery,
        "_blank"
      );
    },
    getValue(data) {
      // eslint-disable-next-line no-prototype-builtins
      if (typeof data === "object" && data.hasOwnProperty("value")) {
        return data.value;
      }
      return data;
    },
    getValueDoubled(data) {
      // eslint-disable-next-line no-prototype-builtins
      if (typeof data === "object" && data.hasOwnProperty("pages")) {
        return data.pages + " | " + data.pagesWithElems;
      }
      return data;
    },
    handleTableHeaderClick(e, cardData) {
      if (e.path[0].className === "table__header") {
        this.collapseProject(cardData);
      }
    },
    handleFilterChange(newValue, type) {
      this._$setQueryParams(type, newValue);
    },
    handleSearchProject(query) {
      const projectElements = document.querySelectorAll(".table__wrapper");
      if (!query) {
        return this.renderAllCardsElements(projectElements);
      }
      query = query.trim().toLowerCase();

      Array.from(projectElements).forEach((item) => {
        const id = item.classList[1].split("-")[1];
        const projectData = this.projectCards.find((card) => card.id === id);
        const matchProjectName = projectData.project
          .toLowerCase()
          .includes(query);

        if (matchProjectName) {
          return this.renderSingleCardElement(item);
        }
        this.disableSingleCardElement(item);
      });
    },
    renderSingleCardElement(element) {
      element.classList.remove("didnt-match");
      element.classList.add("match");
    },
    disableSingleCardElement(element) {
      element.classList.remove("match");
      element.classList.add("didnt-match");
    },
    renderAllCardsElements(elements) {
      Array.from(elements).forEach((item) => {
        item.classList.remove("didnt-match");
        item.classList.remove("match");
      });
    },
    formateTdText(...args) {
      if (!args[0] && !args[1]) {
        return "-";
      }
      if (args[0] === "-" && args[1] === "-") {
        return "-";
      }
      return `${args[0]} | ${args[1]}`;
    },

    setCardsDefaultHeight() {
      const tableElements = document.querySelectorAll(".table__content");
      Array.from(tableElements).forEach((elem) => {
        elem.style.height = elem.scrollHeight + "px";
      });
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = [
        "executive",
        "project_status",
        "element",
        "data_source",
        "team",
        "page_type",
      ];
      const payload = {
        type: "/dashboards/content/planning",
        take: filters,
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      this.executive = resp.executive;
      this.filtersData = { ...this.filtersData, ...resp };
      this.loadings.filters = false;
    },
    async fetchTableData() {
      try {
        this.loadings.tableData = true;
        const formData = {
          filter: {
            ...this.filters,
            date_from: this.filters.date[0],
            date_to: this.filters.date[1],
          },
        };

        const cards = await this.$store.dispatch(
          "contentPlaning/fetchTableData",
          formData
        );
        if (!cards) {
          return (this.loadings.tableData = false);
        }
        this.projectCards = this.createProjectCard(cards.items);
      } catch (e) {
        console.error("Error while loading tables.", e);
        throw e;
      } finally {
        this.loadings.tableData = false;
      }
    },
    isWeekTd(key) {
      const regex = /[0-9]?[0-9]w/gm;

      return regex.test(key);
    },
    fixTableHeaders(headers) {
      let fixed = deepClone(headers);

      fixed.map((header) => {
        if (["type", "subproj", "page"].includes(header.value)) {
          header.width = "120px";
        } else {
          header.align = "center";
        }

        return header;
      });

      return fixed;
    },
    fixWorksheetsTableHeaders(headers) {
      let fixed = deepClone(headers);

      fixed.map((header) => {
        if (!["url"].includes(header.value)) {
          header.align = "center";
        }

        if (header.value === "json") {
          header.sortable = false;
        }

        return header;
      });

      fixed = [...fixed, { value: "json", text: "" }];

      return fixed;
    },
    initializeJsonInTableItems(items) {
      let fixed = deepClone(items);

      fixed.map((item) => {
        item.json = JSON.stringify(item);

        return item;
      });

      return fixed;
    },
    createProjectCard(cards = []) {
      return cards.map((card) => {
        card.collapsed = false;
        card.projectId = card.id;
        card.id = uid();

        card.currentHeaders = this.fixTableHeaders(card.currentHeaders);
        card.newHeaders = this.fixTableHeaders(card.newHeaders);

        card.worksheet_table?.items?.map((table) => {
          table.search = null;
          table.sort = "default";

          table.headers = this.fixWorksheetsTableHeaders(table.headers);

          table.items = this.initializeJsonInTableItems(table.items);

          return table;
        });

        return card;
      });
    },
    collapseAllProjects() {
      this.projectCards.forEach((card) => (card.collapsed = true));
      const tableElements = document.querySelectorAll(".table__content");
      const tableWrapperElements = document.querySelectorAll(".table__wrapper");
      Array.from(tableElements).forEach((table) => {
        table.style.height = "0";
        table.style.margin = "0";
      });
      Array.from(tableWrapperElements).forEach(
        (wrapper) => (wrapper.style.padding = "0")
      );
    },
    async collapseProject(card) {
      const id = card.id;

      const isCollapsed = this.projectCards.find(
        (card) => card.id === id
      ).collapsed;
      this.projectCards.find((card) => card.id === id).collapsed = !isCollapsed;

      const projectElement = document.querySelector(`.table-${id}`);
      const wrapperElement = document.querySelector(`.wrapper-${id}`);
      const sh = projectElement.scrollHeight;

      await setTimeout(() => {}, 100);

      if (isCollapsed) {
        wrapperElement.style.padding = "0 0 40px 0";
        projectElement.style.margin = "10px";
        return (projectElement.style.height = sh + "px");
      }
      wrapperElement.style.padding = "0";
      projectElement.style.margin = "0";
      projectElement.style.height = "0";
    },
    async handleGetData() {
      await this.fetchTableData();
      // this.setCardsDefaultHeight();
    },
    goTo(path) {
      window.open(`https://${path}`, "_blank");
    },
    getTrClasses(tr) {
      const classes = [];
      if (tr.type !== "") classes.push("type-tr");

      if (tr.subproject !== "") classes.push("subproj-tr");

      return classes.join(" ");
    },
    isExist(item, name) {
      return item[name] !== "" && item[name] !== null;
    },
    calculateProgressBarW(tr, doubled = false) {
      if (doubled) {
        const pages = replaceNan(tr.amount.pagesWithElems);
        const pagesDone = replaceNan(tr.done.current);
        const pagesWithElems = replaceNan(tr.amount.pages);
        const pagesWithElemsDone = replaceNan(tr.done.pages);
        const pagePercent = `${(pagesDone / pages) * 100}%`;
        const pagesWithElemsPercent = `${
          (pagesWithElemsDone / pagesWithElems) * 100
        }%`;
        return [pagePercent, pagesWithElemsPercent];
      }
      const done = Number(tr.done);
      const amount = Number(tr.amount);
      return `${(done / amount) * 100}%`;

      function replaceNan(val) {
        return isNaN(val) ? 0 : Number(val);
      }
    },
  },
  computed: {
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
};
</script>

<style lang="scss">
.clcb {
  white-space: nowrap;
  cursor: pointer;

  & > div {
    padding: 0 10px 0 2px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
