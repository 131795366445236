<template>
  <v-dialog
    v-model="dialog"
    width="1280"
    scrollable
    persistent
    content-class="my-shadow--e2 my-0"
  >
    <element-control-form
      :form="formData"
      :loading="loading"
      @update:form="formData = $event"
      @closeDialog="dialog = false"
      @statusSuccessfullyUpdated="
        $emit('statusSuccessfullyUpdated', data?._ctx)
      "
    />
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import ElementControlForm from "./ElementControlForm.vue";
import UploadFiltersDataMixin from "../../../../mixins/UploadFiltersDataMixin";

export default {
  components: { ElementControlForm },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    data: {
      handler() {
        this.formData = { ...this.data };
      },
      deep: true,
      immediate: true,
    },
  },

  mixins: [Dialog, UploadFiltersDataMixin],

  data: () => ({
    formData: {},
  }),

  provide() {
    return {
      filtersData: () => this.filtersData,
      filtersDataPendingKeys: () => this.filtersDataPendingKeys,
    };
  },

  mounted() {
    this.uploadFiltersData(["users", "languages", "currencies"]);
  },
};
</script>
