<template>
  <div v-if="historyItem" class="text-body-2 py-2 px-4">
    <div class="d-flex justify-space-between">
      <div>
        <b>
          {{ historyItem?.status?.name }}
          <status-icon color="primary" small />
        </b>
      </div>
      <div>
        <v-tooltip bottom open-delay="30">
          <template #activator="{ on }">
            <div v-on="on">
              <v-icon small>mdi-calendar</v-icon>
              {{ $moment(historyItem.date).format("DD.MM.YY") }}
            </div>
          </template>
          {{ $moment(historyItem.date).format("lll") }}
        </v-tooltip>
      </div>
    </div>
    <div class="d-flex mt-2" style="gap: 0.25rem">
      <v-avatar size="38">
        <v-img :src="historyItem?.user?.photo" />
      </v-avatar>
      <div>
        <div>
          <b>{{ historyItem?.user?.name }}</b>
        </div>
        <div>{{ historyItem?.user?.email }}</div>
      </div>
    </div>
    <v-card
      v-if="historyItem?.fields?.length"
      flat
      outlined
      rounded="lg"
      class="pa-0 mt-4"
    >
      <v-card-text class="pa-0">
        <template v-for="(item, idx) in historyItem?.fields">
          <div v-if="item" :key="`field-${idx}`" class="pa-3">
            <div class="d-flex">
              <div>
                <b>{{ item?.field?.name }}</b>
              </div>
              <v-spacer />
              <div style="opacity: 0.5">
                <v-tooltip bottom open-delay="30">
                  <template #activator="{ on }">
                    <div v-on="on">
                      {{ $moment(item.updated_at).format("DD.MM.YY") }}
                    </div>
                  </template>
                  {{ $moment(item.updated_at).format("lll") }}
                </v-tooltip>
              </div>
            </div>
            <div>{{ getValue(item) }}</div>
          </div>
          <v-divider
            v-if="idx !== historyItem?.fields?.length - 1"
            :key="`divider-${idx}`"
          />
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { CONTENT_FIELD_TYPES } from "../defaults";
import StatusIcon from "./StatusIcon.vue";

export default {
  components: { StatusIcon },
  props: ["historyItem"],

  methods: {
    getValue(item) {
      if (item.field.type === CONTENT_FIELD_TYPES.PRICE) {
        return `${item.value.value}${item.additional.symbol}`;
      }
      if (item.field.type === CONTENT_FIELD_TYPES.USERS) {
        return `${item?.additional?.email || item?.additional?.name}`;
      }

      return item.value;
    },
  },
};
</script>
