<template>
  <div>
    <v-btn fixed dark bottom right large color="pink" @click="dialog = true">
      Upload urls <v-icon small right>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      content-class="my-shadow--e4"
      max-width="680px"
    >
      <v-card class="styled-card--default">
        <v-card-title>
          Upload urls
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="4">
              <v-alert class="ma-0" text icon="mdi-bug" type="error">
                Errors: <strong>{{ stat.errors }}</strong>
              </v-alert>
            </v-col>
            <v-col cols="12" md="4">
              <v-alert class="ma-0" text icon="mdi-check" type="success">
                Success: <strong>{{ stat.success }}</strong>
              </v-alert>
            </v-col>
            <v-col cols="12" md="4">
              <v-alert
                class="ma-0"
                text
                icon="mdi-format-list-bulleted"
                type="info"
              >
                Urls: <strong>{{ stat.keys }}</strong>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="errors && errors.length > 0">
            <v-col>
              <v-expansion-panels flat class="border">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Errors
                    <template v-slot:actions>
                      <v-icon color="error"> mdi-bug </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-alert
                      v-for="item in errors"
                      :key="item.line"
                      text
                      class="ma-0"
                      type="error"
                    >
                      <div>
                        <b>
                          <small>Line</small>
                        </b>
                      </div>
                      <div>
                        {{ item.line }}
                      </div>
                      <div class="mt-4">
                        <b>
                          <small>Error message</small>
                        </b>
                      </div>
                      <div>
                        {{ item.error }}
                      </div>
                    </v-alert>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="styled-card--default">
                <v-card-title> Importing form </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <div>
                          Select the columns to import in the order in which you
                          load the data

                          <v-menu
                            bottom
                            content-class="my-shadow--e2"
                            offset-y
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <v-card class="styled-card--default pa-2">
                              <div class="mb-2">
                                <b>
                                  <small>Example</small>
                                </b>
                              </div>
                              <div>
                                url; priority; deadline; status; fields_status;
                                page_type; executive
                              </div>
                            </v-card>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="form.columns"
                          :rules="rules.columns"
                          label="Columns"
                          required
                          hide-details="auto"
                          dense
                          filled
                        />
                      </v-col>
                      <v-col cols="12" class="pb-0">
                        <div>
                          Insert data to import (no headers)

                          <v-menu
                            content-class="my-shadow--e2"
                            bottom
                            offset-y
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <v-card class="styled-card--default pa-2">
                              <div>
                                <b>
                                  <small> Examples </small>
                                </b>
                              </div>
                              <div class="pb-2">
                                https://site.com/url1; 20; 2020-01-12; new;
                                review; Title^Description; klih@boosta.co
                              </div>
                              <div>
                                https://site.com/url1; 10; 2020-01-12; new;
                                review; Title^Description; klih@boosta.co
                              </div>
                            </v-card>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="form.keys"
                          :rules="rules.keys"
                          label="Keywords"
                          hide-details="auto"
                          required
                          filled
                          :rows="3"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" class="pb-0">
                        What to do with existing urls
                      </v-col>
                      <v-col cols="12">
                        <v-radio-group
                          hide-details
                          dense
                          class="ma-0"
                          v-model="form.duplicates"
                        >
                          <v-radio :key="0" label="Skip" value="skip"></v-radio>
                          <v-radio
                            :key="1"
                            label="Replace"
                            value="replace"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip
                    large
                    class="px-6 mr-2"
                    color="transparent"
                    label
                    @click="dialog = false"
                  >
                    Close
                  </v-chip>
                  <v-chip
                    large
                    label
                    class="px-6"
                    color="primary"
                    @click="importData"
                    :disabled="status_importing"
                  >
                    Start Import
                    <v-progress-circular
                      v-if="status_importing"
                      class="ml-2"
                      size="18"
                      width="2"
                      indeterminate
                    />
                  </v-chip>
                </v-card-actions>
                <v-progress-linear
                  v-if="import_progress > 0"
                  v-model="import_progress"
                  :color="importProgressBarColor"
                ></v-progress-linear>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-expansion-panels flat>
                <v-expansion-panel class="border">
                  <v-expansion-panel-header disable-icon-rotate>
                    Allowed columns
                    <template v-slot:actions>
                      <v-icon color="info"> mdi-alert-circle-outline </v-icon>
                      <v-icon small>mdi-chevron-down</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div><b>url *</b> - url on which the phrase should be</div>
                    <div><b>priority</b> - (int) - integer values</div>
                    <div><b>top_priority</b> - (int) - integer values</div>
                    <div>
                      <b>need_top</b> - (int) - integer values, examples: 1, 3,
                      5, 10, 20, etc.
                    </div>
                    <div>
                      <b>page_type</b> - review, payment, games, bonus, casino
                    </div>
                    <div>
                      <b>deadline</b> - datetime (2020-01-12 15:00:00 or
                      2020-01-12)
                    </div>
                    <div>
                      <b>status</b> - new, to do, done, in progress, ta, cw,
                      publishing, dev
                    </div>
                    <div>
                      <b>fields_status</b> - Title%to do^Description%in progress
                      ... (separator - ^)
                    </div>
                    <div>
                      <b>fields_status_responsible</b> - Title%to
                      do%responsible@boosta.co%2024-07-31^Description%in
                      progres%responsible@boosta.co%2024-07-31 ... (separator -
                      ^)
                    </div>
                    <div>
                      <b>fields_deadline</b> -
                      Title%2021-12-15^Description%2021-12-20 ... (separator -
                      ^)
                    </div>
                    <div><b>executive</b> - klih@boosta.co</div>
                    <div><b>topic</b> - Essay</div>
                    <div><b>value</b> - 1</div>
                    <div><b>niche</b> - essay, casino</div>
                    <div><b>need_links</b> - 1</div>
                    <div><b>intent</b> - visa</div>
                    <div><b>main_intent</b> - payment</div>
                    <div><b>country</b> - (iso_alpha_2) (UA, US)</div>
                    <div><b>language</b> - (iso) (uk, en)</div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/plugins/service";

export default {
  name: "UploadUrls",
  data() {
    return {
      valid: true,
      form: {
        columns:
          "url; priority; deadline; status; fields_status; page_type; executive",
        keys: "",
        duplicates: "skip",
      },
      rules: {
        columns: [(v) => !!v || "Columns is required"],
        keys: [(v) => !!v || "Keywords is required"],
      },
      dialog: false,
      status_importing: false,
      importProgressBarColor: "primary",
      import_progress: 0,
      stat: {
        errors: 0,
        success: 0,
        keys: 0,
      },
      errors: [],
    };
  },
  methods: {
    importData() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }

        let keys = this.form.keys
          .split("\n")
          .map((v) => v.trim())
          .filter((v) => v.length > 0);

        this.status_importing = true;

        this.import_progress = 0;
        this.stat.success = 0;
        this.stat.errors = 0;
        this.stat.keys = keys.length;

        this.importDataPieces(keys, 0);
      } catch (e) {
        console.error("Error while import data", e);
        this.import_progress = 0;
        this.status_importing = false;
        this.$message.notification({
          title: "Something wrong",
          text: "Error while trying to import keywords",
          type: "error",
        });
      }
    },
    async importDataPieces(keys, index) {
      try {
        if (keys[index] === undefined) {
          this.import_progress = 100;
          this.status_importing = false;
          return;
        }

        const pack = [];

        for (let i = index; i < index + 1; i++) {
          if (keys[i] === undefined) break;

          pack.push(keys[i]);
        }

        const columns = this.form.columns;

        const url = `/url-control/${this.idProject || 0}/upload-urls`;

        const data = {
          keys: pack,
          columns: columns,
          duplicates: this.form.duplicates,
        };

        const response = await service.post(url, data);

        if (response) {
          this.import_progress = Math.round(((index + 1) * 100) / keys.length);

          if (response.data.success) {
            let indexNext = index + pack.length;

            this.stat.success += pack.length;

            this.importDataPieces(keys, indexNext);

            // last element uploaded successfully
            if (index === keys.length - 1) {
              this.status_importing = false;
              this.import_progress = 0;
              this.stat.errors = 0;
              this.errors = null;
              this.$message.notification({
                title: "Success",
                text: "All keywords successfully uploaded",
                type: "success",
              });
            }
          } else {
            this.stat.errors = response.data.errors.length;
            this.errors = response.data.errors;
            this.status_importing = false;

            // Make progressbar red, then disable him
            this.importProgressBarColor = "error";
            setTimeout(() => {
              this.import_progress = 0;
              this.importProgressBarColor = "primary";
            }, 1000);
          }
        }
      } catch (e) {
        this.import_progress = 0;
        this.status_importing = false;
        this.$message.notification({
          title: "Something wrong",
          text: "Error while trying to import keywords",
          type: "error",
        });
      }
    },
  },
  computed: {
    idProject() {
      return this.$store.state.project.active_project;
    },
  },
};
</script>

<style scoped></style>
