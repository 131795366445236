<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>Tier 2 Links Dashboard</h2>
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filter.project_id"
          :items="_$getProjects"
          item-text="name"
          item-value="id"
          label="Project"
          outlined
          dense
          multiple
          hide-details
          clearable
          :menu-props="menuProps"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filter.type"
          :items="data.types_items"
          item-text="name"
          item-value="id"
          label="Type"
          outlined
          dense
          hide-details
          clearable
          :menu-props="menuProps"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filter.google_index"
          :items="data.google_index"
          item-text="name"
          item-value="id"
          label="Google Index"
          outlined
          dense
          hide-details
          clearable
          :menu-props="menuProps"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filter.level"
          :items="data.levels"
          item-text="name"
          item-value="id"
          label="Level"
          outlined
          dense
          hide-details
          clearable
          :menu-props="menuProps"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <smart-date-filter
          :model-value="filter.date_range"
          @update:modelValue="filter.date_range = $event"
        ></smart-date-filter>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-text-field
          v-model="filter.url_id"
          outlined
          dense
          :messages="urlIdFilterMessages"
          hide-details="auto"
          label="Url id"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-switch
          v-model="filter.onPage"
          inset
          label="On Page"
          hide-details
          :true-value="1"
          :false-value="0"
          dense
          class="mt-1"
        ></v-switch>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-btn
          @click="fetch_data"
          color="primary"
          block
          class="text-normal"
          :loading="loadings.table"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="isset_active_project">
        <template>
          <div v-if="upload.alert.shown">
            <v-alert
              v-for="message in upload.alert.messages.list"
              :key="message"
              outlined
              :type="upload.alert.type"
              prominent
              border="left"
              dismissible
            >
              {{ upload.alert.messages.name }}: {{ message }}
            </v-alert>
          </div>
        </template>
        <v-card flat outlined rounded="lg">
          <v-card-title
            class="d-flex justify-space-between"
            style="gap: 0.25rem"
          >
            <v-text-field
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
              v-model="search"
            />

            <div class="d-flex" style="gap: 0.25rem">
              <v-btn class="text-normal" @click="export_data" height="40">
                Get .CSV
                <v-icon small right> mdi-download-circle-outline </v-icon>
              </v-btn>
              <v-btn
                height="40"
                class="text-normal"
                color="success"
                @click="upload.modal = true"
              >
                Add new links
                <v-icon class="ml-2">mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text class="px-0">
            <v-data-table
              :search="search"
              :headers="headers"
              :items="items"
              :loading="loadings.table"
              :footer-props="{
                'items-per-page-options': [50, 100, 200, 300, -1],
              }"
              :items-per-page="100"
              dense
            >
              <template
                v-for="h in headers"
                v-slot:[`header.${h.value}`]="{ header }"
              >
                <v-tooltip bottom :key="h.text">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>{{ header.title }}</span>
                </v-tooltip>
              </template>

              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :style="get_row_color(item)"
                    style="white-space: nowrap"
                  >
                    <td>
                      {{ item.dateadd }}
                    </td>
                    <td class="text-left">
                      <span
                        v-html="get_truncated_string(item.acceptor_url, 27)"
                      ></span>
                      <a :href="item.acceptor_url" target="_blank">
                        <i class="mdi mdi-link"></i>
                      </a>
                    </td>
                    <td class="text-left">
                      <span
                        v-html="get_truncated_string(item.phrase, 27)"
                      ></span>
                    </td>
                    <td class="text-left">
                      <span
                        v-html="get_truncated_string(item.donor_url, 27)"
                      ></span>
                      <a :href="item.donor_url" target="_blank">
                        <i class="mdi mdi-link"></i>
                      </a>
                    </td>
                    <td>
                      {{ item.type }}
                    </td>
                    <td>
                      <v-switch
                        inset
                        v-model="item.check_link"
                        @change="handleChangeCheckLinkStatus(item)"
                      />
                    </td>
                    <td>
                      <v-btn
                        style="min-width: 38px"
                        class="px-2"
                        @click="open_dialog_problems(item)"
                      >
                        <span
                          v-html="get_color_class_thumbs(item.status)"
                        ></span>
                      </v-btn>
                    </td>
                    <td>
                      {{ item.islink }}
                    </td>
                    <td>
                      {{ item.donorUrIActualGI }}
                    </td>
                    <td>
                      {{ item.nofollow }}
                    </td>
                    <td>
                      {{ item.noindex }}
                    </td>
                    <td>
                      {{ item.first_gi }}
                    </td>
                    <td>
                      {{ item.last_on_page }}
                    </td>
                    <td>
                      {{ item.ah_dr }}
                    </td>
                    <td>
                      {{ item.google_trust }}
                    </td>
                    <td>
                      {{ item.traffic }}
                    </td>
                    <td>
                      {{ item.google_index }}
                    </td>
                    <td>
                      {{ item.sw_visits }}
                    </td>
                    <td>
                      {{ item.sw_GlobalRank }}
                    </td>
                    <td>
                      {{ item.al_GlobalRank }}
                    </td>
                    <td>
                      {{ item.level }}
                    </td>
                    <td>
                      {{ item.parent_acceptor_url }}
                    </td>
                    <td>
                      {{ item.user }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-else cols="12">
        <v-alert icon="mdi-shield-lock-outline" prominent text type="info" dark>
          <v-row align="center">
            <v-col class="grow">
              To view the page, you must select a project.
            </v-col>
            <v-col class="shrink">
              <v-btn
                color="primary"
                dark
                @click="$store.state.project.menu_select_projects = true"
                >Select project
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <v-dialog
      :retain-focus="false"
      v-model="upload.modal"
      content-class="shadow-e2-bordered"
      max-width="1200px"
    >
      <v-card rounded="xl">
        <v-card-title>
          <h3>Add new links</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-row>
                <v-col cols="12">
                  <v-card-title class="pa-0">Import from text</v-card-title>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="12">
                      1. Put the columns to import in the order in which you
                      make the data</v-col
                    >
                    <v-col cols="12">
                      <v-text-field
                        v-model="upload.text.headers"
                        outlined
                        dense
                        :placeholder="'acceptor_url;donor_url;type;date_add'"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">2. Insert data (without headers)</v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="upload.text.rows"
                        outlined
                        hide-details
                        @keydown.tab.prevent="tabber($event)"
                        :placeholder="'acceptor_url <tab> donor_url <tab> type <tab> date_add \n acceptor_url <tab> donor_url <tab> type <tab> date_add '"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn
                    color="success"
                    dark
                    @click="upload_data(1)"
                    width="160px"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="12">
                  <v-card class="shadow-e1-bordered" rounded="lg">
                    <v-card-title>
                      <span>Import from file</span>
                      <v-spacer />
                      <v-btn color="info" text>
                        <a
                          href="/file/Ltt2LevelExample.csv"
                          target="_blank"
                          style="
                            font-size: 15px;
                            text-decoration: none;
                            color: inherit;
                          "
                        >
                          <v-icon class="mr-2">mdi-download</v-icon> Example
                        </a>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-file-input
                            v-model="upload.file.names"
                            counter
                            dense
                            label="File input"
                            multiple
                            placeholder="Select your files"
                            prepend-icon="mdi-paperclip"
                            accept=".csv"
                            outlined
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                              >
                                +{{ upload.file.names.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end">
                      <v-btn
                        class="text-center align-center justify-center center"
                        color="success"
                        dark
                        @click="upload_data(2)"
                        width="120px"
                      >
                        Upload
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card elevation="0">
                    <v-card-title> Allowed columns </v-card-title>
                    <v-card-text>
                      <v-simple-table dense>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>R</td>
                            <td>required</td>
                          </tr>
                          <tr>
                            <td>acceptor_url [R]</td>
                            <td>https://site.com/page</td>
                          </tr>
                          <tr>
                            <td>donor_url [R]</td>
                            <td>https://site.com/page</td>
                          </tr>
                          <tr>
                            <td>type [R]</td>
                            <td>forum</td>
                          </tr>
                          <tr>
                            <td>date_add</td>
                            <td>2014-04-05</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      :retain-focus="false"
      v-model="dialog.problems"
      content-class="my-shadow--e2"
      scrollable
      persistent
      width="1420"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          Problems
          <v-spacer />
          <v-icon @click="dialog.problems = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text
          class="pt-5"
          style="background: var(--card-darken-body-color)"
        >
          <v-row dense>
            <v-col
              v-for="(item, key) in problems_types"
              :key="item.id"
              cols="3"
            >
              <problem-select-card
                :problem="item"
                :name="key"
                :validation="validationResult"
                :isError="validationResult['types']?.length"
                @change:validation="validationResult = $event"
                v-model="problems.selected"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions style="gap: 0.5rem" class="pa-0 flex-column">
          <div class="w-full d-flex px-4 pt-4" style="gap: 1rem">
            <v-radio-group
              v-model="problems.selected_priorities"
              hide-details
              label="What to do?"
              dense
              class="ma-0 pa-0"
            >
              <v-radio
                v-for="item in problems_priorities"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></v-radio>
            </v-radio-group>

            <v-textarea
              v-model="problems.comment"
              name="textarea"
              label="Comments"
              outlined
              hide-details="auto"
              dense
              :error-messages="validationResult?.['comment']"
              @blur="validationResult['comment'] = []"
              placeholder="Problem name - details"
            ></v-textarea>
          </div>
          <div class="w-full">
            <v-divider />
          </div>
          <div
            class="px-4 pb-4 w-full d-flex justify-center"
            style="gap: 0.25rem"
          >
            <v-btn
              large
              class="px-6 text-normal"
              @click="dialog.problems = false"
              :disabled="loadings.save"
            >
              Close
            </v-btn>
            <v-btn
              large
              class="px-6 text-normal"
              color="success"
              @click="save_dialog_problems()"
              :loading="loadings.save"
            >
              Save changes
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import eventPipe from "@/events/eventPipe";
import CopyComponentId from "@/mixins/copyComponentId";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import { DEFAULT_MENU_PROPS } from "@/utils/defaultData";
import Project from "@/mixins/Project";
import service from "../../../plugins/service";
import Location from "../../../mixins/Location";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import apiErrorHandlingMixin from "../../../mixins/ApiErrorHandlingMixin";
import ProblemSelectCard from "./components/ProblemSelectCard.vue";

let dateFormat = require("dateformat");
let date = new Date();

export default {
  name: "Index",
  components: { ProblemSelectCard, SmartDateFilter },
  async mounted() {
    this.parseQuery("filter");

    this.project_id = this.$store.state.project.active_project;

    eventPipe.$on("update-active-project", () => {
      this.fetch_data();
    });

    this.fetch_types_list();
    this.fetch_links_problems();
    this.fetch_data();
  },
  mixins: [
    CopyComponentId,
    FiltersHandling,
    Project,
    Location,
    apiErrorHandlingMixin,
  ],
  data: () => ({
    search: "",
    copyComponentId: "jh124s0-da-s89e12=40-sda",
    date_modal: false,
    project_id: null,
    menuProps: DEFAULT_MENU_PROPS,
    loadings: {
      table: false,
      save: false,
    },
    upload: {
      modal: false,
      text: {
        headers: "acceptor_url;donor_url;type;date_add",
        rows: "",
      },
      file: {
        names: [],
      },
      alert: {
        messages: {
          list: [],
          name: "",
          type: 0,
        },
        shown: false,
        type: "info",
      },
    },
    dialog: { problems: false },
    problems: {
      types: [],
      priorities: [],
      dialog: [],
      selected: [],
      selected_priorities: 1,
      link_id: false,
      comment: "",
    },
    problems_types: [],
    problems_priorities: [],
    headers: [
      { text: "Date", title: "Date", value: "dateadd", class: "text-left" },
      {
        text: "Acceptor Url",
        title: "Acceptor Url",
        value: "acceptor_url",
        class: "text-left",
      },
      { text: "Phrase", title: "Phrase", value: "phrase", class: "text-left" },
      {
        text: "Donor Url",
        title: "Donor Url",
        value: "donor_url",
        class: "text-left",
      },
      { text: "Type", title: "Type", value: "type", class: "text-left" },
      {
        text: "Cl",
        title: "Check Link",
        value: "check_link",
        class: "text-left",
      },
      { text: "Err", title: "Err", value: "", class: "text-left" },
      {
        text: "OnPage",
        title: "OnPage",
        value: "islink",
        class: "text-center",
      },
      {
        text: "GI",
        title: "GI",
        value: "donorUrIActualGI",
        class: "text-center",
      },
      { text: "NF", title: "NF", value: "nofollow", class: "text-center" },
      { text: "NI", title: "NI", value: "phrase", class: "text-center" },
      {
        text: "First GI",
        title: "First GI",
        value: "first_gi",
        class: "text-center",
      },
      {
        text: "Last OnPage",
        title: "Last OnPage",
        value: "last_on_page",
        class: "text-center",
      },
      { text: "DR", title: "DR", value: "ah_dr", class: "text-center" },
      { text: "GT", title: "GT", value: "google_trust", class: "text-center" },
      {
        text: "Traffic",
        title: "Traffic",
        value: "traffic",
        class: "text-center",
      },
      {
        text: "GI pages",
        title: "GI pages",
        value: "google_index",
        class: "text-center",
      },
      {
        text: "SW visits",
        title: "SW visits",
        value: "sw_visits",
        class: "text-center",
      },
      {
        text: "SW GR",
        title: "SW GR",
        value: "sw_GlobalRank",
        class: "text-center",
      },
      {
        text: "AL GR",
        title: "AL GR",
        value: "al_GlobalRank",
        class: "text-center",
      },
      { text: "level", title: "level", value: "level", class: "text-center" },
      {
        text: "parent_acceptor_url",
        title: "parent_acceptor_url",
        value: "parent_acceptor_url",
        class: "text-center",
      },
      { text: "user", title: "user", value: "user", class: "text-center" },
    ],
    urlIdFilterMessages: null,
    items: [],
    filter: {
      project_id: null,
      type: null,
      url_id: null,
      google_index: null,
      onPage: null,
      date_range: [
        dateFormat(new Date().setMonth(date.getMonth() - 2), "yyyy-mm-dd"),
        dateFormat(date, "yyyy-mm-dd"),
      ],
      level: null,
    },
    data: {
      types_items: [],
      google_index: [
        {
          id: 1,
          name: "in Index",
        },
        {
          id: 0,
          name: "not In Index",
        },
      ],
      levels: [
        {
          id: 0,
          name: "None",
        },
        {
          id: 1,
          name: "1 level",
        },
        {
          id: 2,
          name: "2 level",
        },
        {
          id: 3,
          name: "3 level",
        },
        {
          id: 4,
          name: "4 level",
        },
      ],
    },
  }),
  watch: {
    "dialog.problems"(isVisible) {
      if (!isVisible) {
        this.resetForm();
      }
    },
    "filter.url_id"(value) {
      if (!value) return (this.urlIdFilterMessages = null);

      this.urlIdFilterMessages =
        "If the url id filter is selected - the other filters will be ignored.";
    },
  },
  methods: {
    resetForm() {
      this.validationResult = {};
      this.problems = {
        types: [],
        priorities: [],
        dialog: [],
        selected: [],
        selected_priorities: 1,
        link_id: false,
        comment: "",
      };
    },
    handleChangeCheckLinkStatus(item) {
      const url = `/ltt/check_link/link2/${item.id}/${item.check_link ? 1 : 0}`;
      service.post(url, {});
    },
    tabber(event) {
      let text = this.upload.text.rows,
        originalSelectionStart = event.target.selectionStart,
        textStart = text.slice(0, originalSelectionStart),
        textEnd = text.slice(originalSelectionStart);

      this.upload.text.rows = `${textStart}\t${textEnd}`;
      event.target.value = this.upload.text.rows; // required to make the cursor stay in place.
      event.target.selectionEnd = event.target.selectionStart =
        originalSelectionStart + 1;
    },
    export_data() {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.post(url, self.filter, config).then((response) => {
        if (response.data.success) {
          self.dialog_export = false;
          location.href =
            `${self.$store.state.server_url}/export/ltt/level/index/${self.id_project}?hash=` +
            response.data.hash;
        }
      });

      // let params = new URLSearchParams(self.filter)

      // location.href = `${self.$store.state.server_url}/ltt/export/index/${self.id_project}?${params}`
    },
    get_row_color(item) {
      if (item.disavow === 1) {
        return "background-color: #E399B1 !important;";
      } else {
        if (item.noindex === 1 || item.robotsclose === 1)
          return " background-color: rgba(255, 21, 0, 0.20) !important;";

        if (item.islink === 1) {
          if (item.donorUrIActualGI === 1) {
            return "background-color: rgba(100, 255, 11, 0.20) !important;";
          } else
            return " background-color: rgba(255, 189, 0, 0.20) !important;";
        } else return "background-color: rgba(0, 149, 255, 0.20) !important;";
      }
    },
    get_truncated_string(str, length) {
      if (str != null && str.length != null && str.length > length) {
        return (
          "<span title='" + str + "'>" + str.substring(0, length) + "...</span>"
        );
      }
      return str;
    },
    async save_dialog_problems() {
      try {
        this.loadings.save = true;

        const url = `/ltt/reports/save-link-report/link2/${this.problems.link_id}`;

        const payload = {
          types: this.problems.selected,
          priority: this.problems.selected_priorities,
          comment: this.problems.comment,
        };

        const { data: success } = await service.post(url, payload);

        if (success) {
          this.dialog.problems = false;
        }
      } catch (e) {
        this.handleApiError(e);
      } finally {
        this.loadings.save = false;
      }
    },
    fetch_links_problems() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/ltt/links-problem`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {};

        axios
          .post(url, data, config)
          .then(function (response) {
            self.problems_types = response.data.problems_type;
            self.problems_priorities = response.data.problems_priority;
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    fetch_types_list() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/ltt/links-type`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        axios
          .get(url, config)
          .then(function (response) {
            self.data.types_items = response.data;
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    get_color_class_thumbs(status) {
      let res = { color: "#D3D3D3", class: "mdi-thumb-down" };
      if (status != null) {
        let arr = status.split(",");
        for (let item of arr) {
          switch (item) {
            case "done":
              res = { color: "green", class: "mdi-thumb-up-outline" };
              break;
            case "wait checking seo":
              res = { color: "#ff9900", class: "mdi-clock-in" };
              break;
            case "unchecked":
              res = { color: "grey", class: "mdi-emoticon-angry-outline" };
              break;
            default:
              res = { color: "red", class: "mdi-thumb-down" };
          }
        }
      }
      return (
        '<i style="color:' + res.color + '" class="mdi ' + res.class + '"></i>'
      );
    },
    open_dialog_problems(item) {
      console.log("test", item);
      if (item.problem_type != null) {
        this.problems.selected = item.problem_type
          .split(",")
          .map((e) => parseInt(e));
      } else {
        this.problems.selected = [];
      }
      this.problems.link_id = item.id;
      this.problems.comment = item.comment;

      this.dialog.problems = true;
    },
    upload_data(type) {
      let self = this;
      self.update_loading("upload_data", true);

      self.upload.alert.messages = [];
      self.upload.alert.type = "";
      self.upload.alert.shown = false;

      let url = `${self.$store.state.server_url}/ltt/upload/2level/links/${self.id_project}`;
      let data = {};
      if (type === 1) {
        data = {
          type: type,
          headers: this.upload.text.headers,
          data: this.upload.text.rows,
        };
      } else {
        data = new FormData();
        data.append("file", self.upload.file.names[0]);
        data.append("type", type);
      }

      let config = {
        headers: {
          "Content-Type":
            type === 1 ? "application/json" : "multipart/form-data",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, data, config)
        .then(function (response) {
          self.upload.alert.messages = response.data.messages;
          self.upload.alert.type = response.data.type;
          self.upload.alert.shown = true;
          self.update_loading("upload_data", false);
          self.upload.modal = false;
          self.upload.file.names = [];
        })
        .catch((error) => {
          self.upload.alert.messages = {
            list: [error],
            name: "Axios",
            type: 0,
          };
          self.upload.alert.type = "error";
          self.upload.alert.shown = true;
          self.upload.modal = false;
          self.upload.file.names = [];

          self.update_loading("upload_data", false);
        });
    },
    fetch_data() {
      let self = this;
      this.loadings.table = true;

      let url = `${self.$store.state.server_url}/ltt/2level/json/${self.id_project}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        filter: this.filter,
      };

      axios
        .post(url, data, config)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          this.handleApiError(error);
        })
        .finally(() => {
          this.loadings.table = false;
        });
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
};
</script>

<style scoped></style>
