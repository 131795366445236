<template>
  <v-row class="content-checker">
    <v-col cols="12" md="12">
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          <b>{{ content.title }}</b>
        </v-card-title>
        <v-divider />
        <template v-if="content.items?.length">
          <v-card-title>
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
              clearable
            />
          </v-card-title>
          <v-divider />
        </template>
        <v-card-text class="px-0 pt-0">
          <v-data-table
            v-if="status"
            :search="search"
            :headers="headers"
            :items="content.items"
            :items-per-page="-1"
            hide-default-footer
            class="content-checker__table pa-0"
          >
            <template v-slot:item.num_of_pages="{ item }">
              <div style="white-space: nowrap">
                <span
                  style="cursor: pointer"
                  @click="
                    show_detailed({
                      cat: 'num_of_pages',
                      type: 'all',
                      category: item.category,
                    })
                  "
                >
                  {{ item.num_of_pages.all }}
                </span>
                <sup
                  v-if="
                    item.num_of_pages.new !== 0 ||
                    item.num_of_pages.removed !== 0
                  "
                >
                  <span
                    style="cursor: pointer"
                    :class="{
                      'green--text': item.num_of_pages.new > 0,
                      bold: item.num_of_pages.new > 0,
                    }"
                    @click="
                      show_detailed({
                        cat: 'num_of_pages',
                        type: 'new',
                        category: item.category,
                      })
                    "
                    >{{ item.num_of_pages.new }}</span
                  >
                  /
                  <span
                    style="cursor: pointer"
                    @click="
                      show_detailed({
                        cat: 'num_of_pages',
                        type: 'removed',
                        category: item.category,
                      })
                    "
                    >{{ item.num_of_pages.removed }}</span
                  >
                </sup>
              </div>
            </template>
            <template v-slot:item.code_200="{ item }">
              <div style="white-space: nowrap">
                <span
                  style="cursor: pointer"
                  @click="
                    show_detailed({
                      cat: 'code_200',
                      type: 'all',
                      category: item.category,
                    })
                  "
                >
                  {{ item.code_200.all }}
                </span>
                <sup
                  v-if="item.code_200.new !== 0 || item.code_200.removed !== 0"
                >
                  <span
                    style="cursor: pointer"
                    :class="{
                      'green--text': item.code_200.new > 0,
                      bold: item.code_200.new > 0,
                    }"
                    @click="
                      show_detailed({
                        cat: 'code_200',
                        type: 'new',
                        category: item.category,
                      })
                    "
                    >{{ item.code_200.new }}</span
                  >
                  /
                  <span
                    style="cursor: pointer"
                    @click="
                      show_detailed({
                        cat: 'code_200',
                        type: 'removed',
                        category: item.category,
                      })
                    "
                    >{{ item.code_200.removed }}</span
                  >
                </sup>
              </div>
            </template>
            <template v-slot:item.code_not_200="{ item }">
              <div style="white-space: nowrap">
                <!--                <v-chip-->
                <!--                    label-->
                <!--                    small-->
                <!--                    v-if="item.code_not_200.all > 0"-->
                <!--                    @click="show_detailed({cat: 'not_code_200', type: 'all', category: item.category})"-->
                <!--                    color="error"-->
                <!--                >-->
                <!--                  {{ item.code_not_200.all }}-->
                <!--                </v-chip>-->
                <b
                  v-if="item.code_not_200.all > 0"
                  style="cursor: pointer"
                  class="red--text"
                  @click="
                    show_detailed({
                      cat: 'not_code_200',
                      type: 'all',
                      category: item.category,
                    })
                  "
                >
                  {{ item.code_not_200.all }}
                </b>
                <span
                  v-else
                  style="cursor: pointer"
                  @click="
                    show_detailed({
                      cat: 'not_code_200',
                      type: 'all',
                      category: item.category,
                    })
                  "
                >
                  -
                </span>
                <sup
                  v-if="
                    item.code_not_200.new !== 0 ||
                    item.code_not_200.removed !== 0
                  "
                >
                  <span
                    style="cursor: pointer"
                    @click="
                      show_detailed({
                        cat: 'not_code_200',
                        type: 'new',
                        category: item.category,
                      })
                    "
                  >
                    {{ item.code_not_200.new }}
                  </span>
                  /
                  <span
                    style="cursor: pointer"
                    @click="
                      show_detailed({
                        cat: 'not_code_200',
                        type: 'removed',
                        category: item.category,
                      })
                    "
                  >
                    {{ item.code_not_200.removed }}
                  </span>
                </sup>
              </div>
            </template>
            <template v-slot:item.ni="{ item }">
              <div style="white-space: nowrap">
                <span
                  style="cursor: pointer"
                  @click="
                    show_detailed({
                      cat: 'noindex',
                      type: 'all',
                      category: item.category,
                    })
                  "
                >
                  <span
                    :class="{
                      'red--text': item.ni.all > 0,
                      bold: item.ni.all > 0,
                    }"
                    >{{ item.ni.all }}</span
                  >
                </span>
                <sup v-if="item.ni.new !== 0 || item.ni.removed !== 0">
                  <span
                    style="cursor: pointer"
                    @click="
                      show_detailed({
                        cat: 'noindex',
                        type: 'new',
                        category: item.category,
                      })
                    "
                    >{{ item.ni.new }}</span
                  >
                  /
                  <span
                    style="cursor: pointer"
                    @click="
                      show_detailed({
                        cat: 'noindex',
                        type: 'removed',
                        category: item.category,
                      })
                    "
                    >{{ item.ni.removed }}</span
                  >
                </sup>
              </div>
            </template>
            <template v-slot:item.title="{ item }">
              <span
                style="cursor: pointer"
                @click="
                  show_detailed({
                    cat: 'tdh',
                    type: 'title',
                    category: item.category,
                  })
                "
              >
                {{ item.title }}
              </span>
            </template>
            <template v-slot:item.description="{ item }">
              <span
                style="cursor: pointer"
                @click="
                  show_detailed({
                    cat: 'tdh',
                    type: 'description',
                    category: item.category,
                  })
                "
              >
                {{ item.description }}
              </span>
            </template>
            <template v-slot:item.h1="{ item }">
              <span
                style="cursor: pointer"
                @click="
                  show_detailed({
                    cat: 'tdh',
                    type: 'h1',
                    category: item.category,
                  })
                "
              >
                {{ item.h1 }}
              </span>
            </template>
            <template v-slot:item.text="{ item }">
              <span
                style="cursor: pointer"
                @click="
                  show_detailed({
                    cat: 'text',
                    type: 'all',
                    category: item.category,
                  })
                "
              >
                {{ item.text }}
              </span>
            </template>
            <template v-slot:item.uniq_text="{ item }">
              <span
                style="cursor: pointer"
                @click="
                  show_detailed({
                    cat: 'uniq_text',
                    type: 'all',
                    category: item.category,
                  })
                "
              >
                {{ item.uniq_text }}
              </span>
            </template>
            <template v-slot:item.ni_to_i="{ item }">
              <b
                v-if="item.ni_to_i > 0"
                @click="
                  show_detailed({
                    cat: 'noindex_to_index',
                    type: 'all',
                    category: item.category,
                  })
                "
                class="red--text"
              >
                {{ item.ni_to_i }}
              </b>
              <span v-else style="cursor: pointer">-</span>
            </template>
            <template v-slot:item.i_to_ni="{ item }">
              <b
                v-if="item.i_to_ni > 0"
                @click="
                  show_detailed({
                    cat: 'index_to_noindex',
                    type: 'all',
                    category: item.category,
                  })
                "
                class="red--text"
              >
                {{ item.i_to_ni }}
              </b>
              <span v-else style="cursor: pointer">-</span>
            </template>
            <template v-slot:item.non_ascii_url="{ item }">
              <b
                v-if="item.non_ascii_url > 0"
                @click="
                  show_detailed({
                    cat: 'non_ascii_url',
                    type: 'all',
                    category: item.category,
                  })
                "
                class="red--text"
                style="cursor: pointer"
              >
                {{ item.non_ascii_url }}
              </b>
              <span v-else>0</span>
            </template>
            <template v-slot:item.non_ascii_text="{ item }">
              <b
                v-if="item.non_ascii_text > 0"
                @click="
                  show_detailed({
                    cat: 'non_ascii_text',
                    type: 'all',
                    category: item.category,
                  })
                "
                style="cursor: pointer"
                class="red--text"
              >
                {{ item.non_ascii_text }}
              </b>
              <span v-else>0</span>
            </template>
            <template v-slot:item.non_ascii_html="{ item }">
              <b
                v-if="item.non_ascii_html > 0"
                @click="
                  show_detailed({
                    cat: 'non_ascii_html',
                    type: 'all',
                    category: item.category,
                  })
                "
                style="cursor: pointer"
                class="red--text"
              >
                {{ item.non_ascii_html }}
              </b>
              <span v-else>0</span>
            </template>
          </v-data-table>
          <v-alert
            v-else
            rounded="lg"
            type="warning"
            text
            border="left"
            class="mx-4 mt-4 mb-0"
          >
            <div>
              Comparison is impossible, since there is no data for at least one
              day
            </div>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ContentCheckerChildIndex",
  props: ["content", "dates", "id", "status", "skip_noindex"],
  data() {
    return {
      search: undefined,
      headers: [
        { text: "SubProject", value: "category" },
        {
          text: "Num of pages",
          value: "num_of_pages",
          align: "center",
          sortable: false,
          width: "90",
        },
        {
          text: "Code 200",
          value: "code_200",
          align: "center",
          sortable: false,
          width: "90",
        },
        {
          text: "Title",
          value: "title",
          align: "center",
          sortable: false,
          width: "90",
        },
        {
          text: "Description",
          value: "description",
          align: "center",
          sortable: false,
          width: "90",
        },
        {
          text: "H1",
          value: "h1",
          align: "center",
          sortable: false,
          width: "90",
        },
        {
          text: "Text",
          value: "text",
          align: "center",
          sortable: false,
          width: "90",
        },
        {
          text: "Uniq text",
          value: "uniq_text",
          align: "center",
          sortable: false,
          width: "90",
        },
        // {text: '1', value: 'depth_1'},
        // {text: '2', value: 'depth_2'},
        // {text: '3', value: 'depth_3'},
        // {text: '4', value: 'depth_4'},
        {
          text: "Not 200",
          value: "code_not_200",
          align: "center",
          sortable: false,
          width: "90",
        },
        {
          text: "NI",
          value: "ni",
          align: "center",
          sortable: false,
          width: "70",
        },
        {
          text: "NI > I",
          value: "ni_to_i",
          align: "center",
          sortable: false,
          width: "70",
        },
        {
          text: "I > NI",
          value: "i_to_ni",
          align: "center",
          sortable: false,
          width: "70",
        },
        {
          text: "ASCII U",
          value: "non_ascii_url",
          align: "center",
          sortable: false,
        },
        {
          text: "ASCII T",
          value: "non_ascii_text",
          align: "center",
          sortable: false,
        },
        { text: "ASCII H", value: "non_ascii_html", align: "center" },
        // {text: 'Last crawl', value: 'last_crawl'},
        // {text: 'Pre last crawl', value: 'pre_last_crawl'},
      ],
    };
  },
  methods: {
    open_url(url) {
      window.open(url, "_blank");
    },
    show_detailed(params) {
      params.dates = this.dates;
      params.skip_noindex = this.skip_noindex;
      params.project_id = this.content.id;
      let routeData = this.$router.resolve({
        name: "content-checker.changes",
        params: { id: this.id },
        query: params,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss">
.content-checker {
  &__table {
    tbody {
      tr {
        td {
          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(6),
          &:nth-child(8),
          &:nth-child(10),
          &:nth-child(12) {
            border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
          }
        }
      }
    }
  }
}
</style>
