<template>
  <v-menu
    right
    nudge-left="400"
    :close-on-content-click="false"
    content-class="my-shadow--e2"
  >
    <template #activator="{ on }">
      <v-card
        v-on="on"
        class="pa-4 text-center"
        outlined
        rounded="lg"
        :disabled="loadings.history"
        :style="{ opacity: loadings.history ? '0.5' : 1 }"
        @click="handleShowHistory"
      >
        Show resolved problems
        <v-progress-circular
          v-if="loadings.history"
          size="12"
          width="2"
          indeterminate
        />
        <v-icon v-else small right>mdi-sync-alert</v-icon>
      </v-card>
    </template>
    <v-card flat outlined rounded="lg" width="380">
      <v-card-title class="text-body-2 pb-1 pt-2">
        Resolved problems
        <v-spacer />
        <v-btn
          icon
          :loading="loadings.history"
          @click="handleShowHistory(true)"
        >
          <v-icon small> mdi-refresh </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="pt-0 pb-3">
        <v-text-field
          v-model="search"
          hide-details
          dense
          outlined
          placeholder="Type to search"
          append-icon="mdi-magnify"
        />
      </v-card-title>
      <v-divider />
      <v-card-text style="max-height: 820px; overflow-y: scroll" class="pa-0">
        <template v-if="loadings.history">
          <div class="px-4 pb-4 pt-2">
            <div class="skeleton" style="height: 120px; width: 100%" />
          </div>
        </template>
        <template v-else-if="history?.[form._ctx.url_id]?.length">
          <div class="d-flex flex-column">
            <template
              v-for="(historyItem, idx) in searchByJson(
                search,
                history[form._ctx.url_id]
              )"
            >
              <div
                v-if="historyItem"
                :key="`problem-${idx}`"
                class="text-body-2 py-2 px-4"
              >
                <div class="d-flex justify-space-between">
                  <div>
                    <b>
                      {{ historyItem?.status?.name }}
                      <status-icon color="primary" small />
                    </b>
                  </div>
                  <div>
                    <v-tooltip bottom open-delay="30">
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-icon small>mdi-calendar</v-icon>
                          {{ $moment(historyItem.date).format("DD.MM.YY") }}
                        </div>
                      </template>
                      {{ $moment(historyItem.date).format("lll") }}
                    </v-tooltip>
                  </div>
                </div>
                <div class="d-flex mt-2" style="gap: 0.25rem">
                  <v-avatar size="38">
                    <v-img :src="historyItem?.user?.photo" />
                  </v-avatar>
                  <div>
                    <div>
                      <b>{{ historyItem?.user?.name }}</b>
                    </div>
                    <div>{{ historyItem?.user?.email }}</div>
                  </div>
                </div>
                <v-card
                  v-if="historyItem?.problems?.length"
                  flat
                  outlined
                  rounded="lg"
                  class="pa-0 mt-4"
                >
                  <v-card-text class="pa-0">
                    <template v-for="(item, idx) in historyItem?.problems">
                      <div v-if="item" :key="`field-${idx}`" class="pa-3">
                        <div class="d-flex">
                          <div>
                            <v-chip
                              v-if="historyItem?.is_resolved"
                              class="flat-chip flat-chip--success"
                              small
                            >
                              Resolved <v-icon small right>mdi-check</v-icon>
                            </v-chip>
                            <v-chip
                              v-else
                              class="flat-chip flat-chip--warning"
                              small
                            >
                              Not resolved
                              <v-icon small right>mdi-close</v-icon>
                            </v-chip>
                          </div>
                          <v-spacer />
                          <div style="opacity: 0.5">
                            <v-tooltip bottom open-delay="30">
                              <template #activator="{ on }">
                                <div v-on="on">
                                  {{
                                    $moment(item.updated_at).format("DD.MM.YY")
                                  }}
                                </div>
                              </template>
                              {{ $moment(item.updated_at).format("lll") }}
                            </v-tooltip>
                          </div>
                        </div>
                        <div class="mt-2">
                          <span v-if="item.comment">
                            {{ item.comment }}
                          </span>
                          <span v-else style="opacity: 0.5">
                            No comment...
                          </span>
                        </div>
                      </div>
                      <v-divider
                        v-if="idx !== historyItem?.problems?.length - 1"
                        :key="`divider-${idx}`"
                      />
                    </template>
                  </v-card-text>
                </v-card>
              </div>
              <v-divider
                v-if="
                  historyItem && idx !== history?.[form._ctx.url_id]?.length - 1
                "
                :key="idx"
              />
            </template>
          </div>
        </template>
        <template v-else-if="!history?.[form._ctx.url_id]?.length">
          <div class="text-center" style="opacity: 0.5">
            <div>
              <v-icon small>mdi-border-none-variant</v-icon>
            </div>
            <small>No solved problems found</small>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { SiteWorkSheetApiService } from "../services/SiteWorkSheetApiService";
import StatusIcon from "./StatusIcon.vue";

export default {
  components: { StatusIcon },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    loadings: {
      history: false,
    },
    search: null,
    history: {},
  }),

  methods: {
    async handleShowHistory(forceUpload = false) {
      if (forceUpload !== true) {
        if (this.history?.[this.form._ctx.url_id]?.length) return;
      }

      try {
        this.loadings.history = true;

        const { data } = await SiteWorkSheetApiService().getProblemsHistory(
          this.form._ctx.url_id
        );

        this.history = {};

        await this.$nextTick();

        // По ключу "айди урла" сохраняю полученные данные
        // Нужно для кейса, когда пользователь нажимает "Show history" в разных модалках "Edit status"
        // Был баг, что во всех модалках одинаковые данные подгружались из-за первого условия в этом методе
        this.$set(this.history, this.form._ctx.url_id, data);
      } catch (e) {
        console.error("Error while loading fields history.", e);
      } finally {
        this.loadings.history = false;
      }
    },

    searchByJson(searchQuery, items) {
      if (!searchQuery) return items;

      return (
        items?.filter((item) => {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(String(searchQuery).toLowerCase());
        }) || []
      );
    },
  },
};
</script>
