<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12">
          <page-header
            title="Anchors Plan LB Tier2"
            :breadcrumbs="[
              { text: 'Home', to: '/' },
              { text: 'Anchors Plan LB Tier2', disabled: true },
            ]"
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-card
            flat
            outlined
            rounded="lg"
            v-if="isset_active_project"
            :loading="loadings.data"
          >
            <v-card-title class="d-flex" style="gap: 0.25rem">
              <v-menu
                transition="slide-x-transition"
                content-class="my-shadow--e2"
                rounded="lg"
                nudge-bottom="38"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-fab-transition>
                    <v-btn v-on="on" color="primary" class="text-normal">
                      Filters <v-icon small right>mdi-filter</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </template>
                <v-card flat outlined rounded="lg" width="420">
                  <v-card-title class="text-body-2"> Filters </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <smart-date-filter
                          :model-value="filter.date_range"
                          @update:modelValue="filter.date_range = $event"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filter.user"
                          :items="users"
                          item-text="name"
                          item-value="id"
                          label="Performer"
                          dense
                          outlined
                          hide-details
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="filter.status"
                          :items="status"
                          item-text="name"
                          item-value="id"
                          label="Status"
                          chips
                          dense
                          outlined
                          multiple
                          hide-details
                          deletable-chips
                          clearable
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      @click="fetch_data"
                      class="px-6 text-normal"
                      large
                      color="primary"
                      :loading="loadings.data"
                    >
                      Apply
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-menu>

              <v-menu
                bottom
                nudge-bottom="38"
                content-class="my-shadow--e2"
                rounded="lg"
              >
                <template #activator="{ on }">
                  <v-btn v-on="on" class="text-normal">
                    Exports
                    <v-icon small right>mdi-export</v-icon>
                  </v-btn>
                </template>
                <v-card flat outlined rounded="lg">
                  <v-card-title class="text-body-2"> Exports </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-card
                          flat
                          outlined
                          rounded="lg"
                          @click="export_manager_data"
                          class="text-body-2 pa-4"
                        >
                          Progress
                          <v-icon small right>mdi-export</v-icon>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <v-card
                          flat
                          outlined
                          rounded="lg"
                          @click="export_data"
                          class="text-body-2 pa-4"
                        >
                          Posted links
                          <v-icon small right>mdi-export</v-icon>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card-title>
            <v-card-text class="px-0">
              <TableAnchorPlanIndex
                :type="2"
                :status="status"
                :level="2"
                :loading="loadings.data"
                :items="table.items"
                :performers="users"
              ></TableAnchorPlanIndex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            class="text-normal"
            @click="$store.dispatch('toggle_select_project_menu')"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "@/events/eventPipe";
import TableAnchorPlanIndex from "../../../components/AnchorPlan/Child/TableAnchorPlanIndex";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import PageHeader from "../../UI/PageHeader.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";

let dateFormat = require("dateformat");

export default {
  name: "LinkbuilderAnchorPlanTier2Index",
  components: { PageHeader, SmartDateFilter, TableAnchorPlanIndex },
  metaInfo: {
    title: "Anchor Plan LB Tasks - LTT - SI",
  },
  mixins: [FiltersHandling],
  data() {
    return {
      loadings: {
        data: false,
        status: false,
        performers: false,
      },
      date_modal: false,
      date_modal_export: false,
      table: {
        items: [],
      },
      users: [],
      status: [],
      filter: {
        user: false,
        date_range_export: [
          dateFormat(new Date(), "yyyy-mm-dd"),
          dateFormat(new Date(), "yyyy-mm-dd"),
        ],
        date_range: [],
        status: [1, 2, 3, 4, 6],
      },
    };
  },
  methods: {
    fetch_status() {
      this.loadings.status = true;

      let url = `${this.$store.state.server_url}/anchor-plan/tasks/list/status`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, {}, config)
        .then((response) => {
          this.status = response.data;
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(() => {
          this.loadings.performers = false;
        });
    },
    fetch_data() {
      this.loadings.data = true;

      let url = `${this.$store.state.server_url}/anchor-plan/tier/tasks/0/2`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        filter: this.filter,
      };

      this.table.items = [];

      axios
        .post(url, data, config)
        .then((response) => {
          this.table.items = response.data.tasks;
          // this.users = response.data.users
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(() => {
          this.loadings.data = false;
        });
    },
    fetch_performers() {
      this.loadings.performers = true;

      let url = `${this.$store.state.server_url}/anchor-plan/tasks/list/performers`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(url, {}, config)
        .then((response) => {
          this.users = response.data;
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(() => {
          this.loadings.performers = false;
        });
    },
    export_manager_data() {
      let url = `${this.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.post(url, this.filter, config).then((response) => {
        if (response.data.success) {
          location.href =
            `${this.$store.state.server_url}/export/anchor-plan-manager/index/0?hash=` +
            response.data.hash;
        }
      });
    },
    export_data() {
      let url = `${this.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.post(url, this.filter, config).then((response) => {
        if (response.data.success) {
          location.href =
            `${this.$store.state.server_url}/export/anchor-plan/index/0?hash=` +
            response.data.hash;
        }
      });
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  async mounted() {
    await this.parseQuery("filter");

    eventPipe.$on("update-active-project", () => {
      this.fetch_data();
    });

    this.fetch_status();
    this.fetch_performers();
    this.fetch_data();
  },
};
</script>

<style scoped></style>
