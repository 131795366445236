<template>
  <v-form ref="formRef">
    <v-row>
      <!-- START Основная информаци -->

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2"> Main information </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Name
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.name"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['name']"
                  @blur="validation['name'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Contact
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.contacts"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['contacts']"
                  @blur="validation['contacts'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Language
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.language_id"
                  :items="filtersData?.languages"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['language_id']"
                  @blur="validation['language_id'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Rate per 100 words
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.price_per_100_words"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['price_per_100_words']"
                  @blur="validation['price_per_100_words'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Experience in gambling betting years
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.experience"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['experience']"
                  @blur="validation['experience'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Topics
                </element-control-content-field-label>
                <v-textarea
                  v-model="form.thematics"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['thematics']"
                  @blur="validation['thematics'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Specialization
                </element-control-content-field-label>
                <v-textarea
                  v-model="form.specialization"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['specialization']"
                  @blur="validation['specialization'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Status
                </element-control-content-field-label>
                <v-autocomplete
                  v-model="form.status"
                  hide-details="auto"
                  :items="filtersData['freelancer_statuses']"
                  dense
                  outlined
                  :error-messages="validation['status']"
                  @blur="validation['status'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Portfolio
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.portfolio"
                  hide-details="auto"
                  dense
                  outlined
                  label="Portfolio"
                  :error-messages="validation['portfolio']"
                  @blur="validation['portfolio'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- END Основная информаци -->
      <!-- START Качество и выполнение работы -->

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            Quality and performance of work
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Average delivery time
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.avg_complete_time"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['avg_complete_time']"
                  @blur="validation['avg_complete_time'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  SEO Knowledge
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.seo_knowledge"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['seo_knowledge']"
                  @blur="validation['seo_knowledge'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Understanding of terminology and niche knowledge
                </element-control-content-field-label>
                <radio-group-range
                  v-model="form.niche_knowledge"
                  :to="10"
                  :error-messages="validation['niche_knowledge']"
                  @blur="validation['niche_knowledge'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Texts quality
                </element-control-content-field-label>
                <radio-group-range
                  v-model="form.texts_quality"
                  :to="10"
                  :error-messages="validation['texts_quality']"
                  @blur="validation['texts_quality'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Average time for revisions and corrections
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.avg_edits_time"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['avg_edits_time']"
                  @blur="validation['avg_edits_time'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- END Качество и выполнение работы -->
      <!-- START Финансовые условия -->

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            Financial conditions
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Payment methods
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.payments"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['payments']"
                  @blur="validation['payments'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Minimum order
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.min_order"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['min_order']"
                  @blur="validation['min_order'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Rate for text optimization
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.price_text_optimization"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['price_text_optimization']"
                  @blur="validation['price_text_optimization'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Rate for urgent orders
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.price_urgency"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['price_urgency']"
                  @blur="validation['price_urgency'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- END Финансовые условия -->
      <!-- START Оценка коммуникации -->

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            Evaluation of communication
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Punctuality
                </element-control-content-field-label>
                <v-textarea
                  v-model="form.punctual"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['punctual']"
                  @blur="validation['punctual'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="required">
                  Communication
                </element-control-content-field-label>
                <v-textarea
                  v-model="form.communication"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['communication']"
                  @blur="validation['communication'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Final recommendation
                </element-control-content-field-label>
                <v-textarea
                  v-model="form.summary_recommendation"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['summary_recommendation']"
                  @blur="validation['summary_recommendation'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Comments
                </element-control-content-field-label>
                <v-textarea
                  v-model="form.comments"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['comments']"
                  @blur="validation['comments'] = []"
                />
              </v-col>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  NDA Availability
                </element-control-content-field-label>
                <v-text-field
                  v-model="form.nda"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['nda']"
                  @blur="validation['nda'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- END Оценка коммуникации -->
      <!-- START Другое -->

      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2"> Other </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <element-control-content-field-label :field="notRequired">
                  Experience with competitors
                </element-control-content-field-label>
                <v-textarea
                  v-model="form.experience_competitors"
                  hide-details="auto"
                  dense
                  outlined
                  :error-messages="validation['experience_competitors']"
                  @blur="validation['experience_competitors'] = []"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import RadioGroupRange from "./RadioGroupRange.vue";
import ElementControlContentFieldLabel from "../../SiteWorkplace/SiteWorksheet/components/ElementControlContentFieldLabel.vue";

export default {
  components: { ElementControlContentFieldLabel, RadioGroupRange },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    validationKey: {
      type: String,
      default: "",
    },
    filtersData: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    notRequired: { pivot: { is_required: false } },
    required: { pivot: { is_required: true } },
  }),

  computed: {
    validation() {
      return this.$store.getters[this.validationKey];
    },
    form: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
