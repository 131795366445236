<template>
  <v-menu bottom nudge-bottom="36" content-class="my-shadow--e2">
    <template #activator="{ on }">
      <v-btn v-on="on" class="px-2" style="min-width: 36px">
        <v-icon small>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        <b>Actions</b>
      </v-card-title>
      <v-card-text class="pa-0 pb-2">
        <v-list dense class="pt-0">
          <v-menu right content-class="my-shadow--e3" nudge-left="16">
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-dots-vertical</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Copy data from </v-list-item-title>
              </v-list-item>
            </template>
            <v-card flat outlined rounded="lg" width="240">
              <v-card-title class="text-body-2 pb-1">
                <b>Copy data from</b>
              </v-card-title>
              <v-card-subtitle style="line-height: 150%" class="pb-0 mt-0">
                Select the source group from which the data will be copied
              </v-card-subtitle>
              <v-card-text class="px-0">
                <v-list dense>
                  <v-list-item
                    v-for="preset in presets || []"
                    :key="preset.value"
                    @click="$emit('copy', { presetData: preset, key: dataKey })"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ preset.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    presets: {
      type: Array,
      default: () => [],
    },
    dataKey: {
      type: String,
      default: null,
    },
  },
};
</script>
