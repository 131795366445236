<template>
  <div>
    <v-card
      class="text-center pa-4"
      outlined
      rounded="lg"
      @click="handleShowComments"
    >
      Chat
      <v-icon small right>mdi-message</v-icon>
    </v-card>
    <v-dialog
      v-model="isCommentsDialogVisible"
      content-class="my-shadow--e2"
      width="840"
      scrollable
    >
      <v-card flat outlined rounded="lg" cla>
        <v-card-title class="text-body-2 pa-3">
          Chat
          <v-spacer />
          <v-icon @click="isCommentsDialogVisible = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-3 pt-5" ref="messagesListRef">
          <div
            v-if="!mutableForm?.messages?.length"
            class="d-flex justify-center flex-column pa-4 align-center"
            style="opacity: 0.5; min-height: 320px"
          >
            <v-icon>mdi-email-open-outline</v-icon>
            <small>No messages yet. You can leave a message</small>
          </div>
          <transition-group v-else name="slide" tag="div" class="row">
            <v-col
              v-for="message in mutableForm?.messages || []"
              :key="`message-${message.id}`"
              cols="12"
            >
              <div class="d-flex" style="gap: 0.5rem">
                <v-tooltip
                  bottom
                  open-delay="100"
                  content-class="my-shadow--e2 pa-0 rounded-lg"
                >
                  <template #activator="{ on }">
                    <v-avatar size="28" v-on="on">
                      <v-img :src="message?.user?.photo" />
                    </v-avatar>
                  </template>
                  <v-card flat outlined rounded="lg" class="pa-4 text-body-2">
                    <v-row dense>
                      <v-col cols="auto">
                        <v-avatar size="38">
                          <v-img :src="message?.user?.photo" />
                        </v-avatar>
                      </v-col>
                      <v-col cols="fill" class="d-flex flex-column">
                        <template v-if="message?.user?.name">
                          <div
                            class="text-body-2"
                            style="
                              max-width: 200px;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                            :title="message?.user?.email"
                          >
                            <b>{{ message?.user?.email }}</b>
                          </div>
                          <div class="text-body-2">
                            {{ message?.user?.name }}
                          </div>
                        </template>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tooltip>
                <v-card
                  flat
                  class="text-body-2"
                  :color="isMyMessage(message.user) ? 'primary' : ''"
                  :class="{
                    'white--text': isMyMessage(message.user),
                  }"
                  :style="{
                    'max-width': '620px',
                    background: isMyMessage(message.user)
                      ? ''
                      : 'var(--card-darken-body-color)',
                  }"
                >
                  <div class="px-3 pt-3">
                    {{ message.message }}
                  </div>
                  <div
                    class="pl-3 pt-1 pr-1 pb-1 text-end"
                    style="opacity: 0.5; line-height: 100%"
                  >
                    <small>
                      {{
                        $moment(message?.created_at).isSame(new Date(), "day")
                          ? $moment(message?.created_at).format("hh:mm")
                          : $moment(message?.created_at).format("ll")
                      }}
                    </small>
                  </div>
                </v-card>
              </div>
            </v-col>
          </transition-group>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-textarea
            v-model="message"
            hide-details
            dense
            outlined
            placeholder="Enter message"
            append-icon="mdi-send"
            :loading="loadings.send || loadings.messages"
            @keydown.shift.enter="handleSendComment"
            @click:append="handleSendComment"
          ></v-textarea>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SiteWorkSheetApiService } from "../services/SiteWorkSheetApiService";
import { deepClone } from "../../../../utils/functions";

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    isCommentsDialogVisible: false,
    loadings: {
      send: false,
      messages: false,
    },
    message: null,
    mutableForm: null,
  }),

  watch: {
    form: {
      handler(newData) {
        if (!newData) return;

        this.mutableForm = deepClone(newData);
      },
      deep: true,
      immediate: true,
    },
    isCommentsDialogVisible: {
      handler(isVisible) {
        if (isVisible)
          SiteWorkSheetApiService().readChat(
            this.mutableForm?.url_info?.url_id
          );
      },
      immediate: true,
    },
  },

  methods: {
    isMyMessage(user) {
      return user?.id === this.$store.state.auth.user.id;
    },
    handleShowComments() {
      this.isCommentsDialogVisible = !this.isCommentsDialogVisible;
    },
    async uploadFreshMessages() {
      try {
        this.loadings.messages = true;
        const { data } =
          await SiteWorkSheetApiService().getWorksheetDetailsById(
            this.mutableForm?.url_info?.url_id
          );

        this.$set(this.mutableForm, "messages", data.messages);

        this.$nextTick(() => {
          this.$refs.messagesListRef.scrollTop =
            this.$refs.messagesListRef.scrollHeight;
        });
      } catch (e) {
        console.error("Error while loading fresh messages.", e);
      } finally {
        this.loadings.messages = false;
      }
    },
    async handleSendComment(e) {
      if (e) e?.preventDefault();

      try {
        if (!this.message) return;

        this.loadings.send = true;

        await SiteWorkSheetApiService().sendMessageToChat(
          this.mutableForm?.url_info?.url_id,
          { message: this.message?.trim() }
        );

        this.uploadFreshMessages();
      } catch (e) {
        console.error("Error while sending message to chat.", e);
      } finally {
        this.loadings.send = false;
      }
    },
  },
};
</script>
