export default {
  data: () => ({
    validationResult: {},
  }),

  methods: {
    parseValidationResult(errors) {
      Object.entries(errors).forEach(([key, value]) => {
        if (typeof value === "string")
          return this.$set(this.validationResult, key, [value]);

        this.$set(this.validationResult, key, value);
      });
    },
    handleApiError(error) {
      if (error?.response?.data?.errors)
        this.parseValidationResult(error.response.data.errors);
    },
  },
};
