<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card flat outlined rounded="lg">
            <v-card-title class="pa-3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-card-title>
            <v-card-text class="px-0">
              <v-data-table
                dense
                :search="search"
                :loading="loading"
                :headers="headers"
                :items-per-page="25"
                :footer-props="{ itemsPerPageOptions: [25, 50, 150, 300, -1] }"
                :items="items"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn icon @click="editField(item)">
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-btn fixed dark fab bottom right color="pink" @click="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      content-class="my-shadow--e3"
      scrollable
      max-width="1000px"
    >
      <v-card flat rounded="lg">
        <v-card-title class="pa-3 text-body-2">
          Status
          <v-spacer />
          <v-icon @click="dialog = false"> mdi-close </v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-btn color="info" text @click="addTitle">Add Title</v-btn>
            <v-container class="px-0" v-if="form.title.length > 0">
              <v-row v-for="(i, ind) in form.title" :key="ind">
                <v-col>
                  <v-autocomplete
                    v-model="i.from"
                    outlined
                    label="From"
                    :items="status_items"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-model="i.to"
                    outlined
                    label="To"
                    :items="status_items"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="i.title"
                    outlined
                    label="Title"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col class="flex-shrink-0" style="max-width: 65px"
                  ><v-btn icon @click="deleteTitle(ind)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  ></v-col
                >
              </v-row>
            </v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="form.process_group"
                      :items="filtersData.process_group"
                      hide-details
                      outlined
                      label="Process Group"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.name"
                      outlined
                      label="Name"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="d-flex justify-end" cols="auto">
                <v-color-picker mode="hexa" v-model="form.color" />
              </v-col>
            </v-row>
            <v-select
              v-model="form.type_id"
              outlined
              label="Type"
              :items="type_items"
            ></v-select>
            <v-text-field
              v-model="form.main_status"
              outlined
              label="Main Status"
            ></v-text-field>
            <v-text-field
              v-model="form.user_guide"
              outlined
              label="User guide"
            ></v-text-field>
            <v-text-field
              v-model="form.notifications_delay"
              outlined
              label="Notify Every X Min"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="form.critical_estimate"
              outlined
              label="Critical Estimate (h)"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="form.priority"
              outlined
              label="Priority"
              type="number"
            ></v-text-field>
            <v-select
              v-model="form.responsible_priority"
              outlined
              label="Performer"
              :items="responsible_priority_items"
            ></v-select>
            <v-select
              v-model="form.responsible_priority_visible"
              outlined
              label="Performer Visible"
              :items="responsible_priority_visible_items"
            ></v-select>
            <v-autocomplete
              v-model="form.overall_status"
              hide-details
              outlined
              :loading="loadings.filters"
              clearable
              label="Overall status"
              :items="filtersData.overall_status"
            />
            <v-btn color="info" text @click="addNext('next_status_recheck')"
              >Add Recheck</v-btn
            >
            <v-container
              class="px-0"
              v-if="form['next_status_recheck'].length > 0"
            >
              <v-row v-for="(i, ind) in form['next_status_recheck']" :key="ind">
                <v-col>
                  <v-autocomplete
                    v-model="i.status"
                    outlined
                    label="Status"
                    :items="statusItems"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-select
                    v-model="i.performer"
                    outlined
                    label="Performer"
                    :items="responsible_priority_items"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col>
                  <v-btn
                    class="ma-2"
                    color="warning"
                    text
                    @click="openProblemsDialog(i.status)"
                  >
                    Edit Problems
                  </v-btn>
                </v-col>
                <v-col class="flex-shrink-0" style="max-width: 65px"
                  ><v-btn icon @click="deleteNext('next_status_recheck', ind)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  ></v-col
                >
              </v-row>
            </v-container>

            <v-btn color="info" text @click="addNext('next_status_approve')"
              >Add Approve</v-btn
            >
            <v-container
              class="px-0"
              v-if="form['next_status_approve'].length > 0"
            >
              <v-row v-for="(i, ind) in form['next_status_approve']" :key="ind">
                <v-col>
                  <v-autocomplete
                    v-model="i.status"
                    outlined
                    label="Status"
                    :items="statusItems"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-select
                    v-model="i.performer"
                    outlined
                    label="Performer"
                    :items="responsible_priority_items"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col class="flex-shrink-0" style="max-width: 65px"
                  ><v-btn icon @click="deleteNext('next_status_approve', ind)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  ></v-col
                >
              </v-row>
            </v-container>

            <v-btn color="info" text @click="addField()">Add Field</v-btn>
            <v-container class="px-0" v-if="form.fields.length > 0">
              <v-row v-for="(i, ind) in form.fields" :key="ind">
                <v-col>
                  <v-autocomplete
                    v-model="i.gpm_field_id"
                    outlined
                    label="Field"
                    :items="field_items"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-select
                    v-model="i.json_rules"
                    label="Rules"
                    multiple
                    outlined
                    clearable
                    hide-details
                    :items="rules_items"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="i.sort_by"
                    type="number"
                    label="Sort By"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="i.position"
                    label="Positions"
                    outlined
                    hide-details
                    :items="position_items"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="i.responsible_priority"
                    outlined
                    label="Performer"
                    :items="responsible_priority_items"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col class="flex-shrink-0" style="max-width: 65px"
                  ><v-btn icon @click="deleteField(ind)"
                    ><v-icon>mdi-close</v-icon></v-btn
                  ></v-col
                >
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn
            text
            large
            class="px-6 text-normal"
            outlined
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            large
            class="text-normal px-6"
            @click="createStatus()"
            :disabled="dialog_loading"
            :loading="dialog_loading"
          >
            <span v-if="dialog_edit">Save changes</span>
            <span v-else>Create</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="problems_dialog"
      persistent
      scrollable
      max-width="1000px"
    >
      <v-card>
        <v-card-title> Problems </v-card-title>
        <v-card-text>
          <v-btn color="info" text @click="addNext('problem')"
            >Add Problem</v-btn
          >
          <v-container class="px-0" v-if="problems.length > 0">
            <v-row v-for="(i, ind) in problems" :key="ind">
              <v-col>
                <v-text-field
                  v-model="i.text"
                  outlined
                  label="Problem"
                  hide-details
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="i.category"
                  outlined
                  label="Category"
                  hide-details
                />
              </v-col>
              <v-col class="flex-shrink-0" style="max-width: 65px"
                ><v-btn icon @click="deleteNext('problem', i.value)"
                  ><v-icon>mdi-close</v-icon></v-btn
                ></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="problems_dialog = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveRecheckProblems()"
            :disabled="dialog_loading"
            :loading="dialog_loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StatusManager",
  data() {
    return {
      loadings: {
        filters: false,
      },
      filtersData: {
        overall_status: [],
        process_group: [],
      },
      search: "",
      dialog_edit: false,
      dialog: false,
      dialog_loading: false,
      loading: false,
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: "Type", align: "start", value: "entity_type" },
        { text: "Name", align: "start", value: "name" },
        { text: "Main Status", align: "start", value: "main_status" },
        // {text: 'Title', align: 'start', value: 'title'},
        {
          text: "Responsible Priority",
          align: "start",
          value: "responsible_priority",
        },
        { text: "", align: "start", value: "actions" },
      ],
      items: [],
      form: {
        name: null,
        color: "",
        main_status: null,
        notifications_delay: 0,
        priority: 0,
        type_id: 1,
        critical_estimate: 0,
        title: [],
        fields: [],
        responsible_priority: null,
        responsible_priority_visible: null,
        overall_status: null,
        process_group: null,
        next_status_recheck: [],
        next_status_approve: [],
        user_guide: null,
      },
      status_items: [],
      responsible_priority_items: [
        { text: "All", value: 0 },
        { text: "SEO", value: 50 },
        { text: "GP", value: 1 },
      ],
      responsible_priority_visible_items: [
        { text: "All", value: 0 },
        { text: "SEO", value: 50 },
        { text: "GP", value: 1 },
        { text: "CW", value: 2 },
        { text: "CM", value: 4 },
        { text: "WM", value: 3 },
        { text: "OM", value: 5 },
      ],
      position_items: [
        { text: "Info", value: "info" },
        { text: "Content", value: "content" },
        { text: "Both", value: "both" },
      ],
      rules_items: [{ text: "Required", value: "required" }],
      field_items: [],
      type_items: [
        { text: "Guest Post", value: 1 },
        { text: "3rd Blog", value: 2 },
        { text: "Research Hub", value: 3 },
        { text: "Testimonials", value: 4 },
      ],
      types: [
        { text: "Text", value: "text" },
        { text: "Num", value: "num" },
        { text: "Textarea", value: "textarea" },
        { text: "Link", value: "link" },
        { text: "Editor", value: "editor" },
        { text: "Checklist", value: "checklist" },
        { text: "Email", value: "email" },
        { text: "Price", value: "price" },
        { text: "Url Anchor", value: "url_anchor" },
        { text: "Useful Links", value: "useful_links" },
        { text: "Task Price", value: "task_price" },
        { text: "Url Anchor Simple", value: "url_anchor_simple" },
      ],
      problems_dialog: false,
      problems_dialog_id: null,
      problems: [],
      problems_items: {},
    };
  },
  computed: {
    statusItems() {
      return this.status_items.filter((v) => v.type === this.form.type_id);
    },
  },
  methods: {
    async fetchFilters() {
      try {
        const payload = {
          type: "/gpm/admin/status",
          take: ["overall_status", "process_group"],
        };
        this.loadings.filters = true;
        const resp = await this.$store.dispatch("global/getFilters", payload);
        this.loadings.filters = false;

        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } catch (e) {
        console.error(e);
        this.loadings.filters = false;
      }
    },
    saveRecheckProblems() {
      let self = this;

      let url = `${self.$store.state.server_url}/gpm/admin/save-recheck-problems`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(
          url,
          {
            problems: self.problems,
            statusID: self.form.id,
            recheckStatusID: self.problems_dialog_id,
          },
          config
        )
        .then(() => {
          self.fetchRecheckProblems(self.form.id);
        })
        .finally(() => {
          self.problems_dialog = false;
        });
    },
    fetchRecheckProblems(id) {
      let self = this;

      let url = `${self.$store.state.server_url}/gpm/entity-view/recheck-problems`;

      let config = {
        params: {
          statusID: id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios.get(url, config).then((r) => {
        self.problems_items = r.data.items;
      });
    },
    openProblemsDialog(id) {
      let self = this;

      self.problems_dialog = true;
      self.problems_dialog_id = id;

      self.problems =
        self.problems_items[id] !== undefined ? self.problems_items[id] : [];
    },
    closeDialog() {
      this.dialog = false;
      this.dialog_edit = false;
    },
    addField() {
      this.form.fields.push({
        gpm_field_id: null,
        json_rules: [],
        sort_by: null,
        position: null,
      });
    },
    deleteField(ind) {
      this.form.fields = this.form.fields.filter((v, i) => i !== ind);
    },
    addTitle() {
      this.form.title.push({
        from: 0,
        to: 0,
        title: null,
      });
    },
    deleteTitle(ind) {
      this.form.title = this.form.title.filter((v, i) => i !== ind);
    },
    addNext(type) {
      if (type === "next_status_recheck") {
        this.form[type].push({
          status: null,
          performer: null,
          problems: [],
        });
      } else if (type === "problem") {
        this.problems.push({
          value: "d" + Math.floor(Math.random() * 100000),
          text: null,
          category: null,
        });
      } else {
        this.form[type].push({
          status: null,
          performer: null,
        });
      }
    },
    deleteNext(type, ind) {
      if (type === "problem") {
        this.problems = this.problems.filter((v) => v.value !== ind);
      } else {
        this.form[type] = this.form[type].filter((v, i) => i !== ind);
      }
    },
    editField(item) {
      this.fetchRecheckProblems(item.id);
      this.form = item;
      this.dialog = true;
      this.dialog_edit = true;
    },
    fetchFields() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/admin/get-fields`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.field_items = r.data.items.map((v) => {
            return {
              text: `${v.id} ${v.title} (${v.type})`,
              value: v.id,
            };
          });
        })
        .finally(() => (self.loading = false));
    },
    fetchData() {
      let self = this;

      self.loading = true;

      let url = `${self.$store.state.server_url}/gpm/admin/get-statuses`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.items = r.data.items;
          self.status_items = self.items.map((v) => {
            return {
              text: `${v.id} ${v.name}`,
              value: v.id,
              type: v.type_id,
            };
          });
          self.status_items.unshift({ text: "Default", value: 0 });
        })
        .finally(() => (self.loading = false));
    },
    createStatus() {
      this.dialog_loading = true;

      let url = `${this.$store.state.server_url}/gpm/admin/create-status`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .post(
          url,
          {
            form: {
              ...this.form,
            },
            edit: this.dialog_edit,
          },
          config
        )
        .then(() => {
          this.fetchData();
        })
        .finally(() => {
          this.dialog_loading = false;
          this.dialog_edit = false;
          this.dialog = false;
        });
    },
  },
  created() {
    this.fetchData();
    this.fetchFields();
    this.fetchFilters();
  },
};
</script>

<style scoped></style>
