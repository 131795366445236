var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('page-header',{attrs:{"title":"Status manager","breadcrumbs":[
          { text: 'Home', to: '/' },
          {
            text: 'Site workplace',
            to: { name: 'site-workplace.dashboard' },
          },
          { text: 'Status manager', disabled: true },
        ]}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"text-body-2"},[_c('b',[_vm._v("Statuses")]),_c('v-spacer'),(false)?_c('v-btn',{staticClass:"text-normal",attrs:{"color":"primary"},on:{"click":_vm.handleCreateStatus}},[_vm._v(" Create new status "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-divider'),_c('v-card-title',{staticClass:"pa-3"},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","outlined":"","placeholder":"Type to search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-0 pt-0 pb-2"},[_c('v-data-table',{attrs:{"loading":_vm.loadings.statuses,"search":_vm.search,"items":_vm.tableData.items,"headers":[..._vm.tableData.headers, { text: '', value: '_actions' }]},scopedSlots:_vm._u([{key:`item._actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"text-normal",attrs:{"loading":_vm.loadings.edit === item.id,"disabled":!!_vm.loadings.edit && _vm.loadings.edit !== item.id},on:{"click":function($event){return _vm.handleEditStatus(item)}}},[_vm._v(" Edit "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)})],1)],1)],1)],1),_c('create-status-dialog',{model:{value:(_vm.isCreateStatusDialogVisible),callback:function ($$v) {_vm.isCreateStatusDialogVisible=$$v},expression:"isCreateStatusDialogVisible"}}),_c('edit-status-dialog',{attrs:{"data":_vm.editStatusData},model:{value:(_vm.isEditStatusDialogVisible),callback:function ($$v) {_vm.isEditStatusDialogVisible=$$v},expression:"isEditStatusDialogVisible"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }