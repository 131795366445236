<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <h2>4DX Main Dashboard</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
      <v-col cols="12">
        <v-form ref="formRef">
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <smart-autocomplete
                :model-value="filters.sections"
                @update:modelValue="filters.sections = $event"
                :items="filtersData.sections"
                label="Metrics"
                :rules="[(v) => !!v?.length || 'Select metric']"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.view_dimension"
                label="View Dimension"
                :items="filtersData.view_dimension"
                multiple
                hide-details
                dense
                clearable
                outlined
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.subproject"
                :items="filtersData.subproject"
                label="Subproject"
                hide-details
                clearable
                dense
                outlined
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.responsible"
                :items="filtersData.responsible"
                label="Responsible"
                hide-details
                clearable
                multiple
                dense
                outlined
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.page_type"
                :items="filtersData.page_type"
                label="Page Type"
                multiple
                hide-details
                clearable
                dense
                outlined
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <easy-range-input
                :model-value="filters.need_top"
                @update:modelValue="filters.need_top = $event"
                label="Need Top"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <smart-date-filter
                :model-value="filters.date"
                @update:modelValue="filters.date = $event"
              >
              </smart-date-filter>
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.niche"
                :items="filtersData.niche"
                label="Niche"
                hide-details
                clearable
                dense
                outlined
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.team"
                :items="filtersData.team"
                label="Team"
                multiple
                hide-details
                clearable
                dense
                outlined
                @change="handleUpdateDimension($event, 'by_project')"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.main_seo"
                :items="filtersData.main_seo"
                label="Main SEO"
                multiple
                clearable
                hide-details
                dense
                outlined
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project"
                :items="filtersData.project"
                label="Project"
                multiple
                hide-details
                clearable
                dense
                outlined
                @change="handleUpdateDimension($event, 'by_project')"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project_status"
                :items="filtersData.project_status"
                label="Project Status"
                hide-details
                dense
                clearable
                multiple
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.click_filter"
                :items="[
                  {
                    text: 'With clicks',
                    value: 'with_clicks',
                  },
                  {
                    text: 'Only top 5 or With click',
                    value: 'only_top_5_or_with_clicks',
                  },
                ]"
                label="Only top 5"
                hide-details
                dense
                clearable
                outlined
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-btn
                style="height: 40px"
                block
                class="text-normal"
                color="primary"
                @click="handleClickGetData"
                :loading="loadings.sections.length > 0"
                >Get Data
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col v-if="loadings.all" cols="12" class="d-flex justify-center">
        <div>
          <v-card width="400" class="shadow-e1-bordered" rounded="lg">
            <v-card-title class="d-flex justify-center">
              <v-progress-circular indeterminate size="34" width="3" />
            </v-card-title>
            <v-card-text class="text-center">
              Loading Sections. Sections will appear when all sections have
              finished loading.
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <v-col v-if="isAllowedRender" cols="12">
        <v-row>
          <template
            v-for="(group, idx) in getSortedGroups(
              Object.values(sections),
              true
            )"
          >
            <v-col
              v-if="group.items && group.items.length > 0"
              cols="12"
              :key="group.name + idx"
            >
              <v-row class="d-flex" style="gap: 1rem">
                <v-col cols="12">
                  <h2>{{ group.name }}</h2>
                </v-col>
                <v-col
                  v-for="(section, idx) in getSortedByPriority(group.items)"
                  cols="12"
                  ref="sectionRefs"
                  class="pa-0"
                  :key="idx"
                >
                  <main4dx-section-factory :data="section" />
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EasyRangeInput from "../UI/EasyRangeInput.vue";
import Main4dxSectionFactory from "./components/Main4dxSectionFactory.vue";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import service from "../../plugins/service";
import { deepClone } from "../../utils/functions";
import SmartDateFilter from "../UI/SmartDateFilter.vue";
import SmartAutocomplete from "../UI/SmartAutocomplete.vue";
import _4DXMixins from "../../mixins/components/_4DXMixins";
import LazyDomElementRenderingMixin from "../../mixins/LazyDomElementRenderingMixin";

export default {
  components: {
    SmartAutocomplete,
    SmartDateFilter,
    Main4dxSectionFactory,
    EasyRangeInput,
  },
  mixins: [FiltersHandling, _4DXMixins, LazyDomElementRenderingMixin],
  data: () => ({
    filters: {
      sections: [],
      click_filter: null,
      view_dimension: ["summary"],
      page_type: [],
      need_top: [null, null],
      niche: 2,
      team: [],
      main_seo: [],
      project: [],
      date: [null, null],
      project_status: [1],
    },
    sections: {
      other: {
        name: "Other",
        items: [],
      },
    },
    loadings: {
      section: false,
      sections: [],
      all: false,
    },
    isAllowedRender: false,
    filtersData: {
      main_seo: [],
      page_type: [],
      project: [],
      niche: [],
      view_dimension: [],
      sections: [],
      project_status: [],
      link_type: [],
      responsible: [],
      subproject: [],
      team: [],
    },
    groupPriority: {},
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "4DX",
        disabled: true,
      },
      {
        text: "Main Dashboard",
        disabled: true,
      },
    ],
  }),
  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.$store.commit("fourDxMainDashboard/setFilters", filters);
        this._$handleFiltersChange(filters);
      },
    },
  },
  async mounted() {
    await this.parseQuery("filters");

    const haveParams = !!Object.keys(this.$route.query).length;

    if (haveParams) this.handleClickGetData();

    this.fetchFilters();
  },
  methods: {
    clearSections() {
      this.sections = {
        other: {
          name: "Other",
          items: [],
        },
      };
    },
    getSortedByPriority(items, reverse = false) {
      const tmpr = deepClone(items);

      return tmpr.sort((a, b) => {
        if (reverse) {
          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1;
          return 0;
        }

        if (a.priority < b.priority) return 1;
        if (a.priority > b.priority) return -1;
        return 0;
      });
    },
    getSortedGroups(items) {
      const tmpr = deepClone(items);

      return tmpr.sort((a, b) => {
        const aPrior = this.groupPriority[a.name];
        const bPrior = this.groupPriority[b.name];

        if (aPrior < bPrior) return -1;
        if (aPrior > bPrior) return 1;
        return 0;
      });
    },
    async fetchFilters() {
      try {
        const filters = [
          "main_seo",
          "project",
          "niche",
          "view_dimension",
          "sections",
          "project_status",
          "link_type",
          "team",
          "page_type",
          "subproject",
          "responsible",
        ];
        const payload = {
          type: "/fdx/nt/index",
          take: filters,
        };
        this.loadings.filters = true;
        const resp = await this.$store.dispatch("global/getFilters", payload);
        this.loadings.filters = false;

        if (resp) {
          this.filtersData = { ...this.filtersData, ...resp };
        }
      } catch (e) {
        console.error(e);
        this.loadings.filters = false;
      }
    },
    handleFetchSections(additionalGroupData) {
      if (!this.filters.sections.length > 0) {
        this.$message.notification({
          title: "Validation error",
          text: "Select sections to load.",
          type: "error",
        });
        return;
      }

      this.isAllowedRender = false;
      this.loadings.all = true;

      const promises = [];

      this.filters.sections.forEach((sectionName, sectionIndx) => {
        additionalGroupData.forEach((groupData, groupIdx) => {
          promises.push(this.fetchSection(sectionName, groupData, sectionIndx));
          this.groupPriority[groupData.name] = groupIdx;
        });
      });

      Promise.allSettled(promises).then(() => {
        this.isAllowedRender = true;
        this.loadings.all = false;

        this.$nextTick(() => {
          this.initializeLazyRender(this.$refs.sectionRefs);
        });
      });
    },
    async handleClickGetData() {
      if (!this.$refs.formRef?.validate()) return;

      this.clearSections();

      try {
        const url = "/fdx/nt/groups";

        const configs = {
          params: this.filters,
        };

        const resp = await service.get(url, true, configs);

        if (resp) {
          this.handleFetchSections(resp.data.items);
        }
      } catch (e) {
        console.error(e);
      }
    },
    sortSectionToGroups(sectionData, groupData, sectionPriority) {
      if (!sectionData.group && !groupData.name)
        this.sections.other.items.push(sectionData);

      const groupName = groupData.name || sectionData.group;

      if (this.sections[groupName] === undefined) {
        this.sections[groupName] = {
          priority: sectionPriority,
          name: groupName,
          items: [],
        };
      }

      const idx = this.sections[groupName].items.findIndex(
        (v) => v.title === sectionData.title
      );

      if (idx !== -1) {
        return (this.sections[groupName].items[idx] = sectionData);
      }
      this.sections[groupName].items.push(sectionData);
    },
    async fetchSection(sectionType, groupData, sectionPriority) {
      try {
        const url = "/fdx/nt/index?type=" + sectionType;

        this.loadings.sections.push(sectionType);

        const configs = {
          params: { ...this.filters, ...groupData.filter },
        };

        const resp = await service.get(url, true, configs);

        if (resp) {
          if (Array.isArray(resp.data)) {
            resp.data.forEach((sectionData) => {
              this.sortSectionToGroups(sectionData, groupData, sectionPriority);
            });
            return;
          }

          this.sortSectionToGroups(resp.data, groupData, sectionPriority);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.sections = this.loadings.sections.filter(
          (v) => v !== sectionType
        );
      }
    },
  },
};
</script>
