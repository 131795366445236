<template>
  <div>
    <v-container fluid v-if="isset_active_project">
      <v-row>
        <v-col cols="12">
          <page-header
            title="Conversion report"
            :breadcrumbs="[
              { text: 'Home', to: '/' },
              { text: 'Analytics', disabled: true },
              { text: 'Conversion report', disabled: true },
            ]"
          />
        </v-col>
        <v-col cols="12" class="d-flex" style="gap: 0.25rem">
          <v-menu
            bottom
            :close-on-content-click="false"
            nudge-bottom="40"
            content-class="my-shadow--e2"
          >
            <template v-slot:activator="{ on }">
              <v-btn color="primary" class="text-normal" v-on="on">
                Filters<v-icon small right>mdi-filter</v-icon>
              </v-btn>
            </template>
            <v-card flat outlined rounded="lg" width="420">
              <v-card-title class="text-body-2"> Filters </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <smart-date-filter
                      :model-value="filter.date_range"
                      @update:modelValue="filter.date_range = $event"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="filter.subproject_id"
                      :items="subprojects"
                      chips
                      small-chips
                      label="Subprojects"
                      outlined
                      hide-details
                      dense
                      item-text="name"
                      item-value="id"
                      clearable
                      deletable-chips
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  @click="fetch_data"
                  color="primary"
                  large
                  class="px-6 text-normal"
                  :loading="loadings.data"
                >
                  Apply
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-btn @click="upload.modal = true" class="text-normal">
            Upload <v-icon small right>mdi-upload</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-card
            flat
            outlined
            rounded="lg"
            v-if="isset_active_project"
            :loading="loadings.data"
          >
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                dense
                outlined
                hide-details
                class="align-end"
              ></v-text-field>
            </v-card-title>
            <v-card-text class="px-0">
              <v-data-table
                :headers="table.headers"
                :items="table.items"
                :search="search"
                :sort-by="['visits']"
                :sort-desc="[true]"
                :footer-props="{ itemsPerPageOptions: [50, -1] }"
                :items-per-page="50"
                class="fixed-table-sw-d"
                dense
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.domain">
                      <td>
                        {{ item.name }}
                      </td>
                      <td class="text-right">
                        {{ item.visits }}
                      </td>
                      <td class="text-right">
                        {{ item.registrations }}
                      </td>
                      <td
                        class="text-right"
                        :style="'background-color:' + item.r_v_color"
                      >
                        {{ item.r_v }}%
                      </td>
                      <td class="text-right">
                        {{ item.ndc }}
                      </td>
                      <td class="text-right">
                        {{ item.v_n }}
                      </td>
                      <td
                        class="text-right"
                        :style="'background-color:' + item.r_n_color"
                      >
                        {{ item.r_n }}%
                      </td>
                      <td
                        class="text-right"
                        :style="'background-color:' + item.d_v_color"
                      >
                        {{ item.d_v }}%
                      </td>
                      <td class="text-right">
                        {{ item.visit_delta }}
                      </td>
                      <td class="text-right">
                        {{ item.registrations_delta }}
                      </td>
                      <td class="text-right">{{ item.r_v_delta }}%</td>
                      <td class="text-right">
                        {{ item.ndc_delta }}
                      </td>
                      <td class="text-right">{{ item.d_v_delta }}%</td>
                      <td
                        class="text-right"
                        :style="'background-color:' + item.earnings_color"
                      >
                        {{ item.earnings }}
                      </td>
                      <td
                        class="text-right"
                        :style="'background-color:' + item.rev_ndc_color"
                      >
                        {{ item.rev_ndc }}
                      </td>
                      <td class="text-right">
                        {{ item.rev_ndc_y }}
                      </td>
                      <td class="text-right">
                        {{ item.cl_cost }}
                      </td>
                      <td class="text-right">
                        {{ item.cl_cost_y }}
                      </td>
                      <td
                        class="text-right"
                        :style="'background-color:' + item.deposits_color"
                      >
                        {{ item.deposits }}
                      </td>
                      <td class="text-right">
                        {{ item.clicks_keitaro }}
                      </td>
                      <td
                        class="text-right"
                        :style="'background-color:' + item.keitaro_visits_color"
                      >
                        {{ item.keitaro_visits }}%
                      </td>
                      <td class="text-right">
                        {{ item.partnership }}
                      </td>
                      <td class="text-right">
                        {{ item.downloads }}
                      </td>
                      <td class="text-right">{{ item.gross_win }}$</td>
                      <td class="text-right">{{ item.bonus_money }}$</td>
                      <td class="text-right">{{ item.casino_profit }}$</td>
                      <td class="text-right">{{ item.your_earnings }}$</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <upload-n-d-c
        v-if="upload.modal === true"
        v-model="upload.modal"
        level="1"
      ></upload-n-d-c>
    </v-container>
    <v-alert
      icon="mdi-shield-lock-outline"
      prominent
      text
      type="info"
      dark
      v-else
    >
      <v-row align="center">
        <v-col class="grow">
          To view the page, you must select a project.
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="primary"
            dark
            class="text-normal"
            @click="$store.dispatch('toggle_select_project_menu')"
            >Select project
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import eventPipe from "../../../events/eventPipe";
import UploadNDC from "./UploadNDC";
import PageHeader from "../../UI/PageHeader.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";

let dateFormat = require("dateformat");

let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
let beforeOneWeek2 = new Date(beforeOneWeek);
let day = beforeOneWeek.getDay();
let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday - 6));
let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

export default {
  name: "ConversionIndex",
  mixins: [FiltersHandling],
  data() {
    return {
      search: "",
      loadings: {
        fetch_data: false,
        data: false,
      },
      date_modal: false,
      filter: {
        date_range: [
          dateFormat(lastMonday, "yyyy-mm-dd"),
          dateFormat(lastSunday, "yyyy-mm-dd"),
        ],
        subproject_id: undefined,
      },
      upload: { modal: false },
      urls: [],
      countries: [],
      table: {
        headers: [
          { text: "Brand name", value: "name", class: "text-left" },
          { text: "Visits", value: "visits", class: "text-right" },
          { text: "Regs", value: "registrations", class: "text-right" },
          { text: "R/V %", value: "r_v", class: "text-right" },
          { text: "Ndc", value: "ndc", class: "text-right" },
          { text: "V / N", value: "v_n", class: "text-right" },
          { text: "N/R, %", value: "r_n", class: "text-right" },
          { text: "N/V, %", value: "d_v", class: "text-right" },
          { text: "V, Δ", value: "visit_delta", class: "text-right" },
          { text: "R, Δ", value: "registrations_delta", class: "text-right" },
          { text: "R/V, %, Δ", value: "r_v_delta", class: "text-right" },
          { text: "NDC, Δ", value: "ndc_delta", class: "text-right" },
          { text: "NDC/V %, Δ", value: "d_v_delta", class: "text-right" },
          { text: "Pub revenue", value: "earnings", class: "text-right" },
          { text: "Rev/NDC", value: "rev_ndc", class: "text-right" },
          { text: "Rev/NDC Y", value: "rev_ndc_y", class: "text-right" },
          { text: "Cl Cost", value: "cl_cost", class: "text-right" },
          { text: "Cl cost Y", value: "cl_cost_y", class: "text-right" },
          { text: "Deposits", value: "deposits", class: "text-right" },
          {
            text: "Clicks keitaro",
            value: "clicks_keitaro",
            class: "text-right",
          },
          {
            text: "Keitaro/visits",
            value: "keitaro_visits",
            class: "text-right",
          },
          { text: "Partnership", value: "partnership", class: "text-right" },
          { text: "Downloads", value: "downloads", class: "text-right" },
          { text: "Gross Win", value: "gross_win", class: "text-right" },
          { text: "Bonus Money", value: "bonus_money", class: "text-right" },
          {
            text: "Casino Profit",
            value: "casino_profit",
            class: "text-right",
          },
          {
            text: "Your Earnings",
            value: "your_earnings",
            class: "text-right",
          },
        ],
        items: [],
      },
    };
  },
  components: { SmartDateFilter, PageHeader, UploadNDC },
  methods: {
    fetch_data() {
      let self = this;

      if (self.id_project !== null && self.id_project !== undefined) {
        if (self.filter.subproject_id === undefined) {
          self.filter.subproject_id = self.subprojects[0]["id"];
        }

        this.loadings.data = true;

        let url = `${self.$store.state.server_url}/analytics/conversion/report/${self.id_project}`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = { filter: self.filter };

        axios
          .post(url, data, config)
          .then(function (response) {
            self.table.items = response.data;
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.loadings.data = false;
          });
      }
    },
    update_setting() {
      this.$store.dispatch("update_ls_serp_setting", this.ls_settings);
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    subprojects() {
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.$store.state.project.active_project)
      );
      if (project && project[0]) return project[0]["subprojects"];
      else return [];
    },
  },
  async mounted() {
    await this.parseQuery("filter");

    eventPipe.$on("update-active-project", () => {
      this.fetch_data();
    });
  },
};
</script>

<style scoped>
.fixed-table-sw-d thead > tr > th:first-child,
.fixed-table-sw-d td:first-child {
  position: sticky;
  left: 0;
  min-width: 90px;
  z-index: 2;
}
</style>
