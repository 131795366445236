<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="styled-card--default">
          <v-card-title>
            {{ name }}
          </v-card-title>
          <v-card-title>
            <v-text-field
              v-model="search"
              clearable
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="d-flex">
            <table-search
              v-if="search_active"
              :model-value="search"
              @update:modelValue="search = $event"
              placeholder="Search in table"
              style="width: 320px"
            />
            <v-spacer />
            <v-menu
              v-if="additionalData && additionalData.colors"
              bottom
              offset-y
              :close-on-content-click="false"
              content-class="my-shadow--e3"
            >
              <template #activator="{ on }">
                <v-chip label outlined v-on="on">
                  <v-icon small left>mdi-information-outline</v-icon>
                  Colors guide
                  <v-icon small right>mdi-chevron-down</v-icon>
                </v-chip>
              </template>
              <v-card class="styled-card--default pa-0" width="420px">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Meaning</th>
                      <th>Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="color in additionalData.colors"
                      :key="color.name"
                    >
                      <td>
                        {{ color.name }}
                      </td>
                      <td>
                        <div
                          :style="{
                            width: '60px',
                            height: '40px',
                            background: color.color,
                          }"
                        ></div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-menu>
          </v-card-text>
          <v-card-text class="px-0 pt-0">
            <v-data-table
              :search="search"
              :headers="headers"
              :sort-by="['date']"
              :sort-desc="[true]"
              :items="items"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
              }"
              :items-per-page="50"
              dense
              fixed-header
            >
              <template
                v-for="h in headers"
                v-slot:[`header.${h.value}`]="{ header }"
              >
                <v-tooltip bottom :key="h.text">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>{{ header.title }}</span>
                </v-tooltip>
              </template>

              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="(item, i) in items"
                    :key="item.id"
                    :style="get_row_color(item)"
                    style="white-space: nowrap"
                  >
                    <td :title="item.date_full">
                      {{ item.date }}
                    </td>
                    <td class="text-start">
                      <v-menu content-class="my-shadow--e2">
                        <template #activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon small>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-card class="styled-card--light pa-0">
                          <v-list dense>
                            <v-list-item
                              @click="handleGoToViewInfoBySite(item)"
                            >
                              View info by site
                            </v-list-item>

                            <a
                              v-if="
                                item.gpm_entity_id !== null &&
                                item.gpm_entity_id !== undefined
                              "
                              :href="`/gpm/view-entity/${item.gpm_entity_id}`"
                              target="_blank"
                              class="reset-link-styles"
                            >
                              <v-list-item @click="() => {}">
                                Guest post manager (GPM)
                              </v-list-item>
                            </a>
                            <v-list-item
                              @click="handleOpenEditUrlDialog('donor', item)"
                            >
                              Edit Donor url
                            </v-list-item>
                            <v-list-item
                              @click="handleOpenEditUrlDialog('acceptor', item)"
                            >
                              Edit Acceptor url
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                      <span v-if="item.donor_url_actual === null">
                        <span
                          v-html="get_truncated_string(item.donor_url, 34)"
                        ></span>
                        <a :href="item.donor_url" target="_blank">
                          <i class="mdi mdi-link"></i>
                        </a>
                      </span>
                      <span v-else>
                        <span
                          v-html="
                            get_truncated_string(item.donor_url_actual, 34)
                          "
                        >
                        </span>
                        <a
                          :href="item.donor_url_actual"
                          target="_blank"
                          title="Open in new tab"
                        >
                          <i class="mdi mdi-open-in-new"></i>
                        </a>
                      </span>

                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              :color="check_min_params(item).color"
                              v-if="check_min_params(item).active > 0"
                              >mdi-information-outline</v-icon
                            >
                          </template>
                          <span v-html="check_min_params(item).html"></span>
                        </v-tooltip>
                      </span>

                      <span v-if="item.is_js">
                        <v-icon color="red">mdi-nodejs</v-icon>
                      </span>
                    </td>
                    <td class="text-start">
                      {{ item.type }}
                    </td>
                    <td class="text-left">
                      <span
                        v-html="
                          get_truncated_string(
                            get_url_acceptor(
                              item.acceptor_url,
                              item.acceptor_url_actual
                            ),
                            27
                          )
                        "
                      ></span>
                      <a
                        :href="
                          item.acceptor_url_actual != null
                            ? item.acceptor_url_actual
                            : item.acceptor_url
                        "
                        target="_blank"
                      >
                        <span v-if="item.acceptor_code === 200">
                          <i class="mdi mdi-link"></i>
                        </span>
                        <span v-else>
                          <span v-if="item.acceptor_code != null">
                            {{ item.acceptor_code }}
                          </span>
                          <span v-else> none </span>
                        </span>
                      </a>
                    </td>
                    <td class="text-left">
                      <span v-if="item.phrase_html">
                        <span v-html="item.phrase_html"></span>
                        <v-icon
                          color="error"
                          title="Contains Russian characters"
                          >mdi-information</v-icon
                        ></span
                      >
                      <span v-else>
                        <span
                          v-if="item.forced_on_page"
                          v-html="get_truncated_string(item.phrase_source, 27)"
                        ></span>
                        <span
                          v-else
                          v-html="get_truncated_string(item.phrase, 27)"
                        ></span>
                      </span>
                    </td>
                    <td class="text-start">
                      <span v-if="item.phrase_plan == null">
                        <v-select
                          v-model="items[i].phrase_plan"
                          :items="
                            anchors_plan[item.acceptorurlidactu][item.ltypeid]
                          "
                          :item-text="
                            (item) =>
                              `${item.phrase} : ${item.date_start} - ${item.date_finish}`
                          "
                          item-value="id"
                          v-if="
                            anchors_plan &&
                            anchors_plan[item.acceptorurlidactu] &&
                            anchors_plan[item.acceptorurlidactu][item.ltypeid]
                          "
                          @change="
                            update_anchors_plan(item.id, items[i].phrase_plan)
                          "
                          dense
                          hide-details
                        ></v-select>
                      </span>
                      <span v-else>
                        {{ item.phrase_plan }}
                      </span>
                    </td>
                    <td>
                      {{ item.anchor_type }}
                    </td>
                    <td>
                      <v-switch
                        hide-details
                        dense
                        v-model="item.check_link"
                        @change="update_check_link(item.id, item.check_link)"
                      ></v-switch>
                    </td>
                    <td>
                      <v-switch
                        hide-details
                        dense
                        v-model="item.waiting_for_indexation"
                        @change="
                          update_waiting_for_indexation(
                            item.id,
                            item.waiting_for_indexation
                          )
                        "
                      ></v-switch>
                    </td>
                    <td>
                      <v-card class="styled-card--default pa-0">
                        <v-btn icon @click="open_dialog_problems(item)">
                          <span
                            v-if="!item._changed"
                            v-html="get_color_class_thumbs(item.status)"
                          ></span>
                          <span v-else>
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-icon v-on="on" small> mdi-autorenew </v-icon>
                              </template>
                              <div>
                                <div>
                                  The status of this item has been changed
                                </div>
                                <div>
                                  Refresh the page to see the new status
                                </div>
                              </div>
                            </v-tooltip>
                          </span>
                        </v-btn>
                      </v-card>
                    </td>
                    <td class="text-center">
                      <span>{{ item.islink }}</span>
                      <span v-html="get_integer_key_entry(item)"></span>
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                              >mdi-information-outline
                            </v-icon>
                          </template>
                          <div>
                            <div>Last On Page: {{ item.last_on_page }}</div>
                            <div>
                              Last Check On Page:
                              {{ item.last_check_on_page }}
                            </div>
                          </div>
                        </v-tooltip>
                      </span>
                    </td>
                    <td>
                      <span v-html="get_thematic(item)"></span>
                    </td>
                    <td>
                      <span
                        :title="`${item.all_percent}% - ${item.all_acceptor} of acceptor url topics ${item.all_donor}`"
                        >{{ item.all_percent }}%
                      </span>
                      <v-icon
                        color="primary"
                        dark
                        @click="fetch_common_theme(item.id)"
                        >mdi-more
                      </v-icon>
                    </td>
                    <td class="text-start">
                      <div class="d-flex align-center">
                        <spam-table-cell :item="item" />
                        <v-icon
                          color="primary"
                          dark
                          @click="fetch_spams(item.id)"
                          >mdi-more
                        </v-icon>
                      </div>
                    </td>
                    <td>
                      <span>{{ item.donorUrIActualGI }}</span>
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                              >mdi-information-outline
                            </v-icon>
                          </template>
                          <div>
                            <div>First Gi: {{ item.first_gi }}</div>
                            <div>Last On Gi: {{ item.last_on_gi }}</div>
                            <div>Last Check Gi: {{ item.last_check_gi }}</div>
                          </div>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.add_my_url_send"
                              v-bind="attrs"
                              v-on="on"
                              color="green"
                              >mdi-email-alert-outline
                            </v-icon>
                            <v-icon
                              @click="update_send_index(item)"
                              v-bind="attrs"
                              v-on="on"
                              v-else-if="!item.send_index"
                              >mdi-email-alert-outline
                            </v-icon>
                            <v-icon
                              v-else
                              v-bind="attrs"
                              color="#FFD700"
                              v-on="on"
                              >mdi-email-alert-outline
                            </v-icon>
                          </template>
                          <div v-if="item.add_my_url_send">
                            Send: {{ item.add_my_url_send }}
                          </div>
                        </v-tooltip>
                      </span>
                    </td>
                    <td>
                      <div
                        :style="get_follow_color(item)"
                        title="Link scheduled - dofollow, affixed -  nofollow!"
                      >
                        {{ item.nofollow }}
                      </div>
                    </td>
                    <td>
                      {{ item.robotsclose }}
                    </td>
                    <td>
                      {{ item.noindex }}
                    </td>
                    <td>
                      {{ item.tags_s_u }}
                    </td>
                    <td>
                      {{ item.url_equal_canonical }}
                    </td>
                    <td>
                      {{ item.ah_dr }}
                    </td>
                    <td>
                      {{ item.google_trust }}
                    </td>
                    <td>
                      {{ item.top_country }}
                    </td>
                    <td>
                      {{ item.traffic }}
                    </td>
                    <td>
                      {{ item.lang }}
                    </td>
                    <td>
                      {{ item.google_index }}
                    </td>
                    <td>
                      {{ item.sw_visits }}
                    </td>
                    <td>
                      {{ item.sw_GlobalRank }}
                    </td>
                    <td>
                      {{ item.score }}
                    </td>
                    <td>
                      {{ item.spam_score }}
                    </td>
                    <td>
                      <span
                        title="Domain use ( domain use on page - domain use GI)"
                      >
                        {{ item.domain_use }} ({{ item.domain_use_on_page }} -
                        {{ item.domain_use_gi }})
                      </span>
                    </td>
                    <td>
                      <span
                        title="Domain use project ( Domain use project on page - Domain use project GI )"
                      >
                        {{ item.domain_use_project }} ({{
                          item.domain_use_project_on_page
                        }}
                        - {{ item.domain_use_project_gi }})
                      </span>
                    </td>
                    <td>
                      {{ item.user }}
                    </td>
                    <td>
                      <span v-html="get_price(item.price)"></span>
                    </td>
                    <td>
                      <p style="margin: 0; font-size: 10px">
                        {{ item.email }}
                      </p>
                      <p style="margin: 0; font-size: 10px">
                        {{ item.login }}
                      </p>
                      <p style="margin: 0; font-size: 10px">
                        {{ item.password }}
                      </p>
                    </td>
                    <td>
                      <v-menu top offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="ma-2" v-bind="attrs" v-on="on"
                            >mdi-cog
                          </v-icon>
                        </template>
                        <v-list>
                          <v-list-item
                            @change="
                              update_link_data(
                                'forced_on_page',
                                item.id,
                                item.forced_on_page
                              )
                            "
                          >
                            <v-list-item-action>
                              <v-switch
                                v-model="item.forced_on_page"
                              ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title
                              >Forced On Page
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            @change="
                              update_link_data(
                                'previous_month',
                                item.id,
                                item.previous_month
                              )
                            "
                          >
                            <v-list-item-action>
                              <v-switch
                                v-model="item.previous_month"
                              ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title
                              >Previous Month
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action
                              @click="
                                update_link_data(
                                  'update_info',
                                  item.id,
                                  null,
                                  item
                                )
                              "
                            >
                              <v-btn
                                icon
                                :loading="loadings.updateLink"
                                :color="`${
                                  item.successfuly_added ? 'success' : ''
                                }`"
                              >
                                <v-icon v-if="!item.successfuly_added">
                                  mdi-reload
                                </v-icon>
                                <v-icon v-else
                                  >mdi-check-circle-outline
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                            <v-list-item-title>
                              <template v-if="!item.successfuly_added">
                                Update Info
                              </template>
                              <template v-else>In line for update</template>
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            @change="
                              update_link_data(
                                'trouble',
                                item.id,
                                item.is_trouble
                              )
                            "
                          >
                            <v-list-item-action>
                              <v-switch v-model="item.is_trouble"></v-switch>
                            </v-list-item-action>
                            <v-list-item-title
                              >Trouble
                              <span v-if="item.status_id > 0">
                                -> "{{ trouble_status[item.status_id] }}"</span
                              >
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                    <template v-if="checkTraffic">
                      <td>
                        {{ item.traffic_post }}
                      </td>
                      <td>
                        {{ item.traffic_now }}
                      </td>
                      <td>
                        {{ item.traffic_difference }}
                      </td>
                      <td>
                        <div
                          v-if="String(item.traffic_percent) !== '0'"
                          :style="`${getTrafficBgColor(item.traffic_percent)}`"
                        >
                          {{ item.traffic_percent }}
                        </div>
                      </td>
                    </template>
                    <template v-if="temporaryGp">
                      <td>{{ item.duration }}</td>
                      <td>{{ item.price_gp }}</td>
                      <td>{{ item.expired }}</td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      :retain-focus="false"
      v-model="dialog.thematic"
      width="900"
      scrollable
      content-class="my-shadow--e2"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          <b>Thematic</b>
          <v-spacer />
          <v-icon @click="dialog.thematic = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-title class="pa-4">
          <v-text-field
            dense
            hide-details
            outlined
            placeholder="Type to search"
            append-icon="mdi-magnify"
            v-model="thematicSearchQuery"
          />
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="4">
              <b>Donor topics by key</b>
            </v-col>
            <v-col cols="12" sm="4">
              <b>Topic donor page</b>
            </v-col>
            <v-col cols="12" sm="4">
              <b>Topic acceptor page</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-row
                v-for="item in searchThematic(common_theme.donor_domain)"
                dense
                :key="item.id"
              >
                <v-col cols="12" sm="6" class="text-body-2">
                  {{ item.topic }}
                </v-col>
                <v-col cols="12" sm="6" :style="item.style ? item.style : ''">
                  {{ item.value }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4">
              <v-row
                v-for="item in searchThematic(common_theme.donor_url)"
                dense
                :key="item.id"
              >
                <v-col cols="12" sm="6">
                  {{ item.topic }}
                </v-col>
                <v-col cols="12" sm="6" :style="item.style ? item.style : ''">
                  {{ item.value }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4">
              <v-row
                v-for="item in searchThematic(common_theme.acceptor_url)"
                dense
                :key="item.id"
              >
                <v-col cols="12" sm="6">
                  {{ item.topic }}
                </v-col>
                <v-col cols="12" sm="6" :style="item.style ? item.style : ''">
                  {{ item.value }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="text-normal px-6"
            @click="dialog.thematic = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :retain-focus="false"
      v-model="dialog.spams"
      max-width="1200px"
      content-class="my-shadow--e2"
      scrollable
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2">
          <b>Spams</b>
          <v-spacer />
          <v-icon @click="dialog.spams = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-title class="pa-4">
          <v-text-field
            hide-details
            dense
            outlined
            placeholder="Type to search"
            append-icon="mdi-magnify"
            v-model="spamsSearchQuery"
          />
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0 pb-3">
          <template>
            <v-data-table
              :search="spamsSearchQuery"
              dense
              :items="spams"
              :headers="[
                {
                  text: 'Our link',
                  value: 'our_link',
                },
                {
                  text: 'Title',
                  value: 'title',
                },
                {
                  text: 'H1',
                  value: 'h1',
                },
                {
                  text: 'Anchor',
                  value: 'anchor',
                },
              ]"
            />
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large class="px-6 text-normal" @click="dialog.spams = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :retain-focus="false"
      v-model="problemsDialogModel"
      content-class="my-shadow--e3"
      scrollable
    >
      <v-card class="styled-card--default">
        <v-card-title>
          Problems
          <v-spacer />
          <v-btn icon @click="problemsDialogModel = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <v-row dense>
            <v-col v-for="(item, i) in problems_types" :key="item.id">
              <v-card class="styled-card--default">
                <v-card-title>
                  {{ i }}
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-5">
                  <v-row v-for="problem in item" dense :key="problem.id">
                    <v-col cols="8">
                      <span
                        :class="{
                          'primary--text': problems.selected?.includes(
                            problem.id
                          ),
                        }"
                      >
                        {{ problem.type }}
                      </span>
                    </v-col>
                    <v-col cols="4" class="d-flex justify-end">
                      <v-checkbox
                        v-model="problems.selected"
                        :value="problem.id"
                        hide-details
                        dense
                        class="ma-0"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <v-card class="styled-card--error">
                    <v-card-title>
                      Тип проблемы
                      <v-spacer />
                      <v-icon
                        v-if="validationErrors.includes('errorType')"
                        color="error"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="pt-5">
                      <v-radio-group
                        v-model="problems.selected_priorities"
                        :error="validationErrors.length > 0"
                        class="fz-14"
                      >
                        <v-radio
                          v-for="item in problems_priorities"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></v-radio>
                      </v-radio-group>

                      <v-textarea
                        v-model="problems.comment"
                        name="textarea"
                        hide-details
                        filled
                        auto-grow
                        label="Details:"
                        placeholder="Problem Name - details"
                      ></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
          <extended-chip
            @click="problemsDialogModel = false"
            label
            large
            class="px-6"
            color="transparent"
          >
            Close
          </extended-chip>
          <extended-chip
            @click="save_dialog_problems"
            label
            large
            class="px-6"
            color="success"
            :loading="loadings.saving"
            :disabled="loadings.saving"
          >
            Save changes
          </extended-chip>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-url-dialog
      :model-value="editUrlDialog"
      @update:modelValue="editUrlDialog = $event"
      :data="editUrlData"
    />
  </div>
</template>

<script>
import axios from "axios";
import SpamTableCell from "@/components/Ltt/DeletedLinks/SpamTableCell.vue";
import EditUrlDialog from "@/components/Ltt/Child/EditUrlDialog.vue";
import TableSearch from "@/components/UI/TableSearch.vue";
import ExtendedChip from "@/components/UI/ExtendedChip.vue";

export default {
  name: "Links",
  created() {
    let self = this;
    self.fetch_anchors_plan();
  },
  props: {
    items: {},
    additionalData: {},
    name: {},
    settings: {
      type: Object,
      default() {
        return {};
      },
    },
    search_active: {},
    min_parameters: {},
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { ExtendedChip, TableSearch, EditUrlDialog, SpamTableCell },
  data() {
    return {
      search: null,
      thematicSearchQuery: null,
      spamsSearchQuery: null,
      editUrlData: {
        type: "",
        urlData: {},
      },
      editUrlDialog: false,
      dialog: { thematic: false, problems: false, spams: false },
      anchors_plan: [],
      validationErrors: [],
      problemsDialogModel: false,
      loadings: {
        update_waiting_for_indexation: false,
        updateLink: false,
        fetch_data: false,
        types_list: false,
        users_list: false,
        saving: false,
      },
      problems_types: [],
      problems_priorities: [],
      spams: [],
      common_theme: {
        acceptor_url: [],
        donor_domain: [],
        donor_url: [],
      },
      trouble_status: {
        1: "new",
        2: "fix",
        3: "not fix",
      },
      problems: {
        types: [],
        priorities: [],
        dialog: [],
        selected: [],
        selected_priorities: 1,
        link_id: false,
        comment: "",
      },
      currency: { USD: 1, EUR: 1.8 },
    };
  },
  computed: {
    id_project() {
      return this.$store.getters["project/active_project"];
    },
    checkTraffic() {
      if (!this.settings) return false;

      return this.settings.check_traffic;
    },
    temporaryGp() {
      if (!this.settings) return false;

      return this.settings.temporary_gp;
    },
    headers() {
      let headers = [
        { text: "Date", title: "Date", value: "date", class: "text-center" },
        {
          text: "Donor Url",
          title: "Donor Url",
          value: "donor_url",
          class: "text-left",
        },
        { text: "Type", title: "Type", value: "type", class: "text-left" },
        {
          text: "Acceptor Url",
          title: "Acceptor Url",
          value: "acceptor_url_actual",
          class: "text-center",
        },
        {
          text: "Phrase",
          title: "Phrase",
          value: "phrase",
          class: "text-left",
        },
        {
          text: "Phrase Plan",
          title: "Phrase Plan",
          value: "phrase_plan",
          class: "text-left",
        },
        {
          text: "Anchor Type",
          title: "Anchor Type",
          value: "anchor_type",
          class: "text-left",
        },
        { text: "CL", title: "Check Link", value: "", class: "text-left" },
        {
          text: "WI",
          title: "Waiting for indexation",
          value: "",
          class: "text-left",
        },
        { text: "Err", title: "Err", value: "", class: "text-left" },
        { text: "oP", title: "OnPage", value: "islink", class: "text-center" },
        {
          text: "Th",
          title: "Thematic",
          value: "thematic",
          class: "text-center",
        },
        {
          text: "Common theme",
          title: "Common theme",
          value: "all_percent",
          class: "text-center",
        },
        { text: "Spam", title: "Spam", value: "", class: "text-center" },
        {
          text: "GI",
          title: "Donor url actual google index",
          value: "donorUrIActualGI",
          class: "text-center",
        },
        {
          text: "NF",
          title: "Nofollow",
          value: "nofollow",
          class: "text-center",
        },
        {
          text: "NI",
          title: "Noindex",
          value: "noindex",
          class: "text-center",
        },
        {
          text: "RC",
          title: "Robots close",
          value: "robotsclose",
          class: "text-center",
        },
        {
          text: "Tags S U",
          title: "Tags_s_u",
          value: "tags_s_u",
          class: "text-left",
        },
        {
          text: "Url Equal Canonical",
          title: "Url equal canonical",
          value: "url_equal_canonical",
          align: "left",
        },
        { text: "AH DR", title: "Ahrefs DR", value: "ah_dr", align: "left" },
        {
          text: "GT",
          title: "Google trust",
          value: "google_trust",
          align: "left",
        },
        {
          text: "Top Country",
          title: "Top country",
          value: "top_country",
          align: "left",
        },
        { text: "Traffic", title: "Traffic", value: "traffic", align: "left" },
        { text: "Lang", title: "Language", value: "lang", align: "left" },
        {
          text: "Google Index",
          title: "Google index",
          value: "google_index",
          align: "left",
        },
        {
          text: "Sw Visits",
          title: "sw_visits",
          value: "sw_visits",
          align: "left",
        },
        {
          text: "Sw GlobalRank",
          title: "sw_GlobalRank",
          value: "sw_GlobalRank",
          align: "left",
        },
        { text: "Score", title: "score", value: "score", align: "left" },
        {
          text: "Spam Score",
          title: "spam_score",
          value: "spam_score",
          align: "left",
        },
        {
          text: "Domain Use",
          title: "domain_use",
          value: "domain_use",
          align: "left",
        },
        {
          text: "Domain Use Project",
          title: "domain_use_project",
          value: "domain_use_project",
          align: "left",
        },
        {
          text: "Executor",
          title: "Executor user",
          value: "user",
          align: "left",
        },
        { text: "Price", title: "Price", value: "price", align: "left" },
        {
          text: "Access",
          title: "Access email",
          value: "email",
          align: "left",
        },
        { text: "Setting", title: "Setting", value: "", align: "left" },
      ];

      if (this.checkTraffic) {
        headers = [
          ...headers,
          { text: "traffic_post", value: "traffic_post" },
          { text: "traffic_now", value: "traffic_now" },
          { text: "traffic_difference", value: "traffic_difference" },
          { text: "traffic_percent", value: "traffic_percent" },
        ];
      }

      if (this.temporaryGp) {
        headers = [
          ...headers,
          { text: "Duration", value: "duration" },
          { text: "Price GP", value: "price_gp" },
          { text: "Expired", value: "expired" },
        ];
      }

      return headers;
    },
  },
  methods: {
    searchThematic(items) {
      if (!items || !Array.isArray(items)) return [];

      return items.filter((item) => {
        if (!this.thematicSearchQuery) return true;

        const search = String(this.thematicSearchQuery).toLowerCase().trim();
        const name = String(item.topic).toLowerCase().trim();

        return name.includes(search);
      });
    },
    handleOpenEditUrlDialog(type, urlData) {
      this.editUrlDialog = true;
      this.editUrlData = {
        type,
        urlData,
      };
    },
    handleGoToViewInfoBySite(tableRowData) {
      const data = tableRowData;
      const domain = this.url_to_domain(data.donor_url);

      let query = `?domain=${domain}`;

      if (data.country_id) query += `&country=${data.country_id}`;
      if (data.niche_id) query += `&niche=${data.niche_id}`;
      if (data.spiderlink_task_id)
        query += `&spiderlink_project_id=${data.spiderlink_task_id}`;
      if (data.link_type_id) query += `&link_type=${data.link_type_id}`;

      window.open(`/site-analysis${query}`, "_blank");
    },
    getTrafficBgColor(value) {
      const tmprVal = Number(value);

      let bgColor = "background-color:";

      if (tmprVal <= -50) bgColor += "red;";
      if (tmprVal >= -49.99 && tmprVal <= -15) bgColor += "orange;";
      if (tmprVal >= -14.99 && tmprVal < 0) bgColor += "yellow;";
      if (tmprVal >= 0) bgColor += "green;";

      return bgColor + "color:white; font-weight: 600;";
    },
    get_price(price) {
      let json = JSON.parse(price);
      if (json !== null) {
        let all = 0;
        let title = "";

        if (json["price"] && json["price"] != null) {
          let rate = 1;

          rate = this.currency[json["currency_p"]];

          all += parseInt(json["price"]) * rate;
          title +=
            "Domain price - " + json["price"] + json["currency_p"] + "\n";
        }
        if (json["content_price"] && json["content_price"] > 0) {
          all += parseInt(json["content_price"]);
          title +=
            "Content - " + json["content_price"] + json["currency_cp"] + "\n";
        }
        if (json["lb_work_price"] && json["lb_work_price"] != null) {
          all += parseInt(json["lb_work_price"]);
          title +=
            "LB Work - " + json["lb_work_price"] + json["currency_lwp"] + "\n";
        }

        return '<span title="' + title + '">' + all + "$</span>";
      } else {
        return "";
      }
    },
    get_row_color(item) {
      if (item.disavow === 1) {
        return "background-color: #E399B1 !important;"; // disavowed links
      }
      if (item.islink === 1) {
        if (item.donorUrIActualGI === 1) {
          return "background-color: rgba(100, 255, 11, 0.20) !important;"; // если всё ок: страница есть в индексе, есть на странице и не закрыта от индексации
        }
        if (item.noindex === 1 || item.robotsclose === 1) {
          return "background-color: rgba(255, 21, 0, 0.20) !important;"; // если страницы закрыта от индексации
        }
        return "background-color: rgba(255, 189, 0, 0.20) !important;"; // если всё есть, кроме индексации
      }

      return "background-color: rgba(0, 149, 255, 0.20) !important;"; // если страницы нет: то легкий синий
    },
    get_url_acceptor(acceptor_url, acceptor_url_actual) {
      if (acceptor_url_actual != null) {
        return this.get_url_path(acceptor_url_actual);
      }
      return this.get_url_path(acceptor_url);
    },
    save_dialog_problems() {
      this.validationErrors.push("errorType");
      if (!this.problems.selected_priorities) {
        return this.$message.notification({
          title: "Validation",
          text: "Error type are not chosen",
          duration: 10000,
          type: "error",
        });
      }

      this.validationErrors = [];

      let self = this;
      self.update_loading("save_dialog_problems", true);

      let url = `${self.$store.state.server_url}/ltt/reports/save-link-report/link/${self.problems.link_id}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        types: self.problems.selected,
        priority: self.problems.selected_priorities,
        comment: self.problems.comment,
      };

      this.loadings.saving = true;

      axios
        .post(url, data, config)
        .then(function (response) {
          if (response.data) {
            console.log("good");
          }
          self.update_loading("save_dialog_problems", false);
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(() => {
          const idx = this.items.findIndex(
            (v) => v.id === this.problems.link_id
          );
          this.items[idx]._changed = true;
          this.$forceUpdate();
          this.loadings.saving = false;
        });
      this.problemsDialogModel = false;
    },
    get_follow_color(item) {
      if (item.dofollow === 1) {
        if (item.nofollow === 1) {
          return "background-color: red";
        }
      }
      return "";
    },
    async fetch_links_problems(type_id) {
      let self = this;
      self.update_loading("fetch_links_problems", true);

      self.problems_types = [];
      self.problems_priorities = [];

      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/ltt/links-problem`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = { type_id: type_id };

        await axios
          .post(url, data, config)
          .then(function (response) {
            self.problems_types = response.data.problems_type;
            self.problems_priorities = response.data.problems_priority;
            self.update_loading("fetch_links_problems", false);
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    url_to_domain(url = "") {
      if (url[0] === "-") {
        url = url.slice(1, url.length - 1);
      }
      let urlObj = new URL(url);
      return urlObj.hostname.replace("www.", "");
    },
    async open_dialog_problems(item) {
      await this.fetch_links_problems(item.ltypeid);

      const problemsCopy = JSON.parse(JSON.stringify(this.problems));

      if (item.problem_type !== null) {
        problemsCopy.selected = item.problem_type
          .split(",")
          .map((e) => parseInt(e));
      } else {
        problemsCopy.selected = [];
      }

      problemsCopy.link_id = item.id;
      problemsCopy.comment = item.comment;

      problemsCopy.selected_priorities = 1;

      this.problems = problemsCopy;

      this.problemsDialogModel = true;
    },
    check_min_params(item) {
      let str = "";
      let val = 0;
      let color = "red";
      let params = [
        { id: 1, name: "sw_visits", char: ">=" },
        { id: 2, name: "sw_percent", char: ">=" },
        { id: 4, name: "ah_dr", char: ">=" },
        { id: 5, name: "traffic", char: ">=" },
        { id: 6, name: "positions", char: ">=" },
        { id: 7, name: "spam_score", char: ">=" },
        { id: 8, name: "google_index", char: ">=" },
      ];

      let min_parameters = this.min_parameters;

      let min_param = min_parameters.crowd;
      if (item.ltypeid === 6) {
        min_param = min_parameters.guest_post;
      }

      for (let param of params) {
        if (
          min_param[param.id]["join"][0] &&
          min_param[param.id]["join"][0]["value"]
        ) {
          val = min_param[param.id]["join"][0]["value"];
        } else {
          val = min_param[param.id]["join"]["value"];
        }

        if (item[param.name] == null) {
          color = "#d7d707";
        }

        if (param.char === ">=") {
          if (item[param.name] + 1 <= val) {
            str +=
              "<div>" +
              min_param[param.id]["name"] +
              ": " +
              item[param.name] +
              " ( needed - " +
              val +
              ")</div>";
          }
        } else {
          if (item[param.name] >= val) {
            str +=
              "<div>" +
              min_param[param.id]["name"] +
              ": " +
              item[param.name] +
              " ( needed - " +
              val +
              ")</div>";
          }
        }
      }
      return { html: str, active: str.length, color: color };
    },
    get_color_class_thumbs(status) {
      let res = { color: "#D3D3D3", class: "mdi-thumb-down" };
      if (status != null) {
        let arr = status.split(",");
        for (let item of arr) {
          switch (item) {
            case "done":
              res = { color: "green", class: "mdi-thumb-up-outline" };
              break;
            case "wait checking seo":
              res = { color: "#ff9900", class: "mdi-clock-in" };
              break;
            case "unchecked":
              res = { color: "grey", class: "mdi-emoticon-angry-outline" };
              break;
            default:
              res = { color: "red", class: "mdi-thumb-down" };
          }
        }
      }
      return (
        '<i style="color:' + res.color + '" class="mdi ' + res.class + '"></i>'
      );
    },
    get_url_path(url) {
      try {
        return new URL(url).pathname;
      } catch (error) {
        return error; // TypeError, "Failed to construct URL: Invalid URL"
      }
    },
    fetch_spams(id) {
      let self = this;
      self.update_loading("fetch_spams", true);

      this.dialog.spams = true;

      let url = `${self.$store.state.server_url}/ltt/spams-urls/${id}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function (response) {
          self.spams = response.data;
          self.update_loading("fetch_spams", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    fetch_common_theme(id) {
      let self = this;
      self.update_loading("fetch_common_theme", true);

      this.dialog.thematic = true;

      let url = `${self.$store.state.server_url}/ltt/common-theme/link/${id}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function (response) {
          self.common_theme.acceptor_url = response.data.acceptorUrl;
          self.common_theme.donor_domain = response.data.donorDomain;
          self.common_theme.donor_url = response.data.donorUrl;
          self.update_loading("fetch_common_theme", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    fetch_currency(from_currency, to_currency = "USD") {
      let self = this;
      self.update_loading("fetch_currency", true);

      let url = `https://www.alphavantage.co/query?function=CURRENCY_EXCHANGE_RATE&from_currency=${from_currency}&to_currency=${to_currency}&apikey=FTW843FDT9EJRT19`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          "User-Agent": "request",
        },
      };

      axios
        .get(url, config)
        .then(function (response) {
          if (response.data["Realtime Currency Exchange Rate"])
            self.currency[from_currency] =
              response.data["Realtime Currency Exchange Rate"][
                "5. Exchange Rate"
              ];
          else self.currency[from_currency] = 1;
          self.update_loading("fetch_currency", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    fetch_anchors_plan() {
      let self = this;
      self.update_loading("fetch_anchors_plan", true);

      let url = `${self.$store.state.server_url}/ltt/anchors-plan/${this.id_project}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function (response) {
          self.anchors_plan = response.data;
          self.update_loading("fetch_anchors_plan", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    update_link_data(name, link_id, value = null, item) {
      if (item && item.successfuly_added) return;
      this.loadings.updateLink = true;
      let self = this;
      self.update_loading("update_link_data", true);
      let url = `${self.$store.state.server_url}/ltt/update_link_data/link/${link_id}/1`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: { name: name, value: value },
      };

      //  let data = {name: name, value: value}

      axios
        .get(url, config)
        .then((response) => {
          self.anchors_plan = response.data;
          self.update_loading("update_link_data", false);
          if (item) item.successfuly_added = true;
          this.$message.notification({
            title: "Success",
            type: "success",
            text: "The link has been successfully inserted into the queue",
            duration: 6500,
          });
        })
        .catch((error) => {
          this.$message.notification({
            title: "Error",
            type: "error",
            text: error.message,
            duration: 6500,
          });
        })
        .finally(() => {
          this.loadings.updateLink = false;
        });
    },
    update_send_index(item) {
      let self = this;
      self.update_loading("update_send_index", true);

      item.send_index = 1;

      let url = `${self.$store.state.server_url}/ltt/send_index/link/${item.id}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("update_send_index", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    update_check_link(link_id, value) {
      let self = this;
      self.update_loading("update_check_link", true);

      let url = `${
        self.$store.state.server_url
      }/ltt/check_link/link/${link_id}/${value ? 1 : 0}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("update_check_link", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    update_waiting_for_indexation(link_id, value) {
      let self = this;
      self.update_loading("update_waiting_for_indexation", true);

      let url = `${
        self.$store.state.server_url
      }/ltt/waiting_for_indexation/link/${link_id}/${value ? 1 : 0}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("update_waiting_for_indexation", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    update_anchors_plan(id, plan_id) {
      let self = this;

      self.update_loading("update_anchors_plan", true);

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        link_id: id,
        plan_id: plan_id,
      };

      axios
        .post(
          `${self.$store.state.server_url}/ltt/anchors-plan-completed`,
          data,
          config
        )
        .then(function (response) {
          if (response.data === true) {
            console.log("good");
          } else {
            console.log("error");
          }
          self.update_loading("update_anchors_plan", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    get_truncated_string(str, length) {
      if (str != null && str.length != null && str.length > length) {
        return (
          "<span title='" +
          str +
          "'>" +
          this.strip_html(str).substring(0, length) +
          "...</span>"
        );
      }
      return str;
    },
    strip_html(html) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    get_thematic(item) {
      if (item.thematic === 1)
        return (
          '<i style="color: green" class="mdi mdi-arm-flex" title="title: ' +
          item.title +
          '"></i>'
        );
      if (item.thematic === 0)
        return (
          '<i style="color: red" class="mdi mdi-crop" title="title: ' +
          item.title +
          '"></i>'
        );
      if (item.thematic == null)
        return (
          '<i style="color: grey" class="mdi mdi-progress-question" title="title: ' +
          item.title +
          '"></i>'
        );
    },
    get_spam(item) {
      let spamExternalLink = "?",
        spamEssay = "?",
        spamPorn = "?",
        spamReplica = "?",
        externalLink = "?",
        spamCasino = "?";

      if (item.spam_essay != null) {
        spamEssay = item.spam_essay;
        spamPorn = item.spam_porn;
        spamReplica = item.spam_replica;
        spamCasino = item.spam_casino;
        externalLink = item.external_link;
        spamExternalLink =
          item.spam_replica +
          item.spam_porn +
          item.spam_essay +
          item.spam_casino;
      }

      return (
        "<span title='Number of competitors' >" +
        "C<sup " +
        this.check_value_and_return_color(
          item.number_of_competitors,
          "#d5bc03"
        ) +
        "><b>" +
        item.number_of_competitors +
        "</b></sup></span>" +
        "<span title='essay - " +
        item.spam_date +
        "'>e<sup " +
        this.check_value_and_return_color(item.spam_essay, "red") +
        ">" +
        spamEssay +
        "</sup></span>," +
        "<span title='casino - " +
        item.spam_date +
        "'>c<sup " +
        this.check_value_and_return_color(item.spam_casino, "red") +
        ">" +
        spamCasino +
        "</sup></span>," +
        "<span title='porn - " +
        item.spam_date +
        "'>p<sup " +
        this.check_value_and_return_color(item.spam_porn, "red") +
        ">" +
        spamPorn +
        "</sup></span>," +
        "<span title='replica - " +
        item.spam_date +
        "'>r<sup " +
        this.check_value_and_return_color(item.spam_replica, "red") +
        ">" +
        spamReplica +
        "</sup></span>" +
        "=<span title='spam external link'>" +
        spamExternalLink +
        "</span>/" +
        "<span title='external link'>" +
        externalLink +
        "</span>"
      );
    },
    check_value_and_return_color(val, color) {
      if (val > 0) return "style='color:" + color + "'";
      else return "";
    },
    get_integer_key_entry(item) {
      let str = "";
      if (item.integer_key_entry > 0) {
        str =
          '<i style="color: green" title=\'' +
          item.integer_key_entry +
          '\' class="mdi mdi-emoticon-happy"></i>';
      } else {
        if (item.occurrence_of_each_word > 0) {
          str =
            ' <i style="color: #ff9900" title=\'' +
            item.occurrence_of_each_word +
            '\' class="mdi mdi-emoticon-wink"></i>';
        }
        if (item.occurrence_of_each_word === 0) {
          str =
            ' <i style="color: red" title=\'' +
            item.occurrence_of_each_word +
            '\' class="mdi mdi-emoticon-cry-outline"></i>';
        }
      }
      return str;
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
    update_filter() {
      let data = this.$route.query;
      if (data.project) {
        this.$store.state.project.active_project = data.project;

        if (data.date_range)
          this.filter.date_range = data.date_range.split(",");
        if (data.type) this.filter.type = Number(data.type);

        this.$router.replace({ query: null });
      }
    },
  },
};
</script>

<style scoped></style>
