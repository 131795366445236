<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageData.TITLE"
          :breadcrumbs="pageData.BREADCRUMBS"
        />
      </v-col>
      <v-col cols="12" class="pb-0 d-flex">
        <v-btn
          color="primary"
          class="text-normal"
          @click="isAddToQueryDialogVisible = true"
        >
          Add to query <v-icon right small>mdi-plus</v-icon>
        </v-btn>
        <v-spacer />
        <div
          v-if="typeof stats === 'object'"
          class="d-flex"
          style="gap: 0.5rem"
        >
          <template v-for="([k, v], index) in Object.entries(stats)">
            <v-chip label color="transparent" :key="k">
              {{ k }} - {{ v }}
            </v-chip>
            <v-divider
              v-if="index !== Object.entries(stats).length - 1"
              vertical
              :key="k"
            />
          </template>
        </div>
      </v-col>
      <v-col v-if="isDataInTableOutdated" cols="12" class="pb-0">
        <v-alert type="warning" text class="ma-0 rounded-lg">
          <template #default>
            <div class="text-body-2">
              <b>Queue is updated</b>
              <div>
                The queue has been updated, the data in the table is not up to
                date. You can update the data in the table.
              </div>
              <div class="mt-4">
                <v-btn
                  color="warning"
                  @click="initializeDashboard"
                  :loading="pending"
                  class="text-normal"
                >
                  Update table data <v-icon right>mdi-refresh</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title>
            <v-text-field
              v-model="searchQuery"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              clearable
              append-icon="mdi-magnify"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-skeleton-loader
              v-if="!isDataInTableUploadedOnce && pending"
              type="table"
            />
            <v-data-table
              v-else
              dense
              :search="searchQuery"
              :items="tableData?.data || []"
              :headers="headers"
              :loading="pending"
              :page.sync="pagination.current"
              :items-per-page.sync="pagination.per_page"
              :server-items-length="pagination.total"
              :footer-props="{ itemsPerPageOptions: [50, 150, 300, -1] }"
              @update:page="onPageChange"
              @update:items-per-page="onRowsPerPageChange"
            >
              <template
                v-for="key in ['created_at', 'updated_at']"
                #[`item.${key}`]="{ value }"
              >
                {{ $moment(value).format("ll hh:mm") }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <add-to-query-dialog
      v-model="isAddToQueryDialogVisible"
      @isDataInTableOutdated="isDataInTableOutdated = true"
    />
  </v-container>
</template>

<script>
import PageHeader from "../../../components/UI/PageHeader.vue";
import { REDIRECTS_QUERY_PAGE_DATA } from "./utils/defaults";
import { redirectsApiService } from "../services/redirectsApiService";
import AddToQueryDialog from "./components/AddToQueryDialog.vue";
import EasyDataTableSearchMixin from "../../../mixins/utils/EasyDataTableSearchMixin";

export default {
  components: { AddToQueryDialog, PageHeader },

  mixins: [EasyDataTableSearchMixin],

  data: () => ({
    pending: false,
    apiService: null,
    isAddToQueryDialogVisible: false,
    isDataInTableOutdated: false,
    tableData: {},
    headers: REDIRECTS_QUERY_PAGE_DATA.DATA_TABLE_HEADERS,
    isDataInTableUploadedOnce: false,
    stats: {},
    pagination: {
      current: 1, // текущая страница
      per_page: 50, // количество элементов на странице
      total: 0, // общее количество элементов
    },
  }),

  computed: {
    pageData() {
      return REDIRECTS_QUERY_PAGE_DATA;
    },
  },

  created() {
    this.apiService = redirectsApiService();
  },

  mounted() {
    this.initializeDashboard();
  },

  methods: {
    onPageChange(newPage) {
      this.pagination.current = newPage;
      this.initializeDashboard();
    },
    onRowsPerPageChange(newRowsPerPage) {
      this.pagination.per_page = newRowsPerPage;
      this.initializeDashboard();
    },
    handleSearchChanged() {
      this.initializeDashboard();
    },
    async initializeDashboard() {
      try {
        this.pending = true;

        // Получаем данные с сервера
        const { data } = await this.apiService.getQueue({
          page: this.pagination.current,
          rowsPerPage: this.pagination.per_page,
          search: this.searchQuery,
        });

        // Обработка пагинации
        this.tableData = data.redirects;
        this.pagination.total = data.redirects.total;
        this.pagination.current = data.redirects.current_page;
        this.pagination.per_page = data.redirects.per_page;
        this.stats = data.stat;
        this.isDataInTableUploadedOnce = true;
      } catch (e) {
        console.error("Error while initializing page.", e);
      } finally {
        this.pending = false;
        this.isDataInTableOutdated = false;
      }
    },
  },
};
</script>
