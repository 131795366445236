<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Base of freelance"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Base of freelance', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title style="gap: 0.5rem">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
              clearable
            />
            <v-btn
              class="text-normal"
              height="40"
              color="primary"
              @click="
                isCreateNewFreelancerDialogVisible =
                  !isCreateNewFreelancerDialogVisible
              "
            >
              Add freelancer <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :search="search"
              :headers="tableData.headers"
              :items="tableData.items"
              :loading="loadings.table"
            >
              <template #[`item._actions`]="{ item }">
                <v-menu
                  bottom
                  nudge-bottom="32"
                  content-class="my-shadow--e2"
                  :close-on-content-click="false"
                >
                  <template #activator="{ on }">
                    <v-btn v-on="on" style="min-width: 32px" class="px-2">
                      <v-progress-circular
                        v-if="
                          [loadings.edit, loadings.delete].includes(item.id)
                        "
                        size="16"
                        width="2"
                        indeterminate
                      />
                      <v-icon v-else small>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-card flat outlined rounded="lg">
                    <v-card-title class="text-body-2"> Actions </v-card-title>
                    <v-card-text class="px-0">
                      <v-list dense class="pa-0">
                        <v-list-item @click="handleEditFreelancer(item)">
                          <v-list-item-icon class="mr-2">
                            <v-progress-circular
                              v-if="loadings.edit === item.id"
                              width="2"
                              size="16"
                              indeterminate
                            />
                            <v-icon v-else small>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title> Edit </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="handleDeleteFreelancer(item)"
                          class="error--text"
                        >
                          <v-list-item-icon class="mr-2">
                            <v-progress-circular
                              v-if="loadings.delete === item.id"
                              width="2"
                              size="16"
                              indeterminate
                            />
                            <v-icon v-else small color="error">
                              mdi-trash-can
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title> Delete </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
              <template #[`item.created_at`]="{ value }">
                {{ value ? $moment(value).format("ll") : "" }}
              </template>
              <template #[`item.updated_at`]="{ value }">
                {{ value ? $moment(value).format("ll") : "" }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-freelancer-dialog
      v-model="isCreateNewFreelancerDialogVisible"
      :form="createNewFreelancerForm"
      @update:form="createNewFreelancerForm = $event"
      @success="handleSuccessCreated"
    />
    <edit-freelancer-dialog
      v-model="isEditNewFreelancerDialogVisible"
      :form="editFreelancerForm"
      @update:form="editFreelancerForm = $event"
      @success="handleSuccessEdited"
    />
    <v-dialog
      v-model="aamIsAwaiting['delete']"
      width="320"
      content-class="my-shadow--e2"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2"> Delete freelancer </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          Are you sure you want to delete "{{ aamActiveSessionData?.name }}"
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            class="px-6 text-normal"
            @click="aamResolveAwaiting('reject', 'delete')"
          >
            No, leave
          </v-btn>
          <v-btn
            color="error"
            large
            class="px-6 text-normal"
            @click="aamResolveAwaiting('resolve', 'delete')"
          >
            Yes, delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "../UI/PageHeader.vue";
import { FREELANCER_DATA_TABLE_HEADERS } from "./defaults";
import CreateFreelancerDialog from "./components/CreateFreelancerDialog.vue";
import uploadFiltersDataMixin from "../../mixins/UploadFiltersDataMixin";
import { ContentFreelancerApiService } from "./services/ContentFreelancerApiService";
import EditFreelancerDialog from "./components/EditFreelancerDialog.vue";
import awaitResolveMixin from "../../mixins/utils/AwaitResolveMixin";

export default {
  components: { EditFreelancerDialog, CreateFreelancerDialog, PageHeader },

  mixins: [uploadFiltersDataMixin, awaitResolveMixin],

  data: () => ({
    search: null,
    isCreateNewFreelancerDialogVisible: false,
    loadings: {
      table: false,
      edit: null,
      delete: null,
    },
    isEditNewFreelancerDialogVisible: false,
    createNewFreelancerForm: {},
    editFreelancerForm: {},
    tableData: {
      headers: FREELANCER_DATA_TABLE_HEADERS,
      items: [],
    },
    filtersData: {},
  }),

  async mounted() {
    await this.initializeFiltersData();
    this.initializeTableData();
  },

  methods: {
    async handleDeleteFreelancer(tableRow) {
      try {
        await this.aamWait("delete", { payload: tableRow });

        this.loadings.delete = tableRow.id;

        const { data } = await ContentFreelancerApiService().deleteFreelancer(
          tableRow.id
        );

        if (data.success) {
          this.tableData.items = this.tableData.items.filter(
            (item) => item.id !== tableRow.id
          );
        }
      } catch (e) {
        console.error("Error while delete freelancer.", e);
      } finally {
        this.loadings.delete = null;
      }
    },
    handleSuccessEdited(editedFreelancedData) {
      const idx = this.tableData.items.findIndex(
        (i) => i.id === editedFreelancedData.id
      );

      this.$set(this.tableData.items, idx, editedFreelancedData);
    },
    handleSuccessCreated(newFreelancerData) {
      this.tableData.items.push(newFreelancerData);
    },
    async handleEditFreelancer(tableRow) {
      try {
        this.loadings.edit = tableRow.id;

        const { data } =
          await ContentFreelancerApiService().getFreelancerDetails(tableRow.id);

        if (data) {
          this.editFreelancerForm = data;
          this.isEditNewFreelancerDialogVisible = true;
        }
      } catch (e) {
        console.error("Error while loading freelanced details.", e);
      } finally {
        this.loadings.edit = null;
      }
    },
    async initializeTableData() {
      try {
        this.loadings.table = true;
        const { data } = await ContentFreelancerApiService().getFreelancers();

        this.tableData.items = data;
      } catch (e) {
        console.error("Error while loading freelancers list.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    async initializeFiltersData() {
      await this.uploadFiltersData(["languages", "freelancer_statuses"]);

      this.$store.commit("bof/setFiltersData", this.filtersData);
    },
  },
};
</script>
