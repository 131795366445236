<template>
  <v-card
    flat
    outlined
    rounded="lg"
    :class="{ 'flat-chip flat-chip--error': hasError }"
  >
    <v-card-title class="text-body-2">
      <b>{{ name }}</b>
      <div v-if="hasError" class="error--text">Select one of the types</div>
    </v-card-title>
    <v-divider />
    <v-card-text class="pr-0">
      <v-row dense>
        <v-col v-for="item in problem" :key="item.id" cols="12">
          <div
            class="d-flex justify-space-between align-center"
            style="gap: 0.5rem; min-height: 42px"
          >
            <div
              :class="{
                'primary--text': modelValue.includes(item.id),
              }"
            >
              {{ item.type }}
            </div>
            <div>
              <v-switch
                v-model="modelValue"
                hide-details
                dense
                class="ma-1 pa-0"
                inset
                :value="item.id"
                @change="validationModel['types'] = []"
              ></v-switch>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    problem: {
      type: Array,
      default: () => ({}),
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: "Unknown problems",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    hasError() {
      return this.isError || this.validationModel["types"]?.length > 0;
    },
    validationModel: {
      get() {
        return this.validation;
      },
      set(newValue) {
        this.$emit("change:validation", newValue);
      },
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
