import Service from "@/plugins/service";

/*eslint-disable*/

export const StatusManagerApiService = () => {
  const ENDPOINTS = {
    STATUSES: "/cm/admin/statuses",
    STATUS_DETAILS: (id) => `/cm/admin/statuses/${id}`,
    STATUS_EDIT: (id) => `/cm/admin/statuses/${id}`,
    GET_PROBLEM: (page_status_id, sibling_id) =>
      `/cm/admin/problems?page_status_id=${page_status_id}&sibling_id=${sibling_id}`,
    UPDATE_PROBLEM: "/cm/admin/problems",
  };

  const getStatuses = async () => {
    return Service.get(ENDPOINTS.STATUSES);
  };

  const getStatusDetails = async (id) => {
    return Service.get(ENDPOINTS.STATUS_DETAILS(id));
  };

  const editStatus = async (id, payload) => {
    return Service.put(
      ENDPOINTS.STATUS_EDIT(id),
      payload,
      true,
      {},
      { disableTossError: true }
    );
  };

  const getProblems = ({ page_status_id, sibling_id }) => {
    // Пример респонса:
    /** @typedef {object} json
     * @property {number} id
     * @property {number} page_status_id
     * @property {number} sibling_id
     * @property {string} problem
     * @property {string} category
     * @property {string} created_at
     * @property {string} updated_at
     */

    return Service.get(ENDPOINTS.GET_PROBLEM(page_status_id, sibling_id));
  };

  const updateProblems = (payload) => {
    return Service.put(ENDPOINTS.UPDATE_PROBLEM, payload);
  };

  return {
    getStatuses,
    getStatusDetails,
    editStatus,
    getProblems,
    updateProblems,
  };
};
