<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <page-header
            title="X Base"
            :breadcrumbs="[
              { text: 'Home', to: '/' },
              { text: 'X Base', disabled: true },
            ]"
          />
        </v-col>
      </v-row>
      <v-row v-if="id_project">
        <v-col cols="12" md="12">
          <v-card flat outlined rounded="lg">
            <v-card-title class="text-body-2">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                placeholder="Type to search"
                dense
                outlined
                hide-details
              ></v-text-field>
              <v-spacer />
              <div class="d-flex" style="gap: 0.25rem">
                <v-btn
                  color="primary"
                  class="text-normal"
                  @click="dialog_add_new = true"
                >
                  Add donors
                  <v-icon small right>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  class="text-normal"
                  @click="dialog = true"
                >
                  Filters
                  <v-icon small right>mdi-filter</v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-0">
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                item-key="donor_domain_id"
                :items-per-page="50"
                :sort-by="['ah_traffic']"
                :footer-props="{
                  itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
                }"
                :sort-desc="[true]"
                fixed-header
              >
                <template v-slot:item.manual_check_id="{ item }">
                  <v-autocomplete
                    v-model="item.manual_check_id"
                    :items="manual_check_status"
                    item-text="name"
                    item-value="id"
                    hide-details
                    dense
                    @change="update_error_type(item)"
                    style="min-width: 200px"
                    clearable
                  ></v-autocomplete>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-alert type="info" text rounded="lg" prominent>
            <div>
              Dashboard required selected project
              <div class="mt-4">
                <v-btn
                  color="info"
                  class="text-normal"
                  @click="$store.dispatch('toggle_select_project_menu')"
                >
                  Select project
                </v-btn>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" scrollable max-width="1000px">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            Filters
            <v-spacer />
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider />
          <v-card-text class="py-10">
            <v-row>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>Main</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                      v-model="filter.working_donors"
                      inset
                      label="Works"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                      v-model="filter.check_failed"
                      inset
                      label="Check failed"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-switch
                      v-model="filter.does_not_work"
                      inset
                      label="Doesn't work"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-autocomplete
                      v-model="filter.manual_check"
                      :items="manual_check_status"
                      item-text="name"
                      item-value="id"
                      chips
                      small-chips
                      label="Manual check"
                      solo
                      multiple
                      deletable-chips
                      clearable
                    ></v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.hide_subdomains"
                      inset
                      label="Hide subdomains"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.has_ltt_link"
                      inset
                      label="Has LTT link"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.has_alp"
                      inset
                      label="Has ALP"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.show"
                      inset
                      label="Hide used donors"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.can_add_post"
                      inset
                      label="Can add post"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.can_add_page"
                      inset
                      label="Can add page"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.can_edit_theme"
                      inset
                      label="Can edit theme"
                    ></v-switch>
                  </v-list-item>

                  <v-list-item>
                    <v-autocomplete
                      v-model="filter.type_cms"
                      :items="type_cms"
                      item-text="name"
                      item-value="id"
                      chips
                      small-chips
                      label="CMS"
                      solo
                      multiple
                      deletable-chips
                      clearable
                    ></v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-switch
                      v-model="filter.google_bad_domain"
                      inset
                      label="Hide Google SB/Compr"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Domains search</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-textarea
                      v-model="filter.domains_search"
                      solo
                      label="Domains search"
                      hint="Separation - new line"
                      no-resize
                    ></v-textarea>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list>
                  <v-list-item>
                    <v-switch
                      v-model="filter.blank_manual"
                      inset
                      label="Blank manual status"
                    ></v-switch>
                  </v-list-item>
                  <v-list-item>
                    <v-autocomplete
                      v-model="filter.country"
                      :items="filtersData?.countries"
                      :loading="filtersDataPendingKeys?.countries"
                      chips
                      small-chips
                      label="Country"
                      solo
                      multiple
                      deletable-chips
                      clearable
                    ></v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-autocomplete
                      v-model="filter.lang"
                      :items="filtersData?.languages"
                      :loading="filtersDataPendingKeys?.languages"
                      chips
                      small-chips
                      label="Language"
                      solo
                      multiple
                      deletable-chips
                      clearable
                    ></v-autocomplete>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>SW Country %</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.country_percent_operator"
                        :items="operators"
                        item-text="name"
                        item-value="id"
                        label=""
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.country_percent"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>Ahrefs DR</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.ahrefs_dr_min"
                        label="Min"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.ahrefs_dr_max"
                        label="Max"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>Ahrefs Traffic</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.ahrefs_traffic_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.ahrefs_traffic"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>SW Global rank</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.sw_global_rank_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.sw_global_rank"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>SW Country rank</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.sw_country_rank_operator"
                        :items="operators"
                        item-text="name"
                        item-value="id"
                        label=""
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.sw_country_rank"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>GI/AH P,%</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.google_index_ahrefs_pages_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.google_index_ahrefs_pages"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>Spam Score</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.spam_score_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.spam_score"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Google index</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.google_index_operator"
                        :items="operators"
                        label=""
                        item-text="name"
                        item-value="id"
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-text-field
                        v-model="filter.google_index"
                        label="Value"
                        type="number"
                        solo
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Date Added</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar style="min-width: 75px" rounded="0">
                      <v-select
                        v-model="filter.date_added_operator"
                        :items="operators"
                        item-text="name"
                        item-value="id"
                        label=""
                        solo
                        hide-details
                      ></v-select>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-menu
                        ref="date_added_menu"
                        v-model="date_added_menu"
                        :close-on-content-click="false"
                        :return-value.sync="filter.date_added"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            v-model="filter.date_added"
                            label="Date added"
                            readonly
                            solo
                            v-bind="attrs"
                            v-on="on"
                          ></v-combobox>
                        </template>
                        <v-date-picker
                          v-model="filter.date_added"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="date_added_menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.date_added_menu.save(filter.date_added)
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn large class="text-normal px-6" @click="dialog = false">
              Close
            </v-btn>
            <v-btn large class="text-normal px-6" @click="export_data">
              Export <v-icon small right> mdi-cloud-download-outline </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              large
              class="text-normal px-6"
              @click="fetch_data"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <upload-new-donors
        v-if="dialog_add_new === true"
        v-model="dialog_add_new"
      ></upload-new-donors>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import UploadNewDonors from "../Child/UploadNewDonors";
import { DATA_TABLE_MOCK } from "./defaults";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import PageHeader from "../../UI/PageHeader.vue";

export default {
  components: { PageHeader, UploadNewDonors },
  name: "DonorBaseCleanIndex",
  mixins: [FiltersHandling, UploadFiltersDataMixin],
  data() {
    return {
      search: "",
      dialog: false,
      dialog_add_new: false,
      headers: DATA_TABLE_MOCK.HEADERS,
      items: [],
      operators: [
        { id: ">=", name: ">=" },
        { id: "<=", name: "<=" },
      ],
      type_cms: [
        { id: "0", name: "All" },
        { id: "1", name: "WordPress" },
        { id: "2", name: "Other" },
      ],
      date_added_menu: [],
      filter: {
        company_id: 21,
        country_id: 1,
        working_donors: 1,
        check_failed: undefined,
        type_cms: undefined,
        show: undefined,
        blank_manual: undefined,
        idProject: undefined,
        can_add_post: undefined,
        hide_subdomains: undefined,
        manual_check: [7, 15, 18],
        can_add_page: undefined,
        can_edit_theme: undefined,
        does_not_work: undefined,
        lang: undefined,
        has_alp: undefined,
        has_ltt_link: undefined,
        google_bad_domain: undefined,
        date_added_operator: ">=",
        date_added: undefined,
        ahrefs_dr_min: undefined,
        ahrefs_dr_max: undefined,
        ahrefs_traffic: undefined,
        ahrefs_traffic_operator: ">=",
        spam_score: undefined,
        domains_search: undefined,
        google_index_operator: ">=",
        google_index: undefined,
        spam_score_operator: ">=",
        sw_global_rank: undefined,
        sw_global_rank_operator: ">=",
        sw_country_rank: undefined,
        sw_country_rank_operator: ">=",
        country_percent: undefined,
        country_percent_operator: ">=",
        google_index_ahrefs_pages_operator: ">=",
        google_index_ahrefs_pages: undefined,
        // take_auto_check: true,
        // take_manual_check_good: true,
        // take_manual_check_bad: true
      },
      index_items: [
        {
          text: "index",
          value: 0,
        },
        {
          text: "noindex",
          value: 1,
        },
      ],
      manual_check_status: [],
      languages: [],
      countries: [],
    };
  },
  methods: {
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    fetch_data() {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        filter: self.filter,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/${this.id_project}`,
          data,
          config
        )
        .then((r) => {
          self.items = r.data;
        })
        .finally(() => {
          self.dialog = false;
          self.$store.state.loading = false;
        });
    },
    export_data() {
      let self = this;

      let url = `${self.$store.state.server_url}/export/base/get-hash`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios.post(url, self.filter, config).then((response) => {
        if (response.data.success) {
          self.dialog_export = false;
          location.href =
            `${self.$store.state.server_url}/export/donor-base/x/index/${this.id_project}?hash=` +
            response.data.hash;
        }
      });
    },
    update_error_type(item) {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let type = self.manual_check_status.find(
        (i) => i.id === item.manual_check_id
      )["type"];
      let data = {
        id: item.id,
        domain_id: item.domain_id,
        errorTypeId: item.manual_check_id,
        type: type,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/update-error-type/${this.id_project}`,
          data,
          config
        )
        .then((r) => {
          self.items = r.data;
          if (type === "domain") {
            self.items
              .filter((i) => i.domain_id === item.domain_id)
              .forEach((v) => {
                v.manual_check_id = item.manual_check_id;
              });
          }
        })
        .finally(() => {
          self.$store.state.loading = false;
        });
    },
    fetch_filter() {
      let self = this;

      self.$store.state.loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {};

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/x/filter/${self.id_project}`,
          data,
          config
        )
        .then((r) => {
          self.manual_check_status = r.data.manualCheckStatus;
          self.countries = r.data.country;
          self.languages = r.data.languages;
        })
        .finally(() => {
          self.dialog = false;
          self.$store.state.loading = false;
        });
    },
  },
  computed: {
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  async mounted() {
    await this.parseQuery("filter");

    this.uploadFiltersData(["countries", "languages"]);

    const haveParams = Object.keys(this.$route.query).length;

    if (this.id_project === undefined || this.id_project === null)
      this.$store.dispatch("toggle_select_project_menu");

    this.$watch("id_project", (value) => {
      if (!value) this.$store.dispatch("toggle_select_project_menu");

      this.fetch_filter();
      this.fetch_data();
    });
    if (haveParams) this.fetch_data();
  },
};
</script>

<style></style>
