import Service from "../../../plugins/service";

export const ContentFreelancerApiService = () => {
  const getFreelancers = () => {
    return Service.get("/cm/admin/content-freelancer");
  };
  const createFreelancer = (payload) => {
    return Service.post(
      "/cm/admin/content-freelancer",
      payload,
      true,
      {},
      { disableTossError: true }
    );
  };
  const editFreelancer = (payload) => {
    return Service.put(
      `/cm/admin/content-freelancer/${payload.id}`,
      payload,
      true,
      {},
      { disableTossError: true }
    );
  };
  const getFreelancerDetails = (id) => {
    return Service.get(`/cm/admin/content-freelancer/${id}`);
  };
  const deleteFreelancer = (id) => {
    return Service.delete(`/cm/admin/content-freelancer/${id}`);
  };

  return {
    getFreelancers,
    createFreelancer,
    editFreelancer,
    getFreelancerDetails,
    deleteFreelancer,
  };
};
