import Service from "../../../../plugins/service";

export const LttContinuePaymentApiService = () => {
  const ENDPOINTS = {
    CONTINUE_PAYMENTS: "/ltt/continue-payment",
    EDIT_STATUS: (urlId, statusId) =>
      `/ltt/continue-payment/change-status/${urlId}/${statusId}`,
  };

  const getContinuePaymentData = (payload) => {
    return Service.post(ENDPOINTS.CONTINUE_PAYMENTS, payload);
  };

  const editStatusFor = (urlId, statusId) => {
    return Service.post(ENDPOINTS.EDIT_STATUS(urlId, statusId));
  };

  return {
    getContinuePaymentData,
    editStatusFor,
  };
};
