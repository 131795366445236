<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <page-header title="Daily Overview" />
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.niche"
          hide-details
          dense
          :loading="loadings.filters"
          item-text="name"
          item-value="id"
          :items="filtersData.niches"
          outlined
          multiple
          label="Niche"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.team"
          hide-details
          dense
          multiple
          :loading="loadings.filters"
          item-text="name"
          item-value="id"
          :items="filtersData.teams"
          outlined
          label="Team"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.executive"
          hide-details
          dense
          item-text="email"
          multiple
          :loading="loadings.filters"
          item-value="id"
          :items="filtersData.executives"
          outlined
          label="Executive"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.labels"
          hide-details
          dense
          multiple
          item-text="title"
          :loading="loadings.filters"
          item-value="id"
          :items="filtersData.labels"
          outlined
          label="Label"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-dialog v-model="dateModal" ref="dialog" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              hide-details
              v-model="filters.date"
              label="Date"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filters.date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateModal = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="dateModal = false"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-btn
          :loading="loadings.table"
          color="primary"
          class="text-normal"
          block
          @click="fetchData"
          >Get Data</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            <v-text-field
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
              v-model="search"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table
              :search="search"
              :headers="tableHeaders"
              :items="tableItems"
              :loading="loadings.table"
              :hide-default-footer="true"
              :items-per-page="-1"
              :custom-sort="_$smartCustomTableSort"
              multi-sort
              class="daily-overall-table"
            >
              <template
                v-for="h in tableHeaders"
                #[`header.${h.value}`]="{ header }"
              >
                <template v-if="h.title">
                  <v-tooltip bottom :key="h.text">
                    <template #activator="{ on }">
                      <span v-on="on">{{ header.text }} </span>
                    </template>
                    <span>{{ header.title }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <span :key="h.value">{{ h.text }}</span>
                </template>
              </template>
              <template #[`item.executive`]="{ item }">
                <a :href="getWSLink(item)" target="_blank">{{
                  item.executive
                }}</a>
              </template>
              <template #[`item.total_time`]="{ value }">
                {{ value ? humanTime(value) : "" }}
              </template>
              <template #[`item.week_a`]="{ item }">
                <td-week
                  :data="item.week_a"
                  type="Week_A"
                  :labels="filters.labels"
                  :email="item.email"
                />
              </template>
              <template #[`item.week_b`]="{ item }">
                <td-week
                  :data="item.week_b"
                  type="Week_B"
                  :labels="filters.labels"
                  :email="item.email"
                />
              </template>
              <template #[`item.week_c`]="{ item }">
                <td-week
                  :data="item.week_c"
                  type="Week_C"
                  :labels="filters.labels"
                  :email="item.email"
                />
              </template>
              <template #[`item.date_time`]="{ item }">
                <div
                  @click="goToDailyV3(item.email)"
                  :class="`colored-td colored-td--clickable ${getDateTimeColor(
                    item
                  )}`"
                  style="white-space: nowrap"
                >
                  {{
                    String(item.date_time) === "0"
                      ? null
                      : humanTime(item.date_time)
                  }}
                </div>
              </template>
              <template #[`item.kpi`]="{ value }">
                {{ value }}
              </template>
              <template #[`item.last_14d`]="{ item }">
                <v-sparkline
                  v-if="item && item.last_14d"
                  :value="item.last_14d"
                  line-width="16"
                  :gradient="sparklineColor"
                  type="bar"
                  :width="300"
                />
              </template>
              <template #[`item.l_a_7d`]="{ item }">
                <v-sparkline
                  :value="item.l_a_7d"
                  line-width="16"
                  :gradient="sparklineColor"
                  type="bar"
                  :width="300"
                />
              </template>
              <template #[`item.l_b_7d`]="{ item }">
                <v-sparkline
                  :value="item.l_b_7d"
                  line-width="16"
                  :gradient="sparklineColor"
                  type="bar"
                  :width="300"
                />
              </template>
              <template #[`item.l_c_7d`]="{ item }">
                <v-sparkline
                  :value="item.l_c_7d"
                  line-width="16"
                  :gradient="sparklineColor"
                  type="bar"
                  :width="300"
                />
              </template>
              <template v-for="key in wsLinkItems" #[`item.${key}`]="{ item }">
                <div :key="key" style="white-space: nowrap">
                  <template v-if="item[key]">
                    <span
                      v-if="item[key] && item[key].all && item[key].all !== 0"
                    >
                      <a
                        :href="getWorkSectionUrl[key].all(item.user_id)"
                        target="_blank"
                        style="margin-right: 4px"
                        >{{ item[key] && item[key].all }}</a
                      >
                    </span>

                    <sup
                      v-if="
                        item[key] &&
                        item[key].not_closed &&
                        item[key].not_closed !== 0
                      "
                    >
                      <a
                        :href="getWorkSectionUrl[key].overdue(item.user_id)"
                        target="_blank"
                        style="color: red"
                        >{{ item[key] && item[key].not_closed }}</a
                      >
                    </sup>
                  </template>
                </div>
              </template>
              <template #[`item.overdue`]="{ item }">
                <overdue-td
                  :data="item"
                  :get-work-section-url="getWorkSectionUrl"
                />
              </template>
              <template #[`item.today_plan`]="{ item }">
                <todays-plan-td
                  :data="item"
                  :get-work-section-url="getWorkSectionUrl"
                />
              </template>
              <template #[`item.wABC`]="{ item }">
                <div
                  class="colored-td colored-td--clickable"
                  @click="handleOpenWABC(item)"
                >
                  {{ item.wABC }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TableSorting from "@/mixins/TableSorting";
import { DAILY_OVERALL_REPORT_TABLE_HEADERS } from "../../utils/defaultData";
import TdWeek from "../../components/DailyOverallReport/UI/TdWeek";
import OverdueTd from "./UI/OverdueTd";
import TodaysPlanTd from "./UI/TodaysPlanTd";
import Location from "../../mixins/Location";
import QueryBuilder from "@/mixins/QueryBuilder";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import PageHeader from "../UI/PageHeader.vue";

export default {
  components: { PageHeader, TodaysPlanTd, OverdueTd, TdWeek },
  mixins: [FiltersHandling, TableSorting, Location, QueryBuilder],
  data: () => ({
    search: null,
    sparklineColor: ["#639ee0", "#2b4d93"],
    wsLinkItems: ["critical", "weekly_ms", "lb_overview", "1-to-1"],
    dateModal: false,
    tableItems: [],
    tableHeaders: DAILY_OVERALL_REPORT_TABLE_HEADERS,
    loadings: {
      table: false,
      filters: false,
    },
    filters: {
      niche: [],
      team: [],
      executive: [],
      labels: [],
      date: null,
    },
    filtersData: {
      executives: [],
      labels: [],
      niches: [],
      teams: [],
    },
  }),
  async mounted() {
    await this.parseQuery("filters");

    if (!this.$route.query.date) {
      this.filters.date = this.$moment()
        .subtract(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD");
    }

    this.fetchData();
    this.fetchFilters();
  },
  computed: {
    getWorkSectionUrl() {
      return {
        todaysPlan: (userId) =>
          `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_term=7&sel_user=${userId}&sel_role=1&flt=1`,
        overdue: (userId) =>
          `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_term=2&sel_user=${userId}&sel_role=1&flt=1`,
        critical: {
          all: (userId) =>
            `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_tag=642378&sel_user=${userId}&sel_role=1&flt=1`,
          overdue: (userId) =>
            `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_term=2&sel_tag=642378&sel_user=${userId}&sel_role=1&flt=1`,
        },
        weekly_ms: {
          all: (userId) =>
            `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_tag=990961&sel_user=${userId}&sel_role=1&flt=1`,
          overdue: (userId) =>
            `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_term=2&sel_tag=990961&sel_user=${userId}&sel_role=1&flt=1`,
        },
        lb_overview: {
          all: (userId) =>
            `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_tag=981338&sel_user=${userId}&sel_role=1&flt=1`,
          overdue: (userId) =>
            `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_term=2&sel_tag=981338&sel_user=${userId}&sel_role=1&flt=1`,
        },
        "1-to-1": {
          all: (userId) =>
            `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_tag=903974&sel_user=${userId}&sel_role=1&flt=1`,
          overdue: (userId) =>
            `https://ra.worksection.com/priority/?order=important&desc=0&order=important&desc=0&sel_term=2&sel_tag=903974&sel_user=${userId}&sel_role=1&flt=1`,
        },
      };
    },
  },
  methods: {
    handleOpenWABC(item) {
      const url = `/worksection/report/week?executive=${item.email}&label=${this.filters.labels} `;

      window.open(url, "_blank");
    },
    getDateTimeColor(item) {
      const hours = Number(item.date_time) / 1000 / 60 / 60;
      if (hours > 6.3 && hours <= 7) return "yellow";

      if (hours <= 6.3) return "red";

      return "";
    },
    getWSLink(item) {
      const date = this.$moment(this.filters.date).format("DD.MM.YYYY");
      const query = `?order=sum_time&desc=0&ctm=0&ctm_=${date}_${date}&sel_user=${item.user_id}&flt=1`;
      const url = "https://ra.worksection.com/report/";
      return url + query;
    },
    goToDailyV3(email) {
      const query = this._$buildQuery({
        date: this.filters.date,
        executive: email,
        labels: this.filters.labels[0],
      });
      let url = `/worksection/report/daily3?${query}`;
      window.open(url, "_blank");
    },
    humanTime(x) {
      const d = this.$moment.duration(x, "milliseconds");
      const h = Math.floor(d.asHours());
      const m = Math.floor(d.asMinutes()) - h * 60;

      if (!h && !m) return "";

      if (!h) return `${m}m`;

      return `${h}h ${m}m`;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const resp = await this.$store.dispatch(
        "daily-overall-report/fetchFilters",
        {}
      );
      if (resp) this.filtersData = resp;
      this.loadings.filters = false;
    },
    async fetchData() {
      this.loadings.table = true;

      const payload = {
        filter: this.filters,
      };

      const resp = await this.$store.dispatch(
        "daily-overall-report/fetchData",
        payload
      );

      if (resp) this.tableItems = resp;

      this.loadings.table = false;
    },
  },
};
</script>

<style lang="scss">
.daily-overall-table {
  table {
    tr {
      th:nth-child(3),
      th:nth-child(5),
      th:nth-child(13),
      th:nth-child(16),
      th:nth-child(17),
      td:nth-child(3),
      td:nth-child(5),
      td:nth-child(13),
      td:nth-child(16),
      td:nth-child(17) {
        border-right: 1px solid rgba(155, 155, 155, 0.3);
      }
    }
  }
}
</style>
