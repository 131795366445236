<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          :title="pageConfig.title"
          :breadcrumbs="pageConfig.breadcrumbs"
        />
      </v-col>
      <v-col cols="12">
        <v-form ref="formRef">
          <v-row dense>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <smart-date-filter
                :model-value="filters.date"
                @update:modelValue="filters.date = $event"
                :custom-presets="customDatePresets"
                :rules="[
                  (v) =>
                    v?.filter?.((v) => !!v)?.length === 2 ||
                    'Select start and finish dates.',
                ]"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.executives"
                :items="filtersData.users"
                item-value="id"
                item-text="email"
                hide-details
                dense
                outlined
                multiple
                label="Executives"
                placeholder="Type to search"
                :loading="filtersDataPendingKeys?.executives"
                clearable
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.teams"
                :items="filtersData.teams"
                hide-details
                dense
                outlined
                multiple
                label="Teams"
                placeholder="Type to search"
                :loading="filtersDataPendingKeys?.teams"
                clearable
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.type"
                :items="['url', 'user']"
                hide-details="auto"
                dense
                outlined
                label="Type"
                placeholder="Type to search"
                validate-on-blur
                :rules="[(v) => !!v || 'Type is required.']"
                :loading="filtersDataPendingKeys?.types"
                clearable
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <easy-range-input
                :model-value="filters.need_top"
                @update:modelValue="filters.need_top = $event"
                label="Need top"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.project"
                :items="filtersData.projects"
                hide-details
                dense
                outlined
                label="Project"
                placeholder="Type to search"
                :loading="filtersDataPendingKeys?.projects"
                clearable
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                v-model="filters.subproject"
                :items="filtersData.subprojects"
                hide-details
                dense
                outlined
                label="Subproject"
                :disabled="!filters.project"
                placeholder="Type to search"
                :loading="filtersDataPendingKeys?.subprojects"
                clearable
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <easy-range-input
                :model-value="filters.priority"
                @update:modelValue="filters.priority = $event"
                label="Priority"
              />
            </v-col>
            <v-col cols="6" md="4" lg="2" xl="fill">
              <v-autocomplete
                hide-details
                dense
                outlined
                label="Status"
                placeholder="Type to search"
                v-model="filters.status"
                :items="filtersData?.page_statuses"
                :loading="filtersDataPendingKeys?.page_statuses"
              />
            </v-col>
            <v-col cols="fill" class="d-flex justify-end">
              <v-btn
                color="primary"
                class="text-normal px-6"
                width="180"
                @click="fetchData"
                height="40"
                :loading="loadings.data"
              >
                Get data
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col v-if="!loadings.data" cols="12">
        <v-card flat outlined rounded="lg" class="py-4 px-0">
          <v-data-table :items="tableData.items" :headers="tableData.headers">
            <template v-for="h in tableData.headers" #[`header.${h.value}`]>
              <div
                v-if="!['name', 'total', 'nt', 'pr'].includes(h.value)"
                class="text-vertical"
                :key="h.value"
              >
                {{ h.text }}
              </div>
              <div v-else :key="h.value">
                {{ h.text }}
              </div>
            </template>
            <template
              v-for="h in tableData.headers"
              #[`item.${h.value}`]="{ value, item }"
            >
              <template v-if="String(value) === '0'">
                <span style="opacity: 0.3" :key="h.value">
                  {{ value }}
                </span>
              </template>
              <template v-else-if="h.value === 'chart'">
                <v-sparkline
                  :key="h.value"
                  :value="value"
                  style="max-width: 140px"
                  line-width="2"
                  :color="getColor(value)"
                  smooth
                ></v-sparkline>
              </template>
              <template v-else-if="h.value === 'url'">
                <a :href="value" target="_blank" :key="h.value">{{ value }}</a>
              </template>
              <template v-else-if="h.value === 'user'">
                <user-preview :name="value" :key="h.value" :opacity="1" />
              </template>
              <template v-else>
                {{ value }}
                <sup v-if="!!item[`_${h.value}`]" :key="h.value">
                  {{ item[`_${h.value}`]?.all }}
                </sup>
              </template>
            </template>
          </v-data-table>
          <content-team-performance-chart v-if="false" :data="chartData" />
        </v-card>
      </v-col>
      <v-col v-else>
        <v-skeleton-loader type="table" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { pageConfig } from "./defaults";
import PageHeader from "@/components/UI/PageHeader.vue";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import { ContentTeamPerformanceApiService } from "../../../services/site-workplace/ContentTeamPerformanceApiService";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import ContentTeamPerformanceChart from "./components/ContentTeamPerformanceChart.vue";
import { DEFAULT_MOMENT_DATE_FORMAT } from "../../../utils/defaultData";
import EasyRangeInput from "../../UI/EasyRangeInput.vue";
import UserPreview from "../../UI/UserPreview.vue";

export default {
  mixins: [UploadFiltersDataMixin, FiltersHandling],

  components: {
    UserPreview,
    EasyRangeInput,
    ContentTeamPerformanceChart,
    SmartDateFilter,
    PageHeader,
  },

  data: () => ({
    filters: {
      status: null,
      date: [null, null],
      executives: [],
      teams: [],
      need_top: [null, null],
      subproject: null,
      type: null,
      project: null,
      priority: [null, null],
    },
    loadings: {
      data: false,
    },
    filtersData: {
      users: [],
      teams: [],
      subprojects: [],
    },
    tableData: {
      items: [],
      headers: [],
    },
    chartData: {
      series: [],
      categories: [],
    },
  }),

  async mounted() {
    await this.parseQuery("filters");
    this.uploadFiltersData(
      ["users", "projects", "subprojects", "teams", "page_statuses"],
      {
        project: this.filters.project,
      }
    );

    if (Object.keys(this.$route.query)?.length) this.fetchData();

    this.$watch("filters.project", {
      handler(projectId) {
        this.filters.subproject = null;
        if (!projectId) this.filtersData.subprojects = [];

        this.uploadFiltersData(["subprojects"], {
          project: projectId,
        });
      },
    });
  },

  computed: {
    pageConfig() {
      return pageConfig;
    },
    customDatePresets() {
      return [
        {
          id: 1,
          name: "Today",
          range: [
            this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
        {
          id: 2,
          name: "Week",
          range: [
            this.$moment()
              .startOf("isoWeek")
              .format(DEFAULT_MOMENT_DATE_FORMAT),
            this.$moment().endOf("isoWeek").format(DEFAULT_MOMENT_DATE_FORMAT),
          ],
        },
      ];
    },
  },

  methods: {
    getColor(data) {
      if (!data || data.length === 0) {
        return "transparent";
      }
      if (data[0] < data[data.length - 1]) {
        return "green";
      }
      return "red";
    },
    async fetchData() {
      if (!this.$refs.formRef?.validate()) return;

      try {
        this.loadings.data = true;

        const { data } =
          await ContentTeamPerformanceApiService().getPerformance(this.filters);

        this.tableData = data;
      } catch (e) {
        console.log(e.response.data);
        console.error("Error while fetching dashboard data.", e);
      } finally {
        this.loadings.data = false;
      }
    },
  },
};
</script>
