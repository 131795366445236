<template>
  <v-dialog
    v-model="dialog"
    width="620"
    content-class="my-shadow--e2"
    scrollable
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2"> Configure problems </v-card-title>
      <v-divider />
      <v-card-text class="pt-5 px-4 pb-5">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2 py-2">
            List of problems
            <v-spacer />
            <v-btn class="text-normal" @click="handleAddProblem">
              Add problem <v-icon small right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <template v-if="!form?.problems?.length">
              <div class="pa-4" style="opacity: 0.5">
                Press "Add Problem" to add new Problem
              </div>
            </template>
            <v-row v-else>
              <v-col
                v-for="(problem, idx) in form?.problems || []"
                :key="`problem-${idx}`"
                cols="12"
              >
                <v-row dense>
                  <v-col cols="fill">
                    <v-text-field
                      v-model="problem.problem"
                      hide-details
                      dense
                      outlined
                      label="Problem"
                    />
                  </v-col>
                  <v-col cols="fill">
                    <v-text-field
                      v-model="problem.category"
                      hide-details
                      dense
                      outlined
                      label="Category"
                    />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center">
                    <v-btn icon @click="handleRemoveProblemById(problem.id)">
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <template v-if="form?.problems?.length > 5">
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn class="text-normal" @click="handleAddProblem">
                Add problem <v-icon small right>mdi-plus</v-icon>
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </template>
        </v-card>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn class="text-normal px-6" large @click="dialog = false"
          >Back</v-btn
        >
        <v-btn
          class="text-normal px-6"
          large
          color="success"
          @click="handleSaveChanges"
          :loading="loading"
          >Save problems changes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import { uid } from "../../../../utils/functions";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    problemTemplate: {
      problem: null,
      category: null,
    },
  }),

  mixins: [Dialog],

  methods: {
    handleRemoveProblemById(id) {
      if (!this.form?.problems?.filter)
        return console.error("Cant remove problem from undefined.");

      this.form.problems = this.form.problems.filter(
        (problem) => problem.id !== id
      );
    },
    handleAddProblem() {
      if (!this?.form?.problems) {
        this.$set(this.form, "problems", []);
      }

      this.form.problems.push({ ...this.problemTemplate, id: uid() });
    },
    handleSaveChanges() {
      this.$emit("save", this.form);
    },
  },
};
</script>
