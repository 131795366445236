var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"content-checker"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"text-body-2"},[_c('b',[_vm._v(_vm._s(_vm.content.title))])]),_c('v-divider'),(_vm.content.items?.length)?[_c('v-card-title',[_c('v-text-field',{attrs:{"hide-details":"","dense":"","outlined":"","placeholder":"Type to search","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider')]:_vm._e(),_c('v-card-text',{staticClass:"px-0 pt-0"},[(_vm.status)?_c('v-data-table',{staticClass:"content-checker__table pa-0",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.content.items,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.num_of_pages",fn:function({ item }){return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                    cat: 'num_of_pages',
                    type: 'all',
                    category: item.category,
                  })}}},[_vm._v(" "+_vm._s(item.num_of_pages.all)+" ")]),(
                  item.num_of_pages.new !== 0 ||
                  item.num_of_pages.removed !== 0
                )?_c('sup',[_c('span',{class:{
                    'green--text': item.num_of_pages.new > 0,
                    bold: item.num_of_pages.new > 0,
                  },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                      cat: 'num_of_pages',
                      type: 'new',
                      category: item.category,
                    })}}},[_vm._v(_vm._s(item.num_of_pages.new))]),_vm._v(" / "),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                      cat: 'num_of_pages',
                      type: 'removed',
                      category: item.category,
                    })}}},[_vm._v(_vm._s(item.num_of_pages.removed))])]):_vm._e()])]}},{key:"item.code_200",fn:function({ item }){return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                    cat: 'code_200',
                    type: 'all',
                    category: item.category,
                  })}}},[_vm._v(" "+_vm._s(item.code_200.all)+" ")]),(item.code_200.new !== 0 || item.code_200.removed !== 0)?_c('sup',[_c('span',{class:{
                    'green--text': item.code_200.new > 0,
                    bold: item.code_200.new > 0,
                  },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                      cat: 'code_200',
                      type: 'new',
                      category: item.category,
                    })}}},[_vm._v(_vm._s(item.code_200.new))]),_vm._v(" / "),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                      cat: 'code_200',
                      type: 'removed',
                      category: item.category,
                    })}}},[_vm._v(_vm._s(item.code_200.removed))])]):_vm._e()])]}},{key:"item.code_not_200",fn:function({ item }){return [_c('div',{staticStyle:{"white-space":"nowrap"}},[(item.code_not_200.all > 0)?_c('b',{staticClass:"red--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                    cat: 'not_code_200',
                    type: 'all',
                    category: item.category,
                  })}}},[_vm._v(" "+_vm._s(item.code_not_200.all)+" ")]):_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                    cat: 'not_code_200',
                    type: 'all',
                    category: item.category,
                  })}}},[_vm._v(" - ")]),(
                  item.code_not_200.new !== 0 ||
                  item.code_not_200.removed !== 0
                )?_c('sup',[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                      cat: 'not_code_200',
                      type: 'new',
                      category: item.category,
                    })}}},[_vm._v(" "+_vm._s(item.code_not_200.new)+" ")]),_vm._v(" / "),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                      cat: 'not_code_200',
                      type: 'removed',
                      category: item.category,
                    })}}},[_vm._v(" "+_vm._s(item.code_not_200.removed)+" ")])]):_vm._e()])]}},{key:"item.ni",fn:function({ item }){return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                    cat: 'noindex',
                    type: 'all',
                    category: item.category,
                  })}}},[_c('span',{class:{
                    'red--text': item.ni.all > 0,
                    bold: item.ni.all > 0,
                  }},[_vm._v(_vm._s(item.ni.all))])]),(item.ni.new !== 0 || item.ni.removed !== 0)?_c('sup',[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                      cat: 'noindex',
                      type: 'new',
                      category: item.category,
                    })}}},[_vm._v(_vm._s(item.ni.new))]),_vm._v(" / "),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                      cat: 'noindex',
                      type: 'removed',
                      category: item.category,
                    })}}},[_vm._v(_vm._s(item.ni.removed))])]):_vm._e()])]}},{key:"item.title",fn:function({ item }){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                  cat: 'tdh',
                  type: 'title',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.description",fn:function({ item }){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                  cat: 'tdh',
                  type: 'description',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.h1",fn:function({ item }){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                  cat: 'tdh',
                  type: 'h1',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.h1)+" ")])]}},{key:"item.text",fn:function({ item }){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                  cat: 'text',
                  type: 'all',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item.uniq_text",fn:function({ item }){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                  cat: 'uniq_text',
                  type: 'all',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.uniq_text)+" ")])]}},{key:"item.ni_to_i",fn:function({ item }){return [(item.ni_to_i > 0)?_c('b',{staticClass:"red--text",on:{"click":function($event){return _vm.show_detailed({
                  cat: 'noindex_to_index',
                  type: 'all',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.ni_to_i)+" ")]):_c('span',{staticStyle:{"cursor":"pointer"}},[_vm._v("-")])]}},{key:"item.i_to_ni",fn:function({ item }){return [(item.i_to_ni > 0)?_c('b',{staticClass:"red--text",on:{"click":function($event){return _vm.show_detailed({
                  cat: 'index_to_noindex',
                  type: 'all',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.i_to_ni)+" ")]):_c('span',{staticStyle:{"cursor":"pointer"}},[_vm._v("-")])]}},{key:"item.non_ascii_url",fn:function({ item }){return [(item.non_ascii_url > 0)?_c('b',{staticClass:"red--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                  cat: 'non_ascii_url',
                  type: 'all',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.non_ascii_url)+" ")]):_c('span',[_vm._v("0")])]}},{key:"item.non_ascii_text",fn:function({ item }){return [(item.non_ascii_text > 0)?_c('b',{staticClass:"red--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                  cat: 'non_ascii_text',
                  type: 'all',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.non_ascii_text)+" ")]):_c('span',[_vm._v("0")])]}},{key:"item.non_ascii_html",fn:function({ item }){return [(item.non_ascii_html > 0)?_c('b',{staticClass:"red--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.show_detailed({
                  cat: 'non_ascii_html',
                  type: 'all',
                  category: item.category,
                })}}},[_vm._v(" "+_vm._s(item.non_ascii_html)+" ")]):_c('span',[_vm._v("0")])]}}],null,false,1310662480)}):_c('v-alert',{staticClass:"mx-4 mt-4 mb-0",attrs:{"rounded":"lg","type":"warning","text":"","border":"left"}},[_c('div',[_vm._v(" Comparison is impossible, since there is no data for at least one day ")])])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }