<template>
  <v-container fluid class="outreach-problems">
    <v-row>
      <v-col cols="12">
        <h2>GP Tracker</h2>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.project"
          label="Project"
          :items="projects"
          item-text="name"
          item-value="id"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.url"
          label="URL"
          :items="filtersData.url"
          item-text="text"
          item-value="value"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.chief_seo"
          label="Chief SEO"
          :items="filtersData.chief_seo"
          item-text="text"
          item-value="value"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.niche"
          label="Niches"
          :items="niches"
          item-text="name"
          item-value="id"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.author"
          label="Author"
          :items="filtersData.author"
          item-text="text"
          item-value="value"
          outlined
          multiple
          dense
          clearable
          :loading="loadings.filters"
          :menu-props="menuProps"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <div class="fix-button-height">
          <smart-date-filter
            :model-value="filters.date"
            @update:modelValue="handleUpdateDateFilter"
          ></smart-date-filter>
        </div>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-menu
          left
          nudge-top="120px"
          content-class="remove-dialog-shadow pa-2"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              style="height: 40px"
              v-on="on"
              block
              outlined
              color="primary"
            >
              Table Filters
            </v-btn>
          </template>
          <v-card rounded="xl" class="shadow-e2-bordered" max-width="660px">
            <v-card-title>Filters</v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col>
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Others</h4>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="filters.anchor_type"
                        :items="filtersData.anchor_type"
                        dense
                        outlined
                        multiple
                        hide-details
                        label="Anchor type"
                        :loading="loadings.filters"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="DR"
                        :model-value="filters.dr"
                        @update:modelValue="filters.dr = $event"
                      ></easy-range-input>
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="AH Keywords"
                        :model-value="filters.ah_keywords"
                        @update:modelValue="filters.ah_keywords = $event"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="filters.do_follow"
                        :items="filtersData.do_follow"
                        dense
                        hide-details
                        outlined
                        :loading="loadings.filters"
                        label="Do Follow"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="filters.on_page"
                        :items="filtersData.on_page"
                        dense
                        hide-details
                        outlined
                        :loading="loadings.filters"
                        label="On Page"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="filters.subproject"
                        label="Subproject"
                        dense
                        outlined
                        item-value="id"
                        item-text="name"
                        hide-details
                        :items="_$getSubprojects"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row dense>
                    <v-col cols="12">
                      <h4>Tier 2</h4>
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="Gp"
                        :model-value="filters.tier_2_gp"
                        @update:modelValue="filters.tier_2_gp = $event"
                      />
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="All"
                        :model-value="filters.tier_2_all"
                        @update:modelValue="filters.tier_2_all = $event"
                      />
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="Forum Comments"
                        :model-value="filters.tier_2_forum_comments"
                        @update:modelValue="
                          filters.tier_2_forum_comments = $event
                        "
                      />
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="Linkshome"
                        :model-value="filters.tier_2_linkshome"
                        @update:modelValue="filters.tier_2_linkshome = $event"
                      />
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="Linksold"
                        :model-value="filters.tier_2_linksold"
                        @update:modelValue="filters.tier_2_linksold = $event"
                      />
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="Linksnew"
                        :model-value="filters.tier_2_linksnew"
                        @update:modelValue="filters.tier_2_linksnew = $event"
                      />
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="Sum"
                        :model-value="filters.tier_2_sum"
                        @update:modelValue="filters.tier_2_sum = $event"
                      />
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="Gp Sum"
                        :model-value="filters.tier2_sum_gp"
                        @update:modelValue="filters.tier2_sum_gp = $event"
                      />
                    </v-col>
                    <v-col cols="12">
                      <easy-range-input
                        label="F Pos"
                        :model-value="filters.f_position"
                        @update:modelValue="filters.f_position = $event"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <h4>Visibility</h4>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="filters.only_unique"
                    label="Only Uniq Key"
                    hide-details
                    dense
                    :true-value="1"
                    :false-value="null"
                    style="margin-top: 0; height: 100%"
                    class="d-flex align-center"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="filters.is_gpm"
                    label="Data from GPM"
                    hide-details
                    dense
                    :true-value="1"
                    :false-value="null"
                    style="margin-top: 0; height: 100%"
                    class="d-flex align-center"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="filters.hidden"
                    label="Hidden"
                    hide-details
                    dense
                    :true-value="1"
                    :false-value="null"
                    style="margin-top: 0; height: 100%"
                    class="d-flex align-center"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="filters.hide_disavowed_links"
                    label="Hide disavowed links"
                    hide-details
                    dense
                    :true-value="1"
                    :false-value="null"
                    style="margin-top: 0; height: 100%"
                    class="d-flex align-center"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    block
                    color="primary"
                    @click="fetchTableData"
                    :loading="loadings.table"
                  >
                    Apply
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-btn
          style="height: 40px"
          block
          color="primary"
          @click="handleGetData"
          :loading="loadings.table"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card class="shadow-e2-bordered" rounded="xl">
          <v-card-title>
            <v-row dense>
              <v-col>
                <v-text-field
                  label="Search"
                  v-model="search"
                  clearable
                  dense
                  hide-details
                  append-outer-icon="mdi-magnify"
                  style="max-width: 225px"
                ></v-text-field>
              </v-col>
              <v-spacer />
              <v-col
                class="d-flex justify-end"
                v-if="tableData.items.length > 0"
              >
                <vue-json-to-csv
                  :json-data="getJsonCSVData()"
                  csv-title="GP_Tracker_table"
                  :separator="'\t'"
                >
                  <v-btn color="success" rounded text>
                    Export CSV <v-icon class="ml-2">mdi-export-variant</v-icon>
                  </v-btn>
                </vue-json-to-csv>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="tableData.headers"
              :items="getItems"
              :search="search"
              :custom-sort="_$smartCustomTableSort"
              class="gp-tracker__table"
              multi-sort
              :sort-by="['url_priority', 'anchor_type', 'url']"
              :sort-desc="[false, true, true]"
              :loading="loadings.table"
              :items-per-page="50"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
              }"
            >
              <template
                v-for="h in tableData.headers"
                #[`header.${h.value}`]="{ header }"
              >
                <v-tooltip v-if="header.tooltip" bottom :key="h.value">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="mr-2">{{ header.text }}</span>
                    <v-icon small color="rgba(155,155,155,0.5)">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <div style="max-width: 150px">{{ header.tooltip }}</div>
                </v-tooltip>
                <span :key="h.value" v-else>
                  {{ header.text }}
                </span>
              </template>
              <template #[`item.url`]="{ item }">
                <div
                  v-if="String(item.onpage) === '0'"
                  class="mark mark--error"
                ></div>
                <v-menu
                  bottom
                  nudge-bottom="40px"
                  :close-on-content-click="false"
                  content-class="menu__tooltip-content"
                >
                  <template #activator="{ on, value }">
                    <div
                      v-on="on"
                      :style="{
                        background: value
                          ? 'rgba(61,96,239,0.14)'
                          : 'transparent',
                        cursor: 'pointer',
                      }"
                    >
                      {{ item.url }}
                    </div>
                  </template>
                  <v-card
                    rounded="xl"
                    max-width="350px"
                    class="shadow-e2-bordered has-close-icon"
                  >
                    <v-card-title>
                      <span>URL info</span>

                      <v-btn
                        rounded
                        @click="toggleUrl(item)"
                        class="ml-2"
                        small
                        outlined
                        :loading="loadingsHiding.includes(item.id)"
                        :color="`${item._hidden ? 'success' : 'warning'}`"
                      >
                        {{ item._hidden ? "Show" : "Hide" }}

                        <v-icon small v-if="item._hidden" class="ml-2"
                          >mdi-eye</v-icon
                        >
                        <v-icon v-else small class="ml-2">mdi-eye-off</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="item && item._urlInfo && item._urlInfo.fullPath"
                        rounded
                        @click="handleCopyText(item._urlInfo.fullPath)"
                        class="ml-2"
                        small
                        text
                      >
                        Copy
                        <v-icon class="ml-2" small>mdi-content-copy</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table v-if="item._urlInfo">
                        <tbody>
                          <tr>
                            <td style="white-space: nowrap">Full path</td>
                            <td>{{ item._urlInfo.fullPath }}</td>
                          </tr>
                          <tr>
                            <td>Prefix</td>
                            <td>{{ item._urlInfo.prefix }}</td>
                          </tr>
                          <tr>
                            <td>Protocol</td>
                            <td>{{ item._urlInfo.protocol }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
              <template #[`item.tier2_gp`]="{ item }">
                <div
                  class="colored-td colored-td--clickable"
                  @click="goToLtt(item, GUEST_POST_TYPE)"
                >
                  {{ item.tier2_gp }}
                </div>
              </template>
              <template v-for="key in diffItems" #[`item.${key}`]="{ value }">
                <td-diff :key="key" :data="value" />
              </template>
              <template
                v-for="key2 in fixZerosItems"
                #[`item.${key2}`]="{ item }"
              >
                <template v-if="key2 === 'tier2_all'">
                  <div
                    :key="key2"
                    class="colored-td colored-td--clickable"
                    @click="goToLtt(item)"
                  >
                    {{ item[key2] }}
                  </div>
                </template>
                <template v-else>
                  {{
                    String(item[key2]) === "0" || String(item[key2]) === "0,0"
                      ? ""
                      : item[key2]
                  }}
                </template>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DEFAULT_MENU_PROPS, GP_TRACKER_TABLE_DATA } from "@/utils/defaultData";
import VueJsonToCsv from "vue-json-to-csv";

import Location from "@/mixins/Location";
import TdDiff from "@/components/Gpm/GPTracker/td/TdDiff";
import EasyRangeInput from "@/components/UI/EasyRangeInput";
import FiltersHandling from "@/mixins/components/FiltersHandling";
import service from "@/plugins/service";
import SmartDateFilter from "../../UI/SmartDateFilter";
import copy from "copy-to-clipboard";
import Project from "@/mixins/Project";

export default {
  components: { SmartDateFilter, EasyRangeInput, TdDiff, VueJsonToCsv },
  mixins: [Location, FiltersHandling, Project],
  created() {
    this.fetchFilters();
    const haveParams = this._$collectParams(
      [
        "date",
        "niche",
        "author",
        "chief_seo",
        "project",
        "url",
        "anchor_type",
        "dr",
        "ah_keywords",
        "tier_2_all",
        "tier_2_forum_comments",
        "tier_2_linkshome",
        "tier_2_linksold",
        "tier_2_linksnew",
        "tier_2_gp",
        "tier_2_sum",
        "tier2_sum_gp",
        "do_follow",
        "on_page",
        "f_position",
      ],
      "filters"
    );
    if (haveParams) this.fetchTableData();
  },
  data: () => ({
    search: null,
    FILTERS_QUERY_IDS: {
      url: "url",
      chief_seo: "chief_seo",
      niche: "niche",
      author: "author",
      date: "date",
      only_unique: "only_unique",
      project: "project",
    },
    ANCHOR_TYPE_PRIORITY: {
      commercial: 100,
      "commercial broad": 90,
      other: 80,
      "domain / url": 70,
      brand: 40,
    },
    tooltipMenu: false,
    menuProps: DEFAULT_MENU_PROPS,
    tableData: {
      headers: GP_TRACKER_TABLE_DATA.headers,
      items: [],
    },
    diffItems: [
      "traffic",
      "kw",
      "main_kw_pos",
      "web_internal_pos",
      "anchor_pos",
      "referred_domains",
      "ur",
    ],
    fixZerosItems: [
      "kw_1_3",
      "kw_3_10",
      "kw_10_100",
      "second_lvl_links",
      "tier2_all",
      "tier2_forum_comments",
      "tier2_linkshome",
      "tier2_linksnew",
      "tier2_linksold",
      "tier2_sum",
    ],
    filters: {
      date: [], // filter
      niche: [], // filter
      author: [], // filter
      chief_seo: [], // filter
      project: [],
      url: [], // filter
      only_unique: false, // filter
      hide_disavowed_links: 1, // filter
      is_gpm: null, // filter
      hidden: false, // filter
      anchor_type: [], // filter
      subproject: null,
      dr: [null, null], // filter
      ah_keywords: [null, null], // filter
      tier_2_all: [null, null], // filter
      tier_2_forum_comments: [null, null], // filter
      tier_2_linkshome: [null, null], // filter
      tier_2_sum: [null, null], // filter
      tier_2_linksold: [null, null], // filter
      tier_2_linksnew: [null, null], // filter
      tier_2_gp: [null, null], // filter
      do_follow: [], // filter
      on_page: [], // filter
      tier2_sum_gp: [null, null], // filter
      f_position: [null, null], // filter
    },
    loadingsHiding: [],
    filtersData: {
      author: [],
      url: [],
      project: [],
      chief_seo: [],
      anchor_type: [],
      do_follow: [],
      on_page: [],
    },
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍♂",
      itemsPlaceholder:
        "Can't find anything. <br> Change filters and try again. 🤷‍♂",
    },
    firstEnter: false,
    loadings: {
      filters: false,
      table: false,
    },
    GUEST_POST_TYPE: 6,
  }),
  computed: {
    getItems() {
      if (this.loadings.table) return [];

      const show = this.filters.hidden;

      return this.tableData.items.filter((v) => v._hidden === show);
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    activeProject() {
      return this.$store.state.project.active_project;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(newValue) {
        this._$handleFiltersChange(newValue);
      },
    },
    "filters.hidden"() {
      this.fetchTableData();
    },
    activeProject() {
      this.filters.project = [];
      this.filters.url = [];
      this.filters.subproject = null;
    },
  },
  methods: {
    goToLtt(item, type) {
      let query = `?url_id=${item.id}`;

      if (type) query += "&type=" + type;

      window.open("/ltt/2level/index" + query, "_blank");
    },
    handleCopyText(string) {
      copy(string);
      this.$message.notification({
        title: "Copy",
        text: `Text <mark>${string}</mark> successfully coppied.`,
        type: "success",
      });
    },
    async toggleUrl(item) {
      this.loadingsHiding.push(item.id);
      const url = "/dashboards/gpm/tracker/toggle-url";
      const payload = {
        urlID: item.id,
      };
      const resp = await service.post(url, payload);

      this.loadingsHiding = this.loadingsHiding.filter((v) => v !== item.id);

      if (resp && resp.status) {
        item._hidden = !item._hidden;
      }
    },
    getJsonCSVData() {
      const copy = JSON.parse(JSON.stringify(this.tableData.items));
      return copy.map((row) => {
        this.diffItems.forEach((key) => {
          row[key] = this.getCSVTraffic(row[key]);
        });

        Object.keys(row).forEach((key) => {
          const value = row[key];

          if (typeof value === "boolean") {
            return (row[key] = value ? "Yes" : "No");
          }

          if (value === null || value === undefined || String(value) === "0") {
            row[key] = "";
          }
        });

        delete row._urlInfo;
        delete row._urlTooltipMenuModel;

        return row;
      });
    },
    getCSVTraffic(traffic) {
      let output = "";

      const prev = traffic.prev;
      const value = traffic.value;
      const diff = traffic.diff;

      output += `${prev ? `pr: ${prev}` : ""}`;
      output += ` ${value ? `cr: ${value}` : ""}`;
      output += ` ${diff ? `dff: ${diff}` : ""}`;

      return output;
    },
    _$smartCustomTableSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        for (let i in sortBy) {
          if (sortBy[i] === "anchor_type") {
            const a1 = this.ANCHOR_TYPE_PRIORITY[a[sortBy[i]]] || 0;
            const b1 = this.ANCHOR_TYPE_PRIORITY[b[sortBy[i]]] || 0;
            if (a1 > b1) return sortDesc[i] ? -1 : 1;
            if (a1 < b1) return sortDesc[i] ? 1 : -1;
            continue;
          }

          if (a[sortBy[i]] === null && b[sortBy[i]] === null) continue;

          const string =
            isNaN(parseInt(a[sortBy[i]])) && isNaN(parseInt(b[sortBy[i]]));

          if (string) {
            let aVal, bVal;
            if (a[sortBy[i]] === null || a[sortBy[i]] === "") aVal = 0;
            if (b[sortBy[i]] === null || b[sortBy[i]] === "") bVal = 0;
            if (aVal === 0 && bVal !== 0) return 1;
            if (bVal === 0 && aVal !== 0) return -1;

            if (a[sortBy[i]] > b[sortBy[i]]) return sortDesc[i] ? -1 : 1;
            if (a[sortBy[i]] < b[sortBy[i]]) return sortDesc[i] ? 1 : -1;
            continue;
          }

          let aVal, bVal;
          if (isNaN(parseInt(a[sortBy[i]]))) aVal = 0;
          if (isNaN(parseInt(b[sortBy[i]]))) bVal = 0;
          if (aVal === 0 && bVal !== 0) return 1;
          if (bVal === 0 && aVal !== 0) return -1;

          if (parseInt(a[sortBy[i]]) > parseInt(b[sortBy[i]]))
            return sortDesc[i] ? -1 : 1;
          if (parseInt(a[sortBy[i]]) < parseInt(b[sortBy[i]]))
            return sortDesc[i] ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
    collectParamsFromQuery() {
      const keys = Object.keys(this.$route.query);
      keys.forEach((key) => {
        const value = this.$route.query[key];

        if (key === this.FILTERS_QUERY_IDS.only_unique) {
          return (this[key] = value === "true");
        }
        if (key === this.FILTERS_QUERY_IDS.date) {
          const result = value.split(",");
          return (this[key] = result);
        }

        const result = value.split(",").map((item) => Number(item));
        return (this[key] = result);
      });

      if (keys.length > 0) {
        return true;
      }
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = [
        "chief_seo",
        "project",
        "url",
        "author",
        "anchor_type",
        "do_follow",
        "on_page",
      ];
      const payload = {
        type: "/dashboards/gpm/tracker",
        take: filters,
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    validate() {
      let error = false;

      const emptyDate = JSON.stringify([null, null]);
      if (
        this.filters.date.length === 0 ||
        JSON.stringify(this.filters.date) === emptyDate
      ) {
        error = true;
        this.$message.notification({
          title: "Validation",
          text: "Date is required filter.",
          type: "warning",
          duration: 6000,
        });
      }

      return error;
    },
    async fetchTableData() {
      if (this.validate()) return;

      this.firstEnter = true;
      this.loadings.table = true;
      const payload = {
        body: {
          filter: this.filters,
        },
        isMock: localStorage.getItem("is_mock") === "1",
      };

      const resp = await this.$store.dispatch(
        "gp-tracker/fetchTableData",
        payload
      );
      if (resp) {
        this.tableData = this.getFixedTableData(resp, this.filters.hidden);
      }
      this.loadings.table = false;
    },
    getFixedTableData({ headers, items }, isHidden) {
      let fixedItems = items.map((item) => {
        item._urlTooltipMenuModel = false;

        item._urlInfo = {
          fullPath: item.url,
          protocol: item.url.match(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g)[0],
          prefix: item.url.match(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g)[1],
        };

        item._hidden = isHidden;

        item.url = String(item.url).replace(
          /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g,
          ""
        );

        return item;
      });

      return {
        headers,
        items: fixedItems,
      };
    },
    handleUpdateDateFilter(event) {
      this.filters.date = event;
    },
    handleGetData() {
      this.fetchTableData();
    },
    getWeeksTd(data) {
      const tdArray = [];
      Object.keys(data).forEach((key) => {
        if (key.search(/[0-9]?[0-9]w/gm) !== -1) {
          tdArray.push(data[key]);
        }
      });
      return tdArray;
    },
  },
};
</script>

<style lang="scss">
.outreach-problems {
  table {
    td {
      position: relative;
    }
  }
}
</style>
