<template>
  <div class="text-body-2">
    <slot />
    <template v-if="field?.pivot?.is_required">
      <v-tooltip open-delay="300" right>
        <template #activator="{ on }">
          <span v-on="on" class="error--text"> * </span>
        </template>
        This field is required to fill up
      </v-tooltip>
    </template>
    <template v-else>
      <small style="opacity: 0.7">(Optional)</small>
    </template>
  </div>
</template>

<script>
export default {
  props: ["field"],
};
</script>
