<template>
  <div class="shell-links">
    <template v-if="computedProjectId">
      <v-row dense>
        <v-col cols="12">
          <page-header
            title="Shell Links"
            :breadcrumbs="[
              { text: 'Home', to: '/' },
              { text: 'Shell Links', disabled: true },
            ]"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <easy-range-input
            label="Price"
            :model-value="filters.price"
            @update:modelValue="filters.price = $event"
          />
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <easy-range-input
            label="Ah Traffic"
            :model-value="filters.ah_traffic"
            @update:modelValue="filters.ah_traffic = $event"
          />
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <easy-range-input
            label="Reg Traffic"
            :model-value="filters.reg_traffic"
            @update:modelValue="filters.reg_traffic = $event"
          />
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <easy-range-input
            label="USA Traffic"
            :model-value="filters.usa_traffic"
            @update:modelValue="filters.usa_traffic = $event"
          />
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            v-model="filters.top_traffic_country"
            hide-details
            dense
            outlined
            clearable
            label="Top Traffic Country"
            :items="filterItems.top_traffic_country"
            placeholder="Enter to search"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <smart-date-filter
            :model-value="filters.date_added"
            @update:modelValue="filters.date_added = $event"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <easy-range-input
            label="SW Monthly Visits"
            :model-value="filters.sw_monthly_visits"
            @update:modelValue="filters.sw_monthly_visits = $event"
          />
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <easy-range-input
            label="DR"
            :model-value="filters.dr"
            @update:modelValue="filters.dr = $event"
          />
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <easy-range-input
            label="Spam Index"
            :model-value="filters.spam_index"
            @update:modelValue="filters.spam_index = $event"
          />
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            v-model="filters.sw_category"
            hide-details
            dense
            clearable
            outlined
            :items="filterItems.sw_category"
            label="SW Category"
            placeholder="Select category"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            v-model="filters.google_trust"
            hide-details
            dense
            clearable
            outlined
            :items="[0, 1, 2]"
            label="GT"
            placeholder="Enter to search"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            v-model="filters.cms"
            hide-details
            dense
            outlined
            multiple
            clearable
            :items="filterItems.cms"
            label="CMS"
            placeholder="Enter to search"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-autocomplete
            v-model="filters.shell_type"
            hide-details
            dense
            outlined
            multiple
            clearable
            :items="filterItems.shell_type"
            label="Shell type"
            placeholder="Enter to search"
          >
          </v-autocomplete>
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="filters.seller"
            hide-details
            dense
            outlined
            multiple
            :items="filterItems.seller"
            clearable
            label="Seller"
            placeholder="Enter to search"
          />
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="filters.access_type"
            hide-details
            dense
            outlined
            multiple
            clearable
            :items="filterItems.access_type"
            label="Access type"
            placeholder="Enter to search"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" md="6" lg="6" xl="fill">
          <v-textarea
            v-model="filters.domains"
            dense
            outlined
            label="Domains"
            height="75px"
            no-resize
          ></v-textarea>
        </v-col>
        <v-col cols="6" md="6" lg="6" xl="fill" class="d-flex justify-end">
          <v-btn
            color="primary"
            height="40"
            class="text-normal"
            @click="fetchData"
            width="180"
            :loading="loadings.tableData"
            >Get Data</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-row>
            <v-col>
              <v-text-field
                v-model="tableSearchModel"
                clearable
                label="Search"
                hide-details
                placeholder="Type to search"
                append-icon="mdi-magnify"
                style="max-width: 350px"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col class="d-flex justify-end" v-if="allowedToExport">
              <vue-json-to-csv
                :json-data="getJsonToCsvData()"
                csv-title="Shell_Links"
                :separator="'\t'"
              >
                <v-btn color="success" rounded text>
                  Export CSV <v-icon class="ml-2">mdi-export-variant</v-icon>
                </v-btn>
              </vue-json-to-csv>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-data-table
            v-model="selectedTableItems"
            :search="tableSearchModel"
            :headers="tableHeaders"
            :items="tableItems"
            :items-per-page="50"
            show-select
            item-key="id"
            :footer-props="{ itemsPerPageOptions: [50, 100, 150, 200, -1] }"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12">
          <v-alert prominent type="info" text>
            <div>To use this tool - select a project</div>
            <div>
              <v-btn
                class="text-normal"
                @click="$store.dispatch('toggle_select_project_menu')"
                color="info"
              >
                Select project
              </v-btn>
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import EasyRangeInput from "@/components/UI/EasyRangeInput";
import SmartDateFilter from "../../UI/SmartDateFilter";
import VueJsonToCsv from "vue-json-to-csv";
import PermissionMixin from "../../../mixins/PermissionMixin";
import Location from "../../../mixins/Location";
import PageHeader from "../../UI/PageHeader.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";

export default {
  components: { PageHeader, SmartDateFilter, EasyRangeInput, VueJsonToCsv },
  mixins: [FiltersHandling, PermissionMixin, Location],
  data: () => ({
    loadings: {
      tableData: false,
    },
    rangePresets: [
      ["1-3", [1, 3]],
      ["1-5", [1, 5]],
      ["4-10", [4, 10]],
      ["4-20", [4, 20]],
      ["1-50", [1, 50]],
      ["11-50", [11, 50]],
    ],
    selectedTableItems: [],
    tableSearchModel: "",
    filters: {
      domains: "",
      price: [0, 0],
      dr: [0, 0],
      ah_traffic: [100, -1],
      reg_traffic: [0, 0],
      usa_traffic: [0, 0],
      top_traffic_country: null,
      sw_monthly_visits: [0, 0],
      spam_index: [0, 0],
      sw_category: "",
      google_trust: null,
      cms: [],
      shell_type: [],
      seller: [],
      access_type: [],
      date_added: [],
    },
    googleTrustItems: [0, 1, 2],
    tableHeaders: [],
    tableItems: [],
    filterItems: {
      access_type: [],
      top_traffic_country: [],
      seller: [],
      shell_type: [],
      cms: [],
      sw_category: [],
    },
    // filters
  }),
  async mounted() {
    await this.parseQuery("filters");

    if (!this.$route.query.date_added) {
      this.filters.date_added = [
        this.$moment().format("YYYY-MM-DD"),
        this.$moment().format("YYYY-MM-DD"),
      ];
    }

    if (!this.$route.query.ah_traffic) {
      this.filters.ah_traffic = [100, -1];
    }

    this.fetchData();
    this.fetchFilters();

    this.$watch("computedProjectId", (value) => {
      if (value) return this.fetchData();

      this.$store.dispatch("toggle_select_project_menu");
    });
  },
  computed: {
    computedProjectId() {
      return parseInt(this.$store.state.project.active_project);
    },
    allowedToExport() {
      return (
        this.prm_permissions.find(
          (v) => v.id_section === 65 && v.id_permission === 8
        ) !== undefined
      );
    },
  },
  methods: {
    getJsonToCsvData() {
      if (this.selectedTableItems.length > 0) return this.selectedTableItems;

      return this.tableItems;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = [
        "access_type",
        "seller",
        "shell_type",
        "cms",
        "sw_category",
        "top_traffic_country",
      ];
      const payload = {
        type: "/donor-base/magbo",
        take: filters,
        filter: {
          projectID: this.$store.getters.active_project,
        },
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filterItems[key] = resp[key];
        });
      }

      this.loadings.filters = false;
    },
    async fetchData() {
      this.loadings.tableData = true;

      const payload = {
        filters: {
          ...this.filters,
          project: this.computedProjectId,
        },
      };

      const resp = await this.$store.dispatch("shell-links/fetchData", payload);

      if (resp) {
        this.tableItems = resp.items;
        this.tableHeaders = resp.headers;
      }

      this.loadings.tableData = false;
    },
  },
};
</script>
