<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Continue payment"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Ltt', disabled: true },
            { text: 'Continue payment', disabled: true },
          ]"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <smart-date-filter
              :model-value="filters.date"
              @update:modelValue="filters.date = $event"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.user"
              :items="filtersData.users"
              :loading="filtersDataPendingKeys?.users"
              hide-details
              dense
              multiple
              item-value="id"
              item-text="email"
              outlined
              clearable
              label="User"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.main_seo"
              :items="filtersData.seo_users"
              :loading="filtersDataPendingKeys?.seo_users"
              hide-details
              dense
              multiple
              outlined
              clearable
              label="Main SEO"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.project"
              :items="filtersData.projects"
              :loading="filtersDataPendingKeys?.projects"
              hide-details
              dense
              multiple
              outlined
              clearable
              label="Project"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.sub_project"
              :items="filtersData.subprojects"
              :loading="filtersDataPendingKeys?.subprojects"
              hide-details
              dense
              multiple
              outlined
              clearable
              label="Sub-project"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.status"
              :items="filtersData.ltt_payment_status"
              :loading="filtersDataPendingKeys?.ltt_payment_status"
              hide-details
              dense
              multiple
              outlined
              clearable
              label="Status"
              placeholder="Type to search"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.link_types"
              :items="filtersData.link_types"
              :loading="filtersDataPendingKeys?.link_types"
              hide-details
              dense
              multiple
              outlined
              clearable
              label="Type of link"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.team"
              :items="filtersData.teams"
              :loading="filtersDataPendingKeys?.teams"
              hide-details
              dense
              multiple
              outlined
              clearable
              label="Team"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-btn
              height="40"
              class="text-normal"
              color="primary"
              block
              clearable
              :loading="loadings.data"
              @click="fetchTableData"
              >Get data</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <template v-for="(section, idx) in sections">
            <v-col :key="`section-${idx}`" cols="12">
              <v-card flat outlined rounded="lg">
                <v-card-title class="pa-4 text-body-2">
                  {{ section.name }}
                </v-card-title>
                <v-divider />
                <v-card-text
                  class="px-3 d-flex flex-column"
                  style="gap: 0.5rem; background: var(--card-darken-body-color)"
                >
                  <v-card
                    v-for="(card, j) in section.items"
                    :key="`card-${j}`"
                    flat
                    outlined
                    rounded="lg"
                  >
                    <v-card-title class="text-body-2">
                      {{ card.name }}
                    </v-card-title>
                    <v-divider />
                    <v-card-title class="pa-4">
                      <v-text-field
                        v-model="card.search"
                        hide-details
                        dense
                        outlined
                        clearable
                        placeholder="Type to search"
                        append-icon="mdi-magnify"
                      />
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="px-0 pt-0 pb-2">
                      <v-data-table
                        :search="card.search"
                        :items="card.items"
                        :headers="dataTable.headers"
                        class="continue-payment-table relative-tr"
                        multi-sort
                        :sort-desc="[false]"
                        :sort-by="['term']"
                        :footer-props="{
                          itemsPerPageOptions: [50, 150, 300, -1],
                        }"
                        :items-per-page="50"
                      >
                        <template #[`item.url_gp`]="{ value, item }">
                          <div class="mark" :class="getMarkClasses(item)" />
                          <pretty-link :url="value" />
                        </template>
                        <template #[`item._actions`]="{ item }">
                          <v-menu
                            bottom
                            nudge-bottom="38"
                            content-class="my-shadow--e2"
                          >
                            <template #activator="{ on }">
                              <v-btn
                                style="min-width: 36px"
                                class="px-2"
                                v-on="on"
                                text
                              >
                                <v-icon small>mdi-menu</v-icon>
                              </v-btn>
                            </template>
                            <v-card flat outlined rounded="lg">
                              <v-card-title class="text-body-2">
                                Actions
                              </v-card-title>
                              <v-divider />
                              <v-card-text class="pa-0 pb-2">
                                <v-list dense>
                                  <v-list-item
                                    @click="
                                      handleVisitDomainInGpmViewEntity(
                                        item.gpm_entity_id
                                      )
                                    "
                                  >
                                    <v-list-item-icon class="mr-2">
                                      <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title> GPM </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                    @click="
                                      handleVisitSiteAnalysis(item.domain)
                                    "
                                  >
                                    <v-list-item-icon class="mr-2">
                                      <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                      Site analysis
                                    </v-list-item-title>
                                  </v-list-item>
                                  <v-list-item @click="handleEditDonor(item)">
                                    <v-list-item-icon class="mr-2">
                                      <v-icon small>mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                      Edit donor
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                        <template #[`item.term`]="{ item, value }">
                          <div v-if="value" class="d-flex">
                            {{ value }}
                            <v-icon
                              small
                              class="ml-1"
                              @click="handleVisitDomainInGpmViewEntity(item.id)"
                              title="Inspect this URL in Gpm view entity"
                            >
                              mdi-open-in-new
                            </v-icon>
                          </div>
                        </template>
                        <template #[`item.status`]="{ item }">
                          <v-select
                            v-model="item.status"
                            :items="filtersData.ltt_payment_status"
                            :loading="
                              filtersDataPendingKeys?.ltt_payment_status ||
                              loadings.editStatus === item.id
                            "
                            :disabled="
                              loadings.editStatus !== null &&
                              loadings.editStatus !== item.id
                            "
                            hide-details
                            dense
                            flat
                            :menu-props="{
                              'content-class':
                                'my-shadow--e2 rounded-lg border',
                            }"
                            @change="handleChangeRowStatus($event, item)"
                          />
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
    <edit-donor-dialog
      v-model="isEditDonorDialogVisible"
      :form="editDonorData"
      @update:form="editDonorData = $event"
      @newDonor="handleNewDonor"
    />
  </v-container>
</template>

<script>
import PageHeader from "../../UI/PageHeader.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import uploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import { LttContinuePaymentApiService } from "./services/LttContinuePaymentApiService";
import { CONTINUE_PAYMENT_HEADERS } from "./default";
import PrettyLink from "../../UI/PrettyLink.vue";
import EditDonorDialog from "./components/EditDonorDialog.vue";

export default {
  components: { EditDonorDialog, PrettyLink, SmartDateFilter, PageHeader },

  mixins: [FiltersHandling, uploadFiltersDataMixin],

  data: () => ({
    search: null,
    filters: {
      date: [null, null],
      team: [],
      user: [],
      main_seo: [],
      project: [],
      sub_project: [],
      status: [],
      link_types: [],
    },
    sections: [],
    dataTable: {
      items: [],
      headers: [],
    },
    loadings: {
      data: false,
      editStatus: null,
    },
    filtersData: {
      users: [],
      seo_users: [],
      projects: [],
      subprojects: [],
      link_types: [],
      ltt_payment_status: [],
    },
    isEditDonorDialogVisible: false,
    editDonorData: {
      done_url: null,
      link_id: null,
      reactiveItem: null,
    },
  }),

  async mounted() {
    await this.parseQuery("filters");

    this.uploadFiltersData([
      "users",
      "teams",
      "seo_users",
      "projects",
      "subprojects",
      "link_types",
      "ltt_payment_status",
    ]);

    this.$watch("filters.project", () => {
      this.filters.sub_project = null;
      this.uploadFiltersData(["subprojects"], {
        projects: this.filters.project,
      });
    });
  },

  methods: {
    handleNewDonor(newDonorString) {
      this.$set(
        this.editDonorData.reactiveItem,
        "domain_donor",
        newDonorString
      );
    },
    handleEditDonor(tableRow) {
      this.isEditDonorDialogVisible = true;
      this.editDonorData.done_url = tableRow.donor;
      this.editDonorData.link_id = tableRow.link_id;
      this.$set(this.editDonorData, "reactiveItem", tableRow);
    },
    getMarkClasses(tableRow) {
      return {
        "mark--gray": tableRow.status === 0,
        "mark--info": tableRow.status === 1,
        "mark--error": tableRow.status === 2,
        "mark--success": tableRow.status === 3,
      };
    },
    handleVisitDomainInGpmViewEntity(urlId) {
      const routeData = this.$router.resolve({
        name: "gpm.view-entity",
        params: { id: urlId },
      });

      window.open(routeData.href, "_blank");
    },
    handleVisitSiteAnalysis(domainDonorString) {
      const routeData = this.$router.resolve({
        name: "site-analysis",
        query: { domain: domainDonorString },
      });

      window.open(routeData.href, "_blank");
    },
    async handleChangeRowStatus(newStatusId, tableRow) {
      try {
        this.loadings.editStatus = tableRow.id;

        await LttContinuePaymentApiService().editStatusFor(
          tableRow.id,
          newStatusId
        );
      } catch (e) {
        console.error("Error while changing status for url.", e);
      } finally {
        this.loadings.editStatus = null;
      }
    },
    async fetchTableData() {
      try {
        this.loadings.data = true;

        const { data } =
          await LttContinuePaymentApiService().getContinuePaymentData(
            this.filters
          );

        this.sections = data?.items || [];
        this.dataTable.headers = data?.headers || CONTINUE_PAYMENT_HEADERS;
      } catch (e) {
        console.error("Error while loading table data.", e);
      } finally {
        this.loadings.data = false;
      }
    },
  },
};
</script>

<style lang="scss">
.continue-payment-table {
  th {
    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(10),
    &:nth-child(12),
    &:nth-child(14),
    &:nth-child(15) {
      border-right: 1px solid var(--card-border);
    }
  }
  tbody {
    tr {
      td:nth-child(1) {
        padding: 0 0.5rem !important ;
      }
      td {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(7),
        &:nth-child(10),
        &:nth-child(12),
        &:nth-child(14),
        &:nth-child(15) {
          border-right: 1px solid var(--card-border);
        }
      }
    }
  }
}
</style>
