import Service from "@/plugins/service";

export default {
  state: {
    contentFieldValidationResults: {},
  },
  mutations: {
    "site-worksheets/setContentFieldValidationResults"(state, payload) {
      state.contentFieldValidationResults = payload;
    },
  },
  actions: {
    async "site-worksheet/fetchData"(ctx, payload) {
      const url = "/dashboards/content/site-worksheet";
      const response = await Service.post(url, payload);
      if (response) {
        return response.data;
      }
      return null;
    },
  },
  getters: {
    "site-worksheets/setContentFieldValidationResults": (state) =>
      state.contentFieldValidationResults,
  },
};
