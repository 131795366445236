<template>
  <v-card flat outlined rounded="lg">
    <v-card-title class="text-body-2">
      Send a comment to selected status
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectedPageStatusId"
            hide-details
            dense
            outlined
            placeholder="type to search"
            :items="provideFiltersData?.()?.filtersData?.page_statuses || []"
            label="Status"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="message"
            hide-details
            dense
            outlined
            placeholder="Enter comment"
            append-icon="mdi-send"
            :disabled="loadings.send || success"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-4 pb-3 pt-0">
      <v-btn
        large
        class="text-normal"
        color="success"
        block
        @click="handleSendComment"
        :disabled="success"
        :loading="loadings.send"
      >
        Send comment
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SiteWorkSheetApiService } from "../services/SiteWorkSheetApiService";
import { deepClone } from "../../../../utils/functions";

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  inject: ["provideFiltersData"],

  emits: ["sent"],

  data: () => ({
    loadings: {
      send: false,
    },
    selectedPageStatusId: null,
    mutableForm: null,
    message: null,
    success: false,
  }),

  watch: {
    form: {
      handler(newData) {
        if (!newData) return;

        this.mutableForm = deepClone(newData);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async uploadFreshComments() {
      try {
        this.loadings.messages = true;
        const { data } =
          await SiteWorkSheetApiService().getWorksheetDetailsById(
            this.mutableForm?.url_info?.url_id
          );

        this.$emit("sent", data?.status_comments);
      } catch (e) {
        console.error("Error while loading fresh messages.", e);
      } finally {
        this.loadings.messages = false;
      }
    },
    async handleSendComment(e) {
      if (e) e?.preventDefault();

      try {
        if (!this.message) return;

        this.loadings.send = true;

        const { data } = await SiteWorkSheetApiService().sendCommentToStatus(
          this.mutableForm?.url_info?.url_id,
          {
            comment: this.message?.trim(),
            status_id: this.selectedPageStatusId,
          }
        );

        if (data.success) {
          this.message = null;
          this.selectedPageStatusId = null;
          this.success = true;
          this.uploadFreshComments();
          setTimeout(() => {
            this.success = false;
          }, 1500);
        }
      } catch (e) {
        console.error("Error while sending message to chat.", e);
      } finally {
        this.loadings.send = false;
      }
    },
  },
};
</script>
