<template>
  <a target="_blank" :href="url" class="text-normal text-decoration-none">
    <span class="primary--text">{{ computedUrl?.host }}</span>
    <span class="grey--text">{{ computedUrl?.pathname }}</span>
    <v-hover v-if="openInNew" v-slot="{ hover }">
      <v-chip
        :class="`text-normal px-1 pl-2 ml-1 ${
          hover ? 'flat-chip flat-chip--primary' : ''
        }`"
        small
        label
        :color="hover ? '' : 'transparent'"
        :href="computedUrl?.href"
        target="_blank"
      >
        <div class="d-flex" style="gap: 0.25rem">
          <v-slide-x-transition>
            <span v-if="hover">Visit</span>
          </v-slide-x-transition>
          <v-icon small>mdi-open-in-new</v-icon>
        </div>
      </v-chip>
    </v-hover>
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    openInNew: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedUrl() {
      return new URL(this.url);
    },
  },
};
</script>
