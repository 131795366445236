<template>
  <div class="week-report" style="padding-bottom: 200px">
    <v-row>
      <v-col cols="12">
        <page-header
          title="Worksection Week report"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Worksection Week report', disabled: true },
          ]"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filter.executive"
          label="Executive"
          :loading="loadingsArray.includes('fetchFilters')"
          item-text="name"
          item-value="email"
          :items="executives"
          :menu-props="menuProps"
          hide-details
          outlined
          dense
          transition="slide-x-translate"
          multiple
          clearable
          append-icon="mdi-chevron-down"
          prepend-inner-icon="mdi-account-outline"
          class="small-chips"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text text-caption"
              style="font-size: 11px !important"
            >
              (+{{ filter.executive.length - 1 }} others)
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filter.label"
          label="Label"
          outlined
          item-text="title"
          item-value="title"
          :menu-props="menuProps"
          :items="labels"
          hide-details="auto"
          :error-messages="validationResult?.['filter.label']"
          @blur="validationResult['filter.label'] = []"
          :loading="loadingsArray.includes('fetchFilters')"
          dense
          clearable
          append-icon="mdi-chevron-down"
          prepend-inner-icon="mdi-filter-outline"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-btn
          @click="handleGetData"
          block
          height="40"
          :loading="loading"
          color="primary"
          class="text-normal"
          >Get Data</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" v-if="done_week.Week_A.series[0] >= 0">
        <div class="text-center mb-3">Week A</div>
        <RadialBarChart :series="done_week.Week_A.series"></RadialBarChart>
      </v-col>
      <v-col cols="4" v-if="done_week.Week_B.series[0] >= 0">
        <div class="text-center mb-3">Week B</div>
        <RadialBarChart :series="done_week.Week_B.series"></RadialBarChart>
      </v-col>
      <v-col cols="4" v-if="done_week.Week_C.series[0] >= 0">
        <div class="text-center mb-3">Week C</div>
        <RadialBarChart :series="done_week.Week_C.series"></RadialBarChart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="position: relative">
        <v-card flat outlined rounded="lg">
          <v-card-title class="text-body-2">
            <v-text-field
              v-model="search"
              hide-details
              dense
              outlined
              placeholder="Type to search"
              append-icon="mdi-magnify"
            />
          </v-card-title>
          <v-card-text class="px-0">
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="table-overflow-hidden relative-tr"
              multi-sort
              group-by="tag"
              item-key="table_key"
              :sort-desc="[true, true]"
              :sort-by="['priority', 'priority_project']"
              :items-per-page="-1"
              :custom-sort="_$smartCustomTableSort"
              dense
            >
              <template
                v-for="h in headers"
                v-slot:[`header.${h.value}`]="{ header }"
              >
                <span :title="header.title" :key="h.value">{{
                  header.text
                }}</span>
              </template>
              <template v-slot:item.user_from="{ item }">
                {{ item.user_from.name }}
              </template>
              <template v-slot:item.project_name="{ item }">
                <div
                  v-if="String(item.status) === '1'"
                  class="mark mark--success"
                />

                {{ item.project_name }}
              </template>
              <template v-slot:item.today="{ item }">
                <template>
                  <template v-for="(prior, idx) in item.today">
                    <a
                      :href="`https://ra.worksection.com${prior.page}`"
                      target="_blank"
                      class="reset-link-styles"
                      :key="idx"
                    >
                      <v-chip outlined small @click="() => {}">
                        {{ prior.priority }}
                      </v-chip>
                    </a>
                  </template>
                </template>
              </template>
              <template v-slot:item.d_arr="{ item }">
                <v-sparkline
                  :value="Object.values(item.d_arr)"
                  line-width="16"
                  :gradient="['#639ee0', '#2b4d93']"
                  type="bar"
                  :width="300"
                />
              </template>
              <template v-slot:item.name="{ item }">
                <span
                  ><a
                    target="_blank"
                    :href="'https://ra.worksection.com/' + item.page"
                    >{{ item.name }}</a
                  >
                  <span v-if="item.subtask_all > 0" class="nowrap">
                    [subt: {{ item.subtask_done }}/{{ item.subtask_all }}]
                  </span>
                  <span
                    v-if="item.comments && item.comments.length > 0"
                    class="nowrap"
                  >
                    [cd: {{ item.comments.length }}]
                  </span>
                </span>
              </template>
              <template v-slot:item.l_p="{ item }">
                {{ item.l }}
              </template>
              <template v-slot:item.max_time="{ item }">
                <span v-if="item.max_time">{{ item.max_time }}</span>
                <v-icon v-else color="warning" small>mdi-alert-outline</v-icon>
              </template>
              <template v-slot:item.finish="{ item }">
                <span v-if="item.finish < 1 && item.finish !== ''">
                  <v-chip small :color="getColor(item.finish)" dark>
                    {{ item.finish }}
                  </v-chip>
                </span>
                <span v-else>
                  {{ item.finish }}
                </span>
              </template>
              <template #[`item.d1`]="{ value, item }">
                <td-time :data="item" :value="value" name="d1" />
              </template>
              <template #[`item.d2`]="{ value, item }">
                <td-time :data="item" :value="value" name="d2" />
              </template>
              <template #[`item.d3`]="{ value, item }">
                <td-time :data="item" :value="value" name="d3" />
              </template>
              <template #[`item.d4`]="{ value, item }">
                <td-time :data="item" :value="value" name="d4" />
              </template>
              <template #[`item.d5`]="{ value, item }">
                <td-time :data="item" :value="value" name="d5" />
              </template>
              <template #[`item.d6`]="{ value, item }">
                <td-time :data="item" :value="value" name="d6" />
              </template>
              <template #[`item.d7`]="{ value, item }">
                <td-time :data="item" :value="value" name="d7" />
              </template>
              <template #[`item.d8`]="{ value, item }">
                <td-time :data="item" :value="value" name="d8" />
              </template>
              <template #[`item.week`]="{ value, item }">
                <td-time :data="item" :value="value" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="user_week.categories.length > 0">
        <StackedBarChart
          :series="user_week.series"
          :categories="user_week.categories"
          >>
        </StackedBarChart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RadialBarChart from "@/components/Ltt/Dashboards/WorksectionWeek/RadialBarChart";
import StackedBarChart from "@/components/Ltt/Dashboards/WorksectionWeek/StackedBarChart";
import {
  DEFAULT_MENU_PROPS,
  WEEK_REPORT_TABLE_HEADERS,
} from "@/utils/defaultData";
import TdTime from "@/components/Ltt/Dashboards/WorksectionWeek/TdTime";
import TableSorting from "../../../mixins/TableSorting";
import PageHeader from "../../UI/PageHeader.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import apiErrorHandlingMixin from "../../../mixins/ApiErrorHandlingMixin";

export default {
  name: "WorksectionWeekReportIndex",
  components: {
    PageHeader,
    TdTime,
    RadialBarChart,
    StackedBarChart,
  },
  mixins: [FiltersHandling, TableSorting, apiErrorHandlingMixin],
  data: () => ({
    loadingsArray: [],
    items: [],
    executives: [],
    labels: [],
    loading: false,
    search: "",
    filter: {
      niche: null,
      executive: [],
      label: null,
    },
    done_week: {
      Week_A: { series: [-1] },
      Week_B: { series: [-1] },
      Week_C: { series: [-1] },
    },
    user_week: {
      categories: [],
      series: [],
    },
    FILTERS_QUERY_IDS: {
      EXECUTIVE: "executive",
      LABEL: "label",
    },
    headers: WEEK_REPORT_TABLE_HEADERS,
    menuProps: DEFAULT_MENU_PROPS,
  }),
  methods: {
    getColor(val) {
      // val -> tableRow.finish
      if (val < 0) {
        return "red";
      } else if (val === 0 || val === 1) {
        return "orange";
      }
    },
    getMonday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    async fetchData() {
      try {
        let body = { filter: this.filter };

        this.loading = true;
        const data = await this.$store.dispatch(
          "worksectionReportWeek/fetchData",
          body
        );
        this.loading = false;

        if (!data) {
          return;
        }
        const { done_user_week, done_week, table } = data;

        this.items = this.sortTableItems(table.items);

        this.done_week = done_week;
        this.user_week.categories = done_user_week.categories || [];
        this.user_week.series = done_user_week.series || [];

        this.items.forEach((element) => {
          this.fetchTaskComments(element.page, element.id);

          element.child.forEach((child) => {
            this.fetchTaskComments(child.page, child.parent.id);
          });
        });
      } catch (e) {
        console.error(e);
        this.handleApiError(e);
        this.loading = false;
      }
    },
    async fetchFilters() {
      this.addToLoadings("fetchFilters");
      const filters = await this.$store.dispatch(
        "worksectionReportWeek/fetchFilters"
      );
      if (filters) {
        this.executives = filters.executives;
        this.labels = filters.labels;
      }

      this.removeFromLoadings("fetchFilters");
    },
    async fetchTaskComments(page, parentId) {
      const payload = {
        page,
      };
      const comment = await this.$store.dispatch(
        "worksectionReportWeek/fetchReportComments",
        payload
      );
      if (comment.data) {
        this.items.find((item) => item.id === parentId)["comments"] =
          comment.data;
        return this.$forceUpdate();
      }
    },
    async fetchTaskTimes(id, parentId = false) {
      const loading = parentId || id;

      this.addToLoadings(loading);

      const data = await this.$store.dispatch(
        "worksectionReportWeek/fetchReportTask",
        { id }
      );

      this.updateTaskTime(data, id, parentId);

      this.removeFromLoadings(loading);
    },
    updateTaskTime(data, id, parentId) {
      const times = data.items;
      const time_new = [];
      const searchBy = parentId || id;
      const daysKeys = ["d1", "d2", "d3", "d4", "d5", "d6", "d7", "d8", "week"];

      const times_old = this.items.find((i) => i.id === searchBy)["times"];

      daysKeys.forEach((key) => {
        time_new[key] = times[key];

        if (times_old && times_old[key]) {
          time_new[key] += "\n" + times_old[key];
        }
      });

      this.items.find((i) => i.id === searchBy)["times"] = time_new;
      return this.$forceUpdate();
    },
    sortTableItems(data) {
      // :sort-by="['status','priority_project','priority','date_end','user_to']"
      // :sort-desc="[false,true,true,true,true]"
      return data.sort((a, b) => {
        const {
          status: a1,
          priority_project: b1,
          priority: c1,
          date_end: d1,
          user_to: { name: e1 },
        } = a;
        const {
          status: a2,
          priority_project: b2,
          priority: c2,
          date_end: d2,
          user_to: { name: e2 },
        } = b;

        if (a1 > a2) return 1;
        if (a1 < a2) return -1;

        if (b1 > b2) return -1;
        if (b1 < b2) return 1;

        if (c1 > c2) return 1;
        if (c1 < c2) return -1;

        if (getTime(d1) > getTime(d2)) return 1;
        if (getTime(d1) < getTime(d2)) return -1;

        if (e1 > e2) return 1;
        if (e1 < e2) return -1;

        function getTime(date) {
          return new Date(date).getTime();
        }

        return 0;
      });
    },
    handleGetData() {
      this.fetchData();
    },
    removeFromLoadings(item) {
      this.loadingsArray = this.loadingsArray.filter((i) => i !== item);
    },
    addToLoadings(item) {
      this.loadingsArray = [...this.loadingsArray, item];
    },
  },
  async mounted() {
    const haveParams = Object.keys(this.$route.query).length;

    await this.parseQuery("filter");

    this.fetchFilters();

    if (haveParams) this.fetchData();
  },
};
</script>
