import Service from "../../../../plugins/service";

export const SiteWorkSheetApiService = () => {
  const ENDPOINTS = {
    WORKSHEET_DETAILS: (id) => `/cm/worksheet/${id}`,
    STATUSES_HISTORY: (id) => `/cm/worksheet/${id}/history-statuses`,
    FIELDS_HISTORY: (id) => `/cm/worksheet/${id}/history-fields`,
    PROBLEMS_HISTORY: (id) => `/cm/worksheet/${id}/history-problems`,
    CHAT: (id) => `/cm/worksheet/${id}/messages`,
    READ_CHAT: (id) => `/cm/worksheet/${id}/read-messages`,
    COMMENT: (id) => `/cm/worksheet/${id}/status-comments`,
  };

  const getWorksheetDetailsById = (urlId) => {
    return Service.get(ENDPOINTS.WORKSHEET_DETAILS(urlId));
  };

  const updateStatus = (urlId, payload) => {
    return Service.put(
      ENDPOINTS.WORKSHEET_DETAILS(urlId),
      payload,
      true,
      {},
      { disableTossError: true }
    );
  };

  const getStatusesHistory = (urlId) => {
    return Service.get(ENDPOINTS.STATUSES_HISTORY(urlId));
  };

  const getFieldsHistory = (urlId) => {
    return Service.get(ENDPOINTS.FIELDS_HISTORY(urlId));
  };

  const getProblemsHistory = (urlId) => {
    return Service.get(ENDPOINTS.PROBLEMS_HISTORY(urlId));
  };

  const sendMessageToChat = (urlId, payload) => {
    return Service.post(ENDPOINTS.CHAT(urlId), payload);
  };

  const sendCommentToStatus = (urlId, payload) => {
    return Service.put(ENDPOINTS.COMMENT(urlId), payload);
  };

  const readChat = (urlId) => {
    return Service.put(ENDPOINTS.READ_CHAT(urlId));
  };

  return {
    getWorksheetDetailsById,
    updateStatus,
    getFieldsHistory,
    getStatusesHistory,
    sendMessageToChat,
    readChat,
    sendCommentToStatus,
    getProblemsHistory,
  };
};
