<template>
  <div>
    <v-container class="clean-dahsboard" fluid style="padding-bottom: 200px">
      <v-row>
        <v-col cols="12">
          <v-row dense class="align-end">
            <v-col>
              <h2>Donor base clean</h2>
              <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
            </v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="dialog = true"
                :disabled="!id_project"
                class="text-normal"
              >
                Filters
                <v-icon right>mdi-filter</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                :disabled="!id_project"
                color="primary"
                class="text-normal"
                @click="fetch_data"
                :loading="loadings.data"
                block
                >Get Data
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <template v-if="!id_project">
          <v-alert text type="info" rounded="lg">
            To continue working with the dashboard - select the project.
            <v-spacer />
            <v-btn
              @click="$store.dispatch('toggle_select_project_menu')"
              class="text-normal mt-2"
              color="primary"
            >
              Select project
            </v-btn>
          </v-alert>
        </template>
        <template v-else>
          <v-col cols="12" md="12">
            <v-card flat outlined rounded="lg">
              <v-card-title class="pa-4 text-body-2">
                <v-text-field
                  v-model="search"
                  hide-details
                  dense
                  outlined
                  placeholder="Type to search"
                  append-icon="mdi-magnify"
                  style="max-width: 320px"
                />
                <v-spacer />
                <div>
                  <v-chip
                    label
                    class="mr-2"
                    @click="dialog_price_donors = true"
                  >
                    Import price donors
                    <v-icon right small>mdi-upload</v-icon>
                  </v-chip>
                  <v-chip
                    label
                    class="mr-2"
                    @click="dialog_disavow_donors = true"
                  >
                    Import disavow
                    <v-icon right small>mdi-eye-off</v-icon>
                  </v-chip>
                  <v-chip label class="mr-2" @click="dialog_add_to_list = true">
                    Add domains
                    <v-icon right small>mdi-playlist-plus</v-icon>
                  </v-chip>
                </div>
              </v-card-title>
              <v-divider />
              <v-card-text class="px-0 pt-0 pb-2">
                <div
                  v-show="
                    (!loadings.data &&
                      Array.isArray(items) &&
                      items.length > 0) ||
                    (isAlreadyFetched && loadings.data)
                  "
                  style="position: relative"
                >
                  <loader :show="true" v-show="loadings.data" />
                  <v-data-table
                    v-model="items_selected"
                    :headers="get_headers"
                    :items="computedSearchItems"
                    :search="search"
                    selectable-key="donor_domain_id"
                    :footer-props="{
                      itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
                    }"
                    class="fixed-table-clean-d"
                    show-select
                    :loading="loadings.data"
                    :options.sync="options"
                    :server-items-length="total_items"
                    :sort-by="['traffic', 'ah_traffic_country', 'ah_traffic']"
                    :sort-desc="[true, true, true]"
                    multi-sort
                    fixed-header
                  >
                    <template
                      v-for="h in get_headers"
                      v-slot:[`header.${h.value}`]="{ header }"
                    >
                      <v-tooltip bottom :key="`tooltip-${h.value}`">
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ header.text }}</span
                          ><span v-if="header.icon"
                            ><v-img :src="country_icons" width="20px"
                          /></span>
                        </template>
                        <span>{{ header.title }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.domain="{ item }">
                      <div
                        :class="`d-flex flex-nowrap align-content-center colored-td full-size pl-3 justify-start ${
                          item.found ? 'green' : 'red'
                        }`"
                      >
                        <div
                          class="d-flex justify-space-between"
                          style="width: 100%"
                        >
                          <div style="white-space: nowrap">
                            <a :href="getUrl(item)" target="_blank">
                              {{ item.domain }}
                            </a>
                            <v-tooltip
                              v-if="item.is_checked"
                              content-class="pa-0 shadow-e1-bordered"
                              bottom
                            >
                              <template #activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  small
                                  class="ml-1"
                                  color="info"
                                  title="Type Manual Checked"
                                >
                                  mdi-check-circle-outline
                                </v-icon>
                              </template>
                              <v-list dense rounded>
                                <v-list-item>
                                  {{ item.user_date }}
                                </v-list-item>
                                <v-list-item>
                                  {{ item.user_email }}
                                </v-list-item>
                              </v-list>
                            </v-tooltip>
                            <v-tooltip
                              v-if="item.is_approved"
                              content-class="pa-0 shadow-e1-bordered"
                              bottom
                            >
                              <template #activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  title="Approved by manager"
                                  color="primary"
                                  small
                                >
                                  mdi-account-tie
                                </v-icon>
                              </template>
                              <v-list dense rounded>
                                <v-list-item>
                                  {{ item.approved_by }}
                                </v-list-item>
                                <v-list-item>
                                  {{ item.approved_at }}
                                </v-list-item>
                              </v-list>
                            </v-tooltip>
                            <v-chip
                              v-if="
                                item.added_x_days_ago < 30 &&
                                item.count_link_all === null
                              "
                              x-small
                              class="pa-2 ml-1"
                              color="warning"
                              >NEW
                            </v-chip>
                          </div>
                          <div>
                            <v-menu :close-on-content-click="false" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  small
                                >
                                  <v-icon small>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="toBlacklist(item)">
                                  <v-list-item-icon color="error">
                                    <v-icon>mdi-trash-can</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Add to Blacklist Fast
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  @click="
                                    dialog_blacklist_id = item.id;
                                    dialog_blacklist = true;
                                  "
                                >
                                  <v-list-item-icon color="info">
                                    <v-icon>mdi-trash-can</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Add to Blacklist Pro
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  link
                                  :href="`http://app.serpinsider.com/site-analysis?domain=${
                                    item.domain
                                  }${
                                    item.link_type_id
                                      ? `&link_type=${item.link_type_id}`
                                      : ''
                                  }`"
                                  target="_blank"
                                >
                                  <v-list-item-icon color="red">
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >View Info</v-list-item-title
                                  >
                                </v-list-item>
                                <v-list-item
                                  link
                                  :href="`http://new.spiderlink.pro/dashboards/link-database-management/processing/?domain=${item.donor_domain_id}`"
                                  target="_blank"
                                >
                                  <v-list-item-icon color="red">
                                    <v-icon>mdi-spider</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Open in SpiderLink
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  v-if="item.count_comment > 0"
                                  @click="initComments(item)"
                                >
                                  <v-list-item-icon color="primary">
                                    <v-icon>mdi-comment</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Comments:
                                    {{ item.count_comment }}
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="getExamples(item)">
                                  <v-list-item-icon color="primary">
                                    <v-icon
                                      >mdi-clipboard-text-search-outline
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >View Examples
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  @click="handleOutreachedContact(item)"
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-connection</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Outreach contact
                                  </v-list-item-title>
                                </v-list-item>
                                <v-divider />
                                <v-list-item>
                                  <v-list-item-icon>
                                    <v-switch
                                      v-model="item.verify"
                                      hide-details
                                      dense
                                      class="ma-0"
                                      @change="handleChangeVerify(item)"
                                    ></v-switch>
                                  </v-list-item-icon>
                                  <v-list-item-title>
                                    Send to verify
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-autocomplete
                        v-model="item.tag"
                        :items="
                          tags[item.type_id] ? tags[item.type_id] : tags['']
                        "
                        item-text="name"
                        item-value="id"
                        multiple
                        label="Status"
                        placeholder="Select"
                        small-chips
                        hide-details
                        dense
                        @change="save_data_domains(item)"
                      >
                        <template #selection="{ item }">
                          <v-chip :color="item.color">{{ item.name }}</v-chip>
                        </template>
                      </v-autocomplete>
                      <v-btn
                        v-if="item.tag_count > 0"
                        color="primary"
                        depressed
                        x-small
                        fab
                        @click="initTagsHistory(item)"
                      >
                        <span style="font-size: 10px">{{
                          item.tag_count
                        }}</span>
                      </v-btn>
                    </template>
                    <template v-slot:item.anchor_plan="{ item }">
                      <v-autocomplete
                        v-model="anchor_plans_selected[item.donor_domain_id]"
                        :items="items_anchor_plans[item.type_id]"
                        label="Anchor plan"
                        placeholder="Select"
                        dense
                        clearable
                        hide-details
                      ></v-autocomplete>
                    </template>
                    <template v-slot:item.check_on_page="{ item }">
                      <div
                        style="white-space: nowrap"
                        :style="
                          get_color(
                            item.check_is_index,
                            item.check_on_page,
                            item.ltt_all
                          )
                        "
                      >
                        <span v-if="item.ltt_all > 0"
                          ><span title="all ltt">{{ item.ltt_all }}</span
                          >-<span title="is live">{{ item.check_on_page }}</span
                          >-<span title="can be indexed">{{
                            item.check_is_index
                          }}</span
                          >-<span title="indexed">{{
                            item.check_gi
                          }}</span></span
                        >
                      </div>
                    </template>
                    <template v-slot:item.google_index_query="{ item }">
                      <div class="d-flex align-center">
                        <v-text-field
                          label="Query"
                          placeholder="site:apple/news"
                          hide-details
                          dense
                          @change="save_google_index_query(item)"
                          v-model="item.google_index_query"
                        ></v-text-field>
                      </div>
                    </template>
                    <template v-slot:item.ltt_last_posting="{ item }">
                      <span
                        v-html="
                          get_date(
                            item.last_posting_in_days,
                            item.ltt_last_posting
                          )
                        "
                        :title="item.ltt_last_posting"
                      ></span>
                    </template>
                    <template v-slot:item.last_for_project="{ item }">
                      <span
                        v-html="
                          get_date(
                            item.last_for_project_in_days,
                            item.last_for_project
                          )
                        "
                        :title="item.last_for_project"
                      ></span>
                    </template>
                    <template v-slot:item.sw_category="{ item }">
                      <span :title="item.sw_category">{{
                        getSliced(item.sw_category, 0, 20)
                      }}</span>
                    </template>
                    <template #[`item.price_gpm`]="{ item }">
                      <div style="white-space: nowrap">
                        {{ item.price_gpm }}
                        <span v-if="item.price_gpm">
                          <v-icon small @click="load_price(item)"
                            >mdi-information-outline</v-icon
                          >
                        </span>
                      </div>
                    </template>
                    <template
                      v-for="(key, idx) in [
                        'price_kosmolot',
                        'price_manual',
                        'price_valgro',
                        'price_prposting',
                        'price_bazoom',
                        'price_whitehatlinks',
                        'price_kazboozt',
                        'price_gpm_link_insertion',
                      ]"
                      #[`item.${key}`]="{ item, value }"
                    >
                      <div
                        style="white-space: nowrap"
                        :key="`info-${key}-${idx}`"
                      >
                        {{ value }}
                        <span v-if="value">
                          <v-icon small @click="load_price(item)">
                            mdi-information-outline
                          </v-icon>
                        </span>
                      </div>
                    </template>
                    <template #[`item.comment`]="{ item }">
                      <div v-if="item.comment" style="min-width: 240px">
                        {{ item.comment }}
                      </div>
                    </template>
                    <template v-slot:item.last_try_post_for_project="{ item }">
                      <span
                        v-html="
                          get_date(
                            item.last_try_post_for_project_in_days,
                            item.last_try_post_for_project
                          )
                        "
                        :title="item.last_try_post_for_project"
                      ></span>
                    </template>
                    <template v-slot:item.ltt_all="{ item }">
                      <span
                        v-if="item.ltt_all > 0"
                        :style="
                          get_color(
                            item.is_index,
                            item.ltt_on_page,
                            item.ltt_all
                          )
                        "
                        >{{ item.ltt_all }}|{{ item.ltt_on_page }}|{{
                          item.ltt_follow
                        }}|{{ item.is_index }}|{{ item.ltt_cache }}</span
                      >
                    </template>
                    <template v-slot:item.created_at="{ item }">
                      <span style="white-space: nowrap">{{
                        item.created_at
                      }}</span>
                    </template>
                    <template v-slot:item.whois_creation="{ item }">
                      <span style="white-space: nowrap">{{
                        item.whois_creation
                      }}</span>
                    </template>
                    <template v-slot:item.ltt_last_success_check="{ item }">
                      <span style="white-space: nowrap">{{
                        item.ltt_last_success_check
                      }}</span>
                    </template>
                    <template v-slot:item.ah_ref_domains="{ item }" ]>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <div v-on="on">
                            {{ item.ah_ref_domains }}
                          </div>
                        </template>
                        <div>
                          {{ item.ah_ref_domains_date }}
                        </div>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.linked_root_domains="{ item }" ]>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <div v-on="on">
                            {{ item.linked_root_domains }}
                          </div>
                        </template>
                        <div>
                          {{ item.ah_ref_domains_date }}
                        </div>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.ah_traffic_country="{ item }" ]>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <div v-on="on">
                            {{ item.ah_traffic_country }}
                          </div>
                        </template>
                        <div>
                          {{ item.ah_traffic_country_date }}
                        </div>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </div>
                <template
                  v-if="
                    !loadings.data &&
                    Array.isArray(items) &&
                    items.length === 0 &&
                    isAlreadyFetched
                  "
                >
                  <div class="py-6 text-center">
                    <span style="opacity: 0.8"
                      >No data for this filters...</span
                    >
                  </div>
                </template>
                <template
                  v-else-if="
                    !loadings.data &&
                    Array.isArray(items) &&
                    items.length === 0 &&
                    !isAlreadyFetched
                  "
                >
                  <div class="py-6 text-center">
                    <span style="opacity: 0.8">
                      Select
                      <u class="cursor-pointer" @click="dialog = true"
                        ><b>filters</b></u
                      >
                      and press
                      <u class="cursor-pointer" @click="fetch_data">
                        <b>"get data"</b>
                      </u>
                    </span>
                  </div>
                </template>
                <template v-else-if="loadings.data && !isAlreadyFetched">
                  <v-skeleton-loader type="table" />
                </template>
              </v-card-text>
            </v-card>
            <v-dialog
              v-model="dialog_list_examples"
              scrollable
              max-width="700px"
            >
              <v-card>
                <v-card-text class="py-10">
                  <v-list-item
                    v-for="(item, idx) in list_examples"
                    :key="`list-${item.url}${idx}`"
                    class="mb-3"
                    :href="item.url"
                    target="_blank"
                  >
                    <v-list-item-content
                      v-html="item.url"
                    ></v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialog"
              scrollable
              content-class="remove-dialog-shadow"
            >
              <v-card class="styled-card--default my-shadow--e2" rounded="lg">
                <v-card-title class="d-flex">
                  Filters
                  <v-spacer />
                  <v-text-field
                    v-model="searchSection"
                    hide-details
                    dense
                    outlined
                    append-icon="mdi-magnify"
                    style="width: 120px"
                    clearable
                    label="Search section"
                    placeholder="Enter section name or filter name"
                  ></v-text-field>
                  <v-spacer />
                  <v-btn @click="dialog = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text style="min-height: 300px" class="py-8">
                  <v-row>
                    <v-col cols="12" md="6" lg="4" xl="4">
                      <v-row>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.mainParams.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Main params
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <smart-autocomplete
                                    :model-value="filter.link_type"
                                    label="Link type"
                                    filled
                                    @update:modelValue="
                                      filter.link_type = $event
                                    "
                                    :items="linkTypesItems"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <smart-autocomplete
                                    :model-value="filter.show"
                                    name="show"
                                    @update:modelValue="filter.show = $event"
                                    :items="show_items"
                                    label="Show"
                                    filled
                                    item-text="text"
                                    item-value="value"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <smart-autocomplete
                                    :model-value="filter.tags"
                                    name="tags"
                                    @update:modelValue="filter.tags = $event"
                                    :items="tagItems"
                                    filled
                                    label="Donor bad status"
                                    item-text="name"
                                    item-value="id"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.list"
                                    :items="listItems"
                                    item-text="name"
                                    item-value="id"
                                    chips
                                    small-chips
                                    label="Lists"
                                    filled
                                    dense
                                    hide-details
                                    multiple
                                    deletable-chips
                                    clearable
                                    :menu-props="menuProps"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.hide_list"
                                    :items="listItems"
                                    item-text="name"
                                    item-value="id"
                                    chips
                                    small-chips
                                    label="Hide lists"
                                    filled
                                    dense
                                    hide-details
                                    multiple
                                    deletable-chips
                                    clearable
                                    :menu-props="menuProps"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.url_id"
                                    :items="urls_topic"
                                    item-text="url"
                                    item-value="id"
                                    chips
                                    small-chips
                                    label="Url cosine"
                                    filled
                                    dense
                                    hide-details
                                    :menu-props="menuProps"
                                    deletable-chips
                                    clearable
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.country"
                                    :items="countries_items"
                                    item-text="name"
                                    item-value="id"
                                    label="Country"
                                    prepend-inner-icon="mdi-map-marker-outline"
                                    filled
                                    dense
                                    hide-details
                                    :menu-props="menuProps"
                                    clearable
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.domain_lang"
                                    :items="language_items"
                                    item-text="name"
                                    item-value="id"
                                    label="Language"
                                    prepend-inner-icon="mdi-translate"
                                    filled
                                    dense
                                    hide-details
                                    :menu-props="menuProps"
                                    clearable
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.hide_disavowed_links"
                                    label="Hide disavowed links"
                                    :true-value="1"
                                    :false-value="0"
                                    class="mt-0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.domains_to_check"
                                    label="Domains to check"
                                    class="mt-0"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.is_link_ltt_company"
                                    label="Is link LTT company"
                                    class="mt-0"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.is_checked"
                                    label="is Checked"
                                    class="mt-0"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.is_not_checked"
                                    label="not Checked"
                                    class="mt-0"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.is_approved"
                                    label="is Approved"
                                    class="mt-0"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.is_not_approved"
                                    label="not Approved"
                                    class="mt-0"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="
                                      filter.percent_live_link_ltt_company
                                    "
                                    label="Live links LTT company"
                                    type="number"
                                    dense
                                    hide-details
                                    append-icon="mdi-percent"
                                    filled
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.last_updater"
                                    label="Last updater"
                                    dense
                                    hide-details
                                    prepend-inner-icon="mdi-account"
                                    :items="filtersData.users"
                                    filled
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.dateAdded.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Date added
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <smart-date-filter
                                    :model-value="filter.date_added"
                                    @update:modelValue="
                                      filter.date_added = $event
                                    "
                                    label="Select range"
                                    filled
                                  />
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.domainSearch.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Domain Search
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-col cols="12">
                                <v-tooltip bottom>
                                  <template #activator="{ on }">
                                    <div v-on="on" class="d-flex align-center">
                                      <v-checkbox
                                        v-model="filter.ignore_filters"
                                        label="Ignore filters"
                                        class="mt-0"
                                        :true-value="1"
                                        :false-value="0"
                                        hide-details
                                        dense
                                      />
                                      <v-icon class="ml-1" small
                                        >mdi-information-outline
                                      </v-icon>
                                    </div>
                                  </template>
                                  <div>
                                    Viewing statistics regardless of data and
                                    blacklist settings in Spiderlink
                                  </div>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea
                                  v-model="filter.domains_search"
                                  filled
                                  label="Domain"
                                  rows="4"
                                  placeholder="Enter domains"
                                  hint="Separation - new line"
                                ></v-textarea>
                              </v-col>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(SECTIONS_DATA.lists.keywords)
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Lists
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.favorite_operator"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.favorite"
                                        label="Favorite"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.blacklist_operator"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.blacklist"
                                        label="Blacklist"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.lastPosting.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Last posting
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.last_posting_operator"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-menu bottom>
                                        <template #activator="{ on }">
                                          <v-text-field
                                            v-model="filter.last_posting"
                                            dense
                                            filled
                                            hide-details
                                            label="Select date"
                                            clearable
                                            append-icon="mdi-calendar"
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="filter.last_posting"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="4">
                      <v-row>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.customFilter.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Custom filter
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="9">
                                      <v-autocomplete
                                        v-model="custom_filter"
                                        :items="custom_filters"
                                        label="Custom filter"
                                        placeholder="Select filter"
                                        filled
                                        dense
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        :menu-props="menuProps"
                                        hide-details
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="3">
                                      <v-menu
                                        v-model="customFilterMenu"
                                        left
                                        content-class="shadow-e2-bordered"
                                        rounded="lg"
                                        :close-on-content-click="false"
                                      >
                                        <template #activator="{ on }">
                                          <v-btn
                                            block
                                            v-on="on"
                                            style="height: 100%"
                                            outlined
                                            color="primary"
                                          >
                                            Add
                                            <v-icon>mdi-plus</v-icon>
                                          </v-btn>
                                        </template>
                                        <v-card rounded="lg">
                                          <v-card-title
                                            >Create custom filter
                                          </v-card-title>
                                          <v-card-text>
                                            <v-text-field
                                              v-model="custom_filter_name"
                                              label="Name"
                                              placeholder="Enter name"
                                              dense
                                              outlined
                                              hide-details
                                            ></v-text-field>
                                          </v-card-text>
                                          <v-card-actions
                                            class="d-flex justify-end"
                                          >
                                            <v-btn
                                              @click="customFilterMenu = false"
                                              text
                                            >
                                              Close
                                            </v-btn>
                                            <v-btn
                                              class="ml-2"
                                              width="120px"
                                              color="success"
                                              @click="
                                                handleAddToListNewCustomFilter
                                              "
                                              :loading="
                                                loadings.createCustomFilter
                                              "
                                              >Add to list
                                            </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-menu>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.swRankByCountry.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              SW Rank by country
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.sw_rank_country_operator_min
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.sw_rank_country_min"
                                        label="Min"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.sw_rank_country_operator_max
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        append-icon=""
                                        class="centered-autocomplete"
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.sw_rank_country_max"
                                        label="Max"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.similarWeb.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Similar web
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.sw_rank_operator"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.sw_rank"
                                        label="Global rank"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.sw_exclude_traffic"
                                    :items="countries_items"
                                    hide-details
                                    dense
                                    item-text="name"
                                    prepend-inner-icon="mdi-map-marker-outline"
                                    item-value="id"
                                    filled
                                    :menu-props="menuProps"
                                    label="Exclude traffic"
                                    clearable
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="filter.sw_include_in_keywords"
                                    label="Include in keywords"
                                    filled
                                    dense
                                    hide-details
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.sw_traffic_by_country"
                                    :items="countries_items"
                                    hide-details
                                    dense
                                    prepend-inner-icon="mdi-map-marker-outline"
                                    item-text="name"
                                    item-value="id"
                                    filled
                                    :menu-props="menuProps"
                                    label="Traffic by country"
                                    clearable
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.country_percent_operator
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.blacklist"
                                        label="SW Country %"
                                        type="number"
                                        filled
                                        append-icon="mdi-percent"
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.ahrefsTopTrafficCountry.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Ahrefs Top Traffic Country
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.ah_top_traffic_country_operator_min
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        disabled
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="
                                          filter.ah_top_traffic_country_min
                                        "
                                        label="Min"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.ah_top_traffic_country_operator_max
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        disabled
                                        hide-details
                                        append-icon=""
                                        class="centered-autocomplete"
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="
                                          filter.ah_top_traffic_country_max
                                        "
                                        label="Max"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.ahTrafficByCountry.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Ah traffic by country
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.ah_traffic_by_country_operator_min
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="
                                          filter.ah_traffic_by_country_min
                                        "
                                        label="Min"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.ah_traffic_by_country_operator_max
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        append-icon=""
                                        class="centered-autocomplete"
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="
                                          filter.ah_traffic_by_country_max
                                        "
                                        label="Max"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.ahTraffic.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Ah traffic
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.ah_traffic_operator_min"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.ah_traffic_min"
                                        label="Min"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.ah_traffic_operator_max"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        append-icon=""
                                        class="centered-autocomplete"
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.ah_traffic_max"
                                        label="Max"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(SECTIONS_DATA.ahrefs.keywords)
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Ahrefs
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-col cols="12">
                                <v-row dense>
                                  <v-col cols="3">
                                    <v-autocomplete
                                      v-model="filter.ah_dr_operator"
                                      :items="number_operators_items"
                                      filled
                                      dense
                                      hide-details
                                      append-icon=""
                                      class="centered-autocomplete"
                                      prepend-icon="mdi-tune-variant"
                                      style="font-size: 18px"
                                      :menu-props="menuProps"
                                    ></v-autocomplete>
                                  </v-col>
                                  <v-col cols="9">
                                    <v-text-field
                                      v-model="filter.ah_dr"
                                      label="DR"
                                      type="number"
                                      filled
                                      dense
                                      hide-details
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12">
                                <v-row dense>
                                  <v-col cols="12">
                                    <v-autocomplete
                                      v-model="
                                        filter.ah_exclude_countries_traffic
                                      "
                                      :items="countries_items"
                                      label="Exclude Traffic"
                                      item-text="name"
                                      item-value="id"
                                      filled
                                      dense
                                      multiple
                                      hide-details
                                      :menu-props="menuProps"
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="4">
                      <v-row>
                        <v-col
                          v-if="
                            processSearchSection(SECTIONS_DATA.score.keywords)
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Score
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.score_operator"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.score"
                                        label="Score"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.spam_score_operator"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.spam_score"
                                        label="Spam score"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.index"
                                    :items="index_items"
                                    label="Index"
                                    filled
                                    hide-details
                                    dense
                                    :menu-props="menuProps"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.moderation_gp"
                                    :items="moderation_gp_items"
                                    label="Moderation"
                                    filled
                                    dense
                                    hide-details
                                    :menu-props="menuProps"
                                    clearable
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.donorPrice.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Donor price
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.show_donor_with_price"
                                    label="Show donors with price"
                                    class="mt-0"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.price_operator_min"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.price_min"
                                        label="Min"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.price_operator_max"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        append-icon=""
                                        class="centered-autocomplete"
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.price_max"
                                        label="Max"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.price_types"
                                    :items="filtersData.price_types"
                                    dense
                                    hide-details
                                    clearable
                                    label="Price Type"
                                    :loading="loadings.filters"
                                    filled
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(SECTIONS_DATA.rdLrd.keywords)
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              RD/LRD
                            </v-card-title>
                            <v-card-subtitle>
                              ( Ref domains / linked root domains )
                            </v-card-subtitle>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.ref_domains_linked_root_domains_operator_min
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        class="centered-autocomplete"
                                        append-icon=""
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="
                                          filter.ref_domains_linked_root_domains_min
                                        "
                                        label="Min"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="
                                          filter.ref_domains_linked_root_domains_operator_max
                                        "
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        append-icon=""
                                        class="centered-autocomplete"
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="
                                          filter.ref_domains_linked_root_domains_max
                                        "
                                        label="Max"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-divider />
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(SECTIONS_DATA.alexa.keywords)
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              ALEXA
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.alexa_rank_operator"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        append-icon=""
                                        class="centered-autocomplete"
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.alexa_rank"
                                        label="Gobal rank"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    v-model="filter.follow"
                                    :items="follow_items"
                                    dense
                                    hide-details
                                    filled
                                    label="Follow"
                                    clearable
                                    :menu-props="menuProps"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.textRazor.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Text razor
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-checkbox
                                    v-model="filter.tr_entry"
                                    label="Entry"
                                    class="mt-0"
                                    :true-value="1"
                                    :false-value="0"
                                    hide-details
                                    dense
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea
                                    v-model="filter.tr_include_topics"
                                    filled
                                    label="Include Topics"
                                    hint="Separation - new line"
                                    rows="4"
                                  ></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-col cols="3">
                                      <v-autocomplete
                                        v-model="filter.tr_percent_operator"
                                        :items="number_operators_items"
                                        filled
                                        dense
                                        hide-details
                                        append-icon=""
                                        class="centered-autocomplete"
                                        prepend-icon="mdi-tune-variant"
                                        style="font-size: 18px"
                                        :menu-props="menuProps"
                                      ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="9">
                                      <v-text-field
                                        v-model="filter.tr_percent"
                                        label="Percent"
                                        append-icon="mdi-percent"
                                        type="number"
                                        filled
                                        dense
                                        hide-details
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col
                          v-if="
                            processSearchSection(
                              SECTIONS_DATA.domainZones.keywords
                            )
                          "
                          cols="12"
                        >
                          <v-card
                            class="styled-card--primary-header"
                            rounded="lg"
                          >
                            <v-card-title style="font-size: 16px">
                              Domain zones
                            </v-card-title>
                            <v-divider />
                            <v-card-text>
                              <v-row dense>
                                <v-col cols="12">
                                  <v-row dense>
                                    <v-row dense>
                                      <v-col cols="3">
                                        <v-autocomplete
                                          v-model="filter.domain_zones_operator"
                                          :items="zones_items"
                                          filled
                                          dense
                                          hide-details
                                          append-icon=""
                                          class="centered-autocomplete"
                                          prepend-icon="mdi-tune-variant"
                                          style="font-size: 18px"
                                          :menu-props="menuProps"
                                        />
                                      </v-col>
                                      <v-col cols="9">
                                        <v-textarea
                                          v-model="filter.domain_zones"
                                          filled
                                          rows="4"
                                          label="Domain zones"
                                          hide-details
                                          :placeholder="'.in\n.edu'"
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="d-flex">
                  <v-spacer />
                  <v-btn
                    class="d-flex justify-center text-normal px-6"
                    @click="export_data"
                    large
                  >
                    <template v-if="!loadings.export">
                      Create export
                      <v-icon right small> mdi-cloud-download-outline</v-icon>
                    </template>
                    <template v-else>
                      <v-progress-circular size="18" width="3" indeterminate />
                    </template>
                  </v-btn>
                  <v-btn
                    color="primary"
                    large
                    @click="fetch_data"
                    class="text-normal px-6"
                  >
                    Get Data
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="import_lists.dialog"
              persistent
              max-width="600px"
            >
              <v-card class="styled-card--default" rounded="lg">
                <v-card-title style="font-size: 16px">
                  Import lists
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-4">
                  <v-textarea
                    v-model="import_lists.data"
                    filled
                    dense
                    hide-details
                    name="input-7-4"
                    placeholder="Enter text"
                    hint="domain<TAB>list name"
                    label="Enter data"
                    no-resize
                  ></v-textarea>
                  <v-radio-group v-model="import_lists.type" mandatory>
                    <v-radio label="Add" value="add"></v-radio>
                    <v-radio label="Replace" value="replace"></v-radio>
                  </v-radio-group>
                  <v-alert v-if="import_lists.message !== null" type="success">
                    {{ import_lists.message }}
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip
                    outlined
                    label
                    class="mr-2"
                    @click="import_lists.dialog = false"
                  >
                    Close
                  </v-chip>
                  <v-chip
                    label
                    color="primary"
                    class="d-flex justify-center"
                    style="width: 120px"
                    :disabled="!import_lists.data || import_lists.loading"
                    @click="importLists"
                    :loading="import_lists.loading"
                  >
                    <template v-if="import_lists.loading">
                      <v-progress-circular size="18" width="2" indeterminate />
                    </template>
                    <template v-else> Import</template>
                  </v-chip>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialog_add_to_list"
              persistent
              max-width="400px"
              content-class="remove-dialog-shadow"
            >
              <v-card class="styled-card shadow-e1-bordered" rounded="lg">
                <v-card-title style="font-size: 14px">
                  Add domains to lists
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-list>
                    <v-list-item
                      v-for="(item, idx) in list"
                      :key="`list-${item.id}${idx}`"
                    >
                      <v-list-item-action>
                        <v-checkbox
                          v-model="selected_lists"
                          :value="item.id"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action v-if="item.id_project !== 0">
                        <v-btn
                          small
                          @click="
                            selected_list = item.id;
                            new_list_name = item.name;
                          "
                          icon
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-text-field
                    v-if="selected_list === null"
                    v-model="new_list_name"
                    placeholder="List name"
                    append-icon="mdi-plus"
                    @click:append="createList"
                    filled
                    :loading="loadings.uploadNewListItem"
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-else
                    v-model="new_list_name"
                    placeholder="List name"
                    append-icon="mdi-check"
                    @click:append="updateList"
                    filled
                    :loading="loadings.uploadNewListItem"
                    hide-details
                  ></v-text-field>
                  <v-alert v-if="list_error !== null" dense text type="error">
                    {{ list_error }}
                  </v-alert>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-chip outlined label @click="import_lists.dialog = true">
                    Import
                    <v-icon right small>mdi-import</v-icon>
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-chip
                    label
                    outlined
                    class="mr-2"
                    @click="dialog_add_to_list = false"
                  >
                    Close
                  </v-chip>
                  <v-chip
                    color="primary"
                    label
                    style="width: 120px"
                    class="d-flex justify-center"
                    @click="addDomainsToLists"
                    :disabled="!isDomainsSelected || !isListsSelected"
                  >
                    Save
                  </v-chip>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog_add_to_plan" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline"
                    >Select the task from the Anchor Plan</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-autocomplete
                    v-model="anchor_plan_task"
                    :items="items_anchor_plan_tasks"
                    item-text="name"
                    item-value="id"
                    label="Task"
                    solo
                    clearable
                  ></v-autocomplete>
                  <p>You want to add {{ items_selected.length }} donors</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog_add_to_plan = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="add_domains_to_ap"
                    :disabled="!isDomainsSelected"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialog_price"
              max-width="700px"
              scrollable
              content-class="my-shadow--e3"
            >
              <v-card>
                <v-card-title class="text-body-2 pa-4">
                  <b>Price history</b>
                  <v-spacer />
                  <v-chip
                    label
                    @click="dialog_price = false"
                    style="width: 32px"
                    class="d-flex justify-center pa-0"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-chip>
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex flex-column pa-4" style="gap: 1rem">
                  <template v-for="(prices, key, idx) in domain_prices">
                    <v-card
                      v-if="prices"
                      class="rounded-lg"
                      flat
                      outlined
                      :key="`domain-price-${key}${idx}`"
                    >
                      <v-card-title class="text-body-2">
                        <div class="d-flex" style="gap: 0.25rem">
                          <span>
                            {{ $moment(key).format("ll") }}
                          </span>
                          -
                          <span style="opacity: 0.5">
                            {{ $moment(key).format("hh:mm:ss") }}
                          </span>
                        </div>
                      </v-card-title>
                      <v-card-text class="pa-4 pt-0">
                        <div class="d-flex flex-column" style="gap: 0.5rem">
                          <v-card
                            v-for="(price, idx) in prices"
                            :key="`title-${price.title}${idx}`"
                            flat
                            outlined
                            class="rounded"
                          >
                            <v-card-title class="text-body-2">
                              <div class="d-flex" style="gap: 0.25rem">
                                <span title="Source">
                                  <v-icon size="14"> mdi-source-branch </v-icon>
                                  {{ price.source }}
                                </span>
                                •
                                <span title="Name">{{ price.title }}</span>
                              </div>
                              <v-spacer />
                              <div
                                class="d-flex align-center"
                                style="gap: 0.5rem"
                              >
                                <span v-if="price.max_links" title="Max links">
                                  <span style="opacity: 0.8">
                                    <v-icon size="16" class="mr-1">
                                      mdi-link-variant
                                    </v-icon>
                                  </span>
                                  <b>{{ price.max_links }}</b>
                                </span>
                                <span v-if="price.period" title="Period">
                                  <span style="opacity: 0.5">
                                    <v-icon size="16">mdi-update</v-icon>
                                  </span>
                                  {{ price.period }}
                                </span>
                                <span
                                  v-if="
                                    price.value !== 'null' &&
                                    price.currency !== 'null'
                                  "
                                  title="Price"
                                >
                                  <v-chip label outlined>
                                    {{
                                      price.value !== "null" ? price.value : ""
                                    }}{{
                                      price.currency !== "null"
                                        ? price.currency
                                        : ""
                                    }}
                                  </v-chip>
                                </span>
                              </div>
                            </v-card-title>
                            <v-card-text
                              v-if="price.comment && price.comment !== 'none'"
                              class="pt-0"
                            >
                              «{{ price.comment }}»
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog_sellers" max-width="600px">
              <v-card>
                <v-card-text>
                  <template>
                    <v-card class="mx-auto" tile>
                      <v-list-item
                        v-for="(seller, idx) in domain_sellers"
                        :key="`seller-${seller.id}${idx}`"
                      >
                        <v-list-item-content>
                          {{ seller.contacts }}
                        </v-list-item-content>
                        <v-list-item-action>
                          {{ parseInt(seller.amount) }}$
                        </v-list-item-action>
                        <v-list-item-action>
                          {{ seller.user }}
                        </v-list-item-action>
                      </v-list-item>
                    </v-card>
                  </template>
                </v-card-text>
              </v-card>
            </v-dialog>
            <upload-disavow-donors
              v-if="dialog_disavow_donors === true"
              v-model="dialog_disavow_donors"
              :project="id_project"
            ></upload-disavow-donors>
            <upload-price-donors
              v-if="dialog_price_donors === true"
              v-model="dialog_price_donors"
            ></upload-price-donors>
            <DonorComments
              v-if="dialog_comments === true"
              v-model="dialog_comments"
              :domain_id="this.comments.domain"
              :type_id="this.comments.type"
            >
            </DonorComments>
            <TagsHistory
              v-if="dialog_tags === true"
              v-model="dialog_tags"
              :domain_id="this.comments.domain"
              :type_id="this.comments.type"
            >
            </TagsHistory>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog_blacklist"
      persistent
      scrollable
      max-width="400px"
    >
      <v-card>
        <v-card-title> Add domain to Blacklist</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Add for</v-list-item-title>
                <v-radio-group v-model="dialog_blacklist_for">
                  <v-radio value="user" label="User"></v-radio>
                  <v-radio value="project" label="Project"></v-radio>
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title
                  >Add for the following types
                </v-list-item-title>
                <v-autocomplete
                  v-model="dialog_blacklist_types"
                  :items="linkTypesItems"
                  chips
                  small-chips
                  label="For all types"
                  multiple
                  clearable
                  deletable-chips
                  hint="Leave blank if you want to add for all types"
                ></v-autocomplete>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <SelectMultiple
                :items="tagItems"
                :model="dialog_blacklist_tags"
                :itemText="'name'"
                :itemValue="'id'"
                :label="'Tags'"
                @selectChange="(data) => (dialog_blacklist_tags = data)"
              ></SelectMultiple>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog_blacklist = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="addDomainToBlacklist"
            :loading="dialog_blacklist_loading"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="connected_dialog"
      persistent
      scrollable
      max-width="1000px"
    >
      <v-card>
        <v-card-title> Connected</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="connected_entity_headers"
            :items="connected_entity_items"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="connected_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import SelectMultiple from "@/components/DonorBase/Child/SelectMultiple";
import UploadDisavowDonors from "@/components/DonorBase/Child/UploadDisavowDonors";
import UploadPriceDonors from "@/components/DonorBase/Child/UploadPriceDonors";
import DonorComments from "@/components/AnchorPlan/Child/DonorComments";
import TagsHistory from "@/components/AnchorPlan/Child/TagsHistory";
import eventPipe from "@/events/eventPipe";
import service from "@/plugins/service";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import SmartAutocomplete from "../../UI/SmartAutocomplete";
import { DEFAULT_MENU_PROPS } from "../../../utils/defaultData";
import Location from "../../../mixins/Location";
import copy from "copy-to-clipboard";
import QueryBuilder from "@/mixins/QueryBuilder";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import Loader from "../../Main/Loader.vue";

const icons = require("rendered-country-flags");

export default {
  components: {
    Loader,
    SmartDateFilter,
    SelectMultiple,
    UploadDisavowDonors,
    DonorComments,
    TagsHistory,
    UploadPriceDonors,
    SmartAutocomplete,
  },
  name: "DonorBaseCleanIndex",
  mixins: [FiltersHandling, Location, QueryBuilder],
  data() {
    return {
      SECTIONS_DATA: {
        mainParams: {
          keywords: [
            "main params",
            "link type",
            "show",
            "donor bad status",
            "lists",
            "hide lists",
            "url cosine",
            "country",
            "language",
            "hide disavowed links",
            "domains to check",
            "is link ltt company",
            "is checked",
            "not checked",
            "live links ltt company",
            "last updater",
          ],
        },
        dateAdded: {
          keywords: ["date added", "select range"],
        },
        domainSearch: {
          keywords: ["domain search", "ignore filters", "domain"],
        },
        lists: {
          keywords: ["lists", "favorite", "blacklist"],
        },
        lastPosting: {
          keywords: ["last posting", "select date"],
        },
        customFilter: {
          keywords: ["custom filter", "add custom filter"],
        },
        swRankByCountry: {
          keywords: ["sw rank by country", "min max"],
        },
        similarWeb: {
          keywords: [
            "similar web",
            "global rank",
            "exclude traffic",
            "include in keywords",
            "traffic by country",
            "sw country % percent",
          ],
        },
        ahrefsTopTrafficCountry: {
          keywords: ["ahrefs top traffic country", "min max"],
        },
        ahTrafficByCountry: {
          keywords: ["ah traffic by country", "min max"],
        },
        ahTraffic: {
          keywords: ["ah traffic", "min max"],
        },
        ahrefs: {
          keywords: ["ahrefs", "exclude traffic", "dr"],
        },
        score: {
          keywords: ["score", "spam score", "index", "moderation"],
        },
        donorPrice: {
          keywords: [
            "donor price",
            "show donor with price",
            "min max",
            "price type",
          ],
        },
        rdLrd: {
          keywords: [
            "ref domains linked root domains",
            "rd lrd",
            "rd/lrd",
            "min max",
          ],
        },
        alexa: {
          keywords: ["alexa", "global rank", "follow"],
        },
        textRazor: {
          keywords: ["text razor", "entry", "include topics"],
        },
        domainZones: {
          keywords: ["domain zones", "domain zones"],
        },
      },
      isFetchBlocked: false,
      isAlreadyFetched: false,
      total_items: 0,
      options: {},
      customFilterMenu: false,
      menuProps: DEFAULT_MENU_PROPS,
      connected_dialog: false,
      connected_entity_headers: [
        { text: "Field", value: "title" },
        { text: "Value", value: "value" },
        { text: "Changed At", value: "created" },
        { text: "User", value: "email" },
      ],
      connected_entity_items: [],
      search: "",
      new_list_name: null,
      list: [],
      selected_lists: [],
      selected_list: null,
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Donor base clean",
          disabled: true,
        },
      ],
      import_lists: {
        dialog: false,
        message: null,
        data: null,
        type: "add",
        loading: false,
      },
      searchSection: null,
      comments: {
        type: undefined,
        domain: undefined,
      },
      dialog_comments: false,
      dialog_tags: false,
      dialog_add_to_list: false,
      dialog_new_list: false,
      dialog_blacklist: false,
      dialog_blacklist_loading: false,
      dialog_blacklist_id: 0,
      dialog_blacklist_for: "user",
      dialog_blacklist_types: [],
      dialog_blacklist_tags: [],
      list_error: null,
      settings: [],
      custom_filter: undefined,
      custom_filter_dialog: false,
      custom_filter_name: undefined,
      custom_filters: [],
      anchor_plans_selected: {},
      items_anchor_plan_tasks: [],
      anchor_plan_task: undefined,
      items_anchor_plans: [],
      items_selected: [],
      dialog_add_to_plan: false,
      dialog: false,
      dialog_price: false,
      dialog_sellers: false,
      dialog_disavow_donors: false,
      dialog_price_donors: false,
      headers: {
        "": [
          {
            text: "Domain",
            title: "Domain",
            value: "domain",
            align: "left",
            sortable: false,
          },
          {
            text: "Type",
            title: "Type",
            value: "type",
            align: "center",
            width: "130px",
            sortable: false,
          },
          {
            text: "Check live",
            title: "Check live",
            value: "check_on_page",
            align: "center",
            sortable: false,
          },
          {
            text: "AP",
            title: "Anchor Plan",
            value: "anchor_plan",
            align: "center",
            width: "200px",
            sortable: false,
          },
          {
            text: "LTT %",
            title: "LTT Live Percent",
            value: "ltt_live_percent",
            align: "center",
            sortable: false,
          },
          {
            text: "AH RTraff",
            title: "AH Traffic Regional",
            value: "ah_traffic_country",
            align: "center",
            sortable: false,
            icon: true,
          },
          {
            text: "AH Traff",
            title: "AH Traffic",
            value: "ah_traffic",
            align: "center",
            sortable: false,
          },
          {
            text: "AH DR",
            title: "AH DR",
            value: "ah_dr",
            align: "center",
            sortable: false,
          },
          {
            text: "AH RD",
            title: "AH RD",
            value: "ah_ref_domains",
            align: "center",
            sortable: false,
          },
          {
            text: "DBS",
            title: "Donor Bad Status",
            value: "status",
            width: "200px",
            align: "center",
            sortable: false,
          },
          {
            text: "NI",
            title: "noindex",
            value: "noindex",
            align: "center",
            sortable: false,
          },
          {
            text: "NF",
            title: "nofollow",
            value: "nofollow",
            align: "center",
            sortable: false,
          },
          {
            text: "LRD",
            title: "linked_root_domains",
            value: "linked_root_domains",
            align: "center",
            sortable: false,
          },
          {
            text: "LP",
            title: "Ltt Last Posting",
            value: "ltt_last_posting",
            align: "center",
            sortable: false,
          },
          {
            text: "Spam score",
            title: "Spam score",
            value: "spam_score",
            align: "center",
            sortable: false,
          },
          {
            text: "SW Cat",
            title: "SW Category",
            value: "sw_category",
            align: "left",
            sortable: false,
          },
          {
            text: "GI",
            title: "Google index",
            value: "google_index",
            align: "center",
            sortable: false,
          },
          {
            text: "GI / AHP",
            title: "GI / AHP",
            value: "gi_ahp",
            align: "center",
            sortable: false,
          },
          {
            text: "SW % US",
            title: "SW % US",
            value: "sw_percent",
            align: "center",
            sortable: false,
          },
          {
            text: "SW OS",
            title: "SW Organic Search",
            value: "organic_search",
            align: "center",
            sortable: false,
          },
          {
            text: "SW V",
            title: "SW Visits",
            value: "sw_visits",
            align: "center",
            sortable: false,
          },
          {
            text: "SW S",
            title: "SW Search",
            value: "sw_search",
            align: "center",
            sortable: false,
          },
          {
            text: "GT",
            title: "Google Trust",
            value: "google_trust",
            align: "center",
            sortable: false,
          },
          {
            text: "SW MV",
            title: "SW Monthly Visits",
            value: "monthly_visits",
            align: "center",
            sortable: false,
          },
          {
            text: "Date Add",
            title: "Date Add",
            value: "created_at",
            align: "center",
            sortable: false,
          },
          {
            text: "SW RR",
            title: "SW Rank Regional",
            value: "sw_rank_country",
            align: "center",
            sortable: false,
            icon: true,
          },
          {
            text: "Blacklist",
            title: "Blacklist",
            value: "blacklist_count",
            align: "center",
          },
          {
            text: "Favorite",
            title: "Favorite",
            value: "favorite_count",
            align: "center",
          },
          {
            text: "GI Path",
            title: "Google Index Path",
            value: "google_index_path",
            align: "center",
            sortable: false,
          },
          {
            text: "RPC",
            title: "Relevant pages count",
            value: "google_index_niche",
            align: "center",
            sortable: false,
          },
          {
            text: "WH",
            title: "Whois Creation",
            value: "whois_creation",
            align: "center",
            sortable: false,
          },
          {
            text: "SW R",
            title: "SW Global Rank",
            value: "sw_global_rank",
            align: "center",
            sortable: false,
          },
          {
            text: "For Project",
            title: "Last For Project",
            value: "last_for_project",
            align: "center",
            sortable: false,
          },
          {
            text: "Post For Project",
            title: "Last Try Post For Project",
            value: "last_try_post_for_project",
            align: "center",
            sortable: false,
          },
          {
            text: "LP",
            title: "Link Placement",
            value: "link_placement",
            align: "center",
            sortable: false,
          },
          {
            text: "GI Query",
            title: "Google Index Query",
            value: "google_index_query",
            align: "left",
            width: "200px",
            sortable: false,
          },
          {
            text: "SW Social",
            title: "SW Social",
            value: "social",
            align: "center",
            sortable: false,
          },
          {
            text: "Anchor",
            title: "Anchor",
            value: "anchor",
            align: "center",
            sortable: false,
          },
          {
            text: "SW Ref",
            title: "SW Referrals",
            value: "referrals",
            align: "center",
            sortable: false,
          },
          {
            text: "SW PS",
            title: "SW Paid Search",
            value: "paid_search",
            align: "center",
            sortable: false,
          },
          {
            text: "SW Email",
            title: "SW Email",
            value: "email",
            align: "center",
            sortable: false,
          },
          {
            text: "SW PR",
            title: "SW Paid Referrals",
            value: "paid_referrals",
            align: "center",
            sortable: false,
          },
          {
            text: "Cosine",
            title: "Cosine",
            value: "cosine",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc GPM",
            title: "Price GPM",
            value: "price_gpm",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Kosmolot",
            title: "Price Kosmolot",
            value: "price_kosmolot",
            align: "center",
            sortable: false,
          },
          // --->
          {
            text: "Pc Valgro",
            title: "Price Valgro",
            value: "price_valgro",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Prposting",
            title: "Price Prposting",
            value: "price_prposting",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Bazoom",
            title: "Price Bazoom",
            value: "price_bazoom",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc WHL",
            title: "Price White Hat Links",
            value: "price_whitehatlinks",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Kazboozt",
            title: "Price Kazboozt",
            value: "price_kazboozt",
            align: "center",
            sortable: false,
          },
          {
            text: "PGLI",
            title: "Price GPM Link insetion",
            value: "price_gpm_link_insertion",
            align: "center",
            sortable: false,
          },
          {
            text: "Sellers",
            title: "Sellers",
            value: "sellers",
            align: "center",
            sortable: false,
          },
          {
            text: "Lang",
            title: "Lang",
            value: "lang",
            align: "center",
            sortable: false,
          },
          {
            text: "Topics",
            title: "Topics",
            value: "topics",
            align: "center",
            sortable: false,
          },
          {
            text: "Comment",
            title: "Comment",
            value: "comment",
            align: "center",
            sortable: false,
          },
          {
            text: "Url",
            title: "Url",
            value: "url",
            align: "left",
            sortable: false,
          },
          {
            text: "AH Top Country",
            title: "AH Top Country",
            value: "ah_top_country",
            sortable: false,
          },
          {
            text: "AH Top Traffic",
            title: "ah_top_traffic",
            value: "ah_top_traffic",
            sortable: false,
          },
        ],
        6: [
          { text: "Domain", value: "domain", align: "left", sortable: false },
          { text: "Type", value: "type", align: "center", sortable: false },
          {
            text: "Anchor Plan",
            value: "anchor_plan",
            align: "center",
            sortable: false,
          },
          {
            text: "A|O|D|I|C",
            title: "All|OnPage|DF|Index|Cache",
            value: "ltt_all",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc GPM",
            title: "Price GPM",
            value: "price_gpm",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Kosmolot",
            title: "Price Kosmolot",
            value: "price_kosmolot",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Valgro",
            title: "Price Valgro",
            value: "price_valgro",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Prposting",
            title: "Price Prposting",
            value: "price_prposting",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Bazoom",
            title: "Price Bazoom",
            value: "price_bazoom",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc WHL",
            title: "Price White Hat Links",
            value: "price_whitehatlinks",
            align: "center",
            sortable: false,
          },
          {
            text: "Pc Kazboozt",
            title: "Price Kazboozt",
            value: "price_kazboozt",
            align: "center",
            sortable: false,
          },
          {
            text: "PGLI",
            title: "Price GPM Link insetion",
            value: "price_gpm_link_insertion",
            align: "center",
            sortable: false,
          },
          {
            text: "Donor Bad Status",
            value: "status",
            align: "center",
            sortable: false,
          },
          {
            text: "AH Traffic",
            value: "ah_traffic",
            align: "center",
            sortable: false,
          },
          {
            text: "AH Traffic Regional",
            value: "ah_traffic_country",
            align: "center",
            sortable: false,
            icon: true,
          },
          {
            text: "Monthly Visits",
            value: "monthly_visits",
            align: "center",
            sortable: false,
          },
          {
            text: "SW Top Countries",
            value: "trafficbycountries",
            align: "left",
            sortable: false,
          },
          {
            text: "SW Category",
            value: "sw_category",
            align: "left",
            sortable: false,
          },
          {
            text: "SW Search",
            value: "sw_search",
            align: "center",
            sortable: false,
          },
          { text: "AH DR", value: "ah_dr", align: "center", sortable: false },
          {
            text: "Spam Index",
            value: "spam_score",
            align: "center",
            sortable: false,
          },
          {
            text: "Google Trust Reg",
            value: "google_trust_region",
            align: "center",
            sortable: false,
          },
          {
            text: "Relevant pages count",
            value: "google_index_niche",
            align: "center",
            sortable: false,
          },
          {
            text: "Date Add",
            value: "created_at",
            align: "center",
            sortable: false,
          },
          {
            text: "Last Posting",
            value: "ltt_last_posting",
            align: "center",
            sortable: false,
          },
          {
            text: "Last For Project",
            value: "last_for_project",
            align: "center",
            sortable: false,
          },
          {
            text: "AH Top Country",
            title: "AH Top Country",
            value: "ah_top_country",
            sortable: false,
          },
          {
            text: "AH Top Traffic",
            title: "ah_top_traffic",
            value: "ah_top_traffic",
            sortable: false,
          },
        ],
      },
      items: [],
      dates_menu: undefined,
      dates: [],
      tags: [],
      country_icons: icons["US"],
      last_posting_menu: false,
      filter: {
        show: [],
        tags: ["null"],
        list: [],
        hide_list: [],
        link_type: [],
        ah_exclude_countries_traffic: [],
        domain_status: [0, 1],
        domains_to_check: false,
        is_checked: undefined,
        is_not_checked: undefined,
        is_approved: undefined,
        is_not_approved: undefined,
        url_id: undefined,
        last_updater: undefined,
        country: 1,
        domain_lang: undefined,
        hide_disavowed_links: 1,
        is_link_ltt_company: undefined,
        percent_live_link_ltt_company: undefined,
        country_percent: undefined,
        country_percent_operator: ">=",
        sw_rank_country_min: undefined,
        sw_rank_country_operator_min: ">=",
        sw_rank_country_max: undefined,
        sw_rank_country_operator_max: "<=",
        ah_traffic_by_country_min: undefined,
        ah_traffic_by_country_operator_min: ">=",
        ah_traffic_by_country_max: undefined,
        ah_traffic_by_country_operator_max: "<=",
        ah_traffic_min: undefined,
        ah_traffic_operator_min: ">=",
        ah_traffic_max: undefined,
        ah_traffic_operator_max: "<=",
        domain_zones: "",
        domain_zones_operator: "not",
        alexa_rank: undefined,
        alexa_rank_operator: ">=",
        follow: undefined,
        index: undefined,
        moderation_gp: undefined,
        tr_include_topics: undefined,
        domains_search: undefined,
        tr_percent: undefined,
        tr_entry: false,
        tr_percent_operator: ">=",
        date_added: [null, null],
        sw_rank: undefined,
        sw_rank_operator: ">=",
        sw_exclude_traffic: undefined,
        sw_include_in_keywords: undefined,
        sw_traffic_by_country: undefined,
        ah_dr: undefined,
        ah_dr_operator: ">=",
        favorite: undefined,
        favorite_operator: ">=",
        blacklist: undefined,
        blacklist_operator: ">=",
        score: undefined,
        score_operator: ">=",
        spam_score: undefined,
        ah_top_traffic_country_min: undefined,
        ah_top_traffic_country_max: undefined,
        ah_top_traffic_country_operator_min: ">=",
        ah_top_traffic_country_operator_max: "<=",
        spam_score_operator: ">=",
        price_operator_min: ">=",
        price_operator_max: "<=",
        price_min: undefined,
        price_max: undefined,
        show_donor_with_price: false,
        ref_domains_linked_root_domains_max: undefined,
        ref_domains_linked_root_domains_operator_max: "<=",
        ref_domains_linked_root_domains_min: undefined,
        ref_domains_linked_root_domains_operator_min: ">=",
        last_posting: undefined,
        last_posting_operator: ">=",
      },
      zones_items: [
        {
          text: "not",
          value: "not",
        },
        {
          text: "in",
          value: "in",
        },
      ],
      number_operators_items: [
        {
          text: ">=",
          value: ">=",
        },
        {
          text: "<=",
          value: "<=",
        },
      ],
      status_items: [
        {
          text: "unknown",
          value: 0,
          color: "warning",
        },
        {
          text: "working",
          value: 1,
          color: "success",
        },
        {
          text: "doesn't work",
          value: 2,
          color: "error",
        },
      ],
      urls_topic: [],
      domain_sellers: [],
      domain_prices: [],
      moderation_gp_items: [
        {
          text: "Ok",
          value: 1,
        },
        {
          text: "Not accept Guest Post",
          value: 2,
        },
        {
          text: "Not accept Essay/Casino",
          value: 3,
        },
        {
          text: "No answer",
          value: 4,
        },
      ],
      follow_items: [
        {
          text: "dofollow",
          value: 0,
        },
        {
          text: "nofollow",
          value: 1,
        },
      ],
      index_items: [
        {
          text: "index",
          value: 0,
        },
        {
          text: "noindex",
          value: 1,
        },
      ],
      show_items: [
        {
          text: "Used",
          value: 1,
        },
        {
          text: "Planned",
          value: 2,
        },
        { value: "null", text: "Blank" },
      ],
      language_items: [
        {
          id: 1,
          name: "none",
        },
        {
          id: 2,
          name: "ENGLISH",
        },
        {
          id: 3,
          name: "UNKNOWN",
        },
        {
          id: 4,
          name: "PERSIAN",
        },
        {
          id: 5,
          name: "RUSSIAN",
        },
        {
          id: 6,
          name: "JAPANESE",
        },
        {
          id: 7,
          name: "CHINESE",
        },
        {
          id: 8,
          name: "ARABIC",
        },
        {
          id: 9,
          name: "POLISH",
        },
        {
          id: 10,
          name: "GERMAN",
        },
        {
          id: 11,
          name: "INDONESIAN",
        },
        {
          id: 12,
          name: "MALAY",
        },
        {
          id: 13,
          name: "CHINESET",
        },
        {
          id: 14,
          name: "KOREAN",
        },
        {
          id: 15,
          name: "PORTUGUESE",
        },
        {
          id: 16,
          name: "DUTCH",
        },
        {
          id: 17,
          name: "SPANISH",
        },
        {
          id: 18,
          name: "VIETNAMESE",
        },
        {
          id: 19,
          name: "ITALIAN",
        },
        {
          id: 20,
          name: "SERBIAN",
        },
        {
          id: 21,
          name: "FRENCH",
        },
        {
          id: 22,
          name: "ESTONIAN",
        },
        {
          id: 23,
          name: "GREEK",
        },
        {
          id: 24,
          name: "SWEDISH",
        },
        {
          id: 25,
          name: "UKRAINIAN",
        },
        {
          id: 26,
          name: "TURKISH",
        },
        {
          id: 27,
          name: "TG_UNKNOWN_LANGUAGE",
        },
        {
          id: 28,
          name: "THAI",
        },
        {
          id: 57,
          name: "HEBREW",
        },
        {
          id: 58,
          name: "NORWEGIAN",
        },
        {
          id: 94,
          name: "DANISH",
        },
        {
          id: 95,
          name: "CATALAN",
        },
        {
          id: 112,
          name: "LATVIAN",
        },
        {
          id: 113,
          name: "CZECH",
        },
        {
          id: 133,
          name: "SLOVENIAN",
        },
        {
          id: 134,
          name: "HUNGARIAN",
        },
        {
          id: 135,
          name: "LITHUANIAN",
        },
        {
          id: 136,
          name: "BELARUSIAN",
        },
        {
          id: 137,
          name: "BULGARIAN",
        },
        {
          id: 138,
          name: "TAGALOG",
        },
        {
          id: 181,
          name: "CROATIAN",
        },
        {
          id: 182,
          name: "GEORGIAN",
        },
        {
          id: 203,
          name: "HINDI",
        },
        {
          id: 204,
          name: "ALBANIAN",
        },
        {
          id: 205,
          name: "FINNISH",
        },
        {
          id: 263,
          name: "SLOVAK",
        },
        {
          id: 287,
          name: "BENGALI",
        },
        {
          id: 312,
          name: "AZERBAIJANI",
        },
        {
          id: 351,
          name: "TELUGU",
        },
        {
          id: 404,
          name: "ROMANIAN",
        },
        {
          id: 430,
          name: "KANNADA",
        },
        {
          id: 476,
          name: "BASQUE",
        },
        {
          id: 502,
          name: "MACEDONIAN",
        },
        {
          id: 534,
          name: "WELSH",
        },
        {
          id: 535,
          name: "YIDDISH",
        },
        {
          id: 536,
          name: "ICELANDIC",
        },
        {
          id: 562,
          name: "SWAHILI",
        },
        {
          id: 643,
          name: "ARMENIAN",
        },
        {
          id: 644,
          name: "IRISH",
        },
        {
          id: 670,
          name: "GALICIAN",
        },
        {
          id: 689,
          name: "BURMESE",
        },
        {
          id: 803,
          name: "AMHARIC",
        },
        {
          id: 925,
          name: "KHMER",
        },
        {
          id: 1321,
          name: "MALAYALAM",
        },
        {
          id: 1544,
          name: "MALTESE",
        },
        {
          id: 2150,
          name: "HAITIAN_CREOLE",
        },
        {
          id: 2204,
          name: "URDU",
        },
        {
          id: 2205,
          name: "TAMIL",
        },
        {
          id: 3188,
          name: "AFRIKAANS",
        },
        {
          id: 3216,
          name: "SINHALESE",
        },
        {
          id: 4484,
          name: "PUNJABI",
        },
        {
          id: 5987,
          name: "GUJARATI",
        },
        {
          id: 9272,
          name: "DHIVEHI",
        },
        {
          id: 13573,
          name: "LAOTHIAN",
        },
        {
          id: 20637,
          name: "TIBETAN",
        },
        {
          id: 24078,
          name: "ORIYA",
        },
        {
          id: 37297,
          name: "MONGOLIAN",
        },
        {
          id: 58822,
          name: "INUKTITUT",
        },
      ],
      dialog_list_examples: false,
      list_examples: [],
      filtersData: {
        price_types: [],
        users: [],
      },
      loadings: {
        data: false,
        export: false,
        filters: false,
        uploadNewListItem: false,
        createCustomFilter: false,
        getCustomFilters: false,
      },
    };
  },
  computed: {
    computedSearchItems() {
      if (!this.search) return this.items;

      return this.items.filter((item) =>
        JSON.stringify(item)
          .toLowerCase()
          .includes(String(this.search).toLowerCase())
      );
    },
    tagItems() {
      return this.filter.link_type.indexOf(6) !== -1
        ? this.tags[6]
        : this.tags[""];
    },
    listItems() {
      return this.list;
    },
    isListsSelected() {
      return this.selected_lists.length !== 0;
    },
    isDomainsSelected() {
      return this.items_selected.length !== 0;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    get_headers() {
      return this.filter.link_type.indexOf(6) !== -1
        ? this.headers[6]
        : this.headers[""];
    },
    date_range_text() {
      return this.dates.join(" ~ ");
    },
    countries_items() {
      return this.$store.state.reuse.countries;
    },
    linkTypesItems() {
      return this.$store.state.reuse.link_types;
    },
  },
  async mounted() {
    await this.parseQuery("filter");

    if (!this.id_project) this.$store.dispatch("toggle_select_project_menu");

    this.getCustomFilter();
    this.fetch_tags();
    this.fetchLists();
    this.fetch_urls_topic();
    this.fetchFilters();

    if (this.linkTypesItems.length === 0) {
      this.$store.dispatch("fetch_link_types_list");
    }

    this.fetch_anchors_plans();
    this.fetch_anchors_plan_tasks();

    this.$store.dispatch("fetch_countries_list");
  },
  methods: {
    processSearchSection(arrayOfKeyphrases) {
      if (!arrayOfKeyphrases) return false;

      if (!this.searchSection) return true;

      let match = false;

      for (const keyphrase of arrayOfKeyphrases) {
        if (match) break;

        match = String(keyphrase)
          .toLowerCase()
          .includes(String(this.searchSection).toLowerCase());
      }

      return match;
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["price_types", "users"];
      const payload = {
        type: " /donor-base/clean",
        take: filters,
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        this.filtersData = resp;
      }

      this.loadings.filters = false;
    },
    handleCopyLink() {
      const path =
        window.location.origin +
        window.location.pathname +
        this._$buildQuery(this.filter);
      copy(path);
      this.$message.notification({
        title: "Copied",
        text: "Link with filters successfully copied.",
        type: "success",
      });
    },
    async handleAddToListNewCustomFilter() {
      try {
        this.loadings.createCustomFilter = true;

        const url = `/donor-base/clean/${this.id_project}/set-custom-filter/save`;

        const payload = {
          name: this.custom_filter_name,
          data: this.filter,
        };

        await service.post(url, payload);

        this.getCustomFilter();

        this.loadings.createCustomFilter = false;
        this.customFilterMenu = false;
        this.$message.notification({
          title: "Export created",
          text: "New custom filter successfully added to the list.",
          type: "success",
        });
      } catch {
        this.loadings.createCustomFilter = false;
        this.customFilterMenu = false;
      }
    },
    handleChangeVerify(data) {
      const verify = data.verify ? 1 : 0;

      const url = `/donor-base/clean/${data.id}/verify`;

      const payload = {
        verify,
      };

      service.post(url, payload);
    },
    getSliced(item, from, to) {
      try {
        if (Array.isArray(item) && item.length > 0) {
          return item.slice(from, to);
        }
        return item;
      } catch (e) {
        console.error(e);
        return item;
      }
    },
    viewWasConnected(item) {
      let self = this;

      self.connected_entity_items = [];

      let url = `${self.$store.state.server_url}/gpm/task-view/get-connected-entity`;

      let config = {
        params: {
          domainID: item.donor_domain_id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      axios
        .get(url, config)
        .then((r) => {
          self.connected_entity_items = r.data.connected_entity;
          self.connected_dialog = true;
        })
        .catch((error) => {
          eventPipe.$emit("error", {
            type: "error",
            title: "Something wrong... 🤕",
            text: error.message,
            duration: 6000,
          });
        });
    },
    handleOutreachedContact(item) {
      this.connected_dialog = true;
      this.viewWasConnected(item);
    },
    getUrl(item) {
      if (!item.url) return "";

      if (item.url.includes("example.com")) {
        return "http://" + item.domain;
      } else {
        return item.url;
      }
    },
    getExamples(item) {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let post_data = {
        domainID: item.donor_domain_id,
        typeID: item.type_id,
      };

      let url = `${this.$store.state.server_url}/donor-base/clean/${this.id_project}/get-examples`;

      axios.post(url, post_data, config).then((r) => {
        this.dialog_list_examples = true;
        this.list_examples = r.data.items;
      });
    },
    load_price(item) {
      this.dialog_price = true;

      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = {
        domain: item.donor_domain_id,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/guest-post/${self.id_project}/get-domain-prices`,
          data,
          config
        )
        .then((r) => {
          self.domain_prices = r.data;
        });
    },
    load_sellers(item) {
      this.dialog_sellers = true;
      this.domain_sellers = item.sellers;
    },
    initComments(item) {
      this.comments.domain = item.donor_domain_id;
      this.comments.type = item.type_id;
      this.dialog_comments = true;
    },
    initTagsHistory(item) {
      this.comments.domain = item.donor_domain_id;
      this.comments.type = item.type_id;
      this.dialog_tags = true;
    },
    addDomainToBlacklist() {
      let self = this;

      self.dialog_blacklist_loading = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let post_data = {
        domains: [self.dialog_blacklist_id],
        lists: [1],
        for: self.dialog_blacklist_for,
        types: self.dialog_blacklist_types,
        tags: self.dialog_blacklist_tags,
      };

      let url = `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/add-domains-to-lists`;

      axios
        .post(url, post_data, config)
        .then(() => {
          self.dialog_add_to_list = false;
          self.list_error = null;
          self.items = self.items.filter(
            (x) => x.id !== self.dialog_blacklist_id
          );
          self.dialog_blacklist = false;
          self.dialog_blacklist_loading = false;
        })
        .catch(() => {
          self.list_error = "Some error";
          this.$message.notification({
            title: "Something wronk",
            text: `Try again.`,
            type: "error",
          });
        });
    },
    toBlacklist(item) {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let post_data = {
        domains: [item.donor_domain_id],
        lists: [1],
      };

      let url = `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/add-domains-to-lists`;

      axios
        .post(url, post_data, config)
        .then(() => {
          self.dialog_add_to_list = false;
          self.list_error = null;
          self.items = self.items.filter((x) => x.id !== item.id);
        })
        .catch(() => {
          self.list_error = "Some error";
          this.$message.notification({
            title: "Something wronk",
            text: `Try again.`,
            type: "error",
          });
        });
    },
    importLists() {
      let self = this;

      if (!this.import_lists.data) return;

      let rows = String(this.import_lists.data).split("\n");

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      self.import_lists.loading = true;
      self.import_lists.message = null;

      let data = {
        rows: rows,
        type: self.import_lists.type,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/import-lists`,
          data,
          config
        )
        .then((r) => {
          self.import_lists.message = r.data.message;
        })
        .catch(() => {
          this.$message.notification({
            title: "Something wronk",
            text: `Try again.`,
            type: "error",
          });
        })
        .finally(() => (self.import_lists.loading = false));
    },
    updateList() {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          id: self.selected_list,
          name: self.new_list_name,
        },
      };

      this.loadings.uploadNewListItem = true;

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/update-list`,
          {},
          config
        )
        .then(() => {
          self.fetchLists();
          self.selected_list = null;
          self.new_list_name = null;
        })
        .catch(() => {
          this.$message.notification({
            title: "Something wronk",
            text: `Try again.`,
            type: "error",
          });
        })
        .finally(() => {
          this.loadings.uploadNewListItem = false;
        });
    },
    createList() {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          name: self.new_list_name,
        },
      };

      this.loadings.uploadNewListItem = true;

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/create-list`,
          {},
          config
        )
        .then(() => {
          self.fetchLists();
        })
        .catch(() => {
          this.$message.notification({
            title: "Something wronk",
            text: `Try again.`,
            type: "error",
          });
        })
        .finally(() => {
          this.loadings.uploadNewListItem = false;
        });
    },
    setCustomFilterName() {
      this.custom_filter_dialog = true;
    },
    usedCustomFilter() {
      this.filter = JSON.parse(
        this.custom_filters.find((x) => x.id === this.custom_filter).data
      );
    },
    setCustomFilter() {
      let self = this;

      self.custom_filter_dialog = false;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let post_data = {
        name: self.custom_filter_name,
        data: self.filter,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/set-custom-filter/save`,
          post_data,
          config
        )
        .then(() => {
          self.getCustomFilter();
        })
        .catch(() => {
          this.$message.notification({
            title: "Something wronk",
            text: `Try again.`,
            type: "error",
          });
        });
    },
    getCustomFilter() {
      let self = this;

      this.loadings.getCustomFilters = true;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/get-custom-filter`,
          {},
          config
        )
        .then((response) => {
          this.custom_filters = response.data;
        })
        .finally(() => {
          this.loadings.getCustomFilters = false;
        });
    },
    export_data() {
      let url = `${this.$store.state.server_url}/export/base/create-task`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      const payload = {
        typeID: 17,
        json: this.filter,
        projectId: this.id_project,
      };

      this.loadings.export = true;

      axios
        .post(url, payload, config)
        .then((response) => {
          if (response.data.success) {
            this.dialog_export = false;
            this.$message.notification({
              title: "Success",
              text: "Successfully added to the upload queue.",
              type: "success",
              duration: 6000,
            });
          }
        })
        .finally(() => {
          this.loadings.export = false;
        });
    },
    fetchLists() {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .get(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/get-lists`,
          config
        )
        .then((r) => {
          self.list = r.data.items;
        });
    },
    addDomainsToLists() {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let domains = [];

      self.items_selected.forEach((item) => {
        domains.push(item.donor_domain_id);
      });

      let post_data = {
        domains: domains,
        lists: self.selected_lists,
      };

      let url = `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/add-domains-to-lists`;

      axios
        .post(url, post_data, config)
        .then(() => {
          self.dialog_add_to_list = false;
          self.list_error = null;
        })
        .catch(() => {
          self.list_error = "Some error";
          this.$message.notification({
            title: "Something wronk",
            text: `Try again.`,
            type: "error",
          });
        });
    },
    add_domains_to_ap() {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      let data = [];

      self.items_selected.forEach((item) => {
        //  if (self.anchor_plans_selected[item.donor_domain_id] !== undefined) {
        data.push({
          id_anchor_plan: self.anchor_plans_selected[item.donor_domain_id],
          id_url: item.url_id,
          id_link_type: item.type_id,
          id_domain: item.donor_domain_id,
        });
        //   }
      });

      let post_data = {
        items: data,
        id_task: self.anchor_plan_task,
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/add-domains-to-ap`,
          post_data,
          config
        )
        .finally(() => (self.dialog_add_to_plan = false));
    },
    get_status_data(status) {
      return this.status_items.filter((v) => v.value === status)[0];
    },
    get_date(last_posting_in_days, date) {
      if (date === null) {
        return null;
      }

      if (date === "0") {
        return "<span>-</span>";
      }

      let days = last_posting_in_days;
      let months = Math.round(days / 30);
      let years = Math.round(days / 365);

      let time_ago = days ? `${days} d` : "";
      let color = "green";

      if (years > 0) {
        time_ago = `${years} y`;
        color = "red";
      } else if (months > 0) {
        time_ago = `${months} m`;
        color = "#ffcc00";
      }
      return '<span style="color: ' + color + '">' + time_ago + "</span>";
    },
    is_dark() {
      return this.$vuetify.theme.dark;
    },
    get_color(is_index, is_link, all) {
      let percent_all = is_link / all;
      let percent = is_index / is_link;

      let color = ""; //'#9ACD32'
      if (percent < 0.2 || percent_all < 0.2) {
        color = "red";
      } else if (percent < 0.5 || percent_all < 0.5) {
        color = this.is_dark() ? "#d6b701" : "#ffcc00";
      }

      return "color:" + color;
    },
    fetch_anchors_plans() {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          filter: self.filter,
        },
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/anchor-plans`,
          {},
          config
        )
        .then((r) => {
          self.items_anchor_plans = r.data.items;
          self.get_project_country_icon();
        });
    },
    save_data_domains(item) {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/tag/save`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          domain: item.donor_domain_id,
          type: item.type_id,
          tags: item.tag,
        };

        axios
          .post(url, data, config)
          .then(function () {})
          .catch(function (error) {
            alert(error);
          });
      }
    },
    save_link_type(item) {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/type/save`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          domain: item.donor_domain_id,
          type: item.type_id,
          new_type: item.new_type,
        };

        axios
          .post(url, data, config)
          .then(function () {})
          .catch(function (error) {
            alert(error);
          });
      }
    },

    save_google_index_query(item) {
      let self = this;
      if (self.id_project !== null && self.id_project !== undefined) {
        let url = `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/google-index-query/save`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        let data = {
          domain: item.donor_domain_id,
          query: item.google_index_query,
        };

        axios
          .post(url, data, config)
          .then(function () {})
          .catch(function (error) {
            alert(error);
          });
      }
    },
    fetch_tags() {
      let self = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/file/tags`,
          {},
          config
        )
        .then((r) => {
          self.tags = r.data;

          self.tags[6].unshift({ id: "null", name: "Without bad status" });
          self.tags[""].unshift({ id: "null", name: "Without bad status" });
        });
    },
    fetch_urls_topic() {
      let self = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/file/urls_topic`,
          {},
          config
        )
        .then((r) => {
          self.urls_topic = r.data;
        });
    },
    fetch_anchors_plan_tasks() {
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          filter: self.filter,
        },
      };

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}/anchor-plan-tasks`,
          {},
          config
        )
        .then((r) => {
          self.items_anchor_plan_tasks = r.data.items;
        });
    },
    fetch_data() {
      this.dialog = false;
      let self = this;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: self.$store.state.auth.token,
        },
        params: {
          filter: self.filter,
        },
      };

      // self.items = [];

      this.loadings.data = true;

      this.isFetchBlocked = true;

      axios
        .post(
          `${self.$store.state.server_url}/donor-base/clean/${self.id_project}`,
          {
            options: this.options,
          },
          config
        )
        .then((r) => {
          self.items_selected = [];
          self.items = r.data.items.map((v) => {
            v.new_type = v.type;
            return v;
          });
          self.total_items = Number(r.data.total_items);
        })
        .finally(() => {
          this.dialog = false;
          this.loadings.data = false;
          this.isFetchBlocked = false;
          this.isAlreadyFetched = true;

          this.$watch("options", {
            handler() {
              if (this.isFetchBlocked) return;

              this.fetch_data();
            },
            deep: true,
          });
        });
    },
    get_project_country_icon() {
      if (this.$store.state.project.allowed_projects.length !== 0) {
        let project = this.$store.state.project.allowed_projects.filter(
          (v) => v.id === parseInt(this.$store.state.project.active_project)
        )[0];

        if (!project) return 0;

        this.country_icons = icons[project.country];
        this.filter.country = project.country_id;
      }
    },
  },
};
</script>

<style lang="scss">
.clean-dahsboard {
  .v-data-table {
    table {
      tbody {
        tr {
          position: relative;

          td {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              position: sticky;
              z-index: 2;
              left: 0;
              //top: 50%;
              //transform: translateY(-50%);
              background-color: #fff;
            }

            &:nth-child(2) {
              left: 64px;
            }

            &:nth-child(3) {
              left: 282px;
              border-right: 1px solid rgba(155, 155, 155, 0.4);
            }
          }
        }
      }
    }
  }
}

.v-application.theme--dark {
  .clean-dahsboard {
    .v-data-table {
      table {
        tbody {
          tr {
            td {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                background-color: #1e1e1e;
              }
            }
          }
        }
      }
    }
  }
}
</style>
