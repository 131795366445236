<template>
  <v-dialog
    v-model="dialog"
    scrollable
    width="920"
    content-class="my-shadow--e2"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Edit status "<u>{{ formData?._ctx?.name }}</u
        >"
        <v-icon small right>mdi-pencil</v-icon>
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <status-form
          v-model="formData"
          :validation="validationResult"
          ref="statusFormRef"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          large
          class="px-6 text-normal"
          color="success"
          :loading="loadings.confirm"
          @click="handleConfirm"
        >
          Save status changes <v-icon small right>mdi-pencil</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";
import Notification from "../../../../mixins/Notification";
import StatusForm from "./StatusForm.vue";
import { StatusManagerApiService } from "../services/StatusManagerApiService";
import ApiErrorHandlingMixin from "../../../../mixins/ApiErrorHandlingMixin";

export default {
  components: { StatusForm },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  mixins: [Dialog, ApiErrorHandlingMixin, Notification],

  watch: {
    dialog(isVisible) {
      if (!isVisible) this.$refs.statusFormRef?.resetChanges();
    },
    data: {
      handler(value) {
        if (value) this.formData = value;
      },
      deep: true,
      immediate: true,
    },
  },

  data: () => ({
    formData: {},
    loadings: {
      confirm: false,
    },
  }),

  methods: {
    resetForm() {
      this.$set(this, "formData", {});
    },

    recheckItemAdapter(item) {
      return {
        page_status_id: item.status,
        performer: item.performer,
        button_text: item.button_text,
      };
    },
    approveItemAdapter(item) {
      return {
        page_status_id: item.status,
        performer: item.performer,
        button_text: item.button_text,
      };
    },
    fieldItemAdapter(item) {
      return {
        field_id: item.field,
        order: item.order,
        is_required: item.is_required,
        position: item.position,
      };
    },
    getEditStatusPayload(formData) {
      // Адаптер данных, которые я отправляю на бекенд

      const approve_statuses = { ...formData.approves };
      const recheck_statuses = { ...formData.rechecks };

      if (Object.keys(recheck_statuses).length) {
        const entries = Object.entries(recheck_statuses);

        entries.forEach(([key, value]) => {
          if (Array.isArray(value))
            return (recheck_statuses[key] = value.map((item) =>
              this.recheckItemAdapter(item)
            ));
        });
      }

      if (Object.keys(approve_statuses).length) {
        const entries = Object.entries(approve_statuses);

        entries.forEach(([key, value]) => {
          if (Array.isArray(value))
            return (approve_statuses[key] = value.map((item) =>
              this.approveItemAdapter(item)
            ));
        });
      }

      return {
        approve_statuses,
        recheck_statuses,
        fields:
          formData?.fields?.map((field) => this.fieldItemAdapter(field)) || [],
      };
    },
    async handleConfirm() {
      try {
        this.loadings.confirm = true;

        const payload = this.getEditStatusPayload(this.formData);

        await StatusManagerApiService().editStatus(this.data.id, payload);
        this.resetForm();
        this.dialog = false;
        this.mSuccess({
          title: "Done",
          text: "Step updated successfully",
        });
      } catch (e) {
        console.error("Error while editing status.", e);
        this.handleApiError(e);
      } finally {
        this.loadings.confirm = false;
      }
    },
  },
};
</script>
