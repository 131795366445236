<template>
  <div class="conversion-2">
    <v-row dense>
      <v-col cols="12">
        <page-header
          title="Conversion 2"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'Conversion 2', disabled: true },
          ]"
        >
        </page-header>
      </v-col>
    </v-row>
    <template v-if="id_project">
      <v-row dense>
        <v-col cols="2">
          <smart-date-filter
            :model-value="filter.date_range"
            :disabled="computedIsComparisonFilterValueExist"
            @update:modelValue="filter.date_range = $event"
          />

          <small
            v-if="computedIsComparisonFilterValueExist"
            class="warning--text"
          >
            When comparison filter enabled - date can't be used.
          </small>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            v-model="filter.subproject_id"
            :items="subprojects"
            label="Subprojects"
            outlined
            item-text="name"
            item-value="id"
            clearable
            hide-details
            dense
            :menu-props="menuProps"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <comparison-filter
            :model-value="filter.comparison"
            @update:modelValue="filter.comparison = $event"
          />
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            @click="fetch_data"
            height="40"
            width="180"
            class="text-normal"
            color="primary"
            :loading="loadings.table"
          >
            Get Data
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card flat outlined rounded="lg">
            <v-card-title class="pa-4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
                :disabled="!table.items.length"
              ></v-text-field>
            </v-card-title>
            <v-divider />
            <v-card-text class="px-0">
              <template v-if="firstEnter && !loadings.table">
                <div class="px-4">Select filters and press "get data"</div>
              </template>
              <template v-else-if="!firstEnter">
                <v-data-table
                  :headers="
                    doubledTable ? table.comparisonHeaders : table.headers
                  "
                  :loading="loadings.table"
                  :items="table.items"
                  :search="search"
                  :sort-by="['visits']"
                  :sort-desc="[true]"
                  :footer-props="{ itemsPerPageOptions: [50, -1] }"
                  :items-per-page="50"
                  class="fixed-table-sw-d"
                  dense
                >
                  <template v-slot:body="{ items }">
                    <tbody v-if="doubledTable">
                      <tr v-for="item in items" :key="item.domain">
                        <td style="white-space: nowrap">
                          <span
                            ><a
                              :href="getReferralUrl(null, item.url)"
                              style="color: #000000"
                              target="_blank"
                              >{{ item.url }}</a
                            ></span
                          >
                          <span
                            ><a target="_blank" :href="item.url_full"
                              ><v-icon small>mdi-open-in-new</v-icon></a
                            ></span
                          >
                        </td>
                        <td class="text-right">
                          {{ item.clicks_gsc1 }}
                        </td>
                        <td class="text-right">
                          {{ item.clicks_gsc2 }}
                        </td>
                        <td class="text-right">
                          {{ item.clicks1 }}
                        </td>
                        <td class="text-right">
                          {{ item.clicks2 }}
                        </td>
                        <td
                          class="text-right"
                          :style="'background-color:' + item.c_c_color1"
                        >
                          {{ item.c_c1 }}%
                        </td>
                        <td
                          class="text-right"
                          :style="'background-color:' + item.c_c_color2"
                        >
                          {{ item.c_c2 }}%
                        </td>
                        <td class="text-right">
                          {{ item.clicks_unique1 }}
                        </td>
                        <td class="text-right">
                          {{ item.clicks_unique2 }}
                        </td>
                        <td
                          class="text-right"
                          :style="
                            'background-color:' + item.c_unique_c_gsc_color1
                          "
                        >
                          {{ item.c_unique_c_gsc1 }}%
                        </td>
                        <td
                          class="text-right"
                          :style="
                            'background-color:' + item.c_unique_c_gsc_color2
                          "
                        >
                          {{ item.c_unique_c_gsc2 }}%
                        </td>
                        <td class="text-right">
                          {{ item.lead1 }}
                        </td>
                        <td class="text-right">
                          {{ item.lead2 }}
                        </td>
                        <td class="text-right">
                          {{ item.sale1 }}
                        </td>
                        <td class="text-right">
                          {{ item.sale2 }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr v-for="item in items" :key="item.domain">
                        <td style="white-space: nowrap">
                          <span
                            ><a
                              :href="getReferralUrl(null, item.url)"
                              style="color: #000000"
                              target="_blank"
                              >{{ item.url }}</a
                            ></span
                          >
                          <span
                            ><a target="_blank" :href="item.url_full"
                              ><v-icon small>mdi-open-in-new</v-icon></a
                            ></span
                          >
                        </td>
                        <td class="text-right">
                          {{ item.clicks_gsc }}
                        </td>
                        <td class="text-right">
                          {{ item.clicks }}
                        </td>
                        <td
                          class="text-right"
                          :style="'background-color:' + item.c_c_color"
                        >
                          {{ item.c_c }}%
                        </td>
                        <td class="text-right">
                          {{ item.clicks_unique }}
                        </td>
                        <td
                          class="text-right"
                          :style="
                            'background-color:' + item.c_unique_c_gsc_color
                          "
                        >
                          {{ item.c_unique_c_gsc }}%
                        </td>
                        <td class="text-right">
                          {{ item.lead }}
                        </td>
                        <td class="text-right">
                          {{ item.sale }}
                        </td>
                        <td>
                          {{ item.recommend }}
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('main_table', item.url)"
                            style="color: #000000"
                            target="_blank"
                            ><span v-if="item.main_table > 0">{{
                              item.main_table
                            }}</span></a
                          >
                        </td>
                        <td
                          class="text-right"
                          :style="
                            'background-color:' + item.main_table_clicks_color
                          "
                        >
                          <a
                            :href="getReferralUrl('main_table', item.url)"
                            style="color: #000000"
                            target="_blank"
                            ><span v-if="item.main_table_clicks > 0">
                              {{ item.main_table_clicks }}%</span
                            ></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('casino_review', item.url)"
                            target="_blank"
                            style="color: #000000"
                          >
                            <span v-if="item.casino_review > 0">{{
                              item.casino_review
                            }}</span></a
                          >
                        </td>
                        <td
                          :style="
                            'background-color:' +
                            item.casino_review_clicks_color
                          "
                        >
                          <a
                            :href="getReferralUrl('casino_review', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.casino_review_clicks > 0"
                              >{{ item.casino_review_clicks }}%</span
                            ></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('top3_table', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.top3_table > 0">{{
                              item.top3_table
                            }}</span></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('top3_table', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.top3_table_clicks > 0"
                              >{{ item.top3_table_clicks }}%</span
                            ></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('bonus_table', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.bonus_table > 0">{{
                              item.bonus_table
                            }}</span></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('bonus_table', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.bonus_table_clicks > 0"
                              >{{ item.bonus_table_clicks }}%</span
                            ></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('sidebar', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.sidebar > 0">{{
                              item.sidebar
                            }}</span></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('sidebar', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.sidebar_clicks > 0"
                              >{{ item.sidebar_clicks }}%</span
                            ></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="
                              getReferralUrl('bonus_sidebar_logo', item.url)
                            "
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.bonus_sidebar_logo > 0">{{
                              item.bonus_sidebar_logo
                            }}</span></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="
                              getReferralUrl('bonus_sidebar_logo', item.url)
                            "
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.bonus_sidebar_logo_clicks > 0"
                              >{{ item.bonus_sidebar_logo_clicks }}%</span
                            ></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="
                              getReferralUrl('related_casino_play', item.url)
                            "
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.related_casino_play > 0">{{
                              item.related_casino_play
                            }}</span></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="
                              getReferralUrl('related_casino_play', item.url)
                            "
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.related_casino_play_clicks > 0"
                              >{{ item.related_casino_play_clicks }}%</span
                            ></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('bonus_poster', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.bonus_poster > 0">{{
                              item.bonus_poster
                            }}</span></a
                          >
                        </td>
                        <td class="text-right">
                          <a
                            :href="getReferralUrl('bonus_poster', item.url)"
                            target="_blank"
                            style="color: #000000"
                            ><span v-if="item.bonus_poster_clicks > 0"
                              >{{ item.bonus_poster_clicks }}%</span
                            ></a
                          >
                        </td>
                        <td class="text-right">
                          {{ item.bonus_header }}
                        </td>
                        <td class="text-right">
                          {{ item.alt_casino_table }}
                        </td>
                        <td class="text-right">
                          {{ item.alt_casino_table_clicks }}
                        </td>
                        <td class="text-right">
                          {{ item.bonus_header_clicks }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row dense>
        <v-col cols="12">
          <v-alert prominent type="info" text>
            <div>To use this tool - select a project</div>
            <div>
              <v-btn
                class="text-normal"
                @click="$store.dispatch('toggle_select_project_menu')"
                color="info"
              >
                Select project
              </v-btn>
            </div>
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import eventPipe from "../../../events/eventPipe";
import {
  CONVERSION_2_TABLE_COMPARISON_HEADERS,
  CONVERSION_2_TABLE_HEADERS,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import PageHeader from "../../UI/PageHeader.vue";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import ComparisonFilter from "../../UI/ComparisonFilter.vue";

let dateFormat = require("dateformat");

let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
let beforeOneWeek2 = new Date(beforeOneWeek);
let day = beforeOneWeek.getDay();
let diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
let lastMonday = new Date(beforeOneWeek.setDate(diffToMonday - 50));
let lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6));

export default {
  name: "Conversion2Index",
  data: () => ({
    search: "",
    loadings: {
      table: false,
    },
    date_modal: false,
    filter: {
      date_range: [
        dateFormat(lastMonday, "yyyy-mm-dd"),
        dateFormat(lastSunday, "yyyy-mm-dd"),
      ],
      country: false,
      subproject_id: undefined,
      comparison: [],
    },
    doubledTable: false,
    urls: [],
    countries: [],
    table: {
      headers: CONVERSION_2_TABLE_HEADERS,
      comparisonHeaders: CONVERSION_2_TABLE_COMPARISON_HEADERS,
      items: [],
    },
    firstEnter: true,
    menuProps: DEFAULT_MENU_PROPS,
    comparisonLabel: null,
  }),
  components: {
    SmartDateFilter,
    PageHeader,
    ComparisonFilter,
  },
  methods: {
    async fetch_data() {
      try {
        if (this.id_project === null || this.id_project === undefined) {
          return this.$store.dispatch("toggle_select_project_menu");
        }

        if (this.filter.comparison.length) {
          this.doubledTable = true;
        } else {
          this.doubledTable = false;
        }

        this.firstEnter = false;
        this.loadings.table = true;

        let data = {
          payload: {
            filter: this.filter,
          },
          projectId: this.id_project,
        };

        const resp = await this.$store.dispatch(
          "conversion-2/fetchTableData",
          data
        );
        if (resp) {
          this.table.items = resp;
          this.$forceUpdate();
        }

        this.loadings.table = false;
      } catch (e) {
        this.loadings.table = false;
        this.doubledTable = false;
      }
    },
    getReferralUrl(table, url) {
      let r =
        "/analytics/conversion3/report?date_range=" +
        this.filter.date_range +
        "&url=" +
        url;
      if (table) {
        r += "&table=" + table;
      }
      return r;
    },
    update_setting() {
      this.$store.dispatch("update_ls_serp_setting", this.ls_settings);
    },
  },
  computed: {
    computedIsComparisonFilterValueExist() {
      return this.filter.comparison?.some((v) => !!v.length);
    },
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
    subprojects() {
      let project = this.$store.state.project.allowed_projects.filter(
        (v) => v.id === parseInt(this.$store.state.project.active_project)
      );
      if (project && project[0]) return project[0]["subprojects"];
      else return [];
    },
  },
  created() {
    eventPipe.$on("update-active-project", () => this.fetch_data);
  },
};
</script>

<style scoped>
.fixed-table-sw-d thead > tr > th:first-child,
.fixed-table-sw-d td:first-child {
  position: sticky;
  left: 0;
  min-width: 90px;
  z-index: 2;
}
</style>
