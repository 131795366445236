export const DATA_TABLE_MOCK = {
  HEADERS: [
    { text: "domain", value: "domain", align: "left", sortable: false },
    { text: "login", value: "login", align: "center", sortable: false },
    {
      text: "password",
      value: "password",
      align: "center",
      sortable: false,
    },
    { text: "address", value: "address", align: "center", sortable: false },
    {
      text: "Manual check",
      value: "manual_check_id",
      align: "center",
      sortable: true,
    },
    {
      text: "Statistics",
      value: "domain_use",
      align: "center",
      sortable: true,
    },
    { text: "GT", value: "google_trust", align: "center", sortable: true },
    { text: "GI", value: "google_index", align: "center", sortable: true },
    {
      text: "AH HP",
      value: "ah_html_pages",
      align: "center",
      sortable: true,
    },
    {
      text: "SW GR",
      value: "global_rank",
      align: "center",
      sortable: true,
    },
    { text: "SW", value: "rank", align: "center", sortable: true },
    { text: "SW", value: "percent", align: "center", sortable: true },
    { text: "AH DR", value: "ah_dr", align: "center", sortable: true },
    {
      text: "AH Rating",
      value: "ah_rating",
      align: "center",
      sortable: true,
    },
    {
      text: "AH RD",
      value: "ah_refdomains",
      align: "center",
      sortable: true,
    },
    {
      text: "AH LRD",
      value: "ah_linked_root_domains",
      align: "center",
      sortable: true,
    },
    {
      text: "AH RP",
      value: "ah_refpages",
      align: "center",
      sortable: true,
    },
    { text: "AH TR", value: "ah_traffic", align: "center", sortable: true },
    {
      text: "GI/AH P,%",
      value: "google_index_ahrefs_pages",
      align: "center",
      sortable: true,
    },
    {
      text: "C OP",
      value: "count_onpage",
      align: "center",
      sortable: true,
    },
    {
      text: "C ERR",
      value: "count_error",
      align: "center",
      sortable: true,
    },
    { text: "DS", value: "ds", align: "center", sortable: true },
    { text: "Lang", value: "lang", align: "center", sortable: true },
    {
      text: "GSB",
      value: "google_safebrowsing",
      align: "center",
      sortable: true,
    },
    {
      text: "GCM",
      value: "google_compromised",
      align: "center",
      sortable: true,
    },
    {
      text: "Post",
      value: "can_add_post",
      align: "center",
      sortable: true,
    },
    { text: "Theme", value: "edit_theme", align: "center", sortable: true },
    { text: "cms", value: "cms_actual", align: "center", sortable: true },
    { text: "id", value: "id", align: "center", sortable: true },
    {
      text: "dAdd",
      value: "dateAddToBase",
      align: "center",
      sortable: true,
    },
    { text: "dCh", value: "checkedDate", align: "center", sortable: true },
    {
      text: "Spam Score",
      value: "spam_score",
      align: "center",
      sortable: true,
    },
  ],
};
