<template>
  <div>
    <template v-if="loading">
      <div class="skeleton" style="height: 120px; width: 100%" />
    </template>
    <template v-else-if="formValue?.info_fields?.length">
      <v-row dense class="d-flex flex-column">
        <template v-for="(field, idx) in computedVisibleInfoFields">
          <v-col v-if="field" cols="12" :key="`field-${idx}`">
            <element-control-info-field-factory
              :field="field"
              :details="formValue?.fields"
              v-model="field.value"
            />
          </v-col>
          <v-divider
            v-if="idx !== computedVisibleInfoFields?.length - 1"
            :key="`field-divider-${idx}`"
          />
        </template>
      </v-row>
    </template>
    <template v-else
      ><div
        class="text-center d-flex flex-column align-center pa-4"
        style="height: 100%; opacity: 0.5"
      >
        <v-icon>mdi-border-none-variant</v-icon>
        <small> There are no info fields... </small>
      </div>
    </template>
    <div
      v-if="computedContainSomeNotRequiredItems"
      class="px-2 pb-2 text-center"
    >
      <v-btn
        class="text-normal"
        :color="isShowMoreVisible ? '' : 'transparent'"
        @click="isShowMoreVisible = !isShowMoreVisible"
      >
        {{ isShowMoreVisible ? "Show less" : "Show more" }}
        <v-icon small right>
          {{ isShowMoreVisible ? "mdi-chevron-down" : "mdi-chevron-up" }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ElementControlInfoFieldFactory from "./ElementControlInfoFieldFactory.vue";

export default {
  props: {
    formValue: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: { ElementControlInfoFieldFactory },

  data: () => ({
    isShowMoreVisible: false,
  }),

  computed: {
    computedContainSomeNotRequiredItems() {
      return this.formValue.info_fields.some(
        (item) => !item?.pivot?.is_required
      );
    },
    computedInfoFields() {
      return this.formValue.info_fields?.toSorted(
        (a, b) => a?.pivot?.order - b?.pivot?.order
      );
    },
    computedVisibleInfoFields() {
      if (this.isShowMoreVisible) {
        return this.computedInfoFields;
      }

      return this.computedInfoFields.filter((item) => item?.pivot?.is_required);
    },
  },
};
</script>
