export default {
  state: {
    filtersData: {},
    editValidationResult: {},
    createValidationResult: {},
  },
  mutations: {
    "bof/setFiltersData"(state, payload) {
      state.filtersData = payload;
    },
    "bof/setEditFreelancerValidationErrors"(state, payload) {
      state.editValidationResult = payload;
    },
    "bof/setCreateFreelancerValidationErrors"(state, payload) {
      state.createValidationResult = payload;
    },
  },
  actions: {},
  getters: {
    "bof/getFiltersData": (state) => state.filtersData,
    "bof/getEditValidationResult": (state) => state.editValidationResult,
    "bof/getCreateValidationResult": (state) => state.createValidationResult,
  },
};
