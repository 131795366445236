<template>
  <v-form ref="formRef">
    <v-row>
      <v-col cols="12" class="d-flex pt-2" style="gap: 0.5rem">
        <v-card flat outlined rounded="lg" width="100%">
          <v-card-title
            style="background: var(--card-darken-body-color)"
            class="pa-2"
          >
            <v-spacer />
            <div>
              <v-btn-toggle
                dense
                class="grouped-buttons"
                v-model="selectedDataSourceId"
              >
                <v-btn
                  v-for="preset in filtersData?.sw_presets || []"
                  :key="preset.value"
                  class="text-normal"
                  :value="preset.value"
                >
                  {{ preset.text }}
                  <v-tooltip
                    v-if="getTotalChangesAmountById(preset.value)"
                    bottom
                  >
                    <template #activator="{ on }">
                      <div v-on="on">
                        <v-badge
                          inline
                          dot
                          color="warning"
                          :content="getTotalChangesAmountById(preset.value)"
                        />
                      </div>
                    </template>
                    This group has unsaved changes
                  </v-tooltip>
                </v-btn>
              </v-btn-toggle>
              <div
                v-if="filtersData?.sw_presets?.length === 1"
                disabled
                style="height: 36px; opacity: 0.5"
                class="text-normal d-flex justify-center align-center"
              >
                No more groups...
              </div>
            </div>
            <v-spacer />
          </v-card-title>
          <v-card-text
            style="background: var(--card-darken-body-color)"
            class="pa-2 pt-0"
          >
            <v-row dense>
              <template v-if="selectedDataSourceId">
                <v-col cols="12" class="pt-2">
                  <v-card flat outlined rounded="lg">
                    <v-card-title class="px-4 py-2 text-body-2">
                      <b>Rechecks</b>
                      <my-badge
                        v-if="computedRechecks?.length"
                        class="grey white--text ml-1"
                      >
                        {{ computedRechecks.length }}
                      </my-badge>
                      <v-spacer />
                      <div class="d-flex" style="gap: 0.25rem">
                        <v-hover
                          v-if="
                            changes[selectedDataSourceId]?.rechecks &&
                            hasInitialValue(selectedDataSourceId, 'rechecks')
                          "
                          v-slot="{ hover }"
                        >
                          <v-btn
                            text
                            class="text-normal"
                            :icon="!hover"
                            @click="
                              handleResetChangesForFormByKey(
                                selectedDataSourceId,
                                'rechecks'
                              )
                            "
                          >
                            <span v-if="hover"> Reset changes </span>
                            <v-icon small class="ml-1">mdi-refresh</v-icon>
                          </v-btn>
                        </v-hover>
                        <v-btn
                          class="text-normal"
                          @click="
                            handleAddItemToFormWithNesting(
                              recheckTemplate,
                              'rechecks'
                            )
                          "
                        >
                          Add Recheck
                          <v-icon small right>mdi-plus</v-icon>
                        </v-btn>
                        <actions-menu
                          @copy="handleCopyData"
                          :presets="computedPresetWithoutSelected"
                          :data-key="'rechecks'"
                        />
                      </div>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="pa-4">
                      <v-row v-if="!computedRechecks?.length">
                        <v-col cols="12">
                          Press "Add Recheck" to add new Recheck
                        </v-col>
                      </v-row>
                      <template v-else>
                        <transition-group tag="div" class="row" name="slide-y">
                          <v-col
                            v-for="(item, idx) in computedRechecks"
                            :key="`title-${item.id}`"
                            cols="12"
                          >
                            <v-row dense>
                              <v-col cols="fill">
                                <v-row dense>
                                  <v-col cols="4">
                                    <v-autocomplete
                                      v-model="item.status"
                                      :items="filtersData?.page_statuses"
                                      :error-messages="
                                        validation[
                                          `recheck_statuses.${selectedDataSourceId}.${idx}.page_status_id`
                                        ]
                                      "
                                      @blur="
                                        validation[
                                          `recheck_statuses.${selectedDataSourceId}.${idx}.page_status_id`
                                        ] = []
                                      "
                                      hide-details="auto"
                                      dense
                                      outlined
                                      label="Status"
                                      placeholder="Select status of recheck"
                                    />
                                  </v-col>
                                  <v-col cols="4">
                                    <v-autocomplete
                                      v-model="item.performer"
                                      :items="filtersData?.users"
                                      :error-messages="
                                        validation[
                                          `recheck_statuses.${selectedDataSourceId}.${idx}.performer`
                                        ]
                                      "
                                      @blur="
                                        validation[
                                          `recheck_statuses.${selectedDataSourceId}.${idx}.performer`
                                        ] = []
                                      "
                                      item-text="email"
                                      item-value="id"
                                      hide-details="auto"
                                      dense
                                      outlined
                                      label="Performer"
                                      placeholder="Select recheck performer"
                                    />
                                  </v-col>
                                  <v-col cols="4">
                                    <v-text-field
                                      v-model="item.button_text"
                                      :error-messages="
                                        validation[
                                          `recheck_statuses.${selectedDataSourceId}.${idx}.button_text`
                                        ]
                                      "
                                      @blur="
                                        validation[
                                          `recheck_statuses.${selectedDataSourceId}.${idx}.button_text`
                                        ] = []
                                      "
                                      hide-details="auto"
                                      dense
                                      outlined
                                      label="Button text"
                                      placeholder="Enter something"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row dense>
                                  <v-col cols="4">
                                    <v-btn
                                      class="text-normal"
                                      height="40"
                                      block
                                      @click="handleConfigureProblems(item)"
                                      :disabled="
                                        item.status === null ||
                                        item.status === undefined
                                      "
                                      :loading="loadings.problems === item.id"
                                    >
                                      Configure problems
                                      <v-icon small right>mdi-cog</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="auto" class="d-flex align-center">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="handleRemoveById('rechecks', item.id)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </transition-group>
                      </template>
                    </v-card-text>
                    <template v-if="computedRechecks?.length > 4">
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          class="text-normal"
                          @click="
                            handleAddItemToFormWithNesting(
                              recheckTemplate,
                              'rechecks'
                            )
                          "
                        >
                          Add Recheck
                          <v-icon small right>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </template>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card flat outlined rounded="lg">
                    <v-card-title class="px-4 py-2 text-body-2">
                      <b>Approves</b>
                      <my-badge
                        v-if="computedApproves?.length"
                        class="grey white--text ml-1"
                      >
                        {{ computedApproves.length }}
                      </my-badge>
                      <v-spacer />
                      <div class="d-flex" style="gap: 0.25rem">
                        <v-hover
                          v-if="
                            changes[selectedDataSourceId]?.approves &&
                            hasInitialValue(selectedDataSourceId, 'approves')
                          "
                          v-slot="{ hover }"
                        >
                          <v-btn
                            text
                            class="text-normal"
                            :icon="!hover"
                            @click="
                              handleResetChangesForFormByKey(
                                selectedDataSourceId,
                                'approves'
                              )
                            "
                          >
                            <span v-if="hover"> Reset changes </span>
                            <v-icon small class="ml-1">mdi-refresh</v-icon>
                          </v-btn>
                        </v-hover>
                        <v-btn
                          class="text-normal"
                          @click="
                            handleAddItemToFormWithNesting(
                              approveTemplate,
                              'approves'
                            )
                          "
                        >
                          Add Approve
                          <v-icon small right>mdi-plus</v-icon>
                        </v-btn>

                        <actions-menu
                          @copy="handleCopyData"
                          :presets="computedPresetWithoutSelected"
                          :data-key="'approves'"
                        />
                      </div>
                    </v-card-title>
                    <v-divider />
                    <v-card-text class="pa-4">
                      <v-row v-if="!computedApproves?.length">
                        <v-col cols="12">
                          Press "Add Approve" to add new Approve
                        </v-col>
                      </v-row>
                      <template v-else>
                        <transition-group tag="div" class="row" name="slide-y">
                          <v-col
                            v-for="(item, idx) in computedApproves"
                            :key="`title-${item.id}`"
                            cols="12"
                          >
                            <v-row dense>
                              <v-col cols="fill">
                                <v-autocomplete
                                  v-model="item.status"
                                  :items="filtersData?.page_statuses"
                                  :error-messages="
                                    validation[
                                      `approve_statuses.${selectedDataSourceId}.${idx}.page_status_id`
                                    ]
                                  "
                                  @blur="
                                    validation[
                                      `approve_statuses.${selectedDataSourceId}.${idx}.page_status_id`
                                    ] = []
                                  "
                                  hide-details="auto"
                                  dense
                                  outlined
                                  label="Status"
                                  placeholder="Select status of recheck"
                                />
                              </v-col>
                              <v-col cols="fill">
                                <v-autocomplete
                                  v-model="item.performer"
                                  :items="filtersData?.users"
                                  :error-messages="
                                    validation[
                                      `approve_statuses.${selectedDataSourceId}.${idx}.performer`
                                    ]
                                  "
                                  @blur="
                                    validation[
                                      `approve_statuses.${selectedDataSourceId}.${idx}.performer`
                                    ] = []
                                  "
                                  item-text="email"
                                  item-value="id"
                                  hide-details="auto"
                                  dense
                                  outlined
                                  label="Performer"
                                  placeholder="Select recheck performer"
                                />
                              </v-col>
                              <v-col cols="fill">
                                <v-text-field
                                  v-model="item.button_text"
                                  :items="[]"
                                  :error-messages="
                                    validation[
                                      `approve_statuses.${selectedDataSourceId}.${idx}.button_text`
                                    ]
                                  "
                                  @blur="
                                    validation[
                                      `approve_statuses.${selectedDataSourceId}.${idx}.button_text`
                                    ] = []
                                  "
                                  hide-details="auto"
                                  dense
                                  outlined
                                  label="Button text"
                                  placeholder="Enter something"
                                />
                              </v-col>
                              <v-col cols="auto">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="handleRemoveById('approves', item.id)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </transition-group>
                      </template>
                    </v-card-text>

                    <template v-if="computedApproves?.length > 4">
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          class="text-normal"
                          @click="
                            handleAddItemToFormWithNesting(
                              approveTemplate,
                              'approves'
                            )
                          "
                        >
                          Add Approve
                          <v-icon small right>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </template>
                  </v-card>
                </v-col>
              </template>
              <v-col v-else cols="12" class="pt-2">
                <v-icon small class="mr-1">mdi-information-outline</v-icon> To
                see the "approves" list and the "recheck" list, you must select
                the active group.
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title class="px-4 py-2 text-body-2">
            <b>Fields</b>
            <my-badge
              v-if="modelValue?.fields?.length"
              class="grey white--text ml-1"
            >
              {{ modelValue.fields.length }}
            </my-badge>
            <v-spacer />
            <div class="d-flex" style="gap: 0.25rem">
              <v-hover
                v-if="
                  changes?.fields &&
                  hasInitialValue(selectedDataSourceId, 'fields')
                "
                v-slot="{ hover }"
              >
                <v-btn
                  text
                  class="text-normal"
                  :icon="!hover"
                  @click="
                    handleResetChangesForFormByKey(
                      selectedDataSourceId,
                      'fields'
                    )
                  "
                >
                  <span v-if="hover"> Reset changes </span>
                  <v-icon small class="ml-1">mdi-refresh</v-icon>
                </v-btn>
              </v-hover>
              <v-btn
                class="text-normal"
                @click="handleAddItemToForm(fieldTemplate, 'fields')"
              >
                Add Field
                <v-icon small right>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text class="pa-4">
            <v-row v-if="!modelValue?.fields?.length">
              <v-col cols="12"> Press "Add Fields" to add new Field </v-col>
            </v-row>
            <template v-else>
              <transition-group tag="div" class="row" name="slide-y">
                <v-col
                  v-for="(item, idx) in modelValue?.fields"
                  :key="`title-${item.id}`"
                  cols="12"
                >
                  <v-row dense>
                    <v-col cols="fill">
                      <v-row dense>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="item.field"
                            :items="filtersData?.cm_fields"
                            :error-messages="
                              validation[`fields.${idx}.field_id`]
                            "
                            @blur="validation[`fields.${idx}.field_id`] = []"
                            hide-details="auto"
                            dense
                            outlined
                            label="Field"
                            placeholder="Select type of Field"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="item.order"
                            :error-messages="validation[`fields.${idx}.order`]"
                            @blur="validation[`fields.${idx}.order`] = []"
                            hide-details
                            dense
                            outlined
                            label="Order"
                            placeholder="Enter number"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="item.position"
                            :items="filtersData?.cm_field_positions"
                            :error-messages="
                              validation[`fields.${idx}.position`]
                            "
                            @blur="validation[`fields.${idx}.position`] = []"
                            hide-details
                            dense
                            outlined
                            label="Position"
                            placeholder="Select fields position"
                          />
                        </v-col>
                        <v-col cols="4" class="d-flex align-center">
                          <v-checkbox
                            v-model="item.is_required"
                            hide-details
                            dense
                            class="ma-1"
                            :label="
                              item.position === 1
                                ? 'Always show'
                                : 'Required to fill'
                            "
                          />
                          <v-menu
                            bottom
                            nudge-bottom="32"
                            content-class="my-shadow--e2"
                          >
                            <template #activator="{ on }">
                              <v-icon small color="grey" v-on="on" class="ma-1"
                                >mdi-information-outline</v-icon
                              >
                            </template>
                            <v-card
                              flat
                              outlined
                              rounded="lg"
                              class="pa-4 text-body-2"
                              width="640"
                            >
                              <v-row>
                                <v-col v-if="item.position === 2" cols="12">
                                  <v-row>
                                    <v-col class="d-flex align-center" cols="4">
                                      <v-checkbox
                                        dense
                                        hide-details
                                        class="ma-1"
                                        :value="true"
                                        :input-value="true"
                                        :true-value="true"
                                        readonly
                                      />
                                      Required to fill
                                    </v-col>
                                    <v-col>
                                      When the checkbox is checked - the user
                                      cannot do the "Approve" action without
                                      filling this field. ( The field becomes
                                      mandatory )
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col class="d-flex align-center" cols="4">
                                      <v-checkbox
                                        dense
                                        hide-details
                                        class="ma-1"
                                        readonly
                                      />
                                      Required to fill
                                    </v-col>
                                    <v-col>
                                      When the checkbox is unchecked - the user
                                      can do an "Approve" action without filling
                                      in this field. ( Field becomes optional )
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col v-else cols="12">
                                  <v-row>
                                    <v-col class="d-flex align-center" cols="4">
                                      <v-checkbox
                                        dense
                                        hide-details
                                        class="ma-1"
                                        :value="true"
                                        :input-value="true"
                                        :true-value="true"
                                        readonly
                                      />
                                      Always show checked
                                    </v-col>
                                    <v-col>
                                      When the checkbox is checked, this info
                                      field is always on visible.
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col class="d-flex align-center" cols="4">
                                      <v-checkbox
                                        dense
                                        hide-details
                                        class="ma-1"
                                        readonly
                                      />
                                      Always show checked
                                    </v-col>
                                    <v-col>
                                      When the checkbox is unchecked - the info
                                      field is hidden behind the "Show more"
                                      button and becomes visible only after
                                      clicking on the "Show more" button.
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        icon
                        color="error"
                        @click="handleRemoveById('fields', item.id)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </transition-group>
            </template>
          </v-card-text>

          <template v-if="modelValue?.fields?.length > 2">
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="text-normal"
                @click="handleAddItemToForm(fieldTemplate, 'fields')"
              >
                Add Field
                <v-icon small right>mdi-plus</v-icon>
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <configure-problems-dialog
      :form="activeConfigureProblemFormData"
      v-model="isConfigureProblemDialogVisible"
      :loading="loadings.saveProblems"
      @save="handleSaveProblems"
    />
  </v-form>
</template>

<script>
import { deepClone, uid } from "../../../../utils/functions";
import MyBadge from "../../../UI/MyBadge.vue";
import UploadFiltersDataMixin from "../../../../mixins/UploadFiltersDataMixin";
import ActionsMenu from "./ActionsMenu.vue";
import ConfigureProblemsDialog from "./ConfigureProblemsDialog.vue";
import { StatusManagerApiService } from "../services/StatusManagerApiService";

/**
 * @typedef {Object} ModelValue
 * @property {Array<Title>} [titles] - Список заголовков.
 * @property {Array<Recheck>} [rechecks] - Список проверок.
 * @property {Array<Approve>} [approves] - Список утверждений.
 * @property {Array<Field>} [fields] - Список полей.
 */

/**
 * @typedef {Object} Recheck
 * @property {number} id - Уникальный идентификатор проверки.
 * @property {string} status - Статус проверки.
 * @property {number} performer - ID исполнителя.
 * @property {string} button_text - Текст кнопки.
 */

/**
 * @typedef {Object} Approve
 * @property {number} id - Уникальный идентификатор утверждения.
 * @property {string} status - Статус утверждения.
 * @property {number} performer - ID исполнителя.
 * @property {string} button_text - Текст кнопки.
 */

/**
 * @typedef {Object} Field
 * @property {number} id - Уникальный идентификатор поля.
 * @property {string} field - Тип поля.
 * @property {Array<string>} [rules] - Правила, применимые к полю.
 */

/**
 * @type {ModelValue}
 */

export default {
  components: { ConfigureProblemsDialog, ActionsMenu, MyBadge },

  mixins: [UploadFiltersDataMixin],

  props: {
    validation: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    isConfigureProblemDialogVisible: false,
    activeConfigureProblemFormData: null,
    loadings: {
      problems: null,
      saveProblems: false,
    },
    selectedDataSourceId: 1,
    recheckTemplate: {
      status: null,
      performer: null,
      button_text: null,
    },
    approveTemplate: {
      status: null,
      performer: null,
      button_text: null,
    },
    fieldTemplate: {
      field: null,
      rules: [],
      order: 1,
      position: 2,
    },
    filtersData: {
      page_statuses: [],
      cm_fields: [],
      cm_field_types: [],
      cm_field_positions: [],
      cm_status_types: [],
      users: [],
    },
    changes: {},
    firstCopyOfFormData: null,
    copyIteration: 0,
  }),

  watch: {
    modelValue: {
      handler(data) {
        this.handleChangeModelValue(data);
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.uploadFiltersData([
      "page_statuses",
      "cm_fields",
      "cm_field_types",
      "cm_field_positions",
      "cm_status_types",
      "users",
      "sw_presets",
    ]);
  },

  computed: {
    computedPresetWithoutSelected() {
      return (
        this.filtersData?.sw_presets?.filter(
          (preset) => preset.value !== this.selectedDataSourceId
        ) || []
      );
    },
    computedRechecks() {
      return this.modelValue?.rechecks?.[this.selectedDataSourceId || 1] || [];
    },
    computedApproves() {
      return this.modelValue?.approves?.[this.selectedDataSourceId || 1] || [];
    },
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    async handleSaveProblems(formData) {
      try {
        this.loadings.saveProblems = true;

        const payload = {
          page_status_id: this.modelValue?._ctx?.id,
          sibling_id: formData?._ctx?.status,
          items: formData.problems,
        };

        const { data } = await StatusManagerApiService().updateProblems(
          payload
        );

        if (data.success) {
          this.isConfigureProblemDialogVisible = false;
          this.activeConfigureProblemFormData = null;
        }
      } catch (e) {
        console.error("Error while saving problems.", e);
      } finally {
        this.loadings.saveProblems = false;
      }
    },
    problemAdapter(item) {
      return {
        problem: item.problem,
        category: item.category,
        id: uid(),
        _ctx: { ...item },
      };
    },
    async handleConfigureProblems(recheckItem) {
      try {
        this.loadings.problems = recheckItem.id;

        let { data: problemResponse } =
          await StatusManagerApiService().getProblems({
            page_status_id: this.modelValue?._ctx?.id,
            sibling_id: recheckItem?.status,
          });

        if (problemResponse?.length)
          problemResponse = problemResponse.map((item) =>
            this.problemAdapter(item)
          );

        this.activeConfigureProblemFormData = {
          _ctx: { ...recheckItem },
          problems: problemResponse,
        };
        this.isConfigureProblemDialogVisible = true;
      } catch (e) {
        console.error("Error while loading data about recheck problems.", e);
      } finally {
        this.loadings.problems = null;
      }
    },
    handleCopyData({ presetData, key }) {
      try {
        if (
          this.modelValue[key] === undefined ||
          this.modelValue[key] === null
        ) {
          this.$set(this.modelValue, key, {});
        }

        if (
          this.modelValue[key][this.selectedDataSourceId] === undefined ||
          this.modelValue[key][this.selectedDataSourceId] === null
        ) {
          this.$set(this.modelValue[key], this.selectedDataSourceId, []);
        }

        if (
          Array.isArray(this.modelValue[key][this.selectedDataSourceId]) &&
          this.modelValue[key][presetData.value] !== undefined
        ) {
          this.modelValue[key][this.selectedDataSourceId] = deepClone(
            this.modelValue[key][presetData.value]
          );
        }
      } catch (e) {
        console.error(
          "Error while copying data from selected preset to current modeLValue.",
          e
        );
      }
    },
    hasInitialValue(dataSourceId, valueKey) {
      try {
        if (valueKey === "fields") {
          return deepClone(this.firstCopyOfFormData[valueKey]);
        }

        return this.firstCopyOfFormData[valueKey][dataSourceId];
      } catch {
        return false;
      }
    },
    handleResetChangesForFormByKey(dataSourceId, valueKey) {
      if (valueKey === "fields") {
        const initialValue = (this.modelValue[valueKey] = deepClone(
          this.firstCopyOfFormData[valueKey]
        ));

        return this.$set(this.modelValue, valueKey, initialValue);
      }

      try {
        const initialValue = (this.modelValue[valueKey][dataSourceId] =
          deepClone(this.firstCopyOfFormData[valueKey][dataSourceId]));

        this.$set(this.modelValue[valueKey], dataSourceId, initialValue);
      } catch (e) {
        console.error("Error while resetting changes.", e);
      }
    },
    resetChanges() {
      this.copyIteration = 0;
      this.changes = {};
      this.firstCopyOfFormData = null;
    },
    handleChangeModelValue(changes) {
      try {
        /**
         * Идея такая:
         * Следить за разницей между первым экземпляром modelValue и текущим состоянием modelValue,
         * что бы показать юзеру что он изменил в форме.
         */

        // Если watch отрабатывает впервые - это инициализация данных из props.value.
        // Нужно сохранить эти данные в this.firstCopyOfFormData
        if (this.copyIteration === 0) {
          this.firstCopyOfFormData = deepClone(changes);
          return this.copyIteration++;
        }

        // После - нужно сравнивать this.firstCopyOfFormData и актуальные данные this.modelValue
        this.handleCaptureChanges(changes);

        this.copyIteration++;
      } catch (e) {
        console.error("Error while handling change of modelValue.", e);
      }
    },
    handleCaptureChanges(data) {
      try {
        // Указываем то за какими ключами будем следить
        const keysToWatch = ["rechecks", "approves", "fields"];

        keysToWatch.forEach((key) => {
          if (key === "fields") {
            return this.handleCaptureChangesFieldsStrategy(data, key);
          } else {
            this.handleCaptureChangesDefaultStrategy(data, key);
          }
        });
      } catch (e) {
        console.error("Error while capturing changes.", e);
      }
    },
    handleCaptureChangesDefaultStrategy(data, key) {
      const data1 = deepClone(
        this.firstCopyOfFormData?.[key]?.[this.selectedDataSourceId] || []
      );
      const data2 = deepClone(data?.[key]?.[this.selectedDataSourceId] || []);

      if (!this.changes?.[this.selectedDataSourceId])
        this.$set(this.changes, this.selectedDataSourceId, []);

      this.changes[this.selectedDataSourceId][key] =
        this.getAmountOfDifferencesInArray(data1, data2)?.totalChanges;
    },
    handleCaptureChangesFieldsStrategy(data, key) {
      const data1 = deepClone(this.firstCopyOfFormData?.[key] || []);
      const data2 = deepClone(data?.[key] || []);

      if (!this.changes[key]) this.$set(this.changes, key, []);

      this.changes[key] = this.getAmountOfDifferencesInArray(
        data1,
        data2
      )?.totalChanges;
    },
    getAmountOfDifferencesInArray(array1 = [], array2 = []) {
      try {
        // Преобразуем массивы в объекты с ключом по id для удобства сравнения
        const map1 = Object.fromEntries(array1.map((item) => [item.id, item]));
        const map2 = Object.fromEntries(array2.map((item) => [item.id, item]));

        // Функция для очистки объекта от свойств без значений
        const cleanObject = (obj) => {
          const cleaned = Object.fromEntries(
            Object.entries(obj).filter(
              ([, value]) => value !== undefined && value !== null
            )
          );
          return cleaned;
        };

        // Считаем добавленные элементы (есть в array2, но нет в array1)
        const added = array2.filter((item) => {
          const isAdded = !map1[item.id];
          return isAdded;
        });

        // Считаем удаленные элементы (есть в array1, но нет в array2)
        const removed = array1.filter((item) => {
          const isRemoved = !map2[item.id];
          return isRemoved;
        });

        // Считаем измененные элементы (id совпадает, но поля отличаются)
        const changed = array1.filter((item) => {
          const correspondingItem = map2[item.id];
          if (!correspondingItem) return false;

          const cleanedItem = cleanObject(item);
          const cleanedCorrespondingItem = cleanObject(correspondingItem);

          const isChanged =
            JSON.stringify(cleanedItem) !==
            JSON.stringify(cleanedCorrespondingItem);

          return isChanged;
        });

        // Подсчитываем общее количество изменений
        const totalChanges = added.length + removed.length + changed.length;

        return {
          added,
          removed,
          changed,
          totalChanges,
        };
      } catch (e) {
        console.error("Error while calculating amount of changes in array.", e);
        return null;
      }
    },
    getTotalChangesAmountById(dataSourceId) {
      try {
        let result = Object.values(this.changes[dataSourceId]).reduce(
          (acc, current) => acc + current,
          0
        );

        // Вручную добавляем кол-во измененных данных в филдах ибо оно не матчится со структурой остальных полей
        result += this.changes["fields"] || 0;

        return result;
      } catch (e) {
        return 0;
      }
    },
    handleRemoveById(target, id) {
      if (Array.isArray(this.modelValue[target])) {
        try {
          return (this.modelValue[target] = this.modelValue[target]?.filter(
            (item) => item.id !== id
          ));
        } catch (e) {
          console.error("Cant remove item by id.", e);
        }
      }

      if (typeof this.modelValue[target] === "object") {
        try {
          this.modelValue[target][this.selectedDataSourceId] =
            this.modelValue?.[target]?.[this.selectedDataSourceId]?.filter(
              (item) => item.id !== id
            );
        } catch (e) {
          console.error("Cant remove item by id.", e);
        }
      }
    },
    handleAddItemToFormWithNesting(template, key) {
      const dataSourceId = this.selectedDataSourceId;

      if (this.modelValue[key] === undefined || this.modelValue[key] === null) {
        this.$set(this.modelValue, key, {});
      }

      // Инициализируем реактивное занчение, если его не существует
      if (
        this.modelValue?.[key]?.[dataSourceId] === undefined ||
        this.modelValue?.[key]?.[dataSourceId] === null
      ) {
        this.$set(this.modelValue[key], dataSourceId, []);
      }

      /**
       * В итоге целевой массив должен быть доступен по пути
       * modelValue: {
       *   [key]: {
       *     [dataSourceId]: any[]
       *   }
       * }
       */

      if (!Array.isArray(this.modelValue[key][dataSourceId]))
        return console.error(
          `Cant add new ${key} because form.${key}.${dataSourceId} are not Array.`
        );

      this.modelValue[key][dataSourceId].push({ ...template, id: uid() });
    },
    handleAddItemToForm(template, key) {
      if (
        this.modelValue?.[key] === undefined ||
        this.modelValue?.[key] === null
      ) {
        this.$set(this.modelValue, key, []);
      }

      if (!Array.isArray(this.modelValue[key])) {
        console.error("Form data:", this.modelValue);
        return console.error(
          `Cant add new ${key} because form.${key} are not Array.`
        );
      }

      this.modelValue[key].push({ ...template, id: uid() });
    },
  },
};
</script>
