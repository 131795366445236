<template>
  <v-menu
    bottom
    transition="slide-y-transition"
    :close-on-content-click="false"
    :nudge-bottom="40"
    content-class="my-shadow--e2"
  >
    <template #activator="{ on }">
      <v-text-field
        readonly
        hide-details
        dense
        :outlined="!filled"
        :filled="filled"
        label="Comparison"
        v-on="on"
        :value="getLabel"
        :disabled="disabled"
      >
      </v-text-field>
    </template>
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2"> Compare </v-card-title>
      <v-divider />
      <v-list dense>
        <v-list-item @click="setComparisonDate(7, 'day', 'Last 7 d.')">
          last 7 days to prev. period
        </v-list-item>
        <v-list-item @click="setComparisonDate(28, 'day', 'Last 28 d.')">
          last 28 days to prev. period
        </v-list-item>
        <v-list-item @click="setComparisonDate(3, 'months', 'Last 3 m.')">
          last 3 months to prev. period
        </v-list-item>
        <v-list-item @click="setComparisonDate(6, 'months', 'Last 6 m.')">
          last 6 months to prev. period
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-title style="font-size: 14px" class="py-2">
        Custom date
      </v-card-title>
      <v-card-text>
        <v-row dense style="max-width: 250px">
          <v-col cols="12">
            <v-menu
              ref="menu"
              v-model="dateMenu1"
              :close-on-content-click="false"
              transition="slide-x-transition"
              offset-x
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date[0]"
                  label="Second date"
                  prepend-icon="mdi-calendar"
                  outlined
                  hide-details
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card>
                <v-date-picker
                  v-model="date[0]"
                  :reactive="true"
                  range
                ></v-date-picker>
                <v-row class="pa-2">
                  <v-col class="d-flex justify-end">
                    <v-btn block color="primary" @click="dateMenu1 = false">
                      Ok
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12">
            <v-menu
              ref="menu"
              v-model="dateMenu2"
              :close-on-content-click="false"
              transition="slide-x-transition"
              offset-x
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date[1]"
                  label="Second date"
                  prepend-icon="mdi-calendar"
                  outlined
                  hide-details
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card>
                <v-date-picker
                  v-model="date[1]"
                  :reactive="true"
                  range
                ></v-date-picker>
                <v-row class="pa-2">
                  <v-col class="d-flex justify-end">
                    <v-btn block color="primary" @click="dateMenu2 = false">
                      Ok
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          block
          class="flat-chip flat-chip--error text-normal"
          @click="handleClearComparison"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    comparisonLabel: null,
    dateMenu1: false,
    dateMenu2: false,
    date: [[], []],
  }),
  watch: {
    date() {
      this.comparison = [this.date[0], this.date[1]];
      console.log(JSON.stringify(this.comparison));

      if (this.comparison && this.comparison.length === 0) {
        return (this.comparisonLabel = null);
      }

      if (JSON.stringify(this.comparison) === JSON.stringify([[], []])) {
        return (this.comparisonLabel = null);
      }

      this.comparisonLabel = "Custom date";
    },
  },
  computed: {
    getLabel() {
      let label = this.comparisonLabel ? this.comparisonLabel : null;

      if (!this.comparison) {
        label = null;
      }

      return label;
    },
    comparison: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleClearComparison() {
      this.comparison = [];
      this.date = [[], []];
      this.comparisonLabel = null;
    },
    setComparisonDate(amount, type, label) {
      this.comparisonLabel = label;
      const m = this.$moment;
      const d = new Date();

      const compare1Start = m(d).subtract(amount, type).format("YYYY-MM-DD");
      const compare1End = m(d).format("YYYY-MM-DD");

      const compare2Start = m(d)
        .subtract(amount * 2, type)
        .format("YYYY-MM-DD");
      const compare2End = m(d).subtract(amount, type).format("YYYY-MM-DD");

      this.comparison = [
        [compare1Start, compare1End],
        [compare2Start, compare2End],
      ];
    },
  },
};
</script>
