export const CONTINUE_PAYMENT_HEADERS = [
  {
    text: "",
    value: "_actions",
    width: "48px",
  },
  {
    text: "Published Date",
    value: "published_date",
    width: "120px",
  },
  {
    text: "URL GP",
    value: "url_gp",
  },
  {
    text: "Acceptor",
    value: "acceptor",
  },
  {
    text: "Donor",
    value: "domain_donor",
  },
  {
    text: "Term",
    value: "term",
    width: "140px",
  },
  {
    text: "Finish date",
    value: "finish_date",
    width: "140px",
  },
  {
    text: "Price",
    value: "price",
    width: "120px",
  },
  {
    text: "Code URL",
    value: "code_url",
  },
  {
    text: "OP",
    title: "Onpage",
    value: "op",
  },
  {
    text: "NI",
    title: "Noindex",
    value: "ni",
  },
  {
    text: "NF",
    title: "Nofollow",
    value: "nf",
  },
  {
    text: "DR",
    title: "Domain rating",
    value: "dr",
  },
  {
    text: "Ah Traffic",
    value: "ah_traffic",
  },
  {
    text: "Email WM",
    value: "email_wm",
  },
  {
    text: "Email GP",
    value: "email_gp",
  },
  {
    text: "Team",
    value: "team",
  },
  {
    text: "Status",
    value: "status",
    width: "200px",
  },
];
