<template>
  <div class="ltt-deleted-links">
    <v-row>
      <v-col cols="12" class="mb-4">
        <page-header
          title="LTT Deleted Links"
          :breadcrumbs="[
            { text: 'Home', to: '/' },
            { text: 'LTT', disabled: true },
            { text: 'Deleted Links', disabled: true },
          ]"
        >
        </page-header>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <div class="fix-button-height">
          <smart-date-filter
            :model-value="filters.date"
            @update:modelValue="filters.date = $event"
          />
        </div>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.user_id"
          :items="filtersData.users"
          :loading="loadings.filters"
          label="User Id"
          item-value="id"
          item-text="name"
          multiple
          placeholder="Type to search"
          hide-details
          dense
          clearable
          outlined
        />
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.project_id"
          :items="projects"
          :loading="loadings.filters"
          label="Projects"
          item-value="id"
          item-text="name"
          multiple
          placeholder="Type to search"
          hide-details
          dense
          clearable
          outlined
        >
          <template #prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="
                    filters.project_id.length > 0 ? 'primary darken-4' : ''
                  "
                  >{{ icon }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Select All</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.subproject"
          :items="filtersData?.subprojects"
          multiple
          hide-details
          dense
          outlined
          label="Subproject"
        />
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.statuses"
          outlined
          dense
          clearable
          hide-details
          :loading="loadings.statuses"
          label="Statuses"
          :items="filtersData.statuses"
          item-text="name"
          item-value="id"
          multiple
          placeholder="Select statuses"
        />
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.type"
          :items="filtersData.types"
          :loading="loadings.filters"
          label="Type"
          item-value="id"
          item-text="name"
          multiple
          placeholder="Type to search"
          hide-details
          dense
          clearable
          outlined
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="8" lg="4" xl="fill">
        <v-text-field
          v-model="filters.donor"
          label="Search donor"
          dense
          class="reduce-append-icon-mt"
          hide-details
          outlined
          append-icon="mdi-magnify"
          @click:append="fetchData"
        >
          <template #append>
            <v-btn icon :loading="loadings.table" @click="fetchData">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.niche"
          hide-details
          dense
          outlined
          label="Niche"
          placeholder="Type to search"
          :items="filtersData?.niches"
          :loading="filtersDataPendingKeys?.niches"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-autocomplete
          v-model="filters.main_seo"
          :items="filtersData.users"
          :loading="loadings.filters"
          label="Main seo"
          item-value="id"
          item-text="name"
          multiple
          placeholder="Type to search"
          hide-details
          dense
          clearable
          outlined
        />
      </v-col>
      <v-col cols="6" md="4" lg="2" xl="fill">
        <v-btn
          color="primary"
          block
          class="text-normal"
          style="height: 40px"
          :loading="loadings.table"
          @click="fetchData"
        >
          Get Data
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!loadings.table">
      <v-col
        v-if="sections.length !== 0"
        cols="12"
        class="d-flex flex-column"
        style="gap: 1rem"
      >
        <v-card
          v-for="(section, i) in sections"
          :key="section.name + i"
          flat
          outlined
          rounded="lg"
          class="ltt-deleted-links__table-wrapper"
        >
          <v-card-title class="text-body-2">
            <v-tooltip bottom open-delay="300">
              <template #activator="{ on }">
                <v-chip
                  v-on="on"
                  label
                  class="flat-chip flat-chip--primary px-2 mr-2"
                >
                  <v-icon small>mdi-domain</v-icon>
                </v-chip>
              </template>
              <template #default> Project </template>
            </v-tooltip>
            <b>{{ section.name }}</b>
          </v-card-title>
          <v-divider />
          <v-card-text
            class="pa-4 d-flex flex-column"
            style="gap: 0.5rem; background: var(--card-darken-body-color)"
          >
            <v-card
              v-for="(table, i) in section.items"
              :key="table.name + i"
              flat
              outlined
              rounded="lg"
            >
              <v-card-title class="text-body-2">
                <v-tooltip bottom open-delay="300">
                  <template #activator="{ on }">
                    <v-chip
                      v-on="on"
                      label
                      class="flat-chip flat-chip--warning px-2 mr-2"
                    >
                      <v-icon small>mdi-tag</v-icon>
                    </v-chip>
                  </template>
                  <template #default> Type </template>
                </v-tooltip>
                <b>{{ table.name }}</b>
                <v-spacer />
                <v-btn @click="updateAllRows(table.items)" class="text-normal">
                  Update rows
                  <v-icon small right>mdi-sync</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider />
              <v-card-title>
                <v-text-field
                  v-model="table.search"
                  clearable
                  hide-details
                  dense
                  outlined
                  placeholder="Type to search"
                  append-icon="mdi-magnify"
                  @change="handleSearch(table, $event)"
                ></v-text-field>
              </v-card-title>
              <v-divider />
              <v-card-text class="px-0 pt-0 pb-2">
                <v-data-table
                  :search="table.search"
                  :headers="tableData.headers"
                  :items="table.items"
                  class="deleted-links-table relative-tr relative-td"
                >
                  <template #body="{ items }">
                    <tbody>
                      <deleted-linkstable-row
                        v-for="(row, index) in items"
                        :model-value="row"
                        @update:modelValue="row = $event"
                        :key="index"
                        :statuses="filtersData.statuses"
                      />
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-else>
        <div
          class="placeholder-text__wrapper d-flex justify-center align-center mt-4"
        >
          <div class="placeholder">
            <span
              >Can't find anything. <br />
              Change filters and try again. 🤷‍♂</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <div style="position: relative">
          <loader :show="true" />
          <v-skeleton-loader type="table-row-divider@10"></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DEFAULT_LTT_DELETED_LINKS_TABLE_DATA } from "@/utils/defaultData";
import Loader from "@/components/Main/Loader";
import Location from "@/mixins/Location";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import PageHeader from "../../UI/PageHeader.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import Service from "../../../plugins/service";
import DeletedLinkstableRow from "./DeletedLinkstableRow.vue";
import uploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";

export default {
  components: {
    PageHeader,
    SmartDateFilter,
    DeletedLinkstableRow,
    Loader,
  },
  mixins: [Location, FiltersHandling, uploadFiltersDataMixin],
  async mounted() {
    this.uploadFiltersData(["niches"]);
    await this.parseQuery("filters");

    if (!this.$route.query.type) {
      const GUEST_POST_ID = 6;
      this.filters.type = [GUEST_POST_ID];
    }

    if (!this.$route.query.date) {
      const date = this.$moment(new Date()).format("YYYY-MM-DD");
      this.filters.date = [date, date];
    }

    this.fetchFilters();
    this.fetchStatuses();

    if (Object.keys(this.$route.query).length) {
      this.fetchData();
    }

    this.$watch("filters.project_id", (projectsIds) => {
      console.log(projectsIds?.length);
      this.uploadFiltersData(["subprojects"], { projects: projectsIds });

      if (!projectsIds?.length) this.filtersData.subprojects = [];
    });
  },
  data: () => ({
    globalSearchModel: "",
    filters: {
      subproject: null,
      niche: null,
      date: [],
      user_id: [],
      main_seo: [],
      project_id: [],
      type: [],
      donor: "",
      statuses: [],
    },
    filtersData: {
      users: [],
      types: [],
      subprojects: [],
    },
    dateMenu: false,
    tableData: {
      items: DEFAULT_LTT_DELETED_LINKS_TABLE_DATA.items,
      headers: DEFAULT_LTT_DELETED_LINKS_TABLE_DATA.headers,
    },
    sections: [],
    loadings: {
      filters: false,
      statuses: false,
      table: false,
      checkQueue: false,
    },
  }),
  computed: {
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    icon() {
      if (this.filters.project_id.length === this.projects.length)
        return "mdi-close-box";
      if (
        this.filters.project_id.length > 0 &&
        this.filters.project_id.length < this.projects.length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    handleSearch(table, query) {
      if (!("search" in table)) this.$set(table, "search", query);
    },
    updateAllRows(data = []) {
      this.$message.notification({
        title: "Update rows",
        text: "all rows have been added to the update queue",
        type: "success",
        duration: 6000,
      });
      data.forEach((item) => {
        this.handleUpdateRow(item);
      });
    },
    async handleUpdateRow({ link_id }) {
      const payload = {
        link_id,
        config: {
          params: {
            name: "update_info",
          },
        },
      };

      await this.$store.dispatch("ltt-deleted-links/updateLink", payload);
    },
    async fetchStatuses() {
      this.loadings.statuses = true;
      try {
        const resp = await this.$store.dispatch(
          "ltt-deleted-links/fetchStatus"
        );
        if (resp) {
          this.filtersData.statuses = resp;
        }
      } catch (e) {
        console.error("Error while loading statuses.", e);
      } finally {
        this.loadings.statuses = false;
      }
    },
    toggle() {
      if (this.filters.project_id.length === this.projects.length) {
        this.filters.project_id = [];
      } else {
        const newValue = this.projects.slice().map((el) => el.id);
        this.filters.project_id = newValue;
      }
    },
    async getUsers() {
      try {
        const url = "/ltt/ltt_deleted_links/users";

        const response = await Service.post(url);

        this.filtersData = { ...this.filtersData, users: response.data.data };
      } catch (e) {
        console.error("Error while loading users.", e);
      }
    },
    async getLinkTypes() {
      try {
        const url = "/ltt/links-type";

        const response = await Service.get(url);

        this.filtersData = { ...this.filtersData, types: response.data };
      } catch (e) {
        console.error("Error while loading users.", e);
      }
    },
    async fetchFilters() {
      try {
        this.loadings.filters = true;

        const promises = [this.getLinkTypes(), this.getUsers()];

        await Promise.allSettled(promises);
      } catch (e) {
        console.error("Error while loading filters.", e);
      } finally {
        this.loadings.filters = false;
      }
    },
    async fetchData() {
      this.loadings.table = true;

      try {
        const payload = {
          filter: this.filters,
        };
        const resp = await this.$store.dispatch(
          "ltt-deleted-links/fetchTableData",
          payload
        );
        if (resp) {
          this.sections = this.generateSections(resp.data);
        }
      } catch (e) {
        console.error("Error while loading data.", e);
      } finally {
        this.loadings.table = false;
      }
    },
    generateSections(data) {
      const output = [];
      const keys = Object.keys(data);

      keys.forEach((sectionKey) => {
        const itemsKeys = Object.keys(data[sectionKey]);
        const items = [];

        itemsKeys.forEach((key) => {
          items.push({
            name: key,
            items: data[sectionKey][key],
          });
        });

        output.push({
          name: sectionKey,
          items,
        });
      });

      return output;
    },
  },
};
</script>
