export default {
  data: () => ({
    searchTimeout: null,
    searchQuery: undefined,
  }),

  watch: {
    searchQuery: {
      handler() {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.handleSearchChanged();
        }, 1500);
      },
    },
  },

  methods: {
    handleSearchChanged() {
      console.log("Search is changed.");
    },
  },
};
