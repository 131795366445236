<template>
  <v-container fluid style="padding-bottom: 200px">
    <v-row dense>
      <v-col cols="12">
        <h2>Managers outreach problems</h2>
        <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
      </v-col>
    </v-row>
    <v-row dense class="mt-6">
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.project"
              label="Project"
              :items="projects"
              item-text="name"
              item-value="id"
              outlined
              multiple
              dense
              clearable
              :loading="loadings.filters"
              :menu-props="menuProps"
              hide-details
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.seo"
              label="Seo"
              :items="filtersData.seo"
              item-text="text"
              item-value="value"
              outlined
              multiple
              dense
              clearable
              :loading="loadings.filters"
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.performer"
              label="Performer"
              :items="filtersData.performer"
              item-text="text"
              item-value="value"
              outlined
              multiple
              dense
              clearable
              :loading="loadings.filters"
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.niche"
              label="Niches"
              :items="niches"
              item-text="name"
              item-value="id"
              outlined
              multiple
              dense
              clearable
              :loading="loadings.filters"
              :menu-props="menuProps"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <smart-date-filter
              :model-value="filters.date"
              @update:modelValue="filters.date = $event"
            ></smart-date-filter>
          </v-col>
          <v-col cols="fill" class="d-flex align-center justify-end">
            <v-btn
              color="primary"
              class="text-normal"
              block
              @click="handleGetData"
              height="40"
              :loading="loadings.table"
            >
              Get Data
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!loadings.table && cards.length > 0">
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title v-if="cards.length !== 0">
            <v-text-field
              v-model="projectSearchModel"
              label="Find user"
              placeholder="By email"
              dense
              outlined
              clearable
              hide-details
              style="max-width: 260px"
              append-icon="mdi-magnify"
            ></v-text-field>
            <v-spacer />
            <v-btn
              class="text-normal"
              @click="createExport"
              :loading="loadings.export"
            >
              Create export
              <v-progress-circular
                v-if="loadings.export"
                indeterminate
                width="2"
                size="18"
              />
              <v-icon v-else small right>mdi-export</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text
            style="background: var(--card-darken-body-color); gap: 1rem"
            class="d-flex flex-column"
          >
            <template v-for="(card, index) in cards">
              <v-card
                v-if="
                  !projectSearchModel ||
                  (projectSearchModel &&
                    card.user.email.includes(projectSearchModel))
                "
                :key="index"
                flat
                outlined
                rounded="lg"
              >
                <v-card-title class="text-body-2">
                  <template v-if="card?.user?.email?.toLowerCase() === 'total'">
                    <v-chip
                      class="px-2 flat-chip flat-chip--success mr-2"
                      label
                    >
                      <v-icon small>mdi-equal</v-icon>
                    </v-chip>
                    Total
                  </template>
                  <template v-else>
                    <v-chip
                      class="px-2 flat-chip flat-chip--primary mr-2"
                      label
                    >
                      <v-icon small>mdi-account</v-icon>
                    </v-chip>
                    {{ card.user.email }}
                  </template>
                </v-card-title>
                <v-divider />
                <v-card-title>
                  <v-text-field
                    hide-details
                    dense
                    outlined
                    placeholder="Type to search"
                    append-icon="mdi-magnify"
                    v-model="card.search"
                  />
                </v-card-title>
                <v-divider />
                <v-card-text class="px-0">
                  <v-data-table
                    :search="card.search"
                    :headers="getFixedHeaders(tableData.headers)"
                    :items="card.items"
                    class="outreach-problems__table"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template #body="{ items }">
                      <tbody>
                        <tr
                          v-for="(tr, index) in items"
                          :key="index"
                          :class="`${
                            tr.name && tr.name !== '%' ? 'marked' : ''
                          }`"
                        >
                          <td-name :data="tr" />
                          <td>
                            {{ tr.category }}
                          </td>
                          <td class="text-center">
                            <template v-if="tr.total">
                              <template
                                v-if="tr.total.donors || tr.total.errors"
                              >
                                <span> {{ tr.total.donors }}</span>
                                <sup>{{ tr.total.errors }}</sup>
                              </template>
                              <template v-else-if="tr.total.errorsPercent">
                                <div
                                  :class="`colored-td colored-td--inline ${getColor(
                                    tr.total.errorsPercent
                                  )}`"
                                >
                                  {{ tr.total.errorsPercent }} %
                                </div>
                              </template>
                              <template v-else-if="tr.total.donorsCurWeek">
                                {{ tr.total.donorsCurWeek }}
                              </template>
                            </template>
                          </td>
                          <template v-for="(td, index) in getWeeksTd(tr)">
                            <td-date :data="td" :key="index" />
                          </template>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </template>
          </v-card-text>
        </v-card>
        <div class="d-flex mb-4"></div>
      </v-col>
    </v-row>
    <template v-else-if="!loadings.table && cards.length === 0">
      <div
        class="placeholder-text__wrapper d-flex justify-center align-center mt-4"
      >
        <div class="placeholder">
          <span
            v-html="
              firstEnter ? tooltips.itemsPlaceholder : tooltips.firstEnter
            "
          ></span>
        </div>
      </div>
    </template>
    <template v-else>
      <v-row dense>
        <v-col cols="12">
          <div
            class="table-no-items-placeholders transparent"
            style="position: relative"
          >
            <loader :show="loadings.table" />
            <v-skeleton-loader
              type="table-row-divider@6"
              width="100%"
            ></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import {
  DEFAULT_MAN_OUTREACH_PROBLEMS_TABLE_DATA,
  DEFAULT_MENU_PROPS,
} from "@/utils/defaultData";
import TdName from "./td/TdName.vue";
import Loader from "@/components/Main/Loader";
import SmartDateFilter from "../../UI/SmartDateFilter.vue";
import FiltersHandling from "../../../mixins/components/FiltersHandling";
import TdDate from "./td/TdDate.vue";
import service from "@/plugins/service";

export default {
  components: { SmartDateFilter, TdDate, TdName, Loader },
  mixins: [FiltersHandling],
  created() {
    const haveParams = this._$collectParamsTo(this, "filters", [
      "date",
      "niche",
      "performer",
      "seo",
      "project",
    ]);

    this.fetchFilters();
    if (haveParams) {
      this.fetchTableData();
    }
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        to: "/",
      },
      {
        text: "Managers outreach problems",
        disabled: true,
      },
    ],
    tooltips: {
      firstEnter: "Select filters and click 'Get Data' 🏌️‍♂",
      itemsPlaceholder:
        "Can't find anything. <br> Change filters and try again. 🤷‍♂",
    },
    firstEnter: false,
    projectSearchModel: null,
    searchTimeout: null,
    FILTERS_QUERY_IDS: {
      seo: "seo",
      project: "project",
      performer: "performer",
      niche: "niche",
      date: "date",
    },
    menuProps: DEFAULT_MENU_PROPS,
    tableData: DEFAULT_MAN_OUTREACH_PROBLEMS_TABLE_DATA,
    cards: [],
    filters: {
      date: [], // filter
      niche: [], // filter
      performer: [], // filter
      seo: [], // filter
      project: [], // filter
    },
    filtersData: {
      seo: [],
      performer: [],
      project: [],
    },
    loadings: {
      filters: false,
      table: false,
      export: false,
    },
  }),
  watch: {
    filters: {
      handler(value) {
        this._$handleFiltersChange(value);
      },
      deep: true,
    },
  },
  computed: {
    projects() {
      return this.$store.state.project.allowed_projects;
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    getFixedHeaders(headers) {
      let fixed = [...headers];

      fixed = fixed.map((header, idx) => {
        if (idx === 0) header.width = "240px";
        if (idx === 1) header.width = "240px";
        return header;
      });

      return fixed;
    },
    async createExport() {
      try {
        this.loadings.export = true;
        let url = "/export/base/create-task";

        let payload = {
          typeID: 20,
          json: {
            ...this.filters,
          },
        };

        const resp = await service.post(url, payload);
        this.loadings.export = false;

        if (resp && resp.data && resp.data.success) {
          this.$message.notification({
            title: "Done",
            text: "Export successfully created.",
            type: "success",
          });
        }
      } catch {
        this.loadings.export = false;
      }
    },
    getColor(percent) {
      if (percent > 0 && percent <= 33) {
        return "green";
      }
      if (percent > 33 && percent <= 66) {
        return "yellow";
      }
      if (percent > 66 && percent <= 100) {
        return "red";
      }
      return "";
    },
    renderAllCardsElements(elements) {
      Array.from(elements).forEach((item) => {
        item.classList.remove("didnt-match");
        item.classList.remove("match");
      });
    },
    renderSingleCardElement(element) {
      element.classList.remove("didnt-match");
      element.classList.add("match");
    },
    disableSingleCardElement(element) {
      element.classList.remove("match");
      element.classList.add("didnt-match");
    },
    async fetchFilters() {
      this.loadings.filters = true;
      const filters = ["seo", "performer"];
      const payload = {
        type: "/dashboards/gpm/outreach-problems",
        take: filters,
      };
      const resp = await this.$store.dispatch("global/getFilters", payload);
      if (resp) {
        const keys = Object.keys(resp);
        keys.forEach((key) => {
          this.filtersData[key] = resp[key];
        });
      }
      this.loadings.filters = false;
    },
    async fetchTableData() {
      this.firstEnter = true;

      try {
        const payload = {
          body: {
            filter: this.filters,
          },
          isMock: localStorage.getItem("is_mock") === "1",
        };

        this.loadings.table = true;

        const resp = await this.$store.dispatch(
          "outreach-problems/fetchTableData",
          payload
        );
        if (resp) {
          this.tableData.headers = this.fixHeaders(resp.headers);
          this.cards = resp.items;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.table = false;
      }
    },
    fixHeaders(headers) {
      headers.forEach((item) => {
        if (item.value !== "name") {
          item.align = "center";
        }
      });
      return headers;
    },
    handleGetData() {
      this.fetchTableData();
    },
    getWeeksTd(data) {
      const tdArray = [];
      Object.keys(data).forEach((key) => {
        if (key.search(/[0-9]?[0-9]w/gm) !== -1) {
          tdArray.push(data[key]);
        }
      });
      return tdArray;
    },
  },
};
</script>
