<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e2"
    width="1240"
    scrollable
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2 pa-5">
        Add task
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-5">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="3">
                <div class="text-body-2 mb-1">Type</div>
                <v-autocomplete
                  v-model="form.type"
                  hide-details
                  dense
                  outlined
                  clearable
                  placeholder="Type to search"
                  :items="types"
                />
              </v-col>
              <v-col cols="3"></v-col>
              <v-col cols="3"></v-col>
              <v-col cols="3"></v-col>
            </v-row>
          </v-col>
          <v-form ref="form" style="width: 100%">
            <v-col cols="12">
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    v-model="form.project"
                    hide-details="auto"
                    dense
                    outlined
                    clearable
                    placeholder="Type to search"
                    label="Project"
                    :items="filtersData?.projects"
                    :rules="[(v) => !!v || 'Item is required']"
                    :loading="filtersDataPendingKeys?.projects"
                  />
                </v-col>
                <v-col cols="9" class="d-flex align-start">
                  <span
                    v-if="
                      !subprojectsForRendering?.length &&
                      !filtersDataPendingKeys?.subprojects
                    "
                    style="opacity: 0.5"
                  >
                    <span style="height: 40px" class="d-flex align-center">
                      <v-progress-circular
                        v-if="filtersDataPendingKeys?.subprojects"
                        size="14"
                        width="1"
                        indeterminate
                      />
                      <v-icon v-else small left>mdi-arrow-left</v-icon>
                      Select project to see the list of subprojects
                    </span>
                  </span>
                  <v-row v-if="!filtersDataPendingKeys?.subprojects">
                    <v-col
                      v-for="subproject in subprojectsForRendering"
                      :key="subproject.value"
                      cols="12"
                    >
                      <v-row>
                        <v-col class="d-flex align-start">
                          <v-card
                            height="40"
                            width="100%"
                            outlined
                            flat
                            rounded="lg"
                            class="d-flex align-center justify-center"
                          >
                            {{ subproject.text }}
                          </v-card>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="d-flex justify-center align-start"
                        >
                          <span
                            style="height: 40px"
                            class="d-flex align-center"
                          >
                            <v-icon small>mdi-arrow-right</v-icon>
                          </span>
                        </v-col>
                        <v-col>
                          <v-autocomplete
                            v-model="subproject.project_to"
                            hide-details="auto"
                            dense
                            outlined
                            clearable
                            placeholder="Type to search"
                            label="Project"
                            :items="filtersData?.projects"
                            :loading="filtersDataPendingKeys?.projects"
                            :rules="[(v) => !!v || 'Item is required']"
                            @change="expandSubprojectsData(subproject)"
                          />
                        </v-col>
                        <v-col>
                          <v-autocomplete
                            v-model="subproject.subproject_to"
                            hide-details="auto"
                            dense
                            outlined
                            clearable
                            placeholder="Type to search"
                            label="Subproject"
                            :items="subproject?.subprojects"
                            :rules="[(v) => !!v || 'Item is required']"
                            :loading="subproject?.loadings?.subprojects"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col v-for="p in [0, 1, 2]" :key="p" cols="12">
                      <v-row>
                        <v-col v-for="i in [0, 1, 2]" :key="i" cols="4">
                          <div
                            class="skeleton"
                            style="width: 100%; height: 40px"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer />
        <v-btn large class="px-6 text-normal" @click="dialog = false">
          Back
        </v-btn>
        <v-btn
          class="px-6 text-normal"
          large
          color="success"
          @click="handleAddTask"
          :loading="loadings.add"
        >
          Process selected
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*eslint-disable*/
import Dialog from "../../../mixins/Dialog";
import Project from "../../../mixins/Project";
import { BackgroundTasksApiService } from "../services/BackgroundTasksApiService";
import UploadFiltersDataMixin from "../../../mixins/UploadFiltersDataMixin";
import service from "../../../plugins/service";
import { deepClone } from "../../../utils/functions";

const TYPES = [
  {
    text: "Main sync projects",
    value: "main_sync_projects",
  },
];

export default {
  mixins: [Dialog, Project, UploadFiltersDataMixin],

  emits: ["added"],

  data: () => ({
    loadings: {
      add: false,
    },
    subprojectsForRendering: [],
    form: {
      type: TYPES[0]?.value,
      project: null,
      subprojects: [],
    },
  }),

  computed: {
    types() {
      return TYPES;
    },
  },

  mounted() {
    this.uploadFiltersData(["projects"], {
      project_status: [0, 1, 2, 3],
    });

    this.$watch("form.project", async (newProjectId) => {
      if (!newProjectId) {
        this.subprojectsForRendering = [];
        this.form.subprojects = [];
        return;
      }

      await this.uploadFiltersData(["subprojects"], {
        project: newProjectId,
        project_status: [0, 1, 2, 3],
        subproject_status: [0, 1, 2, 3],
      });
      this.subprojectsForRendering = Array.from(
        this.filtersData.subprojects.map(this.subprojectsAdapter)
      );
      this.form.subprojects = this.subprojectsForRendering;
    });
  },

  methods: {
    clearForm() {
      this.form = {
        type: TYPES[0]?.value,
        from: {
          project: null,
          subproject: null,
        },
        to: {
          project: null,
          subproject: null,
        },
      };
    },
    subprojectsAdapter(subproject) {
      subproject.project_to = null;
      subproject.subproject_to = null;
      subproject.subprojects = [];
      subproject.loadings = {
        subprojects: false,
      };
      return deepClone(subproject);
    },
    async handleAddTask() {
      let result;

      if (!this.$refs.form.validate()) return;

      result = {
        type: this.form.type,
        subprojects: this.subprojectsForRendering.map((subproject) => {
          return {
            id: subproject.value,
            project_to: subproject.project_to,
            subproject_to: subproject.subproject_to,
          };
        }),
      };

      try {
        this.loadings.add = true;

        const { data } = await BackgroundTasksApiService().addBackgroundTask(
          result
        );

        this.$emit("added", data?.task);

        this.clearForm();
        this.dialog = false;
        this.$message.notification({
          title: "Success",
          text: "Task successfully added to the queue.",
          type: "success",
        });
      } catch (e) {
        console.error("Error while adding task to the queue.", e);
      } finally {
        this.loadings.add = false;
      }
    },
    async expandSubprojectsData(subproject) {
      if (!subproject.project_to) {
        this.$set(subproject, "subproject_to", null);
        this.$set(subproject, "subprojects", []);
        return;
      }

      try {
        this.$set(subproject, "loadings", {
          ...subproject.loadings,
          subprojects: true,
        });
        const response = await service.post(this.ufd_filtersDataEndpoint, {
          take: ["subprojects"],
          filter: { project: subproject.project_to },
        });

        this.$set(subproject, "subprojects", response.data.subprojects || []);
      } catch (e) {
        console.error("Error while uploading filters data.", e);
      } finally {
        this.$set(subproject, "loadings", {
          ...subproject.loadings,
          subprojects: false,
        });
      }
    },
  },
};
</script>
