var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"left":"","nudge-right":"400","close-on-content-click":false,"content-class":"my-shadow--e2"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card',_vm._g({staticClass:"pa-4 text-center",style:({ opacity: _vm.loadings.fieldsHistory ? '0.5' : 1 }),attrs:{"outlined":"","rounded":"lg","disabled":_vm.loadings.fieldsHistory},on:{"click":_vm.handleShowFieldsHistory}},on),[_vm._v(" Show fields history "),(_vm.loadings.fieldsHistory)?_c('v-progress-circular',{attrs:{"size":"12","width":"2","indeterminate":""}}):_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-history")])],1)]}}])},[_c('v-card',{attrs:{"flat":"","outlined":"","rounded":"lg","width":"380"}},[_c('v-card-title',{staticClass:"text-body-2 pb-1 pt-2"},[_vm._v(" Fields history "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","loading":_vm.loadings.fieldsHistory},on:{"click":function($event){return _vm.handleShowFieldsHistory(true)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-refresh ")])],1)],1),_c('v-card-title',{staticClass:"pt-0 pb-3"},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","outlined":"","placeholder":"Type to search","append-icon":"mdi-magnify"},model:{value:(_vm.fieldsHistorySearch),callback:function ($$v) {_vm.fieldsHistorySearch=$$v},expression:"fieldsHistorySearch"}})],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0",staticStyle:{"max-height":"820px","overflow-y":"scroll"}},[(_vm.loadings.fieldsHistory)?[_c('div',{staticClass:"px-4 pb-4 pt-2"},[_c('div',{staticClass:"skeleton",staticStyle:{"height":"120px","width":"100%"}})])]:(_vm.fieldsHistory?.[_vm.form._ctx.url_id]?.length)?[_c('div',{staticClass:"d-flex flex-column"},[_vm._l((_vm.searchByJson(
              _vm.fieldsHistorySearch,
              _vm.fieldsHistory[_vm.form._ctx.url_id]
            )),function(historyItem,idx){return [(historyItem)?_c('field-history-item',{key:`history-item-${idx}`,attrs:{"history-item":historyItem}}):_vm._e(),(
                historyItem &&
                idx !== _vm.fieldsHistory?.[_vm.form._ctx.url_id]?.length - 1
              )?_c('v-divider',{key:idx}):_vm._e()]})],2)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }