<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <smart-date-filter
            :model-value="filters.dates"
            @update:modelValue="filters.dates = $event"
            :loading="loadings.dates"
          ></smart-date-filter>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            v-model="filters.project"
            dense
            hide-details
            outlined
            label="Project"
            :items="_$getProjects"
            item-value="id"
            multiple
            item-text="name"
            placeholder="Select a project"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            v-model="filters.team"
            dense
            hide-details
            outlined
            label="Team"
            :items="filtersData.team"
            multiple
            placeholder="Type to search"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-text-field
            dense
            v-model="filters.min_changes"
            placeholder="Enter number"
            label="Min changes"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            v-model="filters.selected_niche"
            :items="niches"
            item-value="id"
            full-width
            item-text="name"
            label="Niche"
            outlined
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-autocomplete
            dense
            full-width
            v-model="filters.responsible_seo"
            :items="seoItems"
            item-value="id"
            item-text="email"
            outlined
            label="Seo"
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-switch
            class="mt-0"
            v-model="filters.skip_noindex"
            dense
            inset
            :true-value="1"
            :false-value="undefined"
            label="Skip noindex"
            hide-details
          ></v-switch
        ></v-col>
        <v-col cols="6" md="4" lg="2" xl="fill">
          <v-btn
            @click="fetch_projects_by_niche"
            color="primary"
            block
            class="text-normal"
            height="40"
            :loading="loadings.tables"
          >
            Get Data
          </v-btn>
        </v-col>
      </v-row>
      <ContentCheckerChildIndex
        v-for="(content, i) in items_comp"
        :key="content.title + i"
        :content="content"
        :dates="filters.dates"
        :id="content.id"
        :status="content.status"
        :skip_noindex="filters.skip_noindex"
      ></ContentCheckerChildIndex>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import ContentCheckerChildIndex from "./Child/Index";
import Project from "@/mixins/Project";
import Location from "../../mixins/Location";
import service from "@/plugins/service";
import FiltersHandling from "../../mixins/components/FiltersHandling";
import SmartDateFilter from "../../components/UI/SmartDateFilter.vue";

export default {
  name: "ContentCheckerIndex",
  components: {
    SmartDateFilter,
    ContentCheckerChildIndex,
  },
  mixins: [FiltersHandling, Project, Location],
  data() {
    return {
      loadings: {
        tables: false,
        date: false,
        filters: false,
      },
      filters: {
        project: [],
        team: [],
        responsible_seo: undefined,
        allowed_dates: [],
        skip_noindex: true,
        min_changes: null,
        dates: [],
        selected_niche: undefined,
      },
      dateFilterMode: false,
      dates_menu: false,
      dialog_detailed: false,
      dialog_items: [],
      items: [],
      seoItems: [],
      allowed_projects: [],
      filtersData: {
        team: [],
      },
    };
  },
  computed: {
    computedProjectId() {
      return this.$store.state.project.active_project;
    },
    items_comp() {
      let sorter = this.items;
      return sorter.sort(function (a, b) {
        if (a.priority === b.priority) {
          return 0;
        }
        return a.priority < b.priority ? 1 : -1;
      });
    },
    niches() {
      return this.$store.state.reuse.niches;
    },
  },
  methods: {
    async fetchFilters() {
      try {
        const filters = ["team"];
        const payload = {
          type: "/content-checker/dashboard",
          take: filters,
          filter: {
            projectID: this.computedProjectId,
          },
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = resp;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.filters = false;
      }
    },
    async fetchUsers() {
      const projectId = this.computedProjectId;
      const resp = await this.$store.dispatch("global/getUsers", { projectId });

      if (resp) {
        this.seoItems = resp;
      }
    },
    get_allowed_dates(val) {
      return this.allowed_dates.indexOf(val) !== -1;
    },
    async fetch_static_data() {
      this.loadings.dates = true;

      try {
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$store.state.auth.token,
          },
        };

        const r = await axios.get(
          `${this.$store.state.server_url}/content-checker/dashboard/static-data/0`,
          config
        );

        if (!this.$route.query.dates) this.filters.dates = r.data.active_dates;
      } catch (e) {
        console.error("Error while loading static data for dates.", e);
      } finally {
        this.loadings.dates = false;
      }
    },
    async fetch_projects_by_niche() {
      this.items = [];

      this.loadings.tables = true;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
        params: {
          niche: this.selected_niche,
          responsible_seo: this.responsible_seo,
          only_active: true,
          project: this.project,
        },
      };

      const url = `${this.$store.state.server_url}/project`;

      const r = await axios.get(url, config);

      this.allowed_projects = r.data;
      this.allowed_projects.map((v) => {
        this.fetch_data(v);
      });
      this.loadings.tables = false;
    },
    async fetch_data(project) {
      if (!project.id) {
        return this.$message.notification({
          title: "Validation error",
          text: "Cant find project id.",
          type: "warning",
        });
      }

      try {
        const url = `/content-checker/dashboard/${project.id}`;
        const config = {
          params: {
            dates: this.filters.dates,
            skip_noindex: this.filters.skip_noindex,
            min_changes: this.filters.min_changes,
            team: this.filters.team,
          },
        };

        this.loadings.tables = true;

        const resp = await service.get(url, true, config);

        if (resp) {
          this.items.push({
            title: project.name,
            id: project.id,
            priority: project.priority,
            items: resp.data.rows,
            status: resp.data.status,
          });
        }
      } catch (e) {
        console.error("Error while loading data.", e);
      } finally {
        this.dates_menu = false;
        this.loadings.tables = false;
      }
    },
  },
  async mounted() {
    await this.parseQuery("filters");

    this.fetchUsers();
    this.fetchFilters();

    if (this.niches.length === 0) {
      this.$store.dispatch("fetch_niches_list");
    }

    await this.fetch_static_data();

    if (Object.keys(this.$route.query).length) this.fetch_projects_by_niche();
  },
};
</script>

<style scoped></style>
