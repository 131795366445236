<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex align-end">
          <page-header
            title="Positions v1"
            :breadcrumbs="[
              { text: 'Home', to: '/' },
              { text: 'SERP', disabled: true },
              { text: 'Positions v1', disabled: true },
            ]"
          />
          <v-spacer />

          <v-menu
            v-model="isFiltersMenuVisible"
            transition="slide-x-transition"
            :close-on-content-click="false"
            nudge-bottom="40"
            eager
            content-class="my-shadow--e2"
            min-width="420"
            max-width="420"
          >
            <template v-slot:activator="{ on }">
              <v-btn color="primary" class="text-normal" v-on="on">
                Dashboard filters <v-icon small right>mdi-filter</v-icon>
              </v-btn>
            </template>
            <v-card
              class="styled-card--default shadow-e1-bordered"
              rounded="lg"
            >
              <v-form ref="formRef">
                <v-card-title> Dashboard filters </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="project"
                        :items="filtersData.projects"
                        :loading="filtersDataPendingKeys?.projects"
                        :rules="computedProjectAndCategoriesRules"
                        label="Project"
                        outlined
                        hide-details="auto"
                        dense
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="subproject"
                        :items="filtersData.subprojects"
                        :loading="filtersDataPendingKeys?.subprojects"
                        label="Subproject"
                        outlined
                        hide-details
                        dense
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="redirect_project"
                        :items="filtersData.redirect_projects"
                        label="Redirect project"
                        outlined
                        hide-details
                        dense
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="project_categories"
                        :items="filtersData.project_categories"
                        :loading="filtersDataPendingKeys?.project_categories"
                        :rules="computedProjectAndCategoriesRules"
                        multiple
                        hide-details="auto"
                        dense
                        outlined
                        clearable
                        label="Project categories"
                        placeholder="Type to search"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-center">
                  <v-btn
                    @click="updateDashboard"
                    large
                    height="40"
                    color="primary"
                    class="text-normal px-6"
                  >
                    Apply
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-menu>
        </v-col>
        <template v-if="isset_active_project">
          <v-col
            cols="12"
            v-for="item in top_tiles"
            :key="item.label"
            :md="item.size"
          >
            <v-alert
              dense
              :text="item.bg_color === undefined"
              class="mx-auto text-center mb-0"
              :color="item.bg_color !== undefined ? item.bg_color : 'grey'"
              :dark="item.bg_color !== undefined"
              @click="applyFilter(item.label)"
            >
              <v-card-title class="text-center pa-0 pb-2">
                <v-row align="center" justify="center">
                  <div>
                    <v-icon :color="item.icon_color">{{ item.icon }}</v-icon>
                  </div>
                </v-row>
              </v-card-title>
              <v-card-text class="pa-3">
                <v-row align="center" justify="center">
                  <h3 class="text-h5" :style="{ color: item.text_color }">
                    {{ item.text }}<sup>{{ item.sup }}</sup>
                  </h3>
                </v-row>
              </v-card-text>
              <v-card-actions class="text-center pa-2">
                <v-row align="center" justify="center">
                  <h6
                    :class="{
                      'subtitle-2': true,
                      'grey--text': item.bg_color === undefined,
                    }"
                  >
                    {{ item.label }}
                  </h6>
                </v-row>
              </v-card-actions>
            </v-alert>
          </v-col>
        </template>
      </v-row>
      <template v-if="isset_active_project">
        <v-row>
          <v-col cols="12">
            <v-card class="styled-card--default">
              <v-card-title class="d-flex justify-end">
                <v-menu
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  :nudge-right="200"
                  content-class="remove-dialog-shadow pa-1"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-fab-transition>
                      <v-chip
                        label
                        class="flat-chip flat-chip--primary"
                        v-on="on"
                      >
                        Settings <v-icon small right>mdi-tune</v-icon>
                      </v-chip>
                    </v-fab-transition>
                  </template>
                  <v-card rounded="lg" class="styled-card--default styled-card">
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12">
                          <v-radio-group
                            v-model="chart_type"
                            hide-details
                            class="mt-0"
                            dense
                          >
                            <v-radio
                              label="Positions"
                              value="positions"
                            ></v-radio>
                            <v-radio
                              label="Visibility"
                              value="visibility"
                            ></v-radio>
                            <v-radio
                              label="Competitors (all)"
                              value="competitors_all"
                            ></v-radio>
                            <v-radio
                              label="Competitors (top)"
                              value="competitors_top"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          v-if="chart_type === 'competitors_top'"
                          cols="12"
                        >
                          <v-autocomplete
                            v-model="competitor_item"
                            :items="competitors_items"
                            label="Competitor"
                            filled
                            hide-details
                            dense
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          v-if="chart_type === 'competitors_all'"
                          cols="12"
                        >
                          <v-autocomplete
                            v-model="top_item"
                            :items="top_items"
                            label="Top"
                            filled
                            dense
                            hide-details
                            clearable
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-btn
                        :disabled="chart_apply_btn"
                        @click="fetch_chart"
                        class="flat-chip flat-chip--primary"
                        block
                      >
                        Apply
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-card-title>
              <v-divider />
              <v-card-text
                style="min-height: 300px"
                v-if="loadings.multilineChart"
              >
                <div class="skeleton" style="width: 100%; height: 280px"></div>
                <div class="d-flex mt-2 justify-center">
                  <div
                    v-for="idx in [0, 1, 2, 3, 4]"
                    :key="idx"
                    class="skeleton mr-2"
                    style="border-radius: 100px; width: 64px; height: 24px"
                  />
                </div>
              </v-card-text>
              <v-card-text
                style="min-height: 300px"
                v-show="!loadings.multilineChart"
              >
                <positions-line-chart :series="series" :labels="labels" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-card class="styled-card--light" v-if="isset_active_project">
              <v-card-title class="d-flex justify-end">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  style="max-width: 260px"
                ></v-text-field>
                <v-spacer />
                <download-csv
                  :data="export_data.items"
                  :name="export_data.dataFile"
                  :labels="export_data.labels"
                  :fields="export_data.fields"
                  v-on:export-finished="exported_csv"
                >
                  <v-chip color="transparent" label @click="export_csv">
                    Export CSV
                    <v-icon small right>mdi-cloud-download-outline</v-icon>
                  </v-chip>
                </download-csv>
                <v-spacer />
                <div class="mr-2">
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-progress-circular
                        v-on="on"
                        size="34"
                        width="3"
                        color="primary"
                        :value="serp_collected"
                      >
                        {{ serp_collected }}
                      </v-progress-circular>
                    </template>
                    <div>Serp collected</div>
                  </v-tooltip>
                </div>
                <v-menu
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  :nudge-right="200"
                  content-class="remove-dialog-shadow pa-1"
                  min-width="600"
                  max-width="600"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      label
                      class="flat-chip flat-chip--primary mr-2"
                      v-on="on"
                    >
                      Settings <v-icon small right>mdi-tune</v-icon>
                    </v-chip>
                  </template>
                  <v-card class="styled-card--default shadow-e1-bordered">
                    <v-card-title> Real-time table setting </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row>
                        <v-col cols="7">
                          <v-col cols="12">
                            <v-autocomplete
                              label="Url"
                              v-model="select_url"
                              :items="urls"
                              clearable
                              filled
                              return-object
                              placeholder="Type to search"
                              hide-details
                              dense
                              @change="
                                filter_table({
                                  path: select_url ? select_url.value : null,
                                })
                              "
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Cluster"
                              v-model="cluster_item"
                              :items="clusters_items"
                              clearable
                              filled
                              placeholder="Type to search"
                              return-object
                              hide-details
                              dense
                              @change="
                                filter_table({
                                  cluster: cluster_item ? cluster_item : null,
                                })
                              "
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="Subproject"
                              v-model="subproject_selected"
                              :items="filtersData.subprojects"
                              :loading="filtersDataPendingKeys?.subprojects"
                              clearable
                              filled
                              placeholder="Type to search"
                              return-object
                              hide-details
                              dense
                              @change="
                                filter_table({
                                  subproject: subproject_selected
                                    ? subproject_selected.id
                                    : null,
                                })
                              "
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete
                              label="By Top"
                              v-model="select_top_filter"
                              :items="top_filter_items"
                              multiple
                              clearable
                              small-chips
                              deletable-chips
                              return-object
                              placeholder="Type to search"
                              filled
                              hide-details
                              dense
                              @change="
                                filter_table({
                                  top: select_top_filter
                                    ? select_top_filter
                                    : null,
                                })
                              "
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-row dense>
                              <v-col>
                                <v-radio-group
                                  v-model="ls_settings.cell_style"
                                  class="ma-0"
                                  hide-details
                                  dense
                                  @change="update_setting"
                                >
                                  <v-radio value="ilya" label="Ilya"></v-radio>
                                  <v-radio value="vlad" label="Vlad"></v-radio>
                                </v-radio-group>
                              </v-col>
                              <v-col>
                                <v-radio-group
                                  v-model="ls_settings.gradient_style"
                                  @change="update_setting"
                                  hide-details
                                  dense
                                  class="ma-0"
                                >
                                  <v-radio
                                    value="date_by_date"
                                    label="Date by date"
                                  ></v-radio>
                                  <v-radio
                                    value="gradient"
                                    label="Gradient"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-col>
                        <v-col cols="5">
                          <v-row>
                            <v-col cols="12">
                              <v-switch
                                v-model="ls_settings.headers_active.name"
                                @change="update_setting"
                                hide-details
                                dense
                                class="ma-0"
                                label="Cluster"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12">
                              <v-switch
                                v-model="ls_settings.headers_active.url"
                                @change="update_setting"
                                label="Url"
                                hide-details
                                dense
                                class="ma-0"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12">
                              <v-switch
                                v-model="ls_settings.headers_active.phrase_en"
                                @change="update_setting"
                                label="Translate Phrase"
                                hide-details
                                dense
                                class="ma-0"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12">
                              <v-switch
                                v-model="
                                  ls_settings.headers_active.target_serp_top
                                "
                                @change="update_setting"
                                label="Target Top"
                                hide-details
                                dense
                                class="ma-0"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12">
                              <v-switch
                                v-model="target_top_only"
                                @change="update_table"
                                label="Target Top Only"
                                hide-details
                                dense
                                class="ma-0"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12">
                              <v-switch
                                v-model="dropped_keys"
                                @change="update_table"
                                label="Dropped keys"
                                hide-details
                                dense
                                class="ma-0"
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-menu
                  transition="slide-x-transition"
                  :close-on-content-click="false"
                  :nudge-right="200"
                  content-class="remove-dialog-shadow pa-1"
                  min-width="300"
                  max-width="300"
                >
                  <template v-slot:activator="{ on }">
                    <v-chip
                      label
                      class="flat-chip flat-chip--primary mr-2"
                      v-on="on"
                    >
                      Table filters <v-icon small right>mdi-filter</v-icon>
                    </v-chip>
                  </template>
                  <v-card
                    class="styled-card--default shadow-e1-bordered"
                    rounded="lg"
                  >
                    <v-card-title> Table filters </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="content_checker_category"
                            :items="content_checker_categories"
                            item-text="name"
                            item-value="contain"
                            label="Category"
                            filled
                            hide-details
                            dense
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="cluster_item"
                            :items="clusters_items"
                            item-text="name"
                            item-value="id"
                            label="Cluster"
                            filled
                            dense
                            hide-details
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="priority_item"
                            :items="priority_items"
                            item-text="text"
                            item-value="value"
                            label="Priority"
                            filled
                            hide-details
                            dense
                            clearable
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-switch
                            v-model="target_url_another"
                            class="ma-0"
                            label="Wrong Target URL"
                            hide-details
                            dense
                          ></v-switch>
                        </v-col>
                        <v-col cols="12">
                          <v-switch
                            v-model="compare_date"
                            label="Compare date"
                            hide-details
                            dense
                            class="ma-0"
                          ></v-switch>
                        </v-col>
                        <v-col cols="12">
                          <smart-date-filter
                            filled
                            :model-value="date_range"
                            @update:modelValue="date_range = $event"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="report_type"
                            hide-details
                            dense
                            class="ma-0"
                            label="Report Type"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-btn
                        @click="fetch_data"
                        block
                        style="height: 40px"
                        class="flat-chip flat-chip--primary"
                      >
                        Apply
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-4">
                <v-data-table
                  v-if="!loadings.table"
                  :search="search"
                  :headers="computedTableHeaders"
                  :items="table.filtered_items"
                  :sort-by="['value']"
                  :sort-desc="[true]"
                  multi-sort
                  :custom-sort="_$smartCustomTableSort"
                  :items-per-page="15"
                  :footer-props="{
                    itemsPerPageOptions: [10, 50, 100, 250, 500, -1],
                  }"
                >
                  <template
                    v-for="key in computedTableHeaders?.map((v) => v.value)"
                    #[`header.${key}`]="{ header }"
                  >
                    <template v-if="header.text.match(/\d{4}-\d{2}-\d{2}/)">
                      {{ $moment(header.text).format("DD MMM") }}
                    </template>
                    <template v-else>
                      {{ header.text }}
                    </template>
                  </template>
                  <template #body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.phrase">
                        <td v-if="ls_settings.headers_active.name">
                          {{ item.name }}
                        </td>
                        <td>
                          {{ item.phrase }}
                        </td>
                        <td v-if="ls_settings.headers_active.phrase_en">
                          {{ item.phrase_en }}
                        </td>
                        <td v-if="ls_settings.headers_active.url">
                          {{ item.url.path }}
                        </td>
                        <td class="text-center">
                          {{ item.value }}
                        </td>
                        <td
                          v-if="ls_settings.headers_active.target_serp_top"
                          class="text-center"
                        >
                          {{ item.target_serp_top }}
                        </td>
                        <td class="text-center">
                          <div style="width: 100px; display: block">
                            <VtSparkline
                              :items="get_sparkline(item)"
                              :color="get_sparkline_color(item)"
                            ></VtSparkline>
                            <!--                            <v-sparkline-->
                            <!--                                :value="get_sparkline(item)"-->
                            <!--                                line-width="6"-->
                            <!--                                padding="16"-->
                            <!--                                stroke-linecap="round"-->
                            <!--                                smooth="10"-->
                            <!--                                :color="get_sparkline_color(item)"></v-sparkline>-->
                          </div>
                        </td>
                        <td
                          v-for="n in table.count_days"
                          :key="n"
                          class="text-center"
                        >
                          <div v-if="ls_settings.cell_style === 'vlad'">
                            <v-badge
                              v-if="
                                item['p' + (n - 1)].another !== undefined &&
                                item['p' + (n - 1)].another === true
                              "
                              color="warning"
                              icon="mdi-exclamation-thick"
                              overlap
                              left
                              bordered
                            >
                              <v-badge
                                v-if="get_pos_td(item['p' + (n - 1)])[1] !== ''"
                                :color="get_pos_td(item['p' + (n - 1)])[1]"
                                :content="get_pos_td(item['p' + (n - 1)])[0]"
                                overlap
                                bordered
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      label
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      :color="
                                        get_pos_td_class(item['p' + (n - 1)])
                                      "
                                    >
                                      {{ item["p" + (n - 1)].pos }}
                                    </v-chip>
                                  </template>
                                  <span>{{ item["p" + (n - 1)].url }}</span>
                                </v-tooltip>
                              </v-badge>
                              <v-tooltip v-else bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    label
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="
                                      getPositionColor(item['p' + (n - 1)].pos)
                                    "
                                  >
                                    {{ item["p" + (n - 1)].pos }}
                                  </v-chip>
                                </template>
                                <span>{{ item["p" + (n - 1)].url }}</span>
                              </v-tooltip>
                            </v-badge>
                            <v-badge
                              v-else-if="
                                get_pos_td(item['p' + (n - 1)])[1] !== ''
                              "
                              :color="get_pos_td(item['p' + (n - 1)])[1]"
                              :content="get_pos_td(item['p' + (n - 1)])[0]"
                              overlap
                              bordered
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    label
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="
                                      getPositionColor(item['p' + (n - 1)].pos)
                                    "
                                  >
                                    {{ item["p" + (n - 1)].pos }}
                                  </v-chip>
                                </template>
                                <span>{{ item["p" + (n - 1)].url }}</span>
                              </v-tooltip>
                            </v-badge>
                            <v-chip
                              v-else
                              label
                              small
                              :color="getPositionColor(item['p' + (n - 1)].pos)"
                            >
                              {{ item["p" + (n - 1)].pos }}
                            </v-chip>
                          </div>
                          <div v-else>
                            <span style="position: relative">
                              <v-badge
                                v-if="
                                  item['p' + (n - 1)].another !== undefined &&
                                  item['p' + (n - 1)].another === true
                                "
                                color="warning"
                                icon="mdi-exclamation-thick"
                                overlap
                                bordered
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                      label
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      :color="
                                        get_pos_td_class(item['p' + (n - 1)])
                                      "
                                    >
                                      {{ item["p" + (n - 1)].pos }}
                                    </v-chip>
                                  </template>
                                  <span>{{ item["p" + (n - 1)].url }}</span>
                                </v-tooltip>
                              </v-badge>
                              <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    label
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="
                                      getPositionColor(item['p' + (n - 1)].pos)
                                    "
                                  >
                                    {{ item["p" + (n - 1)].pos }}
                                  </v-chip>
                                </template>
                                <span>{{ item["p" + (n - 1)].url }}</span>
                              </v-tooltip>
                              <sup
                                v-html="get_pos_td(item['p' + (n - 1)])[0]"
                                :style="{
                                  position: 'absolute',
                                  right: '-22px',
                                  top: '-3px',
                                  'font-size': '90%',
                                  color: get_pos_td(item['p' + (n - 1)])[1],
                                }"
                              ></sup>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
                <v-skeleton-loader v-else type="table" style="width: 100%" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            <v-alert
              rounded="lg"
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
              dark
            >
              <v-row align="center">
                <v-col class="grow">
                  To view the page, you must select a project or project
                  category.
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    color="primary"
                    class="text-normal"
                    dark
                    @click="
                      $refs.formRef?.validate(), (isFiltersMenuVisible = true)
                    "
                    >Select project</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import VtSparkline from "@/components/Modules/Sparkline/VtSparkline";
import JsonCSV from "vue-json-csv";
import SmartDateFilter from "@/components/UI/SmartDateFilter.vue";
import PositionsLineChart from "@/components/Serp/PositionsLineChart.vue";
import TableSorting from "@/mixins/TableSorting";
import service from "@/plugins/service";
import { deepClone } from "@/utils/functions";
import Project from "@/mixins/Project";
import UploadFiltersDataMixin from "../../mixins/UploadFiltersDataMixin";
import PageHeader from "../UI/PageHeader.vue";

export default {
  name: "PositionsIndex",
  components: {
    PageHeader,
    PositionsLineChart,
    SmartDateFilter,
    VtSparkline,
    "download-csv": JsonCSV,
  },
  mixins: [TableSorting, Project, UploadFiltersDataMixin],
  data() {
    return {
      ls_settings: {
        gradient_style: "gradient",
        cell_style: "vlad",
        headers_active: {
          name: false,
          url: false,
          target_serp_top: false,
          phrase_en: false,
        },
      },
      serp_collected: 0,
      search: "",
      target_top_only: false,
      dropped_keys: false,
      target_url_another: undefined,
      compare_date: undefined,
      top_20: [],
      top_item: undefined,
      competitor_item: undefined,
      cluster_item: undefined,
      language_item: undefined,
      country_item: undefined,
      project_categories: [],
      redirect_project: undefined,
      subproject: undefined,
      project: undefined,
      priority_item: undefined,
      select_top_filter: null,
      priority_items: [
        { text: "F", value: "f" },
        { text: "S", value: "s" },
        { text: "T", value: "t" },
      ],
      isChartRender: false,
      top_filter_items: [
        { text: "All", value: 0 },
        { text: "1", value: 1 },
        { text: "2-3", value: 3 },
        { text: "4-10", value: 10 },
        { text: "11-30", value: 30 },
        { text: "31-50", value: 50 },
        { text: "51-100", value: 100 },
      ],
      top_items: [
        { text: "top 1", value: 1 },
        { text: "top 3", value: 3 },
        { text: "top 10", value: 10 },
        { text: "top 20", value: 20 },
        { text: "top 50", value: 50 },
        { text: "top 100", value: 100 },
      ],
      competitors_items: [],
      content_checker_categories: [],
      content_checker_category: false,
      clusters_items: [],
      languages_items: [],
      isFiltersMenuVisible: false,
      filtersData: {
        projects: [],
        subprojects: [],
        countries: [],
        project_categories: [],
        redirect_projects: [],
      },
      country_items: [],
      chart_type: "positions",
      date_modal: false,
      lastUsedProjectId: null,
      date_range: [null, null],
      report_type: null, // rise / drop
      options_chart: {
        responsive: true,
        width: 500,
        height: 200,
        padding: 10,
        labels: [],
        multi_lines: false,
      },
      series: null,
      labels: null,
      loadings: {
        loadings: false,
        table: false,
        top20: false,
        multilineChart: false,
        fetch_top_20: false,
        competitors_list: false,
        fetch_chart: false,
        fetch_data: false,
        clusters_list: false,
        languages_list: false,
      },
      table: {
        base_headers: [
          { text: "Phrase", value: "phrase", align: "left" },
          { text: "Volume", value: "value", align: "center" },
          { text: "Trend", value: "trend", align: "center" },
        ],
        headers: [],
        count_days: undefined,
        items: [],
        filtered_items: [],
      },
      table_headers: [],
      export_data_status: false,
      export_data: {
        items: [],
        dataFile: "serp_export.csv",
        labels: {},
        fields: [],
      },
      positions_data: {},
      select_url: undefined,
      subproject_selected: undefined,
    };
  },
  methods: {
    updateDashboard() {
      if (!this.$refs.formRef?.validate())
        return (this.isFiltersMenuVisible = true);

      this.fetch_content_checker_categories();
      this.fetch_top_20();
      this.fetch_chart();
      this.fetch_competitors_list();
      this.fetch_data();
      this.fetch_clusters_list();
    },
    async fetchFilters() {
      try {
        const filters = ["countries", "redirect_projects"];
        const payload = {
          type: "/serp/positions",
          take: filters,
          filter: {
            projectID: this.id_project,
          },
        };

        this.loadings.filters = true;

        const resp = await this.$store.dispatch("global/getFilters", payload);

        if (resp) this.filtersData = { ...this.filtersData, ...resp };
      } catch (e) {
        console.error(e);
      } finally {
        this.loadings.filters = false;
      }
    },
    applyFilter(label) {
      switch (label) {
        case "Top 1":
          this.filter_table({ top: { value: 1 } });
          break;
        case "Top 3":
          this.filter_table({ top: { value: 3 } });
          break;
        case "Top 10":
          this.filter_table({ top: { value: 10 } });
          break;
        case "Top 30":
          this.filter_table({ top: { value: 30 } });
          break;
        case "Top 50":
          this.filter_table({ top: { value: 50 } });
          break;
        case "Top 100":
          this.filter_table({ top: { value: 100 } });
          break;
      }
    },
    exported_csv() {
      this.export_data_status = false;
    },
    isDaysHeader(inputString) {
      return /^p\d+$/.test(inputString);
    },
    findHeaderTextByValue(value) {
      try {
        return this.computedTableHeaders.find((header) => {
          const fixedValue = String(value).replace("p", "p_");
          return header.value === fixedValue;
        }).text;
      } catch (e) {
        console.error(
          `Error while finding header text by value. value:${value}`,
          e
        );
        return null;
      }
    },
    export_csv() {
      try {
        let tmprItems = deepClone(this.table.filtered_items);
        for (let i = 0; i < tmprItems.length; i++) {
          const item = tmprItems[i];

          Object.keys(item).forEach((key) => {
            // transform (p1,p2,p3) to (Sun,Tue,Sat) and remove old (p1,p2,p3) value.
            if (this.isDaysHeader(key)) {
              const alternativeName = this.findHeaderTextByValue(key);
              const extractedValue = (item[key] || {}).pos;

              tmprItems[i][alternativeName] = extractedValue;
              delete item[key];
            }

            if (key === "url") item[key] = (item[key] || {}).full;
          });
        }

        this.export_data.items = tmprItems;
        this.export_data.fields = Object.keys(tmprItems[0]);
      } catch (e) {
        console.error("Error while trying to export csv.", e);
      }
    },
    update_table() {
      let copiedItems = deepClone(this.table.items);

      if (this.target_top_only) {
        copiedItems = copiedItems.filter(
          (item) => item.target_serp_top !== "" && item.target_serp_top !== 0
        );
      }

      if (this.dropped_keys) {
        copiedItems = copiedItems.filter(
          (item) => item.p1?.pos !== 101 && item.p0?.pos === 101
        );
      }

      console.log(copiedItems);

      this.table.filtered_items = copiedItems;
    },
    filter_table(options = {}) {
      if (
        options.path !== undefined &&
        options.path !== null &&
        options.path.length !== 0
      ) {
        return (this.table.filtered_items = this.table.items.filter(
          (item) => item.url.path === options.path
        ));
      }

      if (
        options.cluster !== undefined &&
        options.cluster !== null &&
        options.cluster.length !== 0
      ) {
        return (this.table.filtered_items = this.table.items.filter(
          (item) => item.name === options.cluster
        ));
      }

      if (
        options.subproject !== undefined &&
        options.subproject !== null &&
        options.subproject.length !== 0
      ) {
        return (this.table.filtered_items = this.table.items.filter(
          (item) => item.url.sub_project === options.subproject
        ));
      }

      if (
        options.top !== undefined &&
        options.top !== null &&
        options.top.length !== 0
      ) {
        let pos_range = {
          0: [1, 100],
          1: [1, 1],
          3: [2, 3],
          10: [4, 10],
          30: [11, 30],
          50: [21, 50],
          100: [51, 100],
        };

        return (this.table.filtered_items = this.table.items.filter((item) => {
          return (
            options.top.filter(
              (top) =>
                item.p0.pos >= pos_range[top.value][0] &&
                item.p0.pos <= pos_range[top.value][1]
            ).length > 0
          );
        }));
      }

      return (this.table.filtered_items = this.table.items);
    },
    validateProjectId() {
      if (this.id_project === null || this.id_project === undefined) {
        this.$message.notification({
          title: "Please select project",
          text: "Project not selected. Select a project to continue the action.",
          type: "warning",
          duration: 3500,
        });
        return true;
      }
      return false;
    },
    async fetch_top_20() {
      try {
        if (this.validateProjectId()) return;

        const url = `/serp/positions/${this.id_project}`;

        const config = {
          params: {
            type: "get_top_20",
            lang: this.language_item,
            country: this.country_item,
            subproject: this.subproject,
            redirect_project: this.redirect_project,
            project_categories: this.project_categories,
          },
        };

        this.loadings.top20 = true;

        const response = await service.get(url, true, config);

        if (response) this.top_20 = response.data;
      } catch (e) {
        console.error("Error while fetching top 20 data.", e);
      } finally {
        this.loadings.top20 = false;
      }
    },
    async fetch_content_checker_categories() {
      try {
        if (this.validateProjectId()) return;

        const url = `/project/${this.id_project}`;

        const response = await service.get(url);

        if (
          response &&
          response?.data?.project_settings?.content_checker.categories
        ) {
          this.content_checker_categories =
            response.data.project_settings.content_checker.categories ?? null;
        }
      } catch (e) {
        console.error("Error while fetching content checker categories.", e);
      }
    },
    async fetch_competitors_list() {
      try {
        if (this.validateProjectId()) return;

        const url = `/serp/positions/${this.id_project}`;

        const config = {
          params: {
            type: "get_competitors_list",
            lang: this.language_item,
            country: this.country_item,
            subproject: this.subproject,
            redirect_project: this.redirect_project,
            project_categories: this.project_categories,
          },
        };

        const response = await service.get(url, true, config);

        if (response) this.competitors_items = response.data;
      } catch (e) {
        console.error("Error while fetching competitors list.", e);
      }
    },
    async fetch_clusters_list() {
      try {
        const url = `/serp/positions/${this.id_project}`;

        const config = {
          params: {
            type: "get_clusters_list",
            lang: this.language_item,
            country: this.country_item,
            subproject: this.subproject,
            redirect_project: this.redirect_project,
            project_categories: this.project_categories,
          },
        };

        const response = await service.get(url, true, config);

        if (response) {
          this.clusters_items = response.data;
        }
      } catch (e) {
        console.error("Error while fetching clusters list.", e);
      }
    },
    async fetch_languages_list(callback) {
      try {
        const url = `/serp/positions/${this.id_project}`;

        const config = {
          params: {
            type: "get_languages_list",
          },
        };

        const response = await service.get(url, true, config);

        if (response) {
          this.language_item = (response.data[0] && response.data[0].id) || 0;
          this.languages_items = response.data || [];

          if (callback) callback();
        }
      } catch (e) {
        console.error("Error while fetching language list");
      }
    },
    async fetch_chart() {
      try {
        if (this.validateProjectId()) return;

        const url = `/serp/positions/${this.id_project}`;

        this.options_chart.multi_lines = false;

        const config = {
          params: {
            type:
              this.chart_type === "positions"
                ? "chart_keywords"
                : "chart_traffic_visibility",
            lang: this.language_item,
            country: this.country_item,
            subproject: this.subproject,
            redirect_project: this.redirect_project,
            project_categories: this.project_categories,
            chart_type: this.chart_type,
            competitor: this.competitor_item,
            top: this.top_item,
            report_type: this.report_type,
            date_from: this.date_range[0],
            date_to: this.date_range[1],
          },
        };

        this.loadings.multilineChart = true;

        const response = await service.get(url, true, config);

        if (response) {
          this.series = response.data.rows ?? [];
          this.labels = response.data.labels ?? [];
        }
      } catch (e) {
        console.error("Error while fetching chart data.", e);
      } finally {
        this.loadings.multilineChart = false;
      }
    },
    async fetch_data() {
      try {
        if (this.validateProjectId()) return;

        const url = `/serp/positions/${this.id_project}`;

        const config = {
          params: {
            type: "positions",
            lang: this.language_item,
            country: this.country_item,
            subproject: this.subproject,
            redirect_project: this.redirect_project,
            project_categories: this.project_categories,
            cluster: this.cluster_item,
            filter_base: 0,
            date_from: this.date_range[0],
            date_to: this.date_range[1],
            report_type: this.report_type,
            offset_dates: 1,
            compare_dates: this.compare_date,
            target_url_another: this.target_url_another,
            content_checker_category: this.content_checker_category,
            priority: this.priority_item,
          },
        };

        this.loadings.table = true;

        const response = await service.get(url, true, config);

        if (response) {
          this.table.headers = response.data.headers ?? [];
          this.table.items = response.data.body ?? [];
          this.table.filtered_items = response.data.body ?? [];
          this.table.count_days = response.data.range.length;
          this.serp_collected = response.data.serp_collected;
        }
      } catch (e) {
        console.error("Error while fetching main data.", e);
      } finally {
        this.loadings.table = false;
        this.filter_table({
          path: this.select_url ? this.select_url.value : null,
        });
      }
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
    get_sparkline(item) {
      let res = [];

      for (let i = this.table.count_days; i > 0; i--) {
        // for (let i = 1; i <= this.table.count_days; i++) {
        let pos = item["p" + (i - 1)].pos;

        if (pos !== undefined) {
          res.push(pos);
        }
      }

      return res.reverse();
    },
    get_sparkline_color(item) {
      let res = 0;

      for (let i = 1; i <= this.table.count_days; i++) {
        if (item["p" + (i - 1)].diff !== undefined) {
          res += item["p" + (i - 1)].diff;
        }
      }

      if (res === 0) {
        return "grey";
      }

      return res > 0 ? "#4caf50" : "#f44336";
    },
    get_pos_td_class(item) {
      let css = {
        "background-color": "",
      };
      // let pos = 101
      let tableGradient = this.ls_settings.gradient_style === "date_by_date";

      if (item !== null && item !== undefined) {
        // if (item.another !== undefined && cellData.another !== null && cellData.another == true) {
        //   $(td).addClass('td-another-url');
        // }
        if (tableGradient === true) {
          if (item.diff !== undefined) {
            let diff = item.diff;
            let color = "";

            if (diff > 0) {
              color = "#82b54b";
            } else if (diff < 0) {
              color = "#ffb119";
            }
            css["background-color"] = color;
          }
        } else if (item.pos !== undefined && item.pos !== null) {
          css["background-color"] = this.get_position_color(item.pos);
        }
      }

      return css["background-color"];
      // return {}
    },
    get_pos_td(item) {
      if (item !== undefined && item.pos !== undefined) {
        var sup = "";
        let diff = 0;
        let diff_color = "";
        // var classSup = '';

        if (item.diff !== undefined) {
          diff = item.diff;
          var diffEl = diff;

          if (diff > 0) {
            // classSup = 'text-black';
            diff_color = "#7fca82";
            // diff_color = 'grey'
            diffEl = "+" + diff;
            // diffEl = '<i class="v-icon notranslate mdi mdi-arrow-up-thick theme--light success--text" style="font-size: 14px;"></i>' + diff;
          } else if (diffEl < 0) {
            diff_color = "#ff7f7f";
            // diff_color = 'grey'
            // classSup = 'text-black';
            // diffEl = '<i class="v-icon notranslate mdi mdi-arrow-down-thick theme--light error--text" style="font-size: 14px;"></i>' + Math.abs(diff);
          } else {
            // classSup = 'text-black';
            diffEl = "";
          }

          // sup = '<sup class="' + classSup + '" style="margin-left: 5px;">' + diffEl + '</sup>';
          sup = diffEl;
        }

        // return '<a href="" class="text-black" data-toggle="tooltip" data-position="bottom" title="">' + parseInt(item.pos) + sup + '</a>';
        // return parseInt(item.pos) + sup;
        return [sup, diff_color, diff_color];
      }

      return "";

      // $(td).css(css);
      // $(td).attr('data-order', pos);
    },

    getPositionColor(value) {
      if (value === null || value === "") return "";

      if (value === "-") {
        // DARKGREY
        return this.rgba(170, 170, 170);
      }
      if (value === 101) {
        // LIGHTGREY
        return this.rgba(200, 200, 200);
      }

      if (isNaN(value)) return "";

      value = parseInt(value);

      if (between(value, 1, 10)) {
        // GREEN
        const r = Math.round(value * 6.3);
        const g = Math.round(130 + value * 12.5);
        const b = Math.round(value * 6.3);
        return this.rgba(r, g, b, 0.5);
      }
      if (between(value, 11, 30)) {
        // YELLOW
        const r = 255;
        const g = Math.round(250 - value * 1.5);
        const b = Math.round(255 - value * 15);
        return this.rgba(r, g, b);
      }
      if (between(value, 31, 50)) {
        // ORANGE
        const r = 255;
        const g = Math.round(250 - value * 3.4);
        const b = Math.round(140 - value * 2.3);
        return this.rgba(r, g, b);
      }
      if (between(value, 51, 100)) {
        // RED
        const r = 255;
        const g = Math.round(120 - value * 1.2);
        const b = Math.round(120 - value * 1.2);
        return this.rgba(r, g, b);
      }

      return this.rgba(152, 152, 152); // if nothing else

      function between(value, min, max) {
        const x = value;
        return (x - min) * (x - max) <= 0;
      }
    },
    rgba(r, g, b, a = 0.5) {
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    get_position_color(value) {
      return this.getPositionColor(value);
    },
    return_color(val1, val2) {
      var percent = val1 / val2;
      return Math.round(200 * percent);
    },
    update_setting() {
      this.$store.dispatch("update_ls_serp_setting", this.ls_settings);
    },
  },
  computed: {
    computedProjectAndCategoriesRules() {
      let rules = [];

      if (!this.project) rules.push("Select project or Project category.");
      if (!this.project_categories.length)
        rules.push("Select project or Project category.");

      if (this.project || !!this.project_categories.length) rules = [];

      return rules;
    },
    computedTableHeaders() {
      let headers = [];

      for (let i in this.table.headers) {
        if (
          this.ls_settings.headers_active[this.table.headers[i].value] ===
            undefined ||
          this.ls_settings.headers_active[this.table.headers[i].value]
        ) {
          headers.push(this.table.headers[i]);
        }
      }

      return headers;
    },
    urls() {
      return this.table.items.map((item) => {
        return {
          value: item.url.path,
          text: item.url.path,
        };
      });
    },
    stats() {
      let self = this;

      if (this.table.filtered_items.length === 0) {
        return {};
      }

      let actual = "p0";
      let prev = "p" + (self.table.count_days - 1);

      return {
        traffic: {
          actual: self.table.filtered_items.reduce(
            (a, c) =>
              a +
              (c[actual].traffic === undefined
                ? 0
                : parseInt(c[actual].traffic)),
            0
          ),
          prev: self.table.filtered_items.reduce(
            (a, c) =>
              a +
              (c[prev].traffic === undefined ? 0 : parseInt(c[prev].traffic)),
            0
          ),
        },
        positions: {
          p1: {
            actual: self.table.filtered_items.filter((c) => c[actual].pos === 1)
              .length,
            prev: self.table.filtered_items.filter((c) => c[prev].pos === 1)
              .length,
          },
          p3: {
            actual: self.table.filtered_items.filter((c) => c[actual].pos <= 3)
              .length,
            prev: self.table.filtered_items.filter((c) => c[prev].pos <= 3)
              .length,
          },
          p10: {
            actual: self.table.filtered_items.filter((c) => c[actual].pos <= 10)
              .length,
            prev: self.table.filtered_items.filter((c) => c[prev].pos <= 10)
              .length,
          },
          p20: {
            actual: self.table.filtered_items.filter((c) => c[actual].pos <= 20)
              .length,
            prev: self.table.filtered_items.filter((c) => c[prev].pos <= 20)
              .length,
          },
          p50: {
            actual: self.table.filtered_items.filter((c) => c[actual].pos <= 50)
              .length,
            prev: self.table.filtered_items.filter((c) => c[prev].pos <= 50)
              .length,
          },
          p100: {
            actual: self.table.filtered_items.filter(
              (c) => c[actual].pos <= 100
            ).length,
            prev: self.table.filtered_items.filter((c) => c[prev].pos <= 100)
              .length,
          },
        },
        total_move: {
          growing: self.table.filtered_items.filter(
            (c) => c[actual] !== undefined && c[actual].diff > 0
          ).length,
          falling: self.table.filtered_items.filter(
            (c) => c[actual] !== undefined && c[actual].diff < 0
          ).length,
          avg: {
            actual: parseInt(
              self.table.filtered_items.reduce((a, c) => {
                if (c[actual].pos === undefined) {
                  return a + 101;
                }
                return (
                  a +
                  (parseInt(c[actual].pos) === 101
                    ? 101
                    : parseInt(c[actual].pos))
                );
              }, 0) / self.table.filtered_items.length
            ),
            prev: parseInt(
              self.table.filtered_items.reduce((a, c) => {
                if (c[prev].pos === undefined) {
                  return a + 101;
                }
                return (
                  a +
                  (parseInt(c[prev].pos) === 101 ? 101 : parseInt(c[prev].pos))
                );
              }, 0) / self.table.filtered_items.length
            ),
          },
          move:
            parseInt(
              self.table.filtered_items.reduce((a, c) => {
                if (c[prev].pos === undefined) {
                  return a + 101;
                }
                return (
                  a +
                  (parseInt(c[prev].pos) === 101 ? 101 : parseInt(c[prev].pos))
                );
              }, 0)
            ) -
            parseInt(
              self.table.filtered_items.reduce((a, c) => {
                if (c[actual].pos === undefined) {
                  return a + 101;
                }
                return (
                  a +
                  (parseInt(c[actual].pos) === 101
                    ? 101
                    : parseInt(c[actual].pos))
                );
              }, 0)
            ),
        },
      };
    },
    top_tiles() {
      if (this.table.filtered_items.length === 0) {
        return [];
      }

      let pretty = function (actual, prev, item, reverse = false) {
        let delta = prev - actual;

        if (reverse) {
          delta = actual - prev;
        }

        if (delta > 0) {
          item.sup = "+" + delta;
          item.icon = "mdi-chevron-up";
          item.icon_color = "success";
        } else if (delta < 0) {
          item.sup = delta;
          item.icon = "mdi-chevron-down";
          item.icon_color = "error";
        } else {
          item.sup = "";
          item.icon = "mdi-minus";
          item.icon_color = "primary";
        }

        item.text = actual;

        return item;
      };

      return [
        {
          bg_color: this.stats.total_move.move > 0 ? "success" : "warning",
          icon: "mdi-chart-timeline-variant",
          icon_color: "white",
          text: this.stats.total_move.move,
          text_color: "",
          label: "Total Move",
          size: 2,
        },
        pretty(
          this.stats.traffic.actual,
          this.stats.traffic.prev,
          {
            icon: "",
            icon_color: "",
            text: "",
            text_color: "",
            label: "Traffic",
            size: 1,
          },
          true
        ),
        pretty(
          this.stats.positions.p1.actual,
          this.stats.positions.p1.prev,
          {
            icon: "",
            icon_color: "",
            text: "",
            text_color: "",
            label: "Top 1",
            size: 1,
          },
          true
        ),
        pretty(
          this.stats.positions.p3.actual,
          this.stats.positions.p3.prev,
          {
            icon: "",
            icon_color: "",
            text: "",
            text_color: "",
            label: "Top 3",
            size: 1,
          },
          true
        ),
        pretty(
          this.stats.positions.p10.actual,
          this.stats.positions.p10.prev,
          {
            icon: "",
            icon_color: "",
            text: "",
            text_color: "",
            label: "Top 10",
            size: 1,
          },
          true
        ),
        pretty(
          this.stats.positions.p20.actual,
          this.stats.positions.p20.prev,
          {
            icon: "",
            icon_color: "",
            text: "",
            text_color: "",
            label: "Top 20",
            size: 1,
          },
          true
        ),
        pretty(
          this.stats.positions.p50.actual,
          this.stats.positions.p50.prev,
          {
            icon: "",
            icon_color: "",
            text: "",
            text_color: "",
            label: "Top 50",
            size: 1,
          },
          true
        ),
        pretty(
          this.stats.positions.p100.actual,
          this.stats.positions.p100.prev,
          {
            icon: "",
            icon_color: "",
            text: "",
            text_color: "",
            label: "Top 100",
            size: 1,
          },
          true
        ),
        {
          icon: "mdi-chevron-up",
          icon_color: "success",
          text: this.stats.total_move.growing,
          text_color: "",
          label: "Growing",
          size: 1,
        },
        {
          icon: "mdi-chevron-down",
          icon_color: "error",
          text: this.stats.total_move.falling,
          text_color: "",
          label: "Falling",
          size: 1,
        },
        pretty(
          this.stats.total_move.avg.actual,
          this.stats.total_move.avg.prev,
          {
            icon: "",
            icon_color: "",
            text: "",
            text_color: "",
            label: "AVG",
            size: 1,
          }
        ),
      ];
    },
    chart_apply_btn() {
      if (
        (this.chart_type === "competitors_top" &&
          this.competitor_item === undefined) ||
        (this.chart_type === "competitors_all" && this.top_item === undefined)
      ) {
        return true;
      }
      return false;
    },
    isset_active_project() {
      return (
        (this.id_project !== null && this.id_project !== undefined) ||
        !!this.project_categories?.length
      );
    },
    id_project() {
      if (!this.project && !!this.project_categories?.length) return 0;

      return this.project;
    },
  },
  async mounted() {
    if (this.$route.query.project_id) {
      this.project = Number(this.$route.query.project_id);
    }

    if (this.id_project) this.fetchFilters();
    this.uploadFiltersData(["project_categories", "projects", "subprojects"], {
      project: this.id_project,
    });

    if (this.$store.state.ls_serp_position.settings !== null) {
      this.ls_settings = this.$store.state.ls_serp_position.settings;
    }

    let date_from = "";
    let date_to = "";

    let d = new Date();

    date_to = d.toJSON().split("T")[0];

    d.setDate(d.getDate() - 18);

    date_from = d.toJSON().split("T")[0];

    this.date_range = [date_from, date_to];

    this.$watch("project", () => {
      this.subproject = null;
      this.subproject_selected = null;
      const projectId = this.id_project;

      if (!projectId || projectId === this.lastUsedProjectId) return;

      this.uploadFiltersData(["subprojects"], {
        project: this.id_project,
      });

      this.lastUsedProjectId = projectId;
      this.fetchFilters();
      this.fetch_languages_list(() => {
        this.fetch_content_checker_categories();
        this.fetch_top_20();
        this.fetch_chart();
        this.fetch_competitors_list();
        this.fetch_data();
        this.fetch_clusters_list();
      });
    });
  },
};
</script>

<style scoped></style>
