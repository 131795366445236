<template>
  <v-dialog
    v-model="dialog"
    content-class="my-shadow--e2"
    scrollable
    width="420"
  >
    <v-card flat outlined rounded="lg">
      <v-card-title class="text-body-2">
        Edit donor
        <v-spacer />
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-textarea
          v-model="formValue.done_url"
          dense
          outlined
          label="Donor"
          hide-details
        ></v-textarea>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn @click="dialog = false" large class="text-normal px-6"
          >Close</v-btn
        >
        <v-btn
          @click="handleSave"
          large
          class="text-normal px-6"
          color="success"
          :loading="loadings.save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Dialog from "../../../../mixins/Dialog";

export default {
  mixins: [Dialog],

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    loadings: {
      save: false,
    },
  }),

  computed: {
    formValue: {
      get() {
        return this.form;
      },
      set(newValue) {
        this.$emit("update:form", newValue);
      },
    },
  },

  methods: {
    async handleSave() {
      try {
        this.loadings.save = true;

        const resp = await this.$store.dispatch(
          "ltt-deleted-links/updateDonorUrl",
          this.formValue
        );

        if (resp === true) {
          this.$message.notification({
            title: "Saving success.",
            text: "Donor successfully updated.",
            duration: 3500,
            type: "success",
          });
          this.$emit("newDonor", this.formValue.done_url);
        } else {
          this.$message.notification({
            title: "Saving error.",
            text: "Something wrong.",
            duration: 3500,
            type: "error",
          });
        }

        this.formValue = {
          done_url: null,
          link_id: null,
        };
        this.dialog = false;
      } catch (e) {
        console.error("Error while editing donor.", e);
      } finally {
        this.loadings.save = false;
      }
    },
  },
};
</script>
